// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_BaseTable = require("../AdminUi.AdminClient.Components.BaseTable/index.js");
var AdminUi_AdminClient_Components_Table = require("../AdminUi.AdminClient.Components.Table/index.js");
var AdminUi_AdminClient_Components_Table_TableCell = require("../AdminUi.AdminClient.Components.Table.TableCell/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var AdminUi_AdminClient_Components_Utils = require("../AdminUi.AdminClient.Components.Utils/index.js");
var AdminUi_AdminClient_Components_ZText = require("../AdminUi.AdminClient.Components.ZText/index.js");
var AdminUi_AdminClient_Hooks_UseTable = require("../AdminUi.AdminClient.Hooks.UseTable/index.js");
var AdminUi_Antd_Button = require("../AdminUi.Antd.Button/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Reader_Class = require("../Control.Monad.Reader.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Effect = require("../Effect/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Class_Console = require("../Effect.Class.Console/index.js");
var Effect_Uncurried = require("../Effect.Uncurried/index.js");
var Jss_ReactJss = require("../Jss.ReactJss/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var useStyles = Jss_ReactJss.createUseStyles_()()({
    cellSubText: Jss_ReactJss.style({
        fontSize: "12px"
    }),
    viewAllButton: Jss_ReactJss.style({
        textAlign: "center",
        paddingTop: "20px"
    })
});
var mkContentTable = function (dictEq) {
    return function (dictMonadAsk) {
        return function (dictMonadEffect) {
            return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(Control_Monad_Reader_Class.ask(dictMonadAsk))(function (v) {
                return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Components_Table_TableCell.mkTableCell(dictMonadAsk)(dictMonadEffect))(function (tableCell) {
                    return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Components_ZText.mkZText(dictMonadAsk)(dictMonadEffect))(function (zText) {
                        return AdminUi_AdminClient_Components_Utils.comp_(dictMonadEffect)()()()("ContentTable")(function (v1) {
                            var next = AdminUi_AdminClient_Components_Table.getNext(v1.table);
                            var limit = AdminUi_AdminClient_Components_Table.getLimit(v1.table);
                            var initialRowsToDisplay = Data_Array.take(limit)(AdminUi_AdminClient_Components_Table.getRows(v1.table));
                            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useStyles({}))(function (css) {
                                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useContext(v.router))(function (v2) {
                                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(AdminUi_AdminClient_Hooks_UseTable.useTable({
                                        table: v1.table
                                    }))(function (v3) {
                                        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(initialRowsToDisplay))(function (v4) {
                                            return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useEffect(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
                                                return "route";
                                            }))(Data_Maybe.eqMaybe(dictEq)))()(new Data_Symbol.IsSymbol(function () {
                                                return "cells";
                                            }))(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
                                                return "text";
                                            }))(AdminUi_AdminClient_Components_Types.eqZText))()(new Data_Symbol.IsSymbol(function () {
                                                return "subText";
                                            }))(Data_Maybe.eqMaybe(AdminUi_AdminClient_Components_Types.eqZText))))))))(v3.rows)((function () {
                                                var updateRowsToDisplay = Data_Array.take(Data_Array.length(v4.value0))(v3.rows);
                                                return function __do() {
                                                    v4.value1(Data_Function["const"](updateRowsToDisplay))();
                                                    return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                                                };
                                            })()))(function () {
                                                var showViewAll = Data_Array.length(v3.rows) > limit || Data_Maybe.isJust(next);
                                                var renderRow = function (row) {
                                                    return Data_Functor.mapFlipped(Data_Functor.functorArray)(row.cells)(function (cell) {
                                                        return React_Basic.element(tableCell)({
                                                            cell: cell
                                                        });
                                                    });
                                                };
                                                var onRowClick = function (i) {
                                                    var v5 = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v6) {
                                                        return v6.route;
                                                    })(Data_Array.index(v4.value0)(i));
                                                    if (v5 instanceof Data_Maybe.Just) {
                                                        return v2.push(v5.value0);
                                                    };
                                                    if (v5 instanceof Data_Maybe.Nothing) {
                                                        return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                                                    };
                                                    throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Content.ContentTable (line 63, column 22 - line 65, column 26): " + [ v5.constructor.name ]);
                                                };
                                                return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.div()({
                                                    children: [ AdminUi_AdminClient_Components_BaseTable.baseTable()({
                                                        headers: v3.headers,
                                                        rows: Data_Functor.map(Data_Functor.functorArray)(renderRow)(v4.value0),
                                                        onRowClick: Effect_Uncurried.mkEffectFn1(onRowClick)
                                                    }), Data_Monoid.guard(React_Basic.monoidJSX)(showViewAll)(React_Basic_DOM_Generated.div()({
                                                        className: css.viewAllButton,
                                                        children: [ AdminUi_Antd_Button.button()({
                                                            onClick: React_Basic_Events.handler_(Effect_Class_Console.warnShow(Effect_Class.monadEffectEffect)(Data_Show.showString)("Not yet implemented")),
                                                            children: React_Basic_DOM.text("View All")
                                                        }) ]
                                                    })) ]
                                                }));
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        };
    };
};
module.exports = {
    mkContentTable: mkContentTable,
    useStyles: useStyles
};
