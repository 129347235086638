// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_AuthApp = require("../AdminUi.AdminClient.AuthApp/index.js");
var AdminUi_AdminClient_Components_Modal = require("../AdminUi.AdminClient.Components.Modal/index.js");
var AdminUi_AdminClient_Contexts_Modal = require("../AdminUi.AdminClient.Contexts.Modal/index.js");
var AdminUi_AdminClient_Contexts_Router = require("../AdminUi.AdminClient.Contexts.Router/index.js");
var AdminUi_Antd_Alert = require("../AdminUi.Antd.Alert/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect = require("../Effect/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Routing_PushState = require("../Routing.PushState/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var mkApp = function (dictEq) {
    return function (unauthD) {
        return function (pushI) {
            return function (mkAppComp) {
                return function __do() {
                    var routerContext = AdminUi_AdminClient_Contexts_Router.mkRouterContext();
                    var modalContext = AdminUi_AdminClient_Contexts_Modal.mkModalContext();
                    var contexts = {
                        router: routerContext,
                        modal: modalContext
                    };
                    var modalComp = Control_Monad_Reader_Trans.runReaderT(AdminUi_AdminClient_Components_Modal.mkModal(dictEq)(Control_Monad_Reader_Trans.monadAskReaderT(Effect.monadEffect))(Control_Monad_Reader_Trans.monadEffectReader(Effect_Class.monadEffectEffect)))(contexts)();
                    var comp = Control_Monad_Reader_Trans.runReaderT(mkAppComp)(contexts)();
                    var v = pushI.locationState();
                    return React_Basic_Hooks.component()()()("App")(function (v1) {
                        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(AdminUi_AdminClient_Contexts_Router.useRouter(unauthD)(pushI)(v.path))(function (v2) {
                            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(AdminUi_AdminClient_Contexts_Modal.useModal)(function (v3) {
                                return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic.provider(routerContext)({
                                    push: v2.push,
                                    goBack: v2.goBack
                                })([ React_Basic.provider(modalContext)({
                                    showModal: v3.showModal,
                                    hideModal: v3.hideModal
                                })([ React_Basic.element(modalComp)({
                                    modal: v3.modal,
                                    visible: v3.visible,
                                    hideModal: v3.hideModal
                                }), (function () {
                                    if (v2.currRoute instanceof Data_Maybe.Just) {
                                        return React_Basic.element(comp)({
                                            route: v2.currRoute.value0
                                        });
                                    };
                                    if (v2.currRoute instanceof Data_Maybe.Nothing) {
                                        return React_Basic_DOM.text("not found");
                                    };
                                    throw new Error("Failed pattern match at AdminUi.AdminClient (line 65, column 17 - line 67, column 48): " + [ v2.currRoute.constructor.name ]);
                                })() ]) ]));
                            });
                        });
                    })();
                };
            };
        };
    };
};
var mkAdminClient = function (dictEq) {
    return function (dictEq1) {
        return function (v) {
            return function __do() {
                var $$interface = Routing_PushState.makeInterface();
                var unauthApp = mkApp(dictEq1)(v.unauth.routeDuplex)($$interface)(v.unauth.reactComponent)();
                var authApp = mkApp(dictEq)(v.auth.routeDuplex)($$interface)(AdminUi_AdminClient_AuthApp.mkAuthApp(dictEq)(v.apiClient)(v.auth))();
                return React_Basic_Hooks.component()()()("AdminClient")(function (v1) {
                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(new Data_Maybe.Just({})))(function (v2) {
                        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(AdminUi_Antd_Alert.alertErrorBoundary()({
                            children: [ (function () {
                                if (v2.value0 instanceof Data_Maybe.Just) {
                                    return React_Basic.element(authApp)({});
                                };
                                if (v2.value0 instanceof Data_Maybe.Nothing) {
                                    return React_Basic.element(unauthApp)({});
                                };
                                throw new Error("Failed pattern match at AdminUi.AdminClient (line 34, column 15 - line 36, column 48): " + [ v2.value0.constructor.name ]);
                            })() ]
                        }));
                    });
                })();
            };
        };
    };
};
module.exports = {
    mkAdminClient: mkAdminClient,
    mkApp: mkApp
};
