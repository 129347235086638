// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_Content = require("../AdminUi.AdminClient.Components.Content/index.js");
var AdminUi_AdminClient_Components_Icons_AntdIcons = require("../AdminUi.AdminClient.Components.Icons.AntdIcons/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var AdminUi_AdminClient_Components_Utils = require("../AdminUi.AdminClient.Components.Utils/index.js");
var AdminUi_AdminClient_Hooks_UseAffUi = require("../AdminUi.AdminClient.Hooks.UseAffUi/index.js");
var AdminUi_Antd_Button = require("../AdminUi.Antd.Button/index.js");
var AdminUi_Antd_PropLits = require("../AdminUi.Antd.PropLits/index.js");
var AdminUi_Antd_Result = require("../AdminUi.Antd.Result/index.js");
var AdminUi_Antd_Skeleton = require("../AdminUi.Antd.Skeleton/index.js");
var AdminUi_Antd_Tabs = require("../AdminUi.Antd.Tabs/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Show = require("../Data.Show/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var Untagged_Coercible = require("../Untagged.Coercible/index.js");
var skeleton = AdminUi_Antd_Skeleton.skeleton()({
    paragraph: Untagged_Coercible.coerce()({
        rows: 9
    }),
    active: true,
    loading: true,
    title: false
});
var retryUi = function (retryF) {
    return AdminUi_Antd_Result.result()({
        title: React_Basic_DOM.text("Please check your network."),
        icon: AdminUi_AdminClient_Components_Icons_AntdIcons.mkAntdIcon()(AdminUi_AdminClient_Components_Icons_AntdIcons.CloudServerOutlined.value)({}),
        extra: AdminUi_Antd_Button.button()({
            type: AdminUi_Antd_PropLits.primary,
            icon: AdminUi_AdminClient_Components_Icons_AntdIcons.mkAntdIcon()(AdminUi_AdminClient_Components_Icons_AntdIcons.ReloadOutlined.value)({}),
            onClick: React_Basic_Events.handler_(retryF),
            children: React_Basic_DOM.text("Retry")
        })
    });
};
var mkSingleTab = function (dictEq) {
    return function (dictMonadAsk) {
        return function (dictMonadEffect) {
            return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Components_Content.mkContent(dictEq)(dictMonadAsk)(dictMonadEffect))(function (contentComp) {
                return AdminUi_AdminClient_Components_Utils.comp_(dictMonadEffect)()()()("STSingle")(function (v) {
                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(AdminUi_AdminClient_Hooks_UseAffUi.useAffUi({
                        skeleton: skeleton,
                        retryUi: retryUi,
                        aff: v.singleTab,
                        success: function (content) {
                            return React_Basic.element(contentComp)({
                                surface: AdminUi_AdminClient_Components_Types.SPage.value,
                                content: content
                            });
                        }
                    }))(function (jsx) {
                        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(jsx);
                    });
                });
            });
        };
    };
};
var mkMultipleTabs = function (dictEq) {
    return function (dictMonadAsk) {
        return function (dictMonadEffect) {
            return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Components_Content.mkContent(dictEq)(dictMonadAsk)(dictMonadEffect))(function (contentComp) {
                return AdminUi_AdminClient_Components_Utils.comp_(dictMonadEffect)()()()("STMultiple")(function (v) {
                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(AdminUi_AdminClient_Hooks_UseAffUi.useAffUi({
                        skeleton: skeleton,
                        retryUi: retryUi,
                        aff: v.multipleTabs,
                        success: function (tabs) {
                            return AdminUi_Antd_Tabs.tabs()({
                                children: Data_Array.mapWithIndex(function (i) {
                                    return function (tab) {
                                        return AdminUi_Antd_Tabs.tabPane()({
                                            key: Data_Show.show(Data_Show.showInt)(i),
                                            tab: React_Basic_DOM.text(tab.label),
                                            children: [ React_Basic.element(contentComp)({
                                                surface: AdminUi_AdminClient_Components_Types.SPage.value,
                                                content: tab.content
                                            }) ]
                                        });
                                    };
                                })(tabs)
                            });
                        }
                    }))(function (jsx) {
                        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(jsx);
                    });
                });
            });
        };
    };
};
var mkSectionTabs = function (dictEq) {
    return function (dictMonadAsk) {
        return function (dictMonadEffect) {
            return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(mkSingleTab(dictEq)(dictMonadAsk)(dictMonadEffect))(function (singleTabC) {
                return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(mkMultipleTabs(dictEq)(dictMonadAsk)(dictMonadEffect))(function (multipleTabsC) {
                    return AdminUi_AdminClient_Components_Utils.comp_(dictMonadEffect)()()()("SectionTabs")(function (v) {
                        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))((function () {
                            if (v.tabs instanceof AdminUi_AdminClient_Components_Types.STSingle) {
                                return React_Basic.element(singleTabC)({
                                    singleTab: v.tabs.value0
                                });
                            };
                            if (v.tabs instanceof AdminUi_AdminClient_Components_Types.STMultiple) {
                                return React_Basic.element(multipleTabsC)({
                                    multipleTabs: v.tabs.value0
                                });
                            };
                            throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Section.SectionTabs (line 39, column 9 - line 41, column 76): " + [ v.tabs.constructor.name ]);
                        })());
                    });
                });
            });
        };
    };
};
module.exports = {
    mkSectionTabs: mkSectionTabs,
    mkMultipleTabs: mkMultipleTabs,
    mkSingleTab: mkSingleTab,
    skeleton: skeleton,
    retryUi: retryUi
};
