// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_AppAction = require("../AdminUi.AdminClient.Components.AppAction/index.js");
var AdminUi_AdminClient_Components_Content = require("../AdminUi.AdminClient.Components.Content/index.js");
var AdminUi_AdminClient_Components_Content_ContentForm = require("../AdminUi.AdminClient.Components.Content.ContentForm/index.js");
var AdminUi_AdminClient_Components_Details = require("../AdminUi.AdminClient.Components.Details/index.js");
var AdminUi_AdminClient_Components_Modal = require("../AdminUi.AdminClient.Components.Modal/index.js");
var AdminUi_AdminClient_Components_Page = require("../AdminUi.AdminClient.Components.Page/index.js");
var AdminUi_AdminClient_Components_Title = require("../AdminUi.AdminClient.Components.Title/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var AdminUi_AdminClient_Components_ZText = require("../AdminUi.AdminClient.Components.ZText/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Data_Generic_Rep_Bounded = require("../Data.Generic.Rep.Bounded/index.js");
var Data_Generic_Rep_Enum = require("../Data.Generic.Rep.Enum/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var SiloDashboard_Common_Mock_MockData = require("../SiloDashboard.Common.Mock.MockData/index.js");
var branchesAdd = function (brandName) {
    var title = AdminUi_AdminClient_Components_Title.title_("Add Single Branch");
    var form = (function () {
        var confirm = AdminUi_AdminClient_Components_Modal.confirmModal("Confirm")(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.text_("Create ZAP Angus Steakhouse "))(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.bold("Makati"))(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.text_(" branch? "))(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.text_("Your remaining branch limit from 19 branches will reduce to "))(AdminUi_AdminClient_Components_ZText.bold("15 branches left."))))))(AdminUi_AdminClient_Components_Content.details([ AdminUi_AdminClient_Components_Details.pair_("Branch Name")(AdminUi_AdminClient_Components_Details.text_("Makati")), AdminUi_AdminClient_Components_Details.pair_("Address")(AdminUi_AdminClient_Components_Details.text_("Second Floor, Eastwood Mall, Libis, Quezon City")), AdminUi_AdminClient_Components_Details.pair_("Landline No.")(AdminUi_AdminClient_Components_Details.text_("84506953")), AdminUi_AdminClient_Components_Details.pair_("Location")(AdminUi_AdminClient_Components_Details.dvMap({
            address: "Eastwood Mall, Libis, Quezon City",
            latLng: {
                lat: 14.585145,
                lng: 121.088407
            }
        })), AdminUi_AdminClient_Components_Details.pair({
            label: "Cellphone No.",
            value: Data_Maybe.Nothing.value,
            info: Data_Maybe.Nothing.value
        }), AdminUi_AdminClient_Components_Details.pair({
            label: "Settings Profile",
            value: Data_Maybe.Just.create(AdminUi_AdminClient_Components_Details.text_("Default Settings")),
            info: Data_Maybe.Just.create("Primary settings used for brands and branches.")
        }) ]));
        return Control_Applicative.pure(Effect_Aff.applicativeAff)({
            fields: [ AdminUi_AdminClient_Components_Content_ContentForm.formField("Branch Name")(AdminUi_AdminClient_Components_Content_ContentForm.textInput({
                placeholder: "Enter Branch Name..."
            })), AdminUi_AdminClient_Components_Content_ContentForm.formField("Landline No.")(AdminUi_AdminClient_Components_Content_ContentForm.textInput({
                placeholder: "Enter Landline No."
            })), AdminUi_AdminClient_Components_Content_ContentForm.formField("Cellphone No.")(AdminUi_AdminClient_Components_Content_ContentForm.textInput({
                placeholder: "Enter Cellphone No."
            })), AdminUi_AdminClient_Components_Content_ContentForm.formField("Address")(AdminUi_AdminClient_Components_Content_ContentForm.textInput({
                placeholder: "Enter Address"
            })), AdminUi_AdminClient_Components_Content_ContentForm.formField("Location")(AdminUi_AdminClient_Components_Content_ContentForm.googlePlacesInput({
                placeholder: "Search Location...",
                defaultValue: Data_Maybe.Nothing.value
            })), AdminUi_AdminClient_Components_Content_ContentForm.formField("Settings Profile")(AdminUi_AdminClient_Components_Content_ContentForm.select(SiloDashboard_Common_Mock_MockData.eqSettingsProfile)(SiloDashboard_Common_Mock_MockData.displaySettingsProfile)({
                placeholder: "Select settings profile...",
                choices: AdminUi_AdminClient_Components_Content_ContentForm.allChoices(SiloDashboard_Common_Mock_MockData.genericDummySettingsProfile)(Data_Generic_Rep_Bounded.genericBottomSum(Data_Generic_Rep_Bounded.genericBottomConstructor(Data_Generic_Rep_Bounded.genericBottomNoArguments)))(Data_Generic_Rep_Bounded.genericTopSum(Data_Generic_Rep_Bounded.genericTopSum(Data_Generic_Rep_Bounded.genericTopSum(Data_Generic_Rep_Bounded.genericTopConstructor(Data_Generic_Rep_Bounded.genericTopNoArguments)))))(Data_Generic_Rep_Enum.genericEnumSum(Data_Generic_Rep_Enum.genericEnumConstructor(Data_Generic_Rep_Enum.genericEnumNoArguments))(Data_Generic_Rep_Bounded.genericTopConstructor(Data_Generic_Rep_Bounded.genericTopNoArguments))(Data_Generic_Rep_Enum.genericEnumSum(Data_Generic_Rep_Enum.genericEnumConstructor(Data_Generic_Rep_Enum.genericEnumNoArguments))(Data_Generic_Rep_Bounded.genericTopConstructor(Data_Generic_Rep_Bounded.genericTopNoArguments))(Data_Generic_Rep_Enum.genericEnumSum(Data_Generic_Rep_Enum.genericEnumConstructor(Data_Generic_Rep_Enum.genericEnumNoArguments))(Data_Generic_Rep_Bounded.genericTopConstructor(Data_Generic_Rep_Bounded.genericTopNoArguments))(Data_Generic_Rep_Enum.genericEnumConstructor(Data_Generic_Rep_Enum.genericEnumNoArguments))(Data_Generic_Rep_Bounded.genericBottomConstructor(Data_Generic_Rep_Bounded.genericBottomNoArguments)))(Data_Generic_Rep_Bounded.genericBottomSum(Data_Generic_Rep_Bounded.genericBottomConstructor(Data_Generic_Rep_Bounded.genericBottomNoArguments))))(Data_Generic_Rep_Bounded.genericBottomSum(Data_Generic_Rep_Bounded.genericBottomConstructor(Data_Generic_Rep_Bounded.genericBottomNoArguments)))),
                defaultChoice: Data_Maybe.Nothing.value
            })) ],
            submit: {
                label: "Add Branch",
                action: AdminUi_AdminClient_Components_AppAction.showModal(confirm)
            }
        });
    })();
    return Control_Applicative.pure(Control_Monad_Reader_Trans.applicativeReaderT(Data_Identity.applicativeIdentity))(AdminUi_AdminClient_Components_Page.pageForm_(title)(form));
};
module.exports = {
    branchesAdd: branchesAdd
};
