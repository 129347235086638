// Generated by purs version 0.13.6
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Class_Console = require("../Effect.Class.Console/index.js");
var Foreign = require("../Foreign/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Routing_Duplex = require("../Routing.Duplex/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var Web_HTML = require("../Web.HTML/index.js");
var Web_HTML_History = require("../Web.HTML.History/index.js");
var Web_HTML_Window = require("../Web.HTML.Window/index.js");
var UseRouter = function (x) {
    return x;
};
var newtypeUseRouter = new Data_Newtype.Newtype(function (n) {
    return n;
}, UseRouter);
var useRouter = function (routeD) {
    return function ($$interface) {
        return function (initialPath) {
            return React_Basic_Hooks_Internal.coerceHook(newtypeUseRouter)((function () {
                var parseR = function (r) {
                    return Data_Either.hush(Routing_Duplex.parse(routeD)(r));
                };
                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(parseR(initialPath)))(function (v) {
                    var setRoute_ = function ($10) {
                        return v.value1(Data_Function["const"]($10));
                    };
                    return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useEffect(Data_Eq.eqUnit)(Data_Unit.unit)($$interface.listen(function (v1) {
                        return setRoute_(parseR(v1.path));
                    })))(function () {
                        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))({
                            currRoute: v.value0,
                            push: function (r) {
                                return $$interface.pushState(Foreign.unsafeToForeign({}))(Routing_Duplex.print(routeD)(r));
                            },
                            goBack: Control_Bind.bind(Effect.bindEffect)(Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.history))(Web_HTML_History.back)
                        });
                    });
                });
            })());
        };
    };
};
var mkRouterContext = React_Basic.createContext({
    push: Data_Function["const"](Effect_Class_Console.error(Effect_Class.monadEffectEffect)("Router Context Error: You may have forgotten to set the proper values through React's Provider.")),
    goBack: Effect_Class_Console.error(Effect_Class.monadEffectEffect)("Router Context Error: You may have forgotten to set the proper values through React's Provider.")
});
module.exports = {
    UseRouter: UseRouter,
    useRouter: useRouter,
    mkRouterContext: mkRouterContext,
    newtypeUseRouter: newtypeUseRouter
};
