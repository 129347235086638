// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_AppAction = require("../AdminUi.AdminClient.Components.AppAction/index.js");
var AdminUi_AdminClient_Components_Content_ContentForm = require("../AdminUi.AdminClient.Components.Content.ContentForm/index.js");
var AdminUi_AdminClient_Components_Details = require("../AdminUi.AdminClient.Components.Details/index.js");
var AdminUi_AdminClient_Components_Page = require("../AdminUi.AdminClient.Components.Page/index.js");
var AdminUi_AdminClient_Components_Title = require("../AdminUi.AdminClient.Components.Title/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var AdminUi_AdminClient_Components_ZText = require("../AdminUi.AdminClient.Components.ZText/index.js");
var AdminUi_AdminClient_Utils_Display = require("../AdminUi.AdminClient.Utils.Display/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Reader_Class = require("../Control.Monad.Reader.Class/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep_Bounded = require("../Data.Generic.Rep.Bounded/index.js");
var Data_Generic_Rep_Enum = require("../Data.Generic.Rep.Enum/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var SiloDashboard_Common_Mock_MockData = require("../SiloDashboard.Common.Mock.MockData/index.js");
var brandChangeSenderId = function (brandName) {
    return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Data_Identity.bindIdentity))(Control_Monad_Reader_Class.ask(Control_Monad_Reader_Trans.monadAskReaderT(Data_Identity.monadIdentity)))(function (apiClient) {
        var title = AdminUi_AdminClient_Components_Title.title_("Change Sender ID");
        var form = Data_Functor.mapFlipped(Effect_Aff.functorAff)(apiClient.getBrandDetails(brandName))(function (v) {
            return {
                details: new Data_Maybe.Just([ AdminUi_AdminClient_Components_Details.pair_("Current Sender ID")(AdminUi_AdminClient_Components_Details.text_(v.details.senderId)) ]),
                fields: [ AdminUi_AdminClient_Components_Content_ContentForm.formField("New Sender ID")(AdminUi_AdminClient_Components_Content_ContentForm.select(SiloDashboard_Common_Mock_MockData.eqDummySenderId)(SiloDashboard_Common_Mock_MockData.displaySenderId)({
                    placeholder: "Choose new Sender ID...",
                    choices: AdminUi_AdminClient_Components_Content_ContentForm.allChoices(SiloDashboard_Common_Mock_MockData.genericDummySenderId)(Data_Generic_Rep_Bounded.genericBottomSum(Data_Generic_Rep_Bounded.genericBottomConstructor(Data_Generic_Rep_Bounded.genericBottomNoArguments)))(Data_Generic_Rep_Bounded.genericTopSum(Data_Generic_Rep_Bounded.genericTopSum(Data_Generic_Rep_Bounded.genericTopConstructor(Data_Generic_Rep_Bounded.genericTopNoArguments))))(Data_Generic_Rep_Enum.genericEnumSum(Data_Generic_Rep_Enum.genericEnumConstructor(Data_Generic_Rep_Enum.genericEnumNoArguments))(Data_Generic_Rep_Bounded.genericTopConstructor(Data_Generic_Rep_Bounded.genericTopNoArguments))(Data_Generic_Rep_Enum.genericEnumSum(Data_Generic_Rep_Enum.genericEnumConstructor(Data_Generic_Rep_Enum.genericEnumNoArguments))(Data_Generic_Rep_Bounded.genericTopConstructor(Data_Generic_Rep_Bounded.genericTopNoArguments))(Data_Generic_Rep_Enum.genericEnumConstructor(Data_Generic_Rep_Enum.genericEnumNoArguments))(Data_Generic_Rep_Bounded.genericBottomConstructor(Data_Generic_Rep_Bounded.genericBottomNoArguments)))(Data_Generic_Rep_Bounded.genericBottomSum(Data_Generic_Rep_Bounded.genericBottomConstructor(Data_Generic_Rep_Bounded.genericBottomNoArguments)))),
                    defaultChoice: Data_Maybe.Nothing.value
                })) ],
                submit: {
                    label: "Save Changes",
                    action: AdminUi_AdminClient_Components_AppAction.showConfirmModal_("Confirm")(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.text_("Change "))(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.bold(brandName + "'s"))(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.text_(" sender ID to "))(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.bold(AdminUi_AdminClient_Utils_Display.display(SiloDashboard_Common_Mock_MockData.displaySenderId)(SiloDashboard_Common_Mock_MockData.BrandX.value) + "? "))(AdminUi_AdminClient_Components_ZText.text_("All SMS's for " + (brandName + (" will be sent from " + AdminUi_AdminClient_Utils_Display.display(SiloDashboard_Common_Mock_MockData.displaySenderId)(SiloDashboard_Common_Mock_MockData.BrandX.value)))))))))
                },
                cancel: AdminUi_AdminClient_Components_AppAction.goBack
            };
        });
        return Control_Applicative.pure(Control_Monad_Reader_Trans.applicativeReaderT(Data_Identity.applicativeIdentity))(AdminUi_AdminClient_Components_Page.pageForm(Data_Maybe.Nothing.value)(title)(Data_Maybe.Nothing.value)(form));
    });
};
module.exports = {
    brandChangeSenderId: brandChangeSenderId
};
