// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Utils_MockApiClient = require("../AdminUi.AdminClient.Utils.MockApiClient/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var ExpressRpc_MkApiClient = require("../ExpressRpc.MkApiClient/index.js");
var SiloDashboard_Client_Constants = require("../SiloDashboard.Client.Constants/index.js");
var SiloDashboard_Common_Mock_MockData = require("../SiloDashboard.Common.Mock.MockData/index.js");
var ZRPC_ZRPCRead = require("../ZRPC.ZRPCRead/index.js");
var runApi = Control_Monad_Except_Trans.runExceptT;
var configApiClient = function (url) {
    var apiVersions = ExpressRpc_MkApiClient.mkVersionedApiClient()(ExpressRpc_MkApiClient.mkVersionedApiRLCons(new Data_Symbol.IsSymbol(function () {
        return "default";
    }))(ExpressRpc_MkApiClient.mkVersionedApiRLCons(new Data_Symbol.IsSymbol(function () {
        return "v1";
    }))(ExpressRpc_MkApiClient.mkVersionedApiRLNil)()()()(ExpressRpc_MkApiClient.mkFetcherRLConsAuthNoReq(new Data_Symbol.IsSymbol(function () {
        return "test";
    }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "test";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))(ExpressRpc_MkApiClient.mkFetcherRLNil)()()))()()()(ExpressRpc_MkApiClient.mkFetcherRLConsAuthNoReq(new Data_Symbol.IsSymbol(function () {
        return "test";
    }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "test";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))(ExpressRpc_MkApiClient.mkFetcherRLNil)()()))(url);
    return apiVersions.v1;
};
var callApi = function (a) {
    return function (f) {
        return Effect_Aff.runAff_(f)(runApi(a));
    };
};
var apiClientMock = {
    getBrandDetails: function (v) {
        return AdminUi_AdminClient_Utils_MockApiClient.mockApi(SiloDashboard_Common_Mock_MockData.brandDetails);
    },
    getBrandBranches: function (v) {
        return AdminUi_AdminClient_Utils_MockApiClient.mockApi({
            activeBranches: {
                count: 20,
                branches: SiloDashboard_Common_Mock_MockData.branchDetailsList
            },
            deactivatedBranches: {
                branches: SiloDashboard_Common_Mock_MockData.branchDetailsList,
                count: 10
            }
        });
    },
    getBranchDetails: function (v) {
        return function (v1) {
            return AdminUi_AdminClient_Utils_MockApiClient.mockApi(SiloDashboard_Common_Mock_MockData.branchDetails);
        };
    }
};
var apiClient = configApiClient(SiloDashboard_Client_Constants.apiUrl);
module.exports = {
    apiClientMock: apiClientMock,
    apiClient: apiClient,
    configApiClient: configApiClient,
    runApi: runApi,
    callApi: callApi
};
