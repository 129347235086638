// Generated by purs version 0.13.6
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var Effect_Ref = require("../Effect.Ref/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var newRef = Effect_Unsafe.unsafePerformEffect(Effect_Ref["new"](0));
var mockApi = function (a) {
    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Aff.delay(1500.0))(function () {
        return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.modify(function (v) {
            return v + 1 | 0;
        })(newRef)))(function (val) {
            return Control_Applicative.pure(Effect_Aff.applicativeAff)(a);
        });
    });
};
var errorMockApi = function (a) {
    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Aff.delay(1500.0))(function () {
        return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.modify(function (v) {
            return v + 1 | 0;
        })(newRef)))(function (val) {
            var $2 = val > 4;
            if ($2) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)(a);
            };
            return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(Effect_Exception.error("kaboom"));
        });
    });
};
module.exports = {
    newRef: newRef,
    mockApi: mockApi,
    errorMockApi: errorMockApi
};
