// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var Dot = (function () {
    function Dot() {

    };
    Dot.value = new Dot();
    return Dot;
})();
var mkZapIcon = function (dictCoercible) {
    return function (v) {
        return $foreign.dot();
    };
};
module.exports = {
    Dot: Dot,
    mkZapIcon: mkZapIcon,
    dot: $foreign.dot
};
