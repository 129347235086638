// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_Utils = require("../AdminUi.AdminClient.Components.Utils/index.js");
var AdminUi_Antd_Breadcrumb = require("../AdminUi.Antd.Breadcrumb/index.js");
var AdminUi_Antd_Skeleton = require("../AdminUi.Antd.Skeleton/index.js");
var AdminUi_RouteDuplex = require("../AdminUi.RouteDuplex/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_String_Regex = require("../Data.String.Regex/index.js");
var Data_String_Regex_Flags = require("../Data.String.Regex.Flags/index.js");
var Data_String_Regex_Unsafe = require("../Data.String.Regex.Unsafe/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Routing_Duplex = require("../Routing.Duplex/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var Untagged_Coercible = require("../Untagged.Coercible/index.js");
var None = (function () {
    function None() {

    };
    None.value = new None();
    return None;
})();
var Loading = (function () {
    function Loading() {

    };
    Loading.value = new Loading();
    return Loading;
})();
var FetchError = (function () {
    function FetchError() {

    };
    FetchError.value = new FetchError();
    return FetchError;
})();
var Value = (function () {
    function Value(value0) {
        this.value0 = value0;
    };
    Value.create = function (value0) {
        return new Value(value0);
    };
    return Value;
})();
var skeleton = AdminUi_Antd_Skeleton.skeleton()({
    paragraph: Untagged_Coercible.coerce()({
        rows: 1,
        width: 300.0
    }),
    active: true,
    loading: true,
    title: false
});
var routeToBreadcrumbs = function (routeD) {
    return function (route) {
        return function (mOverwrite) {
            var titleCaseWord = function (w) {
                var v = Data_String_CodePoints.uncons(w);
                if (v instanceof Data_Maybe.Just) {
                    return Data_String_Common.toUpper(Data_String_CodePoints.singleton(v.value0.head)) + v.value0.tail;
                };
                if (v instanceof Data_Maybe.Nothing) {
                    return "";
                };
                throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Breadcrumb (line 143, column 23 - line 145, column 20): " + [ v.constructor.name ]);
            };
            var stripLeadingSlash = Data_String_Regex.replace(Data_String_Regex_Unsafe.unsafeRegex("^/")(Data_String_Regex_Flags.noFlags))("");
            var splitPath = Data_String_Common.split("/");
            var splitBySpace = Data_String_Common.split(" ");
            var printRoute = Routing_Duplex.print(routeD);
            var pathOverwrite = Data_Functor.map(Data_Maybe.functorMaybe)(printRoute)(mOverwrite);
            var path = printRoute(route);
            var partsOverwrite = Data_Functor.map(Data_Maybe.functorMaybe)(splitPath)(Data_Functor.map(Data_Maybe.functorMaybe)(stripLeadingSlash)(pathOverwrite));
            var parts = splitPath(stripLeadingSlash(path));
            var parseRoute = (function () {
                var $27 = Routing_Duplex.parse(routeD);
                return function ($28) {
                    return Data_Either.hush($27($28));
                };
            })();
            var links = Data_Traversable.scanl(Data_Traversable.traversableArray)(function (pre) {
                return function (a) {
                    return pre + ("/" + a);
                };
            })("")(parts);
            var routes$prime = Data_Functor.map(Data_Functor.functorArray)(parseRoute)(links);
            var routes = (function () {
                var v = Data_Array.unsnoc(routes$prime);
                if (v instanceof Data_Maybe.Nothing) {
                    return [  ];
                };
                if (v instanceof Data_Maybe.Just) {
                    return Data_Array.snoc(v.value0.init)(Data_Maybe.Nothing.value);
                };
                throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Breadcrumb (line 135, column 14 - line 137, column 47): " + [ v.constructor.name ]);
            })();
            var joinWithSpace = Data_String_Common.joinWith(" ");
            var toTitle = function (t) {
                return joinWithSpace(Data_Functor.map(Data_Functor.functorArray)(titleCaseWord)(splitBySpace(AdminUi_RouteDuplex.parseZname(t))));
            };
            var overwrittenParts = Data_Functor.map(Data_Functor.functorArray)(toTitle)((function () {
                if (partsOverwrite instanceof Data_Maybe.Just) {
                    return partsOverwrite.value0;
                };
                if (partsOverwrite instanceof Data_Maybe.Nothing) {
                    return parts;
                };
                throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Breadcrumb (line 149, column 36 - line 151, column 23): " + [ partsOverwrite.constructor.name ]);
            })());
            return Data_Array.zipWith(function (title) {
                return function (r) {
                    return {
                        title: title,
                        route: r
                    };
                };
            })(overwrittenParts)(routes);
        };
    };
};
var mkBreadcrumb = function (dictEq) {
    return function (dictMonadEffect) {
        return AdminUi_AdminClient_Components_Utils.comp_(dictMonadEffect)()()()("Breadcrumb")(function (props) {
            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(None.value))(function (v) {
                var setOverwrite = function ($29) {
                    return v.value1(Data_Function["const"]($29));
                };
                return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useEffect(dictEq)(props.route)(function __do() {
                    (function () {
                        if (props.overwrite instanceof Data_Maybe.Nothing) {
                            return setOverwrite(None.value)();
                        };
                        if (props.overwrite instanceof Data_Maybe.Just) {
                            setOverwrite(Loading.value)();
                            return Effect_Aff.runAff_(function (v1) {
                                if (v1 instanceof Data_Either.Left) {
                                    return setOverwrite(FetchError.value);
                                };
                                if (v1 instanceof Data_Either.Right) {
                                    return setOverwrite(new Value(v1.value0));
                                };
                                throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Breadcrumb (line 62, column 23 - line 64, column 52): " + [ v1.constructor.name ]);
                            })(props.overwrite.value0)();
                        };
                        throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Breadcrumb (line 57, column 7 - line 64, column 52): " + [ props.overwrite.constructor.name ]);
                    })();
                    return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                }))(function () {
                    var renderBreadcrumb = function (mOverwrite) {
                        return AdminUi_Antd_Breadcrumb.breadcrumb()({
                            children: Data_Functor.mapFlipped(Data_Functor.functorArray)(routeToBreadcrumbs(props.routeDuplex)(props.route)(mOverwrite))(function (bcrumb) {
                                return AdminUi_Antd_Breadcrumb.breadcrumbItem()({
                                    children: [ (function () {
                                        if (bcrumb.route instanceof Data_Maybe.Just) {
                                            return React_Basic_DOM_Generated.a()({
                                                onClick: React_Basic_DOM_Events.capture_(props.push(bcrumb.route.value0)),
                                                children: [ React_Basic_DOM.text(bcrumb.title) ]
                                            });
                                        };
                                        if (bcrumb.route instanceof Data_Maybe.Nothing) {
                                            return React_Basic_DOM.text(bcrumb.title);
                                        };
                                        throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Breadcrumb (line 74, column 25 - line 80, column 57): " + [ bcrumb.route.constructor.name ]);
                                    })() ]
                                });
                            })
                        });
                    };
                    return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))((function () {
                        if (v.value0 instanceof Loading) {
                            return skeleton;
                        };
                        if (v.value0 instanceof Value) {
                            return renderBreadcrumb(new Data_Maybe.Just(v.value0.value0));
                        };
                        return renderBreadcrumb(Data_Maybe.Nothing.value);
                    })());
                });
            });
        });
    };
};
module.exports = {
    mkBreadcrumb: mkBreadcrumb,
    routeToBreadcrumbs: routeToBreadcrumbs
};
