// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var React_Basic = require("../React.Basic/index.js");
var Untagged_Coercible = require("../Untagged.Coercible/index.js");
var tag = function (dictCoercible) {
    var $1 = React_Basic.element($foreign["_tag"]);
    var $2 = Untagged_Coercible.coerce();
    return function ($3) {
        return $1($2($3));
    };
};
module.exports = {
    tag: tag,
    "_tag": $foreign["_tag"]
};
