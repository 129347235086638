// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_BottomContent = require("../AdminUi.AdminClient.Components.BottomContent/index.js");
var AdminUi_AdminClient_Components_SectionWrapper = require("../AdminUi.AdminClient.Components.SectionWrapper/index.js");
var AdminUi_AdminClient_Components_Utils = require("../AdminUi.AdminClient.Components.Utils/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var mkBottomSection = function (dictEq) {
    return function (dictMonadAsk) {
        return function (dictMonadEffect) {
            return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Components_SectionWrapper.mkSectionWrapper(dictMonadAsk)(dictMonadEffect))(function (sectionWrapper) {
                return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Components_BottomContent.mkBottomContent(dictEq)(dictMonadAsk)(dictMonadEffect))(function (bottomContent) {
                    return AdminUi_AdminClient_Components_Utils.comp_(dictMonadEffect)()()()("BottomSection")(function (v) {
                        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))((function () {
                            if (v.section instanceof Data_Maybe.Nothing) {
                                return Data_Monoid.mempty(React_Basic.monoidJSX);
                            };
                            if (v.section instanceof Data_Maybe.Just) {
                                return React_Basic.element(sectionWrapper)({
                                    title: v.section.value0.title,
                                    actionGroup: v.section.value0.actionGroup,
                                    content: React_Basic.element(bottomContent)({
                                        content: v.section.value0.content
                                    })
                                });
                            };
                            throw new Error("Failed pattern match at AdminUi.AdminClient.Components.BottomSection (line 41, column 9 - line 48, column 16): " + [ v.section.constructor.name ]);
                        })());
                    });
                });
            });
        };
    };
};
var bSection = function (title) {
    return function (actionGroup) {
        return function (content) {
            return {
                title: title,
                actionGroup: actionGroup,
                content: content
            };
        };
    };
};
module.exports = {
    bSection: bSection,
    mkBottomSection: mkBottomSection
};
