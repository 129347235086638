// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Colors = require("../AdminUi.AdminClient.Colors/index.js");
var AdminUi_AdminClient_Components_Icons_AntdIcons = require("../AdminUi.AdminClient.Components.Icons.AntdIcons/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var Jss_ReactJss = require("../Jss.ReactJss/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var defaultColor = "#bfbfbf";
var useStyles = Jss_ReactJss.createUseStyles_()()({
    container: Jss_ReactJss.style({
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "center",
        fontSize: "10px",
        marginLeft: "10px"
    }),
    primary: Jss_ReactJss.style({
        color: AdminUi_AdminClient_Colors.getColor(AdminUi_AdminClient_Colors.Primary.value)
    }),
    "default": Jss_ReactJss.style({
        color: defaultColor
    })
});
var mkSort = React_Basic_Hooks.component()()()("Sort")(function (v) {
    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useStyles({}))(function (css) {
        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.span()({
            className: css.container,
            children: [ AdminUi_AdminClient_Components_Icons_AntdIcons.mkAntdIcon()(AdminUi_AdminClient_Components_Icons_AntdIcons.CaretUpFilled.value)({
                className: (function () {
                    if (v.direction instanceof Data_Maybe.Just && v.direction.value0 instanceof AdminUi_AdminClient_Components_Types.Descending) {
                        return css.primary;
                    };
                    return css["default"];
                })()
            }), AdminUi_AdminClient_Components_Icons_AntdIcons.mkAntdIcon()(AdminUi_AdminClient_Components_Icons_AntdIcons.CaretDownFilled.value)({
                className: (function () {
                    if (v.direction instanceof Data_Maybe.Just && v.direction.value0 instanceof AdminUi_AdminClient_Components_Types.Ascending) {
                        return css.primary;
                    };
                    return css["default"];
                })()
            }) ]
        }));
    });
});
var sortRC = Effect_Unsafe.unsafePerformEffect(mkSort);
var sort = function (direction) {
    return React_Basic.element(sortRC)({
        direction: direction
    });
};
var sortAscending = sort(new Data_Maybe.Just(AdminUi_AdminClient_Components_Types.Ascending.value));
var sortDefault = sort(Data_Maybe.Nothing.value);
var sortDescending = sort(new Data_Maybe.Just(AdminUi_AdminClient_Components_Types.Descending.value));
module.exports = {
    sortDefault: sortDefault,
    sortAscending: sortAscending,
    sortDescending: sortDescending
};
