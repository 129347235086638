// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var React_Basic = require("../React.Basic/index.js");
var Untagged_Coercible = require("../Untagged.Coercible/index.js");
var alertErrorBoundary = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_alertErrorBoundary"])(Untagged_Coercible.coerce()(props));
    };
};
var alert = function (dictUnion) {
    return React_Basic.element($foreign["_alert"]);
};
module.exports = {
    alert: alert,
    alertErrorBoundary: alertErrorBoundary,
    "_alert": $foreign["_alert"],
    "_alertErrorBoundary": $foreign["_alertErrorBoundary"]
};
