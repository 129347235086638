// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var React_Basic = require("../React.Basic/index.js");
var Untagged_Coercible = require("../Untagged.Coercible/index.js");
var Route = function (x) {
    return x;
};
var breadcrumbItem = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_breadcrumbItem"])(Untagged_Coercible.coerce()(props));
    };
};
var breadcrumb = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_breadcrumb"])(Untagged_Coercible.coerce()(props));
    };
};
module.exports = {
    Route: Route,
    breadcrumb: breadcrumb,
    breadcrumbItem: breadcrumbItem,
    "_breadcrumb": $foreign["_breadcrumb"],
    "_breadcrumbItem": $foreign["_breadcrumbItem"]
};
