// Generated by purs version 0.13.6
"use strict";
var AdminUi_Antd_Layout = require("../AdminUi.Antd.Layout/index.js");
var AdminUi_Logo = require("../AdminUi.Logo/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Jss_ReactJss = require("../Jss.ReactJss/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var mkBasicLayout = (function () {
    var useStyles = Jss_ReactJss.createUseStyles_()()({
        content: Jss_ReactJss.style({
            backgroundColor: "#fff",
            margin: "25px 15px 0px",
            padding: "30px 25px"
        }),
        header: Jss_ReactJss.style({
            backgroundColor: "#fff",
            padding: "0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }),
        logo: Jss_ReactJss.style({
            height: 40
        }),
        layout: Jss_ReactJss.style({
            minHeight: "100vh"
        })
    });
    return React_Basic_Hooks.component()()()("BasicLayout")(function (v) {
        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useStyles({}))(function (v1) {
            return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(AdminUi_Antd_Layout.layout()({
                className: v1.layout,
                children: [ AdminUi_Antd_Layout.header()({
                    className: v1.header,
                    children: [ AdminUi_Logo.logo(v1.logo) ]
                }), AdminUi_Antd_Layout.content()({
                    className: v1.content,
                    children: v.content
                }) ]
            }));
        });
    });
})();
module.exports = {
    mkBasicLayout: mkBasicLayout
};
