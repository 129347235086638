// Generated by purs version 0.13.6
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Lazy = require("../Control.Lazy/index.js");
var Control_Plus = require("../Control.Plus/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Array_NonEmpty_Internal = require("../Data.Array.NonEmpty.Internal/index.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Generic_Rep_Show = require("../Data.Generic.Rep.Show/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Lazy = require("../Data.Lazy/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Global_Unsafe = require("../Global.Unsafe/index.js");
var Expected = (function () {
    function Expected(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Expected.create = function (value0) {
        return function (value1) {
            return new Expected(value0, value1);
        };
    };
    return Expected;
})();
var ExpectedEndOfPath = (function () {
    function ExpectedEndOfPath(value0) {
        this.value0 = value0;
    };
    ExpectedEndOfPath.create = function (value0) {
        return new ExpectedEndOfPath(value0);
    };
    return ExpectedEndOfPath;
})();
var MissingParam = (function () {
    function MissingParam(value0) {
        this.value0 = value0;
    };
    MissingParam.create = function (value0) {
        return new MissingParam(value0);
    };
    return MissingParam;
})();
var EndOfPath = (function () {
    function EndOfPath() {

    };
    EndOfPath.value = new EndOfPath();
    return EndOfPath;
})();
var Fail = (function () {
    function Fail(value0) {
        this.value0 = value0;
    };
    Fail.create = function (value0) {
        return new Fail(value0);
    };
    return Fail;
})();
var Success = (function () {
    function Success(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Success.create = function (value0) {
        return function (value1) {
            return new Success(value0, value1);
        };
    };
    return Success;
})();
var Alt = (function () {
    function Alt(value0) {
        this.value0 = value0;
    };
    Alt.create = function (value0) {
        return new Alt(value0);
    };
    return Alt;
})();
var Chomp = (function () {
    function Chomp(value0) {
        this.value0 = value0;
    };
    Chomp.create = function (value0) {
        return new Chomp(value0);
    };
    return Chomp;
})();
var Prefix = (function () {
    function Prefix(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Prefix.create = function (value0) {
        return function (value1) {
            return new Prefix(value0, value1);
        };
    };
    return Prefix;
})();
var take = new Chomp(function (state) {
    var v = Data_Array.uncons(state.segments);
    if (v instanceof Data_Maybe.Just) {
        return new Success({
            segments: v.value0.tail,
            params: state.params,
            hash: state.hash
        }, v.value0.head);
    };
    return new Fail(EndOfPath.value);
});
var rest = new Chomp(function (state) {
    return new Success({
        segments: [  ],
        params: state.params,
        hash: state.hash
    }, state.segments);
});
var prefix = Prefix.create;
var parsePath = (function () {
    var toRouteState = function (v) {
        return {
            segments: v.value0.value0,
            params: v.value0.value1,
            hash: v.value1
        };
    };
    var splitNonEmpty = function (v) {
        return function (v1) {
            if (v1 === "") {
                return [  ];
            };
            return Data_String_Common.split(v)(v1);
        };
    };
    var splitSegments = (function () {
        var $235 = splitNonEmpty("/");
        return function ($236) {
            return (function (v) {
                if (v.length === 2 && (v[0] === "" && v[1] === "")) {
                    return [ "" ];
                };
                return Data_Functor.map(Data_Functor.functorArray)(Global_Unsafe.unsafeDecodeURIComponent)(v);
            })($235($236));
        };
    })();
    var splitAt = function (k) {
        return function (p) {
            return function (str) {
                var v = Data_String_CodeUnits.indexOf(p)(str);
                if (v instanceof Data_Maybe.Just) {
                    return new Data_Tuple.Tuple(Data_String_CodeUnits.take(v.value0)(str), Data_String_CodeUnits.drop(v.value0 + Data_String_CodeUnits.length(p) | 0)(str));
                };
                if (v instanceof Data_Maybe.Nothing) {
                    return k(str);
                };
                throw new Error("Failed pattern match at Routing.Duplex.Parser (line 183, column 5 - line 185, column 23): " + [ v.constructor.name ]);
            };
        };
    };
    var splitKeyValue = (function () {
        var $237 = Data_Bifunctor.bimap(Data_Tuple.bifunctorTuple)(Global_Unsafe.unsafeDecodeURIComponent)(Global_Unsafe.unsafeDecodeURIComponent);
        var $238 = splitAt(Data_Function.flip(Data_Tuple.Tuple.create)(""))("=");
        return function ($239) {
            return $237($238($239));
        };
    })();
    var splitParams = (function () {
        var $240 = Data_Functor.map(Data_Functor.functorArray)(splitKeyValue);
        var $241 = splitNonEmpty("&");
        return function ($242) {
            return $240($241($242));
        };
    })();
    var splitPath = (function () {
        var $243 = Data_Bifunctor.bimap(Data_Tuple.bifunctorTuple)(splitSegments)(splitParams);
        var $244 = splitAt(Data_Function.flip(Data_Tuple.Tuple.create)(""))("?");
        return function ($245) {
            return $243($244($245));
        };
    })();
    var $246 = Data_Bifunctor.lmap(Data_Tuple.bifunctorTuple)(splitPath);
    var $247 = splitAt(Data_Function.flip(Data_Tuple.Tuple.create)(""))("#");
    return function ($248) {
        return toRouteState($246($247($248)));
    };
})();
var param = function (key) {
    return new Chomp(function (state) {
        var v = Data_Tuple.lookup(Data_Foldable.foldableArray)(Data_Eq.eqString)(key)(state.params);
        if (v instanceof Data_Maybe.Just) {
            return new Success(state, v.value0);
        };
        return Fail.create(new MissingParam(key));
    });
};
var $$int = (function () {
    var $249 = Data_Maybe.maybe(new Data_Either.Left("Int"))(Data_Either.Right.create);
    return function ($250) {
        return $249(Data_Int.fromString($250));
    };
})();
var hash = new Chomp(function (state) {
    return new Success(state, state.hash);
});
var genericRouteResult = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof Fail) {
        return new Data_Generic_Rep.Inl(x.value0);
    };
    if (x instanceof Success) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Product(x.value0, x.value1));
    };
    throw new Error("Failed pattern match at Routing.Duplex.Parser (line 54, column 1 - line 54, column 64): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return new Fail(x.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr) {
        return new Success(x.value0.value0, x.value0.value1);
    };
    throw new Error("Failed pattern match at Routing.Duplex.Parser (line 54, column 1 - line 54, column 64): " + [ x.constructor.name ]);
});
var genericRouteError = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof Expected) {
        return new Data_Generic_Rep.Inl(new Data_Generic_Rep.Product(x.value0, x.value1));
    };
    if (x instanceof ExpectedEndOfPath) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
    };
    if (x instanceof MissingParam) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
    };
    if (x instanceof EndOfPath) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)));
    };
    throw new Error("Failed pattern match at Routing.Duplex.Parser (line 64, column 1 - line 64, column 58): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return new Expected(x.value0.value0, x.value0.value1);
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return new ExpectedEndOfPath(x.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return new MissingParam(x.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
        return EndOfPath.value;
    };
    throw new Error("Failed pattern match at Routing.Duplex.Parser (line 64, column 1 - line 64, column 58): " + [ x.constructor.name ]);
});
var showRouteError = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericRouteError)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsProduct(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showString))(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showString)))(new Data_Symbol.IsSymbol(function () {
    return "Expected";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showString))(new Data_Symbol.IsSymbol(function () {
    return "ExpectedEndOfPath";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showString))(new Data_Symbol.IsSymbol(function () {
    return "MissingParam";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "EndOfPath";
})))))));
var showRouteResult = function (dictShow) {
    return new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericRouteResult)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(showRouteError))(new Data_Symbol.IsSymbol(function () {
        return "Fail";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsProduct(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "hash";
    }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "params";
    }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "segments";
    }))(Data_Show.showRecordFieldsNil)(Data_Show.showArray(Data_Show.showString)))(Data_Show.showArray(Data_Tuple.showTuple(Data_Show.showString)(Data_Show.showString))))(Data_Show.showString))))(Data_Generic_Rep_Show.genericShowArgsArgument(dictShow)))(new Data_Symbol.IsSymbol(function () {
        return "Success";
    })))));
};
var functorRouteResult = new Data_Functor.Functor(function (f) {
    return function (m) {
        if (m instanceof Fail) {
            return new Fail(m.value0);
        };
        if (m instanceof Success) {
            return new Success(m.value0, f(m.value1));
        };
        throw new Error("Failed pattern match at Routing.Duplex.Parser (line 53, column 1 - line 53, column 58): " + [ m.constructor.name ]);
    };
});
var functorRouteParser = new Data_Functor.Functor(function (f) {
    return function (m) {
        if (m instanceof Alt) {
            return new Alt(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(Data_Functor.map(functorRouteParser)(f))(m.value0));
        };
        if (m instanceof Chomp) {
            return new Chomp(Data_Functor.map(Data_Functor.functorFn)(Data_Functor.map(functorRouteResult)(f))(m.value0));
        };
        if (m instanceof Prefix) {
            return new Prefix(m.value0, Data_Functor.map(functorRouteParser)(f)(m.value1));
        };
        throw new Error("Failed pattern match at Routing.Duplex.Parser (line 72, column 1 - line 72, column 58): " + [ m.constructor.name ]);
    };
});
var eqRouteError = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Expected && y instanceof Expected) {
            return x.value0 === y.value0 && x.value1 === y.value1;
        };
        if (x instanceof ExpectedEndOfPath && y instanceof ExpectedEndOfPath) {
            return x.value0 === y.value0;
        };
        if (x instanceof MissingParam && y instanceof MissingParam) {
            return x.value0 === y.value0;
        };
        if (x instanceof EndOfPath && y instanceof EndOfPath) {
            return true;
        };
        return false;
    };
});
var eqRouteResult = function (dictEq) {
    return new Data_Eq.Eq(function (x) {
        return function (y) {
            if (x instanceof Fail && y instanceof Fail) {
                return Data_Eq.eq(eqRouteError)(x.value0)(y.value0);
            };
            if (x instanceof Success && y instanceof Success) {
                return x.value0.hash === y.value0.hash && Data_Eq.eq(Data_Eq.eqArray(Data_Tuple.eqTuple(Data_Eq.eqString)(Data_Eq.eqString)))(x.value0.params)(y.value0.params) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.segments)(y.value0.segments) && Data_Eq.eq(dictEq)(x.value1)(y.value1);
            };
            return false;
        };
    });
};
var end = new Chomp(function (state) {
    var v = Data_Array.head(state.segments);
    if (v instanceof Data_Maybe.Nothing) {
        return new Success(state, Data_Unit.unit);
    };
    if (v instanceof Data_Maybe.Just) {
        return new Fail(new ExpectedEndOfPath(v.value0));
    };
    throw new Error("Failed pattern match at Routing.Duplex.Parser (line 254, column 3 - line 256, column 45): " + [ v.constructor.name ]);
});
var chompPrefix = function (pre) {
    return function (state) {
        var v = Data_Array.head(state.segments);
        if (v instanceof Data_Maybe.Just && pre === v.value0) {
            return new Success({
                segments: Data_Array.drop(1)(state.segments),
                params: state.params,
                hash: state.hash
            }, Data_Unit.unit);
        };
        if (v instanceof Data_Maybe.Just) {
            return Fail.create(new Expected(pre, v.value0));
        };
        return Fail.create(EndOfPath.value);
    };
};
var runRouteParser = (function () {
    var goAlt = function (v) {
        return function (v1) {
            return function (v2) {
                if (v1 instanceof Fail) {
                    return runRouteParser(v)(v2);
                };
                return v1;
            };
        };
    };
    var go = function ($copy_state) {
        return function ($copy_v) {
            var $tco_var_state = $copy_state;
            var $tco_done = false;
            var $tco_result;
            function $tco_loop(state, v) {
                if (v instanceof Alt) {
                    $tco_done = true;
                    return Data_Foldable.foldl(Data_Array_NonEmpty_Internal.foldableNonEmptyArray)(goAlt(state))(new Fail(EndOfPath.value))(v.value0);
                };
                if (v instanceof Chomp) {
                    $tco_done = true;
                    return v.value0(state);
                };
                if (v instanceof Prefix) {
                    var v1 = chompPrefix(v.value0)(state);
                    if (v1 instanceof Fail) {
                        $tco_done = true;
                        return new Fail(v1.value0);
                    };
                    if (v1 instanceof Success) {
                        $tco_var_state = v1.value0;
                        $copy_v = v.value1;
                        return;
                    };
                    throw new Error("Failed pattern match at Routing.Duplex.Parser (line 149, column 7 - line 151, column 40): " + [ v1.constructor.name ]);
                };
                throw new Error("Failed pattern match at Routing.Duplex.Parser (line 145, column 14 - line 151, column 40): " + [ v.constructor.name ]);
            };
            while (!$tco_done) {
                $tco_result = $tco_loop($tco_var_state, $copy_v);
            };
            return $tco_result;
        };
    };
    return go;
})();
var lazyRouteParser = new Control_Lazy.Lazy(function (k) {
    var parser = Data_Lazy.defer(k);
    return new Chomp(function (state) {
        return runRouteParser(state)(Data_Lazy.force(parser));
    });
});
var many1 = function (dictAlt) {
    return function (dictApplicative) {
        return function (p) {
            var go$prime = function ($copy_state) {
                return function ($copy_xs) {
                    var $tco_var_state = $copy_state;
                    var $tco_done = false;
                    var $tco_result;
                    function $tco_loop(state, xs) {
                        var v = runRouteParser(state)(p);
                        if (v instanceof Fail) {
                            $tco_done = true;
                            return new Success(state, xs);
                        };
                        if (v instanceof Success) {
                            $tco_var_state = v.value0;
                            $copy_xs = Control_Alt.alt(dictAlt)(xs)(Control_Applicative.pure(dictApplicative)(v.value1));
                            return;
                        };
                        throw new Error("Failed pattern match at Routing.Duplex.Parser (line 221, column 5 - line 223, column 53): " + [ v.constructor.name ]);
                    };
                    while (!$tco_done) {
                        $tco_result = $tco_loop($tco_var_state, $copy_xs);
                    };
                    return $tco_result;
                };
            };
            var go = function (state) {
                var v = runRouteParser(state)(p);
                if (v instanceof Fail) {
                    return new Fail(v.value0);
                };
                if (v instanceof Success) {
                    return go$prime(v.value0)(Control_Applicative.pure(dictApplicative)(v.value1));
                };
                throw new Error("Failed pattern match at Routing.Duplex.Parser (line 215, column 5 - line 217, column 46): " + [ v.constructor.name ]);
            };
            return new Chomp(go);
        };
    };
};
var run = function (p) {
    var $251 = Data_Function.flip(runRouteParser)(p);
    return function ($252) {
        return (function (v) {
            if (v instanceof Fail) {
                return new Data_Either.Left(v.value0);
            };
            if (v instanceof Success) {
                return new Data_Either.Right(v.value1);
            };
            throw new Error("Failed pattern match at Routing.Duplex.Parser (line 188, column 49 - line 190, column 29): " + [ v.constructor.name ]);
        })($251(parsePath($252)));
    };
};
var $$boolean = function (v) {
    if (v === "true") {
        return new Data_Either.Right(true);
    };
    if (v === "false") {
        return new Data_Either.Right(false);
    };
    return new Data_Either.Left("Boolean");
};
var as = function (print) {
    return function (decode) {
        return function (p) {
            return new Chomp(function (state) {
                var v = runRouteParser(state)(p);
                if (v instanceof Fail) {
                    return new Fail(v.value0);
                };
                if (v instanceof Success) {
                    var v1 = decode(v.value1);
                    if (v1 instanceof Data_Either.Left) {
                        return Fail.create(new Expected(v1.value0, print(v.value1)));
                    };
                    if (v1 instanceof Data_Either.Right) {
                        return new Success(v.value0, v1.value0);
                    };
                    throw new Error("Failed pattern match at Routing.Duplex.Parser (line 242, column 7 - line 244, column 36): " + [ v1.constructor.name ]);
                };
                throw new Error("Failed pattern match at Routing.Duplex.Parser (line 239, column 3 - line 244, column 36): " + [ v.constructor.name ]);
            });
        };
    };
};
var applyRouteParser = new Control_Apply.Apply(function () {
    return functorRouteParser;
}, function (fx) {
    return function (x) {
        return new Chomp(function (state) {
            var v = runRouteParser(state)(fx);
            if (v instanceof Fail) {
                return new Fail(v.value0);
            };
            if (v instanceof Success) {
                return Data_Functor.map(functorRouteResult)(v.value1)(runRouteParser(v.value0)(x));
            };
            throw new Error("Failed pattern match at Routing.Duplex.Parser (line 76, column 5 - line 78, column 56): " + [ v.constructor.name ]);
        });
    };
});
var applicativeRouteParser = new Control_Applicative.Applicative(function () {
    return applyRouteParser;
}, (function () {
    var $253 = Data_Function.flip(Success.create);
    return function ($254) {
        return Chomp.create($253($254));
    };
})());
var altSnoc = function (ls) {
    return function (v) {
        var v1 = function (v2) {
            return Data_Array_NonEmpty.snoc(ls)(v);
        };
        if (v instanceof Prefix) {
            var $196 = Data_Array_NonEmpty.last(ls);
            if ($196 instanceof Prefix) {
                var $197 = v.value0 === $196.value0;
                if ($197) {
                    return Data_Array_NonEmpty["snoc'"](Data_Array_NonEmpty.init(ls))(new Prefix(v.value0, Control_Alt.alt(altRouteParser)($196.value1)(v.value1)));
                };
                return v1(true);
            };
            return v1(true);
        };
        return v1(true);
    };
};
var altRouteParser = new Control_Alt.Alt(function () {
    return functorRouteParser;
}, function (v) {
    return function (v1) {
        if (v instanceof Alt && v1 instanceof Alt) {
            return new Alt(altAppend(v.value0)(v1.value0));
        };
        if (v instanceof Alt) {
            return new Alt(altSnoc(v.value0)(v1));
        };
        if (v1 instanceof Alt) {
            return new Alt(altCons(v)(v1.value0));
        };
        if (v instanceof Prefix && (v1 instanceof Prefix && v.value0 === v1.value0)) {
            return new Prefix(v.value0, Control_Alt.alt(altRouteParser)(v.value1)(v1.value1));
        };
        return new Alt(Data_Array_NonEmpty.cons(v)(Data_Array_NonEmpty.singleton(v1)));
    };
});
var altCons = function (v) {
    return function (rs) {
        var v1 = function (v2) {
            return Data_Array_NonEmpty.cons(v)(rs);
        };
        if (v instanceof Prefix) {
            var $216 = Data_Array_NonEmpty.head(rs);
            if ($216 instanceof Prefix) {
                var $217 = v.value0 === $216.value0;
                if ($217) {
                    return Data_Array_NonEmpty["cons'"](new Prefix(v.value0, Control_Alt.alt(altRouteParser)(v.value1)($216.value1)))(Data_Array_NonEmpty.tail(rs));
                };
                return v1(true);
            };
            return v1(true);
        };
        return v1(true);
    };
};
var altAppend = function ($copy_ls) {
    return function ($copy_rs) {
        var $tco_var_ls = $copy_ls;
        var $tco_done = false;
        var $tco_result;
        function $tco_loop(ls, rs) {
            var v = function (v1) {
                if (Data_Boolean.otherwise) {
                    return Data_Semigroup.append(Data_Array_NonEmpty_Internal.semigroupNonEmptyArray)(ls)(rs);
                };
                throw new Error("Failed pattern match at Routing.Duplex.Parser (line 98, column 1 - line 101, column 32): " + [ ls.constructor.name, rs.constructor.name ]);
            };
            var $226 = Data_Array_NonEmpty.last(ls);
            if ($226 instanceof Prefix) {
                var $227 = Data_Array_NonEmpty.head(rs);
                if ($227 instanceof Prefix) {
                    var $228 = $226.value0 === $227.value0;
                    if ($228) {
                        var rs$prime = Data_Array_NonEmpty["cons'"](new Prefix($226.value0, Control_Alt.alt(altRouteParser)($226.value1)($227.value1)))(Data_Array_NonEmpty.tail(rs));
                        var v1 = Data_Array_NonEmpty.fromArray(Data_Array_NonEmpty.init(ls));
                        if (v1 instanceof Data_Maybe.Just) {
                            $tco_var_ls = v1.value0;
                            $copy_rs = rs$prime;
                            return;
                        };
                        if (v1 instanceof Data_Maybe.Nothing) {
                            $tco_done = true;
                            return rs$prime;
                        };
                        throw new Error("Failed pattern match at Routing.Duplex.Parser (line 110, column 9 - line 112, column 26): " + [ v1.constructor.name ]);
                    };
                    $tco_done = true;
                    return v(true);
                };
                $tco_done = true;
                return v(true);
            };
            $tco_done = true;
            return v(true);
        };
        while (!$tco_done) {
            $tco_result = $tco_loop($tco_var_ls, $copy_rs);
        };
        return $tco_result;
    };
};
var $$default = (function () {
    var $255 = Data_Function.flip(Control_Alt.alt(altRouteParser));
    var $256 = Control_Applicative.pure(applicativeRouteParser);
    return function ($257) {
        return $255($256($257));
    };
})();
var flag = (function () {
    var $258 = $$default(false);
    var $259 = Data_Functor.map(functorRouteParser)(Data_Function["const"](true));
    return function ($260) {
        return $258($259(param($260)));
    };
})();
var optional = (function () {
    var $261 = $$default(Data_Maybe.Nothing.value);
    var $262 = Data_Functor.map(functorRouteParser)(Data_Maybe.Just.create);
    return function ($263) {
        return $261($262($263));
    };
})();
var many = function (dictAlternative) {
    return function (p) {
        return Control_Alt.alt(altRouteParser)(many1((dictAlternative.Plus1()).Alt0())(dictAlternative.Applicative0())(p))(Control_Applicative.pure(applicativeRouteParser)(Control_Plus.empty(dictAlternative.Plus1())));
    };
};
module.exports = {
    Expected: Expected,
    ExpectedEndOfPath: ExpectedEndOfPath,
    MissingParam: MissingParam,
    EndOfPath: EndOfPath,
    Fail: Fail,
    Success: Success,
    Alt: Alt,
    Chomp: Chomp,
    Prefix: Prefix,
    runRouteParser: runRouteParser,
    parsePath: parsePath,
    run: run,
    prefix: prefix,
    take: take,
    param: param,
    flag: flag,
    many1: many1,
    many: many,
    rest: rest,
    "default": $$default,
    optional: optional,
    as: as,
    "int": $$int,
    "boolean": $$boolean,
    hash: hash,
    end: end,
    eqRouteResult: eqRouteResult,
    functorRouteResult: functorRouteResult,
    genericRouteResult: genericRouteResult,
    showRouteResult: showRouteResult,
    eqRouteError: eqRouteError,
    genericRouteError: genericRouteError,
    showRouteError: showRouteError,
    functorRouteParser: functorRouteParser,
    applyRouteParser: applyRouteParser,
    applicativeRouteParser: applicativeRouteParser,
    altRouteParser: altRouteParser,
    lazyRouteParser: lazyRouteParser
};
