// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Breakpoints = require("../AdminUi.AdminClient.Breakpoints/index.js");
var AdminUi_AdminClient_Components_Icons_AntdIcons = require("../AdminUi.AdminClient.Components.Icons.AntdIcons/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var AdminUi_AdminClient_Components_Utils = require("../AdminUi.AdminClient.Components.Utils/index.js");
var AdminUi_AdminClient_Utils_Display = require("../AdminUi.AdminClient.Utils.Display/index.js");
var AdminUi_Antd_Button = require("../AdminUi.Antd.Button/index.js");
var AdminUi_Antd_PropLits = require("../AdminUi.Antd.PropLits/index.js");
var AdminUi_Antd_Typography = require("../AdminUi.Antd.Typography/index.js");
var AdminUi_Antd_Upload = require("../AdminUi.Antd.Upload/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Control_MonadZero = require("../Control.MonadZero/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Jss_ReactJss = require("../Jss.ReactJss/index.js");
var $$Math = require("../Math/index.js");
var PapaParse = require("../PapaParse/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var Web_File_File = require("../Web.File.File/index.js");
var Web_File_Url = require("../Web.File.Url/index.js");
var FileSize = function (x) {
    return x;
};
var ExceedMaxEntriesErr = (function () {
    function ExceedMaxEntriesErr() {

    };
    ExceedMaxEntriesErr.value = new ExceedMaxEntriesErr();
    return ExceedMaxEntriesErr;
})();
var ParseErr = (function () {
    function ParseErr() {

    };
    ParseErr.value = new ParseErr();
    return ParseErr;
})();
var AnyFile = (function () {
    function AnyFile(value0) {
        this.value0 = value0;
    };
    AnyFile.create = function (value0) {
        return new AnyFile(value0);
    };
    return AnyFile;
})();
var CsvFile = (function () {
    function CsvFile(value0) {
        this.value0 = value0;
    };
    CsvFile.create = function (value0) {
        return new CsvFile(value0);
    };
    return CsvFile;
})();
var useStyles = Jss_ReactJss.createUseStyles()()()({
    downloadTemplate: Jss_ReactJss.style({
        marginBottom: 15,
        fontSize: 12
    }),
    fileDescriptionContainer: Jss_ReactJss.style({
        display: "flex",
        alignItems: "center",
        marginTop: 8
    }),
    fileIcon: Jss_ReactJss.style({
        marginRight: 5
    }),
    container: Jss_ReactJss.style({
        maxWidth: AdminUi_AdminClient_Breakpoints.md,
        display: "flex",
        flexDirection: "column",
        marginTop: "-10px"
    }),
    fileName: Jss_ReactJss.style({
        flex: 2
    }),
    fileSize: Jss_ReactJss.style({
        flex: 1
    }),
    fileDownload: Jss_ReactJss.style({
        marginRight: 5
    }),
    fileDescIcons: Jss_ReactJss.style({
        flex: 1,
        display: "flex"
    }),
    csvFileDesc: Jss_ReactJss.style({
        marginTop: 5
    })
})([ {
    minWidth: AdminUi_AdminClient_Breakpoints.sm,
    classes: {
        fileName: Jss_ReactJss.style({
            flex: 4
        })
    }
} ]);
var mkFileSize = function ($44) {
    return FileSize(Web_File_File.size($44));
};
var fileSizeDisplay = new AdminUi_AdminClient_Utils_Display.Display(function (v) {
    var go = function (size) {
        return function (filePrefix) {
            var m = Data_Array.uncons(filePrefix);
            var firstcand = Data_Show.show(Data_Show.showNumber)($$Math.round(size)) + (" " + Data_Foldable.fold(Data_Foldable.foldableMaybe)(Data_Monoid.monoidString)(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(m)(function (v1) {
                return v1.head;
            })));
            var $11 = size < 1000.0;
            if ($11) {
                return firstcand;
            };
            var tail = Control_Bind.bind(Data_Maybe.bindMaybe)(m)(function (v1) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Data_Maybe.bindMaybe)(Control_MonadZero.guard(Data_Maybe.monadZeroMaybe)(Data_Array.length(v1.tail) > 0))(function () {
                    return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(v1.tail);
                });
            });
            return Data_Maybe.maybe(firstcand)(go(size / 1000.0))(tail);
        };
    };
    var fileSizes = [ "B", "KB", "MB", "GB" ];
    return go(v)(fileSizes);
});
var mkFileInput = React_Basic_Hooks.component()()()("FileInput")(function (v) {
    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(Data_Maybe.Nothing.value))(function (v1) {
        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useStyles({}))(function (css) {
            var setFileState_ = function ($45) {
                return v1.value1(Data_Function["const"]($45));
            };
            var rawFile = Control_Bind.bind(Data_Maybe.bindMaybe)(v1.value0)(function (v2) {
                if (v2 instanceof AnyFile) {
                    return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(v2.value0);
                };
                if (v2 instanceof CsvFile) {
                    return Data_Either.hush(Data_Functor.mapFlipped(Data_Either.functorEither)(v2.value0)(function (v3) {
                        return v3.file;
                    }));
                };
                throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Content.ContentForm.FileInput (line 64, column 15 - line 66, column 55): " + [ v2.constructor.name ]);
            });
            var onAttachFile = function (file) {
                if (v.fileInputType instanceof AdminUi_AdminClient_Components_Types.CsvFileInput) {
                    return Effect_Aff.runAff_(function (res) {
                        return setFileState_(Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new CsvFile((function () {
                            if (res instanceof Data_Either.Left) {
                                return new Data_Either.Left(ParseErr.value);
                            };
                            if (res instanceof Data_Either.Right) {
                                return Control_Bind.discard(Control_Bind.discardUnit)(Data_Either.bindEither)(Control_Applicative.when(Data_Either.applicativeEither)(Data_Array.length(res.value0.errors) > 0)(Control_Monad_Error_Class.throwError(Control_Monad_Error_Class.monadThrowEither)(ParseErr.value)))(function () {
                                    return Control_Bind.discard(Control_Bind.discardUnit)(Data_Either.bindEither)(Data_Foldable.for_(Data_Either.applicativeEither)(Data_Foldable.foldableMaybe)(v.fileInputType.value0.maxEntries)(function (maxEntries) {
                                        return Control_Applicative.when(Data_Either.applicativeEither)(Data_Array.length(res.value0.data) > maxEntries)(Control_Monad_Error_Class.throwError(Control_Monad_Error_Class.monadThrowEither)(ExceedMaxEntriesErr.value));
                                    }))(function () {
                                        return Control_Applicative.pure(Data_Either.applicativeEither)({
                                            file: file,
                                            rows: res.value0.data
                                        });
                                    });
                                });
                            };
                            throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Content.ContentForm.FileInput (line 78, column 33 - line 83, column 59): " + [ res.constructor.name ]);
                        })())));
                    })(PapaParse.papaParse_(file));
                };
                if (v.fileInputType instanceof AdminUi_AdminClient_Components_Types.AnyFileInput) {
                    return setFileState_(new Data_Maybe.Just(new AnyFile(file)));
                };
                throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Content.ContentForm.FileInput (line 72, column 27 - line 87, column 60): " + [ v.fileInputType.constructor.name ]);
            };
            var createObjectUrl_ = function (file) {
                return Data_Traversable.sequence(Data_Traversable.traversableMaybe)(Effect.applicativeEffect)(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(file)(function (f) {
                    return Web_File_Url.createObjectURL(Web_File_File.toBlob(f));
                }));
            };
            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks_Internal.unsafeRenderEffect(createObjectUrl_(rawFile)))(function (v2) {
                return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.div()({
                    className: css.container,
                    children: [ Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(React_Basic.monoidJSX)(function (tUrl) {
                        return React_Basic_DOM_Generated.a()({
                            className: css.downloadTemplate,
                            href: tUrl,
                            children: [ React_Basic_DOM.text("Download Template") ],
                            target: "_blank"
                        });
                    })(v.templateUrl), AdminUi_Antd_Upload.upload()({
                        onRemove: function (v3) {
                            return setFileState_(Data_Maybe.Nothing.value)();
                        },
                        beforeUpload: function (file) {
                            return Data_Functor.voidLeft(Effect.functorEffect)(onAttachFile(file))(false)();
                        },
                        showUploadList: false,
                        fileList: Data_Unfoldable.fromMaybe(Data_Unfoldable.unfoldableArray)(rawFile),
                        children: [ AdminUi_Antd_Button.button()({
                            children: React_Basic.fragment([ AdminUi_AdminClient_Components_Icons_AntdIcons.mkAntdIcon()(AdminUi_AdminClient_Components_Icons_AntdIcons.UploadOutlined.value)({}), React_Basic_DOM.text("Select File") ])
                        }) ],
                        accept: (function () {
                            if (v.fileInputType instanceof AdminUi_AdminClient_Components_Types.AnyFileInput) {
                                return AdminUi_AdminClient_Components_Utils.toUndefined()(Data_Maybe.Nothing.value);
                            };
                            if (v.fileInputType instanceof AdminUi_AdminClient_Components_Types.CsvFileInput) {
                                return AdminUi_AdminClient_Components_Utils.toUndefined()(new Data_Maybe.Just(".csv"));
                            };
                            throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Content.ContentForm.FileInput (line 119, column 19 - line 121, column 64): " + [ v.fileInputType.constructor.name ]);
                        })()
                    }), Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(React_Basic.monoidJSX)(function (file) {
                        return React_Basic_DOM_Generated.div()({
                            className: css.fileDescriptionContainer,
                            children: [ AdminUi_AdminClient_Components_Icons_AntdIcons.mkAntdIcon()(AdminUi_AdminClient_Components_Icons_AntdIcons.PaperClipOutlined.value)({
                                className: css.fileIcon
                            }), AdminUi_Antd_Typography.txt()({
                                className: css.fileName,
                                children: Web_File_File.name(file),
                                ellipsis: true
                            }), AdminUi_Antd_Typography.txt()({
                                className: css.fileSize,
                                children: AdminUi_AdminClient_Utils_Display.display(fileSizeDisplay)(mkFileSize(file))
                            }), React_Basic_DOM_Generated.div()({
                                className: css.fileDescIcons,
                                children: [ Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(React_Basic.monoidJSX)(function (url) {
                                    return React_Basic_DOM_Generated.a()({
                                        children: [ AdminUi_AdminClient_Components_Icons_AntdIcons.mkAntdIcon()(AdminUi_AdminClient_Components_Icons_AntdIcons.DownloadOutlined.value)({}) ],
                                        className: css.fileDownload,
                                        href: url,
                                        download: Web_File_File.name(file)
                                    });
                                })(v2), React_Basic_DOM_Generated.a()({
                                    children: [ AdminUi_AdminClient_Components_Icons_AntdIcons.mkAntdIcon()(AdminUi_AdminClient_Components_Icons_AntdIcons.DeleteOutlined.value)({}) ],
                                    onClick: React_Basic_DOM_Events.capture_(setFileState_(Data_Maybe.Nothing.value))
                                }) ]
                            }) ]
                        });
                    })(rawFile), (function () {
                        if (v.fileInputType instanceof AdminUi_AdminClient_Components_Types.AnyFileInput) {
                            return Data_Monoid.mempty(React_Basic.monoidJSX);
                        };
                        if (v.fileInputType instanceof AdminUi_AdminClient_Components_Types.CsvFileInput) {
                            return Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(React_Basic.monoidJSX)(function (v3) {
                                if (v3 instanceof AnyFile) {
                                    return Data_Monoid.mempty(React_Basic.monoidJSX);
                                };
                                if (v3 instanceof CsvFile) {
                                    return React_Basic_DOM_Generated.div()({
                                        className: css.csvFileDesc,
                                        children: [ (function () {
                                            if (v3.value0 instanceof Data_Either.Left) {
                                                return AdminUi_Antd_Typography.txt()({
                                                    type: AdminUi_Antd_PropLits.danger,
                                                    children: (function () {
                                                        if (v3.value0.value0 instanceof ExceedMaxEntriesErr) {
                                                            return Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)(" ")([ "Please limit csv to", Data_Foldable.fold(Data_Foldable.foldableMaybe)(Data_Monoid.monoidString)(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(v.fileInputType.value0.maxEntries)(Data_Show.show(Data_Show.showInt))), "rows" ]);
                                                        };
                                                        if (v3.value0.value0 instanceof ParseErr) {
                                                            return "Please attach a valid CSV file";
                                                        };
                                                        throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Content.ContentForm.FileInput (line 176, column 41 - line 178, column 87): " + [ v3.value0.value0.constructor.name ]);
                                                    })()
                                                });
                                            };
                                            if (v3.value0 instanceof Data_Either.Right) {
                                                return AdminUi_Antd_Typography.txt()({
                                                    children: Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)(" ")([ Data_Show.show(Data_Show.showInt)(Data_Array.length(v3.value0.value0.rows)), v.fileInputType.value0.entityName, "to be uploaded" ])
                                                });
                                            };
                                            throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Content.ContentForm.FileInput (line 171, column 33 - line 183, column 40): " + [ v3.value0.constructor.name ]);
                                        })() ]
                                    });
                                };
                                throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Content.ContentForm.FileInput (line 165, column 23 - line 185, column 30): " + [ v3.constructor.name ]);
                            })(v1.value0);
                        };
                        throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Content.ContentForm.FileInput (line 161, column 15 - line 187, column 30): " + [ v.fileInputType.constructor.name ]);
                    })() ]
                }));
            });
        });
    });
});
module.exports = {
    mkFileInput: mkFileInput
};
