// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_Utils = require("../AdminUi.AdminClient.Components.Utils/index.js");
var AdminUi_AdminClient_Components_ZText = require("../AdminUi.AdminClient.Components.ZText/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Jss_ReactJss = require("../Jss.ReactJss/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var useStyles = Jss_ReactJss.createUseStyles_()()({
    basicUl: Jss_ReactJss.style({
        padding: "0px 0px 0px 10px",
        listStyle: "none"
    }),
    basicLi: Jss_ReactJss.style({
        margin: "5px 0"
    })
});
var mkContentList = function (dictMonadAsk) {
    return function (dictMonadEffect) {
        return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Components_ZText.mkZText(dictMonadAsk)(dictMonadEffect))(function (zText) {
            return AdminUi_AdminClient_Components_Utils.comp_(dictMonadEffect)()()()("ContentList")(function (v) {
                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useStyles({}))(function (css) {
                    return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.figure()({
                        children: [ Data_Monoid.guard(React_Basic.monoidJSX)(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"])(v.list.caption))(React_Basic_DOM_Generated.figcaption()({
                            children: [ React_Basic_DOM.text(v.list.caption) ]
                        })), React_Basic_DOM_Generated.ul()({
                            className: css.basicUl,
                            children: Data_Array.mapWithIndex(function (idx) {
                                return function (item) {
                                    return React_Basic_DOM_Generated.li()({
                                        key: Data_Show.show(Data_Show.showInt)(idx),
                                        className: css.basicLi,
                                        children: [ React_Basic.element(zText)({
                                            text: item
                                        }) ]
                                    });
                                };
                            })(v.list.items)
                        }) ]
                    }));
                });
            });
        });
    };
};
module.exports = {
    mkContentList: mkContentList
};
