// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var React_Basic = require("../React.Basic/index.js");
var Untagged_Coercible = require("../Untagged.Coercible/index.js");
var tabs = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_tabs"])(Untagged_Coercible.coerce()(props));
    };
};
var tabPane = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_tabPane"])(Untagged_Coercible.coerce()(props));
    };
};
module.exports = {
    tabs: tabs,
    tabPane: tabPane,
    "_tabs": $foreign["_tabs"],
    "_tabPane": $foreign["_tabPane"]
};
