// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_Utils = require("../AdminUi.AdminClient.Components.Utils/index.js");
var AdminUi_AdminClient_Components_ZText = require("../AdminUi.AdminClient.Components.ZText/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Jss_ReactJss = require("../Jss.ReactJss/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var useStyles = Jss_ReactJss.createUseStyles_()()({
    cellSubText: Jss_ReactJss.style({
        fontSize: "12px"
    })
});
var mkTableCell = function (dictMonadAsk) {
    return function (dictMonadEffect) {
        return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Components_ZText.mkZText(dictMonadAsk)(dictMonadEffect))(function (zText) {
            return AdminUi_AdminClient_Components_Utils.comp_(dictMonadEffect)()()()("TableCell")(function (props) {
                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useStyles({}))(function (css) {
                    return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic.fragment(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ React_Basic.element(zText)({
                        text: props.cell.text
                    }) ])((function () {
                        if (props.cell.subText instanceof Data_Maybe.Just) {
                            return [ React_Basic_DOM_Generated.br()({}), React_Basic_DOM_Generated.span()({
                                className: css.cellSubText,
                                children: [ React_Basic.element(zText)({
                                    text: props.cell.subText.value0
                                }) ]
                            }) ];
                        };
                        if (props.cell.subText instanceof Data_Maybe.Nothing) {
                            return Control_Applicative.pure(Control_Applicative.applicativeArray)(Data_Monoid.mempty(React_Basic.monoidJSX));
                        };
                        throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Table.TableCell (line 32, column 10 - line 40, column 33): " + [ props.cell.subText.constructor.name ]);
                    })())));
                });
            });
        });
    };
};
module.exports = {
    mkTableCell: mkTableCell
};
