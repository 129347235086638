// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var AdminUi_AdminClient_Breakpoints = require("../AdminUi.AdminClient.Breakpoints/index.js");
var AdminUi_AdminClient_Components_Content_ContentForm_FileInput = require("../AdminUi.AdminClient.Components.Content.ContentForm.FileInput/index.js");
var AdminUi_AdminClient_Components_Details = require("../AdminUi.AdminClient.Components.Details/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var AdminUi_AdminClient_Components_Utils = require("../AdminUi.AdminClient.Components.Utils/index.js");
var AdminUi_AdminClient_Hooks_UseActionHandler = require("../AdminUi.AdminClient.Hooks.UseActionHandler/index.js");
var AdminUi_AdminClient_Utils_Display = require("../AdminUi.AdminClient.Utils.Display/index.js");
var AdminUi_Antd_Button = require("../AdminUi.Antd.Button/index.js");
var AdminUi_Antd_Form = require("../AdminUi.Antd.Form/index.js");
var AdminUi_Antd_Input = require("../AdminUi.Antd.Input/index.js");
var AdminUi_Antd_PropLits = require("../AdminUi.Antd.PropLits/index.js");
var AdminUi_Antd_Select = require("../AdminUi.Antd.Select/index.js");
var AdminUi_Spacer = require("../AdminUi.Spacer/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep_Bounded = require("../Data.Generic.Rep.Bounded/index.js");
var Data_Generic_Rep_Enum = require("../Data.Generic.Rep.Enum/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unfoldable1 = require("../Data.Unfoldable1/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Class_Console = require("../Effect.Class.Console/index.js");
var Jss_ReactJss = require("../Jss.ReactJss/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var useContentFormStyles = Jss_ReactJss.createUseStyles_()()({
    buttonsWrapper: Jss_ReactJss.style({
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "20px"
    }),
    form: Jss_ReactJss.style({
        maxWidth: AdminUi_AdminClient_Breakpoints.md
    })
});
var textInput = AdminUi_AdminClient_Components_Types.TextInput.create;
var select = function (dictEq) {
    return function (dictDisplay) {
        return function (args) {
            var defaultChoice = Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(Control_Bind.bind(Data_Maybe.bindMaybe)(args.defaultChoice)(function (choice) {
                return Data_Array.elemIndex(dictEq)(choice)(args.choices);
            }))(Data_Show.show(Data_Show.showInt));
            var choices = Data_Array.mapWithIndex(function (i) {
                return function (choice) {
                    return {
                        label: AdminUi_AdminClient_Utils_Display.display(dictDisplay)(choice),
                        value: Data_Show.show(Data_Show.showInt)(i)
                    };
                };
            })(args.choices);
            return new AdminUi_AdminClient_Components_Types.Select({
                placeholder: args.placeholder,
                choices: choices,
                defaultChoice: defaultChoice
            });
        };
    };
};
var mkInputField = function (dictMonadEffect) {
    return Control_Bind.bind((dictMonadEffect.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadEffect)(AdminUi_AdminClient_Components_Content_ContentForm_FileInput.mkFileInput))(function (fileInputC) {
        return AdminUi_AdminClient_Components_Utils.comp_(dictMonadEffect)()()()("InputField")(function (v) {
            return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))((function () {
                if (v.input instanceof AdminUi_AdminClient_Components_Types.TextInput) {
                    return AdminUi_Antd_Input.input()({
                        placeholder: v.input.value0.placeholder
                    });
                };
                if (v.input instanceof AdminUi_AdminClient_Components_Types.Select) {
                    return AdminUi_Antd_Select.select()({
                        allowClear: true,
                        placeholder: React_Basic_DOM.text(v.input.value0.placeholder),
                        defaultValue: AdminUi_AdminClient_Components_Utils.toUndefined()(v.input.value0.defaultChoice),
                        children: Data_Array.mapWithIndex(function (i) {
                            return function (choice) {
                                return AdminUi_Antd_Select.option()({
                                    value: choice.value,
                                    children: choice.label
                                });
                            };
                        })(v.input.value0.choices)
                    });
                };
                if (v.input instanceof AdminUi_AdminClient_Components_Types.GooglePlacesInput) {
                    return React_Basic.element($foreign["_googlePlacesInput"])({
                        placeholder: v.input.value0.placeholder,
                        defaultValue: AdminUi_AdminClient_Components_Utils.toUndefined()(v.input.value0.defaultValue)
                    });
                };
                if (v.input instanceof AdminUi_AdminClient_Components_Types.ImageInput) {
                    return React_Basic.element($foreign["_imageCropper"])({
                        width: v.input.value0.width,
                        height: v.input.value0.height,
                        onCropChange: function (image, croppedAreaPixels) {
                            return Effect_Class_Console.logShow(Effect_Class.monadEffectEffect)(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
                                return "height";
                            }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
                                return "width";
                            }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
                                return "x";
                            }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
                                return "y";
                            }))(Data_Show.showRecordFieldsNil)(Data_Show.showInt))(Data_Show.showInt))(Data_Show.showInt))(Data_Show.showInt)))(croppedAreaPixels)();
                        }
                    });
                };
                if (v.input instanceof AdminUi_AdminClient_Components_Types.FileInput) {
                    return React_Basic.element(fileInputC)(v.input.value0);
                };
                throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Content.ContentForm (line 205, column 9 - line 235, column 52): " + [ v.input.constructor.name ]);
            })());
        });
    });
};
var mkContentForm = function (dictMonadAsk) {
    return function (dictMonadEffect) {
        return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Hooks_UseActionHandler.mkUseActionHandler(dictMonadEffect)(dictMonadAsk))(function (useActionHandler) {
            return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Components_Details.mkDetails(dictMonadAsk)(dictMonadEffect))(function (detailsC) {
                return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(mkInputField(dictMonadEffect))(function (inputField) {
                    return AdminUi_AdminClient_Components_Utils.comp_(dictMonadEffect)()()()("ContentForm")(function (v) {
                        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useContentFormStyles({}))(function (css) {
                            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useActionHandler)(function (actionHandler) {
                                var fields = React_Basic.fragment(Data_Array.mapWithIndex(function (i) {
                                    return function (field) {
                                        return AdminUi_Antd_Form.formItem()({
                                            label: field.label,
                                            children: React_Basic.element(inputField)({
                                                input: field.input
                                            })
                                        });
                                    };
                                })(v.scFormArg.fields));
                                var buttons = React_Basic_DOM_Generated.div()({
                                    className: css.buttonsWrapper,
                                    children: [ AdminUi_Antd_Button.button()({
                                        onClick: React_Basic_DOM_Events.capture_(actionHandler(v.scFormArg.cancel)),
                                        children: React_Basic_DOM.text("Cancel")
                                    }), AdminUi_Spacer.hspacer(2), AdminUi_Antd_Button.button()({
                                        type: AdminUi_Antd_PropLits.primary,
                                        onClick: React_Basic_DOM_Events.capture_(actionHandler(v.scFormArg.submit.action)),
                                        children: React_Basic_DOM.text(v.scFormArg.submit.label)
                                    }) ]
                                });
                                return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic.fragment([ Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(React_Basic.monoidJSX)(function (details) {
                                    return React_Basic.fragment([ React_Basic.element(detailsC)({
                                        columns: 1,
                                        details: details
                                    }), AdminUi_Spacer.vspacer(5) ]);
                                })(v.scFormArg.details), AdminUi_Antd_Form.form()({
                                    colon: false,
                                    layout: AdminUi_Antd_PropLits.vertical,
                                    className: css.form,
                                    children: [ fields, buttons ]
                                }) ]));
                            });
                        });
                    });
                });
            });
        });
    };
};
var imageInput = AdminUi_AdminClient_Components_Types.ImageInput.create;
var googlePlacesInput = AdminUi_AdminClient_Components_Types.GooglePlacesInput.create;
var formField = function (label) {
    return function (input) {
        return {
            label: label,
            input: input
        };
    };
};
var fileInput_ = function (v) {
    return new AdminUi_AdminClient_Components_Types.FileInput({
        templateUrl: v.templateUrl,
        fileInputType: AdminUi_AdminClient_Components_Types.AnyFileInput.value
    });
};
var defaultImageInputSize = {
    width: 180,
    height: 180
};
var imageInput_ = imageInput(defaultImageInputSize);
var csvFileInput = function (v) {
    return new AdminUi_AdminClient_Components_Types.FileInput({
        templateUrl: v.templateUrl,
        fileInputType: new AdminUi_AdminClient_Components_Types.CsvFileInput({
            maxEntries: v.maxEntries,
            entityName: v.entityName
        })
    });
};
var allChoices = function (dictGeneric) {
    return function (dictGenericBottom) {
        return function (dictGenericTop) {
            return function (dictGenericEnum) {
                return Data_Unfoldable1.unfoldr1(Data_Unfoldable1.unfoldable1Array)(Control_Apply.apply(Control_Apply.applyFn)(Data_Tuple.Tuple.create)(Data_Generic_Rep_Enum.genericSucc(dictGeneric)(dictGenericEnum)))(Data_Generic_Rep_Bounded.genericBottom(dictGeneric)(dictGenericBottom));
            };
        };
    };
};
module.exports = {
    mkContentForm: mkContentForm,
    formField: formField,
    textInput: textInput,
    select: select,
    allChoices: allChoices,
    googlePlacesInput: googlePlacesInput,
    imageInput: imageInput,
    imageInput_: imageInput_,
    defaultImageInputSize: defaultImageInputSize,
    fileInput_: fileInput_,
    csvFileInput: csvFileInput
};
