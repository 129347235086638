// Generated by purs version 0.13.6
"use strict";
var Untagged_Coercible = require("../Untagged.Coercible/index.js");
var Untagged_Union = require("../Untagged.Union/index.js");
var title = function (dictCoercible) {
    return function (r) {
        var t$prime = Untagged_Coercible.coerce()(r);
        return {
            overline: Untagged_Union.uorToMaybe(t$prime.overline),
            caption: Untagged_Union.uorToMaybe(t$prime.caption),
            tag: Untagged_Union.uorToMaybe(t$prime.tag),
            main: t$prime.main
        };
    };
};
var title_ = function (main) {
    return title()({
        main: main
    });
};
module.exports = {
    title: title,
    title_: title_
};
