// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_AppAction = require("../AdminUi.AdminClient.Components.AppAction/index.js");
var AdminUi_AdminClient_Components_Content_ContentForm = require("../AdminUi.AdminClient.Components.Content.ContentForm/index.js");
var AdminUi_AdminClient_Components_Page = require("../AdminUi.AdminClient.Components.Page/index.js");
var AdminUi_AdminClient_Components_Title = require("../AdminUi.AdminClient.Components.Title/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var posAddMultipleCashierCodes = function (v) {
    return function (v1) {
        return function (v2) {
            var title = AdminUi_AdminClient_Components_Title.title_("Add Multiple Cashier Codes");
            var form = Control_Applicative.pure(Effect_Aff.applicativeAff)({
                fields: [ AdminUi_AdminClient_Components_Content_ContentForm.formField("Upload CSV File containing Cashier Codes")(AdminUi_AdminClient_Components_Content_ContentForm.csvFileInput({
                    templateUrl: Control_Applicative.pure(Data_Maybe.applicativeMaybe)("https://s3-ap-southeast-1.amazonaws.com/merchant.assets.zap.com.ph/zap-sms-blast.xlsx"),
                    entityName: "cashier codes",
                    maxEntries: new Data_Maybe.Just(10)
                })) ],
                submit: {
                    label: "Add Cashier Codes",
                    action: AdminUi_AdminClient_Components_AppAction.goBack
                }
            });
            return Control_Applicative.pure(Control_Monad_Reader_Trans.applicativeReaderT(Data_Identity.applicativeIdentity))(AdminUi_AdminClient_Components_Page.pageForm_(title)(form));
        };
    };
};
module.exports = {
    posAddMultipleCashierCodes: posAddMultipleCashierCodes
};
