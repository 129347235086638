// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_Icons_AntdIcons = require("../AdminUi.AdminClient.Components.Icons.AntdIcons/index.js");
var AdminUi_AdminClient_Components_Icons_ZapIcons = require("../AdminUi.AdminClient.Components.Icons.ZapIcons/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var mkIcon = function (dictCoercible) {
    return function (v) {
        if (v instanceof AdminUi_AdminClient_Components_Types.ZapI) {
            return AdminUi_AdminClient_Components_Icons_ZapIcons.mkZapIcon()(v.value0);
        };
        if (v instanceof AdminUi_AdminClient_Components_Types.AntdI) {
            return AdminUi_AdminClient_Components_Icons_AntdIcons.mkAntdIcon()(v.value0);
        };
        throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Icons (line 15, column 10 - line 17, column 29): " + [ v.constructor.name ]);
    };
};
module.exports = {
    mkIcon: mkIcon
};
