// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var React_Basic = require("../React.Basic/index.js");
var Untagged_Coercible = require("../Untagged.Coercible/index.js");
var sider = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_sider"])(Untagged_Coercible.coerce()(props));
    };
};
var layout = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_layout"])(Untagged_Coercible.coerce()(props));
    };
};
var header = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_header"])(Untagged_Coercible.coerce()(props));
    };
};
var footer = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_footer"])(Untagged_Coercible.coerce()(props));
    };
};
var content = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_content"])(Untagged_Coercible.coerce()(props));
    };
};
module.exports = {
    layout: layout,
    content: content,
    footer: footer,
    header: header,
    sider: sider,
    "_layout": $foreign["_layout"],
    "_content": $foreign["_content"],
    "_footer": $foreign["_footer"],
    "_header": $foreign["_header"],
    "_sider": $foreign["_sider"]
};
