// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_AppAction = require("../AdminUi.AdminClient.Components.AppAction/index.js");
var AdminUi_AdminClient_Components_Content = require("../AdminUi.AdminClient.Components.Content/index.js");
var AdminUi_AdminClient_Components_Section = require("../AdminUi.AdminClient.Components.Section/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var pageForm = function (breadcrumbOverwrite) {
    return function (title) {
        return function (actionGroup) {
            return function (formArgAff) {
                return {
                    breadcrumbOverwrite: breadcrumbOverwrite,
                    title: title,
                    actionGroup: actionGroup,
                    sections: [ AdminUi_AdminClient_Components_Section.section_(Data_Maybe.Nothing.value)(Data_Maybe.Nothing.value)(Data_Functor.mapFlipped(Effect_Aff.functorAff)(formArgAff)(AdminUi_AdminClient_Components_Content.form)) ],
                    bottomSection: Data_Maybe.Nothing.value
                };
            };
        };
    };
};
var pageForm_ = function (title) {
    return function (formArgs) {
        return pageForm(Data_Maybe.Nothing.value)(title)(Data_Maybe.Nothing.value)(Data_Functor.mapFlipped(Effect_Aff.functorAff)(formArgs)(function (v) {
            return {
                fields: v.fields,
                submit: v.submit,
                cancel: AdminUi_AdminClient_Components_AppAction.goBack,
                details: Data_Maybe.Nothing.value
            };
        }));
    };
};
var page = function (breadcrumbOverwrite) {
    return function (title) {
        return function (actionGroup) {
            return function (sections) {
                return function (bottomSection) {
                    return {
                        breadcrumbOverwrite: breadcrumbOverwrite,
                        title: title,
                        actionGroup: actionGroup,
                        sections: sections,
                        bottomSection: bottomSection
                    };
                };
            };
        };
    };
};
module.exports = {
    page: page,
    pageForm: pageForm,
    pageForm_: pageForm_
};
