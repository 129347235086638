// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Colors = require("../AdminUi.AdminClient.Colors/index.js");
var AdminUi_AdminClient_Components_Icons = require("../AdminUi.AdminClient.Components.Icons/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var AdminUi_AdminClient_Components_Utils = require("../AdminUi.AdminClient.Components.Utils/index.js");
var AdminUi_AdminClient_Hooks_UseActionHandler = require("../AdminUi.AdminClient.Hooks.UseActionHandler/index.js");
var AdminUi_Antd_Typography = require("../AdminUi.Antd.Typography/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Jss_ReactJss = require("../Jss.ReactJss/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Record = require("../Record/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var zString = function (dictUnion) {
    return function (dictNub) {
        return function (props) {
            var defaultProps = {
                bold: false,
                italic: false,
                underline: false,
                disabled: false
            };
            return AdminUi_AdminClient_Components_Types.ZString.create(Record.merge()()(props)(defaultProps));
        };
    };
};
var zString_ = zString()()({});
var useStyles = Jss_ReactJss.createUseStyles_()()({
    link: Jss_ReactJss.style({
        color: AdminUi_AdminClient_Colors.getColor(AdminUi_AdminClient_Colors.Primary.value)
    })
});
var text_ = function ($21) {
    return AdminUi_AdminClient_Components_Types.ZTString.create(zString_($21));
};
var text = function (dictUnion) {
    return function (dictNub) {
        return function (props) {
            var $22 = zString()()(props);
            return function ($23) {
                return AdminUi_AdminClient_Components_Types.ZTString.create($22($23));
            };
        };
    };
};
var underline = text()()({
    underline: true
});
var mkZText = function (dictMonadAsk) {
    return function (dictMonadEffect) {
        return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Hooks_UseActionHandler.mkUseActionHandler(dictMonadEffect)(dictMonadAsk))(function (useActionHandler) {
            return AdminUi_AdminClient_Components_Utils.comp_(dictMonadEffect)()()()("ZText")(function (props) {
                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useStyles({}))(function (css) {
                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useActionHandler)(function (actionHandler) {
                        var renderZString = function (className) {
                            return function (v) {
                                var splitByNewline = Data_String_Common.split("\x0a");
                                var spans = Data_Functor.mapFlipped(Data_Functor.functorArray)(splitByNewline(v.value1))(function (a$prime) {
                                    return [ AdminUi_Antd_Typography.txt()({
                                        className: className,
                                        strong: v.value0.bold,
                                        underline: v.value0.underline,
                                        disabled: v.value0.disabled,
                                        children: (function () {
                                            if (v.value0.italic) {
                                                return React_Basic_DOM_Generated.i_([ React_Basic_DOM.text(a$prime) ]);
                                            };
                                            if (!v.value0.italic) {
                                                return React_Basic_DOM.text(a$prime);
                                            };
                                            throw new Error("Failed pattern match at AdminUi.AdminClient.Components.ZText (line 128, column 23 - line 130, column 43): " + [ v.value0.italic.constructor.name ]);
                                        })()
                                    }) ];
                                });
                                var joinWithBreak = Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidArray)([ React_Basic_DOM_Generated.br()({}) ]);
                                return React_Basic.fragment(joinWithBreak(spans));
                            };
                        };
                        var renderZText = function (v) {
                            if (v instanceof AdminUi_AdminClient_Components_Types.ZText) {
                                return React_Basic.fragment(Data_Functor.map(Data_Functor.functorArray)(renderZText)(v.value0));
                            };
                            if (v instanceof AdminUi_AdminClient_Components_Types.ZTString) {
                                return renderZString(Data_Monoid.mempty(Data_Monoid.monoidString))(v.value0);
                            };
                            if (v instanceof AdminUi_AdminClient_Components_Types.ZTIcon) {
                                return AdminUi_AdminClient_Components_Icons.mkIcon()(v.value0)({});
                            };
                            if (v instanceof AdminUi_AdminClient_Components_Types.ZTLink) {
                                return React_Basic_DOM_Generated.a()({
                                    onClick: React_Basic_DOM_Events.capture_(actionHandler(v.value1)),
                                    children: [ renderZString(css.link)(v.value0) ]
                                });
                            };
                            throw new Error("Failed pattern match at AdminUi.AdminClient.Components.ZText (line 102, column 21 - line 110, column 14): " + [ v.constructor.name ]);
                        };
                        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(renderZText(props.text));
                    });
                });
            });
        });
    };
};
var link = function (dictUnion) {
    return function (dictNub) {
        return function (props) {
            return function (label) {
                return AdminUi_AdminClient_Components_Types.ZTLink.create(zString()()(props)(label));
            };
        };
    };
};
var link_ = link()()({});
var italic = text()()({
    italic: true
});
var icon = AdminUi_AdminClient_Components_Types.ZTIcon.create;
var disabled = text()()({
    disabled: true
});
var bold = text()()({
    bold: true
});
module.exports = {
    text: text,
    text_: text_,
    bold: bold,
    italic: italic,
    underline: underline,
    disabled: disabled,
    icon: icon,
    link: link,
    link_: link_,
    mkZText: mkZText
};
