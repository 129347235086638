// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var AdminUi_AdminClient_Breakpoints = require("../AdminUi.AdminClient.Breakpoints/index.js");
var AdminUi_AdminClient_Colors = require("../AdminUi.AdminClient.Colors/index.js");
var AdminUi_AdminClient_Components_Icons = require("../AdminUi.AdminClient.Components.Icons/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var AdminUi_AdminClient_Components_Utils = require("../AdminUi.AdminClient.Components.Utils/index.js");
var AdminUi_AdminClient_Hooks_UseActionHandler = require("../AdminUi.AdminClient.Hooks.UseActionHandler/index.js");
var AdminUi_Antd_Button = require("../AdminUi.Antd.Button/index.js");
var AdminUi_Antd_PropLits = require("../AdminUi.Antd.PropLits/index.js");
var AdminUi_Antd_Typography = require("../AdminUi.Antd.Typography/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Show = require("../Data.Show/index.js");
var Jss_ReactJss = require("../Jss.ReactJss/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var useDvTextStyles = Jss_ReactJss.createUseStyles_()()({
    text: Jss_ReactJss["style'"](function (props) {
        return {
            color: AdminUi_AdminClient_Colors.getColor(props.color),
            fontSize: "16px"
        };
    }),
    iconText: Jss_ReactJss.style({
        marginRight: 5
    })
});
var useDvMapStyles = Jss_ReactJss.createUseStyles_()()({
    address: Jss_ReactJss.style({
        fontSize: 16,
        display: "block",
        color: AdminUi_AdminClient_Colors.getColor(AdminUi_AdminClient_Colors.Black.value)
    }),
    mapDetailContainer: Jss_ReactJss.style({
        width: "100%",
        height: "100%"
    }),
    mapDetail: Jss_ReactJss.style({
        marginTop: 5,
        width: "250px",
        height: "180px"
    })
});
var useDetailsStyle = Jss_ReactJss.createUseStyles()()()({
    detailsWrapper: Jss_ReactJss["style'"](Data_Function["const"]({
        display: "grid",
        gridTemplateColumns: "1fr",
        gridGap: "20px 10px"
    })),
    textCell: Jss_ReactJss.style({
        gridRowEnd: "span 1"
    }),
    dvmapCell: Jss_ReactJss.style({
        gridRowEnd: "span 3"
    }),
    dvimageCell: Jss_ReactJss.style({
        gridRowEnd: "span 3"
    }),
    info: Jss_ReactJss.style({
        fontSize: "12px",
        display: "block",
        marginTop: 3,
        lineHeight: 1
    }),
    dvImg: Jss_ReactJss.style({
        maxWidth: "180px",
        borderRadius: 4,
        marginTop: 10
    })
})([ {
    minWidth: AdminUi_AdminClient_Breakpoints.md,
    classes: {
        detailsWrapper: Jss_ReactJss["style'"](function (v) {
            return {
                gridTemplateColumns: Data_Foldable.foldl(Data_Foldable.foldableArray)(function (acc) {
                    return function (a) {
                        return acc + "1fr ";
                    };
                })("")(Data_Array.range(1)(v.columns))
            };
        })
    }
} ]);
var text_ = function (s) {
    return new AdminUi_AdminClient_Components_Types.DVText({
        color: AdminUi_AdminClient_Colors.Black.value,
        icon: Data_Maybe.Nothing.value,
        text: s,
        rightAction: Data_Maybe.Nothing.value
    });
};
var text = AdminUi_AdminClient_Components_Types.DVText.create;
var pair_ = function (label) {
    return function (value) {
        return {
            label: label,
            value: Control_Applicative.pure(Data_Maybe.applicativeMaybe)(value),
            info: Data_Maybe.Nothing.value
        };
    };
};
var pair = Control_Category.identity(Control_Category.categoryFn);
var mkDvText = function (dictMonadAsk) {
    return function (dictMonadEffect) {
        return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Hooks_UseActionHandler.mkUseActionHandler(dictMonadEffect)(dictMonadAsk))(function (useActionHandler) {
            return AdminUi_AdminClient_Components_Utils.comp_(dictMonadEffect)()()()("DvText")(function (v) {
                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useDvTextStyles({
                    color: v.color
                }))(function (css) {
                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useActionHandler)(function (actionHandler) {
                        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic.fragment([ AdminUi_Antd_Typography.txt()({
                            children: React_Basic.fragment([ Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(React_Basic.monoidJSX)(function (i) {
                                return AdminUi_AdminClient_Components_Icons.mkIcon()(i)({
                                    className: css.iconText
                                });
                            })(v.icon), React_Basic_DOM.text(v.text) ]),
                            strong: true,
                            className: css.text
                        }), Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(React_Basic.monoidJSX)(function (v1) {
                            return AdminUi_Antd_Button.button()({
                                type: AdminUi_Antd_PropLits.link,
                                onClick: React_Basic_DOM_Events.capture_(actionHandler(v1.action)),
                                children: React_Basic_DOM.text(v1.label)
                            });
                        })(v.rightAction) ]));
                    });
                });
            });
        });
    };
};
var mkDvMap = function (dictMonadEffect) {
    return AdminUi_AdminClient_Components_Utils.comp_(dictMonadEffect)()()()("DvMap")(function (v) {
        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useDvMapStyles({}))(function (css) {
            return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.div()({
                className: css.mapDetailContainer,
                children: [ AdminUi_Antd_Typography.txt()({
                    children: React_Basic_DOM.text(v.location.address),
                    className: css.address,
                    strong: true
                }), AdminUi_Antd_Typography.txt()({
                    children: React_Basic_DOM.text(Data_Show.show(Data_Show.showNumber)(v.location.latLng.lat) + (", " + Data_Show.show(Data_Show.showNumber)(v.location.latLng.lng)))
                }), React_Basic_DOM_Generated.div()({
                    className: css.mapDetail,
                    children: [ React_Basic.element($foreign.mapDetail)({
                        latLng: v.location.latLng
                    }) ]
                }) ]
            }));
        });
    });
};
var mkDetails = function (dictMonadAsk) {
    return function (dictMonadEffect) {
        return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(mkDvText(dictMonadAsk)(dictMonadEffect))(function (textC) {
            return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(mkDvMap(dictMonadEffect))(function (dvMapC) {
                return AdminUi_AdminClient_Components_Utils.comp_(dictMonadEffect)()()()("CDetails")(function (v) {
                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useDetailsStyle({
                        columns: v.columns
                    }))(function (css) {
                        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.div()({
                            className: css.detailsWrapper,
                            children: Data_Array.mapWithIndex(function (index) {
                                return function (v1) {
                                    return React_Basic_DOM_Generated.div()({
                                        key: Data_Show.show(Data_Show.showInt)(index),
                                        className: Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(Data_Monoid.monoidString)(function (v2) {
                                            if (v2 instanceof AdminUi_AdminClient_Components_Types.DVText) {
                                                return css.textCell;
                                            };
                                            if (v2 instanceof AdminUi_AdminClient_Components_Types.DVImg) {
                                                return css.dvimageCell;
                                            };
                                            if (v2 instanceof AdminUi_AdminClient_Components_Types.DVMap) {
                                                return css.dvmapCell;
                                            };
                                            throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Details (line 77, column 27 - line 80, column 53): " + [ v2.constructor.name ]);
                                        })(v1.value),
                                        children: [ React_Basic_DOM_Generated.div()({
                                            children: [ React_Basic_DOM.text(v1.label) ]
                                        }), (function () {
                                            if (v1.value instanceof Data_Maybe.Nothing) {
                                                return React_Basic_DOM.text("-");
                                            };
                                            if (v1.value instanceof Data_Maybe.Just) {
                                                if (v1.value.value0 instanceof AdminUi_AdminClient_Components_Types.DVText) {
                                                    return React_Basic.element(textC)(v1.value.value0.value0);
                                                };
                                                if (v1.value.value0 instanceof AdminUi_AdminClient_Components_Types.DVImg) {
                                                    return React_Basic_DOM_Generated.img()({
                                                        src: v1.value.value0.value0,
                                                        className: css.dvImg
                                                    });
                                                };
                                                if (v1.value.value0 instanceof AdminUi_AdminClient_Components_Types.DVMap) {
                                                    return React_Basic.element(dvMapC)({
                                                        location: v1.value.value0.value0
                                                    });
                                                };
                                                throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Details (line 89, column 40 - line 92, column 74): " + [ v1.value.value0.constructor.name ]);
                                            };
                                            throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Details (line 87, column 25 - line 92, column 74): " + [ v1.value.constructor.name ]);
                                        })(), Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(React_Basic.monoidJSX)(function (i) {
                                            return AdminUi_Antd_Typography.txt()({
                                                className: css.info,
                                                children: React_Basic_DOM.text(i)
                                            });
                                        })(v1.info) ]
                                    });
                                };
                            })(v.details)
                        }));
                    });
                });
            });
        });
    };
};
var dvMap = AdminUi_AdminClient_Components_Types.DVMap.create;
var dvImg = AdminUi_AdminClient_Components_Types.DVImg.create;
module.exports = {
    pair: pair,
    pair_: pair_,
    text: text,
    text_: text_,
    dvMap: dvMap,
    dvImg: dvImg,
    mkDetails: mkDetails,
    useDetailsStyle: useDetailsStyle,
    mkDvText: mkDvText,
    useDvTextStyles: useDvTextStyles,
    mkDvMap: mkDvMap,
    useDvMapStyles: useDvMapStyles,
    mapDetail: $foreign.mapDetail
};
