// Generated by purs version 0.13.6
"use strict";
var Data_Symbol = require("../Data.Symbol/index.js");
var Literals_Int = require("../Literals.Int/index.js");
var Literals_String = require("../Literals.String/index.js");
var xxl = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "xxl";
}));
var xs = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "xs";
}));
var xl = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "xl";
}));
var warning = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "warning";
}));
var vertical = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "vertical";
}));
var validating = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "validating";
}));
var two = Literals_Int.intLit(new Data_Symbol.IsSymbol(function () {
    return "2";
}))();
var topRight = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "topRight";
}));
var topLeft = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "topLeft";
}));
var topCenter = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "topCenter";
}));
var top = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "top";
}));
var three = Literals_Int.intLit(new Data_Symbol.IsSymbol(function () {
    return "3";
}))();
var tags = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "tags";
}));
var success = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "success";
}));
var small = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "small";
}));
var sm = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "sm";
}));
var round = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "round";
}));
var right = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "right";
}));
var responsive = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "responsive";
}));
var primary = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "primary";
}));
var one = Literals_Int.intLit(new Data_Symbol.IsSymbol(function () {
    return "1";
}))();
var multiple = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "multiple";
}));
var middle = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "middle";
}));
var md = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "md";
}));
var link = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "link";
}));
var line = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "line";
}));
var light = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "light";
}));
var lg = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "lg";
}));
var left = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "left";
}));
var large = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "large";
}));
var inline = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "inline";
}));
var hover = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "hover";
}));
var horizontal = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "horizontal";
}));
var ghost = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "ghost";
}));
var four = Literals_Int.intLit(new Data_Symbol.IsSymbol(function () {
    return "4";
}))();
var error = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "error";
}));
var editableCard = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "editable-card";
}));
var dashed = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "dashed";
}));
var dark = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "dark";
}));
var danger = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "danger";
}));
var clickTrigger = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "clickTrigger";
}));
var click = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "click";
}));
var circle = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "circle";
}));
var card = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "card";
}));
var bottomRight = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "bottomRight";
}));
var bottomLeft = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "bottomLeft";
}));
var bottomCenter = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "bottomCenter";
}));
var bottom = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "bottom";
}));
module.exports = {
    circle: circle,
    round: round,
    small: small,
    middle: middle,
    large: large,
    primary: primary,
    ghost: ghost,
    dashed: dashed,
    danger: danger,
    link: link,
    horizontal: horizontal,
    inline: inline,
    vertical: vertical,
    success: success,
    warning: warning,
    error: error,
    validating: validating,
    one: one,
    two: two,
    three: three,
    four: four,
    multiple: multiple,
    tags: tags,
    clickTrigger: clickTrigger,
    responsive: responsive,
    light: light,
    dark: dark,
    xs: xs,
    sm: sm,
    md: md,
    lg: lg,
    xl: xl,
    xxl: xxl,
    bottomLeft: bottomLeft,
    bottomCenter: bottomCenter,
    bottomRight: bottomRight,
    topLeft: topLeft,
    topCenter: topCenter,
    topRight: topRight,
    click: click,
    hover: hover,
    top: top,
    bottom: bottom,
    right: right,
    left: left,
    line: line,
    card: card,
    editableCard: editableCard
};
