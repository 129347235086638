// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_BottomContent_InfiniteTable = require("../AdminUi.AdminClient.Components.BottomContent.InfiniteTable/index.js");
var AdminUi_AdminClient_Components_Icons_AntdIcons = require("../AdminUi.AdminClient.Components.Icons.AntdIcons/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var AdminUi_AdminClient_Components_Utils = require("../AdminUi.AdminClient.Components.Utils/index.js");
var AdminUi_AdminClient_Hooks_UseAffUi = require("../AdminUi.AdminClient.Hooks.UseAffUi/index.js");
var AdminUi_Antd_Button = require("../AdminUi.Antd.Button/index.js");
var AdminUi_Antd_PropLits = require("../AdminUi.Antd.PropLits/index.js");
var AdminUi_Antd_Result = require("../AdminUi.Antd.Result/index.js");
var AdminUi_Antd_Skeleton = require("../AdminUi.Antd.Skeleton/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var Untagged_Coercible = require("../Untagged.Coercible/index.js");
var skeleton = AdminUi_Antd_Skeleton.skeleton()({
    paragraph: Untagged_Coercible.coerce()({
        rows: 9
    }),
    active: true,
    loading: true,
    title: false
});
var retryUi = function (retryF) {
    return AdminUi_Antd_Result.result()({
        title: React_Basic_DOM.text("Please check your network."),
        icon: AdminUi_AdminClient_Components_Icons_AntdIcons.mkAntdIcon()(AdminUi_AdminClient_Components_Icons_AntdIcons.CloudServerOutlined.value)({}),
        extra: AdminUi_Antd_Button.button()({
            type: AdminUi_Antd_PropLits.primary,
            icon: AdminUi_AdminClient_Components_Icons_AntdIcons.mkAntdIcon()(AdminUi_AdminClient_Components_Icons_AntdIcons.ReloadOutlined.value)({}),
            onClick: React_Basic_Events.handler_(retryF),
            children: React_Basic_DOM.text("Retry")
        })
    });
};
var mkBottomContent = function (dictEq) {
    return function (dictMonadAsk) {
        return function (dictMonadEffect) {
            return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Components_BottomContent_InfiniteTable.mkInfiniteTable(dictEq)(dictMonadAsk)(dictMonadEffect))(function (infiniteTable$prime) {
                return AdminUi_AdminClient_Components_Utils.comp_(dictMonadEffect)()()()("Content")(function (v) {
                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(AdminUi_AdminClient_Hooks_UseAffUi.useAffUi({
                        skeleton: skeleton,
                        retryUi: retryUi,
                        aff: v.content,
                        success: function (v1) {
                            return React_Basic.element(infiniteTable$prime)({
                                table: v1.value0.value0
                            });
                        }
                    }))(function (jsx) {
                        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(jsx);
                    });
                });
            });
        };
    };
};
var bCollection = AdminUi_AdminClient_Components_Types.BCCollection.create;
module.exports = {
    bCollection: bCollection,
    mkBottomContent: mkBottomContent
};
