// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_Table = require("../AdminUi.AdminClient.Components.Table/index.js");
var Data_Date = require("../Data.Date/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var SiloDashboard_Client_Routes = require("../SiloDashboard.Client.Routes/index.js");
var Branch = function (x) {
    return x;
};
var branchNewtype = new Data_Newtype.Newtype(function (n) {
    return n;
}, Branch);
var tableSpecBranch = new AdminUi_AdminClient_Components_Table.TableSpec(function (v) {
    return {
        row: AdminUi_AdminClient_Components_Table.rowClick(branchNewtype)(function (v1) {
            return new SiloDashboard_Client_Routes.BranchOverview(v1.brandName, v1.name);
        }),
        headers: [ AdminUi_AdminClient_Components_Table.sortableH(Data_Ord.ordString)(branchNewtype)(AdminUi_AdminClient_Components_Table.toCellString)("Name")(function (v1) {
            return v1.name;
        }), AdminUi_AdminClient_Components_Table.sortableH(Data_Ord.ordString)(branchNewtype)(AdminUi_AdminClient_Components_Table.toCellString)("POS's Assigned")(function (v1) {
            return v1.pos;
        }), AdminUi_AdminClient_Components_Table.sortableH(Data_Date.ordDate)(branchNewtype)(AdminUi_AdminClient_Components_Table.toCellDate)("Date Created")(function (v1) {
            return v1.dateCreated;
        }), AdminUi_AdminClient_Components_Table.sortableH(Data_Date.ordDate)(branchNewtype)(AdminUi_AdminClient_Components_Table.toCellDate)("Date Updated")(function (v1) {
            return v1.dateUpdated;
        }) ]
    };
});
var branchTable = function (brandName) {
    return function (branchDetails) {
        var toBranch = function (v) {
            return {
                brandName: brandName,
                name: v.name,
                pos: v.pos,
                dateCreated: v.dateCreated,
                dateUpdated: v.dateUpdated
            };
        };
        return AdminUi_AdminClient_Components_Table.table(tableSpecBranch)(Data_Functor.map(Data_Functor.functorArray)(toBranch)(branchDetails));
    };
};
module.exports = {
    branchTable: branchTable,
    Branch: Branch,
    branchNewtype: branchNewtype,
    tableSpecBranch: tableSpecBranch
};
