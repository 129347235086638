// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var AdminUi_Colors = require("../AdminUi.Colors/index.js");
var AdminUi_Spacer = require("../AdminUi.Spacer/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Effect_Uncurried = require("../Effect.Uncurried/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var Jss_ReactJss = require("../Jss.ReactJss/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var Untagged_Coercible = require("../Untagged.Coercible/index.js");
var reactOtpInput = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_reactOtpInput"])(Untagged_Coercible.coerce()(props));
    };
};
var conv = function (c) {
    return React_Basic.element(Effect_Unsafe.unsafePerformEffect(c));
};
var otpInput = (function () {
    var useStyles = Jss_ReactJss.createUseStyles_()()({
        container: Jss_ReactJss.style({
            display: "flex",
            justifyContent: "center"
        }),
        input: Jss_ReactJss.style({
            fontSize: "16px",
            padding: "8px 12px",
            borderRadius: "4px",
            height: "40px",
            width: "40px !important",
            border: "1px solid #D9D9D9"
        }),
        error: Jss_ReactJss.style({
            borderColor: AdminUi_Colors.danger
        })
    });
    return conv(React_Basic_Hooks.component()()()("OtpInput")(function (v) {
        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useStyles({}))(function (v1) {
            return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(reactOtpInput()({
                numInputs: v.numInputs,
                onChange: Effect_Uncurried.mkEffectFn1(v.onChange),
                value: v.value,
                containerStyle: v1.container,
                inputStyle: v1.input,
                errorStyle: v1.error,
                separator: AdminUi_Spacer.hspacer(2),
                hasErrored: v.hasErrored
            }));
        });
    }));
})();
module.exports = {
    reactOtpInput: reactOtpInput,
    otpInput: otpInput,
    conv: conv,
    "_reactOtpInput": $foreign["_reactOtpInput"]
};
