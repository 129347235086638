// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var AdminUi_AdminClient_Components_Utils = require("../AdminUi.AdminClient.Components.Utils/index.js");
var AdminUi_AdminClient_Hooks_UseActionHandler = require("../AdminUi.AdminClient.Hooks.UseActionHandler/index.js");
var AdminUi_Antd_Button = require("../AdminUi.Antd.Button/index.js");
var AdminUi_Antd_Dropdown = require("../AdminUi.Antd.Dropdown/index.js");
var AdminUi_Antd_Menu = require("../AdminUi.Antd.Menu/index.js");
var AdminUi_Antd_PropLits = require("../AdminUi.Antd.PropLits/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Jss_ReactJss = require("../Jss.ReactJss/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var useActionGroupStyles = Jss_ReactJss.createUseStyles_()()({
    container: Jss_ReactJss.style({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px 0"
    }),
    actionButton: Jss_ReactJss.style({
        marginLeft: "10px"
    }),
    secondaryButton: Jss_ReactJss.style({
        paddingLeft: "10px",
        paddingRight: "10px"
    })
});
var mkActionGroup = function (dictMonadEffect) {
    return function (dictMonadAsk) {
        var renderDropdownAction = function (aHandler) {
            return function (index) {
                return function (v) {
                    return AdminUi_Antd_Menu.menuItem()({
                        key: Data_Show.show(Data_Show.showInt)(index),
                        children: [ React_Basic_DOM_Generated.a()({
                            onClick: React_Basic_Events.handler_(aHandler(v.action)),
                            children: [ React_Basic_DOM.text(v.label) ]
                        }) ]
                    });
                };
            };
        };
        var renderPrimaryAction = function (v) {
            return React_Basic_DOM_Generated.div()({
                className: v.className,
                children: [ (function () {
                    if (v.paction instanceof AdminUi_AdminClient_Components_Types.PAButton) {
                        return AdminUi_Antd_Button.button()({
                            type: AdminUi_Antd_PropLits.primary,
                            onClick: React_Basic_Events.handler_(v.actionHandler(v.paction.value0.action)),
                            children: React_Basic_DOM.text(v.paction.value0.label)
                        });
                    };
                    if (v.paction instanceof AdminUi_AdminClient_Components_Types.PADropdown) {
                        return AdminUi_Antd_Dropdown.dropdown()({
                            overlay: AdminUi_Antd_Menu.menu()({
                                children: Data_Array.mapWithIndex(renderDropdownAction(v.actionHandler))(v.paction.value0.actions)
                            }),
                            placement: AdminUi_Antd_PropLits.bottomRight,
                            trigger: [ AdminUi_Antd_PropLits.click ],
                            children: [ AdminUi_Antd_Button.button()({
                                type: AdminUi_Antd_PropLits.primary,
                                children: React_Basic_DOM.text(v.paction.value0.label)
                            }) ]
                        });
                    };
                    throw new Error("Failed pattern match at AdminUi.AdminClient.Components.ActionGroup (line 136, column 11 - line 157, column 18): " + [ v.paction.constructor.name ]);
                })() ]
            });
        };
        return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Hooks_UseActionHandler.mkUseActionHandler(dictMonadEffect)(dictMonadAsk))(function (useActionHandler) {
            return AdminUi_AdminClient_Components_Utils.comp_(dictMonadEffect)()()()("ActionGroup")(function (props) {
                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useActionGroupStyles({}))(function (css) {
                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useActionHandler)(function (actionHandler) {
                        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(false))(function (v) {
                            var secondaryActionButton = function (actions) {
                                return [ (function () {
                                    if (actions.length === 0) {
                                        return Data_Monoid.mempty(React_Basic.monoidJSX);
                                    };
                                    return AdminUi_Antd_Dropdown.dropdown()({
                                        overlay: AdminUi_Antd_Menu.menu()({
                                            children: Data_Array.mapWithIndex(renderDropdownAction(actionHandler))(actions)
                                        }),
                                        placement: AdminUi_Antd_PropLits.bottomRight,
                                        trigger: [ AdminUi_Antd_PropLits.click ],
                                        children: [ AdminUi_Antd_Button.button()({
                                            className: Jss_ReactJss.styles([ css.actionButton, css.secondaryButton ]),
                                            children: React_Basic_DOM.text("\u25aa\u25aa\u25aa")
                                        }) ]
                                    });
                                })() ];
                            };
                            return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))((function () {
                                if (props.actionGroup instanceof Data_Maybe.Nothing) {
                                    return Data_Monoid.mempty(React_Basic.monoidJSX);
                                };
                                if (props.actionGroup instanceof Data_Maybe.Just && (props.actionGroup.value0.primary.length === 0 && props.actionGroup.value0.secondary.length === 0)) {
                                    return Data_Monoid.mempty(React_Basic.monoidJSX);
                                };
                                if (props.actionGroup instanceof Data_Maybe.Just) {
                                    return React_Basic_DOM_Generated.div()({
                                        className: css.container,
                                        children: Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Functor.mapFlipped(Data_Functor.functorArray)(props.actionGroup.value0.primary)(function (paction) {
                                            return renderPrimaryAction({
                                                actionHandler: actionHandler,
                                                className: css.actionButton,
                                                paction: paction
                                            });
                                        }))(secondaryActionButton(props.actionGroup.value0.secondary))
                                    });
                                };
                                throw new Error("Failed pattern match at AdminUi.AdminClient.Components.ActionGroup (line 108, column 9 - line 124, column 16): " + [ props.actionGroup.constructor.name ]);
                            })());
                        });
                    });
                });
            });
        });
    };
};
var actionGroup = function (primary) {
    return function (secondary) {
        return {
            primary: primary,
            secondary: secondary
        };
    };
};
var actionGroup_ = function (primary$prime) {
    return function (secondary) {
        return actionGroup([ primary$prime ])(secondary);
    };
};
var primaryActionGroup = function (primary) {
    return actionGroup(primary)([  ]);
};
var primaryActionGroup_ = function (primary$prime) {
    return primaryActionGroup([ primary$prime ]);
};
var actionDropdown = function (label) {
    return function (actions) {
        return new AdminUi_AdminClient_Components_Types.PADropdown({
            label: label,
            actions: actions
        });
    };
};
var action = function (label) {
    return function (a) {
        return {
            label: label,
            action: a
        };
    };
};
var actionButton = function (label) {
    var $32 = action(label);
    return function ($33) {
        return AdminUi_AdminClient_Components_Types.PAButton.create($32($33));
    };
};
module.exports = {
    actionGroup: actionGroup,
    actionGroup_: actionGroup_,
    primaryActionGroup: primaryActionGroup,
    primaryActionGroup_: primaryActionGroup_,
    action: action,
    actionButton: actionButton,
    actionDropdown: actionDropdown,
    mkActionGroup: mkActionGroup
};
