// Generated by purs version 0.13.6
"use strict";
var emptyRouteState = {
    segments: [  ],
    params: [  ],
    hash: ""
};
module.exports = {
    emptyRouteState: emptyRouteState
};
