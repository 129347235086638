// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Colors = require("../AdminUi.AdminClient.Colors/index.js");
var AdminUi_AdminClient_Components_Content = require("../AdminUi.AdminClient.Components.Content/index.js");
var AdminUi_AdminClient_Components_Icons = require("../AdminUi.AdminClient.Components.Icons/index.js");
var AdminUi_AdminClient_Components_Icons_AntdIcons = require("../AdminUi.AdminClient.Components.Icons.AntdIcons/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var AdminUi_AdminClient_Components_Utils = require("../AdminUi.AdminClient.Components.Utils/index.js");
var AdminUi_AdminClient_Components_ZText = require("../AdminUi.AdminClient.Components.ZText/index.js");
var AdminUi_Antd_Button = require("../AdminUi.Antd.Button/index.js");
var AdminUi_Antd_Modal = require("../AdminUi.Antd.Modal/index.js");
var AdminUi_Antd_PropLits = require("../AdminUi.Antd.PropLits/index.js");
var AdminUi_Antd_Typography = require("../AdminUi.Antd.Typography/index.js");
var AdminUi_Spacer = require("../AdminUi.Spacer/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Plus = require("../Control.Plus/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Jss_ReactJss = require("../Jss.ReactJss/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var useStyles = Jss_ReactJss.createUseStyles_()()({
    header: Jss_ReactJss.style({
        display: "flex",
        alignItems: "center",
        paddingBottom: "10px"
    }),
    title: Jss_ReactJss.style({
        margin: "0 !important",
        marginBottom: "0 !important"
    }),
    icon: Jss_ReactJss.style({
        fontSize: "20px",
        paddingRight: "10px"
    }),
    content: Jss_ReactJss.style({
        padding: "0 28px"
    }),
    footer: Jss_ReactJss.style({
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: "20px"
    }),
    cancelButton: Jss_ReactJss.style({
        marginRight: "10px"
    })
});
var mkModal = function (dictEq) {
    return function (dictMonadAsk) {
        return function (dictMonadEffect) {
            return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Components_ZText.mkZText(dictMonadAsk)(dictMonadEffect))(function (zText) {
                return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Components_Content.mkContent(dictEq)(dictMonadAsk)(dictMonadEffect))(function (contentComp) {
                    return AdminUi_AdminClient_Components_Utils.comp_(dictMonadEffect)()()()("Modal")(function (props) {
                        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useStyles({}))(function (css) {
                            var modalActionHandler = function (v) {
                                return props.hideModal;
                            };
                            return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(AdminUi_Antd_Modal.modal()({
                                visible: Data_Maybe.isJust(props.modal) && props.visible,
                                footer: Data_Nullable["null"],
                                width: 540,
                                closable: true,
                                zIndex: 1,
                                onCancel: React_Basic_Events.handler_(props.hideModal),
                                children: [ Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(React_Basic.monoidJSX)(function (v) {
                                    return React_Basic_DOM_Generated.div()({
                                        children: [ React_Basic_DOM_Generated.div()({
                                            className: css.header,
                                            children: [ (function () {
                                                if (v.title.icon instanceof Data_Maybe.Just) {
                                                    return AdminUi_AdminClient_Components_Icons.mkIcon()(v.title.icon.value0.src)({
                                                        className: css.icon,
                                                        style: React_Basic_DOM_Internal.css({
                                                            color: AdminUi_AdminClient_Colors.getColor(v.title.icon.value0.color)
                                                        })
                                                    });
                                                };
                                                if (v.title.icon instanceof Data_Maybe.Nothing) {
                                                    return AdminUi_Spacer.hspacer(5);
                                                };
                                                throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Modal (line 135, column 33 - line 144, column 55): " + [ v.title.icon.constructor.name ]);
                                            })(), AdminUi_Antd_Typography.title()({
                                                className: css.title,
                                                level: AdminUi_Antd_PropLits.four,
                                                children: v.title.text
                                            }) ]
                                        }), React_Basic_DOM_Generated.div()({
                                            className: css.content,
                                            children: [ React_Basic.element(contentComp)({
                                                surface: AdminUi_AdminClient_Components_Types.SModal.value,
                                                content: v.content
                                            }) ]
                                        }), Data_Monoid.guard(React_Basic.monoidJSX)(Data_Maybe.isJust(Control_Apply.applySecond(Data_Maybe.applyMaybe)(v.actions.secondary)(v.actions.primary)))(React_Basic_DOM_Generated.div()({
                                            className: css.footer,
                                            children: [ Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(React_Basic.monoidJSX)(function (v1) {
                                                return AdminUi_Antd_Button.button()({
                                                    className: css.cancelButton,
                                                    onClick: React_Basic_Events.handler_(modalActionHandler(v1.action)),
                                                    children: React_Basic_DOM.text(v1.label)
                                                });
                                            })(v.actions.secondary), Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(React_Basic.monoidJSX)(function (v1) {
                                                return AdminUi_Antd_Button.button()({
                                                    type: AdminUi_Antd_PropLits.primary,
                                                    onClick: React_Basic_Events.handler_(modalActionHandler(v1.action)),
                                                    children: React_Basic_DOM.text(v1.label)
                                                });
                                            })(v.actions.primary) ]
                                        })) ]
                                    });
                                })(props.modal) ]
                            }));
                        });
                    });
                });
            });
        };
    };
};
var formModal_ = function (title) {
    return function (fields) {
        return function (submit) {
            return {
                title: {
                    icon: Control_Plus.empty(Data_Maybe.plusMaybe),
                    text: title
                },
                content: AdminUi_AdminClient_Components_Content.form({
                    details: Data_Maybe.Nothing.value,
                    fields: fields,
                    submit: submit,
                    cancel: new AdminUi_AdminClient_Components_Types.AAModalAction(AdminUi_AdminClient_Components_Types.CloseModal.value)
                }),
                actions: {
                    primary: Data_Maybe.Nothing.value,
                    secondary: Data_Maybe.Nothing.value
                }
            };
        };
    };
};
var baseConfirmModal = function (title) {
    return function (content) {
        return {
            title: {
                icon: Control_Applicative.pure(Data_Maybe.applicativeMaybe)({
                    color: AdminUi_AdminClient_Colors.Warning.value,
                    src: new AdminUi_AdminClient_Components_Types.AntdI(AdminUi_AdminClient_Components_Icons_AntdIcons.ExclamationCircleOutlined.value)
                }),
                text: title
            },
            content: content,
            actions: {
                primary: Control_Applicative.pure(Data_Maybe.applicativeMaybe)({
                    label: "OK",
                    action: AdminUi_AdminClient_Components_Types.CloseModal.value
                }),
                secondary: Control_Applicative.pure(Data_Maybe.applicativeMaybe)({
                    label: "Cancel",
                    action: AdminUi_AdminClient_Components_Types.CloseModal.value
                })
            }
        };
    };
};
var confirmModal = function (title) {
    return function (message) {
        return function (body) {
            return baseConfirmModal(title)(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semiGroupContent)(AdminUi_AdminClient_Components_Content.txt(message))(body));
        };
    };
};
var confirmModal_ = function (title) {
    return function (message) {
        return baseConfirmModal(title)(AdminUi_AdminClient_Components_Content.txt(message));
    };
};
module.exports = {
    confirmModal_: confirmModal_,
    confirmModal: confirmModal,
    formModal_: formModal_,
    mkModal: mkModal
};
