// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Colors = require("../AdminUi.AdminClient.Colors/index.js");
var AdminUi_AdminClient_Components_ActionGroup = require("../AdminUi.AdminClient.Components.ActionGroup/index.js");
var AdminUi_AdminClient_Components_AppAction = require("../AdminUi.AdminClient.Components.AppAction/index.js");
var AdminUi_AdminClient_Components_BottomContent = require("../AdminUi.AdminClient.Components.BottomContent/index.js");
var AdminUi_AdminClient_Components_BottomSection = require("../AdminUi.AdminClient.Components.BottomSection/index.js");
var AdminUi_AdminClient_Components_Content = require("../AdminUi.AdminClient.Components.Content/index.js");
var AdminUi_AdminClient_Components_Content_ContentForm = require("../AdminUi.AdminClient.Components.Content.ContentForm/index.js");
var AdminUi_AdminClient_Components_Details = require("../AdminUi.AdminClient.Components.Details/index.js");
var AdminUi_AdminClient_Components_Icons_ZapIcons = require("../AdminUi.AdminClient.Components.Icons.ZapIcons/index.js");
var AdminUi_AdminClient_Components_Modal = require("../AdminUi.AdminClient.Components.Modal/index.js");
var AdminUi_AdminClient_Components_Page = require("../AdminUi.AdminClient.Components.Page/index.js");
var AdminUi_AdminClient_Components_Section = require("../AdminUi.AdminClient.Components.Section/index.js");
var AdminUi_AdminClient_Components_Title = require("../AdminUi.AdminClient.Components.Title/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var AdminUi_AdminClient_Components_ZText = require("../AdminUi.AdminClient.Components.ZText/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var SiloDashboard_Client_Routes = require("../SiloDashboard.Client.Routes/index.js");
var SiloDashboard_Client_Tables_Cashier = require("../SiloDashboard.Client.Tables.Cashier/index.js");
var SiloDashboard_Client_Tables_EntityLog = require("../SiloDashboard.Client.Tables.EntityLog/index.js");
var posOverview = function (brandName) {
    return function (branchName) {
        return function (posName) {
            var title$prime = AdminUi_AdminClient_Components_Title.title()({
                overline: brandName + (" - " + branchName),
                main: posName
            });
            var posLogs = new Data_Maybe.Just(AdminUi_AdminClient_Components_BottomSection.bSection(new Data_Maybe.Just(AdminUi_AdminClient_Components_Title.title_("POS Logs")))(Data_Maybe.Just.create(AdminUi_AdminClient_Components_ActionGroup.primaryActionGroup_(AdminUi_AdminClient_Components_ActionGroup.actionButton("Exports Logs")(AdminUi_AdminClient_Components_AppAction.showConfirmModal_("Export Logs")(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.text_("An email containing the exported file will be sent to "))(AdminUi_AdminClient_Components_ZText.bold("dustin@zap.com.ph")))))))(Control_Applicative.pure(Effect_Aff.applicativeAff)(AdminUi_AdminClient_Components_BottomContent.bCollection(SiloDashboard_Client_Tables_EntityLog.posLogTable))));
            var posDetails = AdminUi_AdminClient_Components_Section.section_(Data_Maybe.Nothing.value)(Data_Maybe.Nothing.value)(Control_Applicative.pure(Effect_Aff.applicativeAff)(AdminUi_AdminClient_Components_Content.details([ AdminUi_AdminClient_Components_Details.pair_("Status")(AdminUi_AdminClient_Components_Details.text({
                color: AdminUi_AdminClient_Colors.Success.value,
                icon: Data_Maybe.Just.create(new AdminUi_AdminClient_Components_Types.ZapI(AdminUi_AdminClient_Components_Icons_ZapIcons.Dot.value)),
                text: "Online",
                rightAction: Data_Maybe.Nothing.value
            })), AdminUi_AdminClient_Components_Details.pair_("Implementation")(AdminUi_AdminClient_Components_Details.text_("Bolt")), AdminUi_AdminClient_Components_Details.pair_("Version")(AdminUi_AdminClient_Components_Details.text_("1.8.0")) ])));
            var cashierCodesList = (function () {
                var singleCashierCodeForm = AdminUi_AdminClient_Components_Modal.formModal_("Add Single Cashier Code")([ AdminUi_AdminClient_Components_Content_ContentForm.formField("Cashier Name")(AdminUi_AdminClient_Components_Content_ContentForm.textInput({
                    placeholder: "Enter cashier name..."
                })), AdminUi_AdminClient_Components_Content_ContentForm.formField("Cashier Code")(AdminUi_AdminClient_Components_Content_ContentForm.textInput({
                    placeholder: "Enter cashier code..."
                })) ])({
                    label: "Add Single Cashier Code",
                    action: AdminUi_AdminClient_Components_AppAction.closeModal
                });
                return AdminUi_AdminClient_Components_Section.section_(new Data_Maybe.Just(AdminUi_AdminClient_Components_Title.title_("Cashier Codes")))(Data_Maybe.Just.create(AdminUi_AdminClient_Components_ActionGroup.primaryActionGroup([ AdminUi_AdminClient_Components_ActionGroup.actionDropdown("Add Cashier Codes")([ AdminUi_AdminClient_Components_ActionGroup.action("Single Cashier Code")(AdminUi_AdminClient_Components_AppAction.showModal(singleCashierCodeForm)), AdminUi_AdminClient_Components_ActionGroup.action("Multiple Cashier Codes")(AdminUi_AdminClient_Components_AppAction.goTo(new SiloDashboard_Client_Routes.POSAddMultipleCashierCodes(brandName, branchName, posName))) ]), AdminUi_AdminClient_Components_ActionGroup.actionButton("Export")(AdminUi_AdminClient_Components_AppAction.showConfirmModal_("Export Cashier Codes")(AdminUi_AdminClient_Components_ZText.text_(""))) ])))(Control_Applicative.pure(Effect_Aff.applicativeAff)(AdminUi_AdminClient_Components_Content.collection(SiloDashboard_Client_Tables_Cashier.cashierTable)));
            })();
            var sections = [ posDetails, cashierCodesList ];
            var actionGroup = Data_Maybe.Just.create(AdminUi_AdminClient_Components_ActionGroup.actionGroup_(AdminUi_AdminClient_Components_ActionGroup.actionButton("Rename")(AdminUi_AdminClient_Components_AppAction.goTo(new SiloDashboard_Client_Routes.POSRename(brandName, branchName, posName))))([ AdminUi_AdminClient_Components_ActionGroup.action("Deactivate POS")(AdminUi_AdminClient_Components_AppAction.showConfirmModal_("Confirm")(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.text_("Deactivate "))(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.bold(posName))(AdminUi_AdminClient_Components_ZText.text_("? You will no longer be able to use this POS."))))) ]));
            return Control_Applicative.pure(Control_Monad_Reader_Trans.applicativeReaderT(Data_Identity.applicativeIdentity))(AdminUi_AdminClient_Components_Page.page(Data_Maybe.Nothing.value)(title$prime)(actionGroup)(sections)(posLogs));
        };
    };
};
module.exports = {
    posOverview: posOverview
};
