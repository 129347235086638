"use strict";
var AdminUi_AdminClient_Components_ActionGroup = require("../AdminUi.AdminClient.Components.ActionGroup/index.js");
var AdminUi_AdminClient_Components_AppAction = require("../AdminUi.AdminClient.Components.AppAction/index.js");
var AdminUi_AdminClient_Components_BottomContent = require("../AdminUi.AdminClient.Components.BottomContent/index.js");
var AdminUi_AdminClient_Components_BottomSection = require("../AdminUi.AdminClient.Components.BottomSection/index.js");
var AdminUi_AdminClient_Components_Content = require("../AdminUi.AdminClient.Components.Content/index.js");
var AdminUi_AdminClient_Components_Details = require("../AdminUi.AdminClient.Components.Details/index.js");
var AdminUi_AdminClient_Components_Page = require("../AdminUi.AdminClient.Components.Page/index.js");
var AdminUi_AdminClient_Components_Section = require("../AdminUi.AdminClient.Components.Section/index.js");
var AdminUi_AdminClient_Components_Title = require("../AdminUi.AdminClient.Components.Title/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var AdminUi_AdminClient_Components_Types_AffV = require("../AdminUi.AdminClient.Components.Types.AffV/index.js");
var AdminUi_AdminClient_Components_ZText = require("../AdminUi.AdminClient.Components.ZText/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Reader_Class = require("../Control.Monad.Reader.Class/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var SiloDashboard_Client_Routes = require("../SiloDashboard.Client.Routes/index.js");
var SiloDashboard_Client_Tables_Branch = require("../SiloDashboard.Client.Tables.Branch/index.js");
var SiloDashboard_Client_Tables_EntityLog = require("../SiloDashboard.Client.Tables.EntityLog/index.js");
var brandOverview = function (brandName) {
    return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Data_Identity.bindIdentity))(Control_Monad_Reader_Class.ask(Control_Monad_Reader_Trans.monadAskReaderT(Data_Identity.monadIdentity)))(function (apiClient) {
        var title$prime = AdminUi_AdminClient_Components_Title.title_(brandName);
        var brandLogs = new Data_Maybe.Just(AdminUi_AdminClient_Components_BottomSection.bSection(new Data_Maybe.Just(AdminUi_AdminClient_Components_Title.title_("Brand Logs")))(new Data_Maybe.Just(AdminUi_AdminClient_Components_ActionGroup.primaryActionGroup_(AdminUi_AdminClient_Components_ActionGroup.actionButton("Exports Logs")(AdminUi_AdminClient_Components_AppAction.showConfirmModal_("Export Logs")(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.text_("An email containing the exported file will be sent to "))(AdminUi_AdminClient_Components_ZText.bold("dustin@zap.com.ph")))))))(Control_Applicative.pure(Effect_Aff.applicativeAff)(AdminUi_AdminClient_Components_BottomContent.bCollection(SiloDashboard_Client_Tables_EntityLog.brandLogTable))));
        var brandDetailsA = apiClient.getBrandDetails(brandName);
        var brandDetails = (function () {
            var details = Data_Functor.mapFlipped(Effect_Aff.functorAff)(brandDetailsA)(function (v) {
                return AdminUi_AdminClient_Components_Content.details([ AdminUi_AdminClient_Components_Details.pair_("Sender ID")(AdminUi_AdminClient_Components_Details.text_(v.details.senderId)), AdminUi_AdminClient_Components_Details.pair_("Image")(AdminUi_AdminClient_Components_Details.dvImg(v.details.image)), AdminUi_AdminClient_Components_Details.pair({
                    label: "Settings Profile",
                    value: Data_Maybe.Just.create(AdminUi_AdminClient_Components_Details.text_(v.details.settingsProfile)),
                    info: Data_Maybe.Just.create("Settings in case internet goes down.")
                }) ]);
            });
            return AdminUi_AdminClient_Components_Section.section_(Data_Maybe.Nothing.value)(Data_Maybe.Nothing.value)(details);
        })();
        var branchList = AdminUi_AdminClient_Components_Section.section(new Data_Maybe.Just(AdminUi_AdminClient_Components_Title.title()({
            main: "Branches",
            caption: AdminUi_AdminClient_Components_Types_AffV.mkAffV(brandDetailsA)(function (dm) {
                if (dm instanceof Data_Maybe.Just) {
                    return "Branch Limit Usage: " + (Data_Show.show(Data_Show.showInt)(dm.value0.branchLimitUsage.used) + ("/" + Data_Show.show(Data_Show.showInt)(dm.value0.branchLimitUsage.limit)));
                };
                if (dm instanceof Data_Maybe.Nothing) {
                    return "Branch Limit Usage: " + "--";
                };
                throw new Error("Failed pattern match at SiloDashboard.Client.AuthRoutes.Brands.BrandOverview (line 79, column 27 - line 81, column 54): " + [ dm.constructor.name ]);
            })
        })))(Data_Maybe.Just.create(AdminUi_AdminClient_Components_ActionGroup.primaryActionGroup([ AdminUi_AdminClient_Components_ActionGroup.actionDropdown("Add Branch")([ AdminUi_AdminClient_Components_ActionGroup.action("Single Branch")(AdminUi_AdminClient_Components_AppAction.goTo(new SiloDashboard_Client_Routes.BranchesAdd(brandName))), AdminUi_AdminClient_Components_ActionGroup.action("Multiple Branches")(AdminUi_AdminClient_Components_AppAction.goTo(new SiloDashboard_Client_Routes.BranchesAddMulti(brandName))) ]), AdminUi_AdminClient_Components_ActionGroup.actionButton("Exports Branches")(AdminUi_AdminClient_Components_AppAction.showConfirmModal_("Export Branches")(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.text_("An email containing the exported file will be sent to "))(AdminUi_AdminClient_Components_ZText.bold("dustin@zap.com.ph")))) ])))(Data_Functor.mapFlipped(Effect_Aff.functorAff)(apiClient.getBrandBranches(brandName))(function (v) {
            return [ {
                label: "Active (" + (Data_Show.show(Data_Show.showInt)(v.activeBranches.count) + ")"),
                content: AdminUi_AdminClient_Components_Content.collection(SiloDashboard_Client_Tables_Branch.branchTable(brandName)(v.activeBranches.branches))
            }, {
                label: "Deactivated (" + (Data_Show.show(Data_Show.showInt)(v.deactivatedBranches.count) + ")"),
                content: AdminUi_AdminClient_Components_Content.collection(SiloDashboard_Client_Tables_Branch.branchTable(brandName)(v.deactivatedBranches.branches))
            } ];
        }));
        var sections = [ brandDetails, branchList ];
        var bcrumbOverwrite = new Data_Maybe.Just(Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Aff.delay(2000.0))(function () {
            return Control_Applicative.pure(Effect_Aff.applicativeAff)(new SiloDashboard_Client_Routes.BrandOverview(brandName + " Party"));
        }));
        var actionGroup = Data_Maybe.Just.create(AdminUi_AdminClient_Components_ActionGroup.actionGroup_(AdminUi_AdminClient_Components_ActionGroup.actionButton("Change Image")(AdminUi_AdminClient_Components_AppAction.goTo(new SiloDashboard_Client_Routes.BrandChangeImage(brandName))))([ AdminUi_AdminClient_Components_ActionGroup.action("Rename")(AdminUi_AdminClient_Components_AppAction.goTo(new SiloDashboard_Client_Routes.BrandRename(brandName))), AdminUi_AdminClient_Components_ActionGroup.action("Change Sender ID")(AdminUi_AdminClient_Components_AppAction.goTo(new SiloDashboard_Client_Routes.BrandChangeSenderId(brandName))), AdminUi_AdminClient_Components_ActionGroup.action("Change Settings Profile")(AdminUi_AdminClient_Components_AppAction.goTo(new SiloDashboard_Client_Routes.BrandChangeSettings(brandName))) ]));
        return Control_Applicative.pure(Control_Monad_Reader_Trans.applicativeReaderT(Data_Identity.applicativeIdentity))(AdminUi_AdminClient_Components_Page.page(bcrumbOverwrite)(title$prime)(actionGroup)(sections)(brandLogs));
    });
};
module.exports = {
    brandOverview: brandOverview
};
