// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_BaseInfiniteTable = require("../AdminUi.AdminClient.Components.BaseInfiniteTable/index.js");
var AdminUi_AdminClient_Components_Icons = require("../AdminUi.AdminClient.Components.Icons/index.js");
var AdminUi_AdminClient_Components_Icons_AntdIcons = require("../AdminUi.AdminClient.Components.Icons.AntdIcons/index.js");
var AdminUi_AdminClient_Components_Table = require("../AdminUi.AdminClient.Components.Table/index.js");
var AdminUi_AdminClient_Components_Table_TableCell = require("../AdminUi.AdminClient.Components.Table.TableCell/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var AdminUi_AdminClient_Components_Utils = require("../AdminUi.AdminClient.Components.Utils/index.js");
var AdminUi_AdminClient_Hooks_UseTable = require("../AdminUi.AdminClient.Hooks.UseTable/index.js");
var AdminUi_Antd_Button = require("../AdminUi.Antd.Button/index.js");
var AdminUi_Antd_PropLits = require("../AdminUi.Antd.PropLits/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Reader_Class = require("../Control.Monad.Reader.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Jss_ReactJss = require("../Jss.ReactJss/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var FetchError = (function () {
    function FetchError(value0) {
        this.value0 = value0;
    };
    FetchError.create = function (value0) {
        return new FetchError(value0);
    };
    return FetchError;
})();
var useStyles = Jss_ReactJss.createUseStyles_()()({
    cellSubText: Jss_ReactJss.style({
        fontSize: "12px"
    }),
    retryContainer: Jss_ReactJss.style({
        textAlign: "center",
        paddingTop: "20px"
    }),
    retryButton: Jss_ReactJss.style({})
});
var mkInfiniteTable = function (dictEq) {
    return function (dictMonadAsk) {
        return function (dictMonadEffect) {
            return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(Control_Monad_Reader_Class.ask(dictMonadAsk))(function (v) {
                return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Components_Table_TableCell.mkTableCell(dictMonadAsk)(dictMonadEffect))(function (tableCell) {
                    return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadEffect)(AdminUi_AdminClient_Components_BaseInfiniteTable.mkBaseInfiniteTable))(function (baseInfiniteTable) {
                        return AdminUi_AdminClient_Components_Utils.comp_(dictMonadEffect)()()()("InfiniteTable")(function (v1) {
                            var next = AdminUi_AdminClient_Components_Table.getNext(v1.table);
                            var limit = AdminUi_AdminClient_Components_Table.getLimit(v1.table);
                            var isAsync = AdminUi_AdminClient_Components_Table.isAsync(v1.table);
                            var initialRows = AdminUi_AdminClient_Components_Table.getRows(v1.table);
                            var initialRowsToDisplay = (function () {
                                if (isAsync) {
                                    return initialRows;
                                };
                                if (!isAsync) {
                                    return Data_Array.take(limit)(initialRows);
                                };
                                throw new Error("Failed pattern match at AdminUi.AdminClient.Components.BottomContent.InfiniteTable (line 51, column 30 - line 53, column 46): " + [ isAsync.constructor.name ]);
                            })();
                            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useStyles({}))(function (css) {
                                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useContext(v.router))(function (v2) {
                                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(initialRowsToDisplay))(function (v3) {
                                        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(Data_Functor.map(Data_Maybe.functorMaybe)(function (v4) {
                                            return v4.marker;
                                        })(next)))(function (v4) {
                                            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(false))(function (v5) {
                                                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(Data_Maybe.Nothing.value))(function (v6) {
                                                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(AdminUi_AdminClient_Hooks_UseTable.useTable({
                                                        table: v1.table
                                                    }))(function (v7) {
                                                        var rowsToDisplayCount = Data_Array.length(v3.value0);
                                                        var rowCount = Data_Array.length(v7.rows);
                                                        var renderRow = function (row) {
                                                            return Data_Functor.mapFlipped(Data_Functor.functorArray)(row.cells)(function (cell) {
                                                                return React_Basic.element(tableCell)({
                                                                    cell: cell
                                                                });
                                                            });
                                                        };
                                                        var onRowClick = function (i) {
                                                            var v8 = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v9) {
                                                                return v9.route;
                                                            })(Data_Array.index(v3.value0)(i));
                                                            if (v8 instanceof Data_Maybe.Just) {
                                                                return v2.push(v8.value0);
                                                            };
                                                            if (v8 instanceof Data_Maybe.Nothing) {
                                                                return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                                                            };
                                                            throw new Error("Failed pattern match at AdminUi.AdminClient.Components.BottomContent.InfiniteTable (line 78, column 22 - line 80, column 26): " + [ v8.constructor.name ]);
                                                        };
                                                        var hasMore = (Data_Array.length(v3.value0) < Data_Array.length(v7.rows) || Data_Maybe.isJust(v4.value0)) && Data_Maybe.isNothing(v6.value0);
                                                        var fetchMore = (function () {
                                                            if (next instanceof Data_Maybe.Just && v4.value0 instanceof Data_Maybe.Just) {
                                                                return Effect_Aff.runAff_(function (v8) {
                                                                    if (v8 instanceof Data_Either.Left) {
                                                                        return function __do() {
                                                                            v6.value1(Data_Function["const"](new Data_Maybe.Just(new FetchError(v8.value0))))();
                                                                            return v5.value1(Data_Function["const"](false))();
                                                                        };
                                                                    };
                                                                    if (v8 instanceof Data_Either.Right) {
                                                                        return function __do() {
                                                                            v4.value1(Data_Function["const"](v8.value0.marker))();
                                                                            v3.value1(function (v9) {
                                                                                return Data_Semigroup.append(Data_Semigroup.semigroupArray)(v9)(v8.value0.rows);
                                                                            })();
                                                                            return v5.value1(Data_Function["const"](false))();
                                                                        };
                                                                    };
                                                                    throw new Error("Failed pattern match at AdminUi.AdminClient.Components.BottomContent.InfiniteTable (line 104, column 23 - line 111, column 49): " + [ v8.constructor.name ]);
                                                                })(next.value0.fetch(v4.value0.value0));
                                                            };
                                                            return v5.value1(Data_Function["const"](false));
                                                        })();
                                                        var loadMore = Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(!v5.value0)(function __do() {
                                                            v6.value1(Data_Function["const"](Data_Maybe.Nothing.value))();
                                                            v5.value1(Data_Function["const"](true))();
                                                            if (isAsync) {
                                                                return fetchMore();
                                                            };
                                                            if (!isAsync) {
                                                                var notEnoughRows = (rowCount - rowsToDisplayCount | 0) < limit;
                                                                var limit$prime = (function () {
                                                                    if (notEnoughRows) {
                                                                        return rowCount - rowsToDisplayCount | 0;
                                                                    };
                                                                    return limit;
                                                                })();
                                                                var rows$prime = Data_Array.take(rowsToDisplayCount + limit$prime | 0)(v7.rows);
                                                                return v3.value1(Data_Function["const"](rows$prime))();
                                                            };
                                                            throw new Error("Failed pattern match at AdminUi.AdminClient.Components.BottomContent.InfiniteTable (line 86, column 11 - line 99, column 45): " + [ isAsync.constructor.name ]);
                                                        });
                                                        var renderRetry = React_Basic_DOM_Generated.div()({
                                                            className: css.retryContainer,
                                                            children: [ AdminUi_Antd_Button.button()({
                                                                className: css.retryButton,
                                                                shape: AdminUi_Antd_PropLits.circle,
                                                                icon: AdminUi_AdminClient_Components_Icons.mkIcon()(new AdminUi_AdminClient_Components_Types.AntdI(AdminUi_AdminClient_Components_Icons_AntdIcons.ReloadOutlined.value))({}),
                                                                onClick: React_Basic_Events.handler_(loadMore)
                                                            }), React_Basic_DOM_Generated.br()({}), React_Basic_DOM.text("Failed to load more") ]
                                                        });
                                                        return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useEffect(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
                                                            return "route";
                                                        }))(Data_Maybe.eqMaybe(dictEq)))()(new Data_Symbol.IsSymbol(function () {
                                                            return "cells";
                                                        }))(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
                                                            return "text";
                                                        }))(AdminUi_AdminClient_Components_Types.eqZText))()(new Data_Symbol.IsSymbol(function () {
                                                            return "subText";
                                                        }))(Data_Maybe.eqMaybe(AdminUi_AdminClient_Components_Types.eqZText))))))))(v7.rows)((function () {
                                                            var rows$prime = Data_Array.take(rowsToDisplayCount)(v7.rows);
                                                            return function __do() {
                                                                Data_Monoid.guard(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit)))(!isAsync)(v3.value1)(Data_Function["const"](rows$prime))();
                                                                return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                                                            };
                                                        })()))(function () {
                                                            return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.div()({
                                                                children: [ React_Basic.element(baseInfiniteTable)({
                                                                    headers: v7.headers,
                                                                    rows: Data_Functor.map(Data_Functor.functorArray)(renderRow)(v3.value0),
                                                                    onRowClick: onRowClick,
                                                                    loadMore: loadMore,
                                                                    hasMore: hasMore
                                                                }), (function () {
                                                                    if (v6.value0 instanceof Data_Maybe.Just) {
                                                                        return renderRetry;
                                                                    };
                                                                    if (v6.value0 instanceof Data_Maybe.Nothing) {
                                                                        return Data_Monoid.mempty(React_Basic.monoidJSX);
                                                                    };
                                                                    throw new Error("Failed pattern match at AdminUi.AdminClient.Components.BottomContent.InfiniteTable (line 145, column 15 - line 147, column 34): " + [ v6.value0.constructor.name ]);
                                                                })() ]
                                                            }));
                                                        });
                                                    });
                                                });
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        };
    };
};
module.exports = {
    mkInfiniteTable: mkInfiniteTable
};
