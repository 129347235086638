// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_Types_AffV = require("../AdminUi.AdminClient.Components.Types.AffV/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Control_MonadZero = require("../Control.MonadZero/index.js");
var Control_Plus = require("../Control.Plus/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var Effect_Timer = require("../Effect.Timer/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var UseAffUi = function (x) {
    return x;
};
var UseAffVUi = function (x) {
    return x;
};
var newtypeUseAffVUi = new Data_Newtype.Newtype(function (n) {
    return n;
}, UseAffVUi);
var newtypeUseAffUi = new Data_Newtype.Newtype(function (n) {
    return n;
}, UseAffUi);
var useAffUi = function (v) {
    return React_Basic_Hooks_Internal.coerceHook(newtypeUseAffUi)(React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(false))(function (v1) {
        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(true))(function (v2) {
            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(Data_Maybe.Nothing.value))(function (v3) {
                var retry = v2.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean));
                var callReq = function __do() {
                    v3.value1(Data_Function["const"](Control_Plus.empty(Data_Maybe.plusMaybe)))();
                    var fiber = Effect_Aff.launchAff(Control_Bind.bind(Effect_Aff.bindAff)(Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff)(v.aff))(function (r) {
                        return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v3.value1(function (v4) {
                            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(r);
                        }));
                    }))();
                    return fiber;
                };
                return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useEffect(Data_Eq.eqBoolean)(v2.value0)(function __do() {
                    var fiber = callReq();
                    return Effect_Aff.launchAff_(Effect_Aff.killFiber(Effect_Exception.error("useAffUi: Fiber cancelled hook unmount"))(fiber));
                }))(function () {
                    return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useEffect(Data_Eq.eqUnit)(Data_Unit.unit)(Control_Bind.bind(Effect.bindEffect)(Effect_Timer.setTimeout(150)(v1.value1(Data_Function["const"](true))))((function () {
                        var $36 = Control_Applicative.pure(Effect.applicativeEffect);
                        return function ($37) {
                            return $36(Effect_Timer.clearTimeout($37));
                        };
                    })())))(function () {
                        var affRes_ = Data_Functor.voidLeft(Data_Maybe.functorMaybe)(Control_MonadZero.guard(Data_Maybe.monadZeroMaybe)(v1.value0 || Data_Maybe.isJust(v3.value0)))(v3.value0);
                        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(React_Basic.monoidJSX)(function (v4) {
                            if (v4 instanceof Data_Maybe.Nothing) {
                                return v.skeleton;
                            };
                            if (v4 instanceof Data_Maybe.Just && v4.value0 instanceof Data_Either.Right) {
                                return v.success(v4.value0.value0);
                            };
                            if (v4 instanceof Data_Maybe.Just && v4.value0 instanceof Data_Either.Left) {
                                return v.retryUi(retry);
                            };
                            throw new Error("Failed pattern match at AdminUi.AdminClient.Hooks.UseAffUi (line 69, column 17 - line 72, column 49): " + [ v4.constructor.name ]);
                        })(affRes_));
                    });
                });
            });
        });
    }));
};
var useAffVUi = function (v) {
    return React_Basic_Hooks_Internal.coerceHook(newtypeUseAffVUi)(React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useAffUi({
        aff: AdminUi_AdminClient_Components_Types_AffV.runAffV(v.affV),
        success: v.success,
        skeleton: v.skeleton,
        retryUi: Data_Function["const"](Data_Monoid.mempty(React_Basic.monoidJSX))
    }))(function (ui) {
        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(ui);
    }));
};
module.exports = {
    useAffUi: useAffUi,
    useAffVUi: useAffVUi,
    newtypeUseAffUi: newtypeUseAffUi,
    newtypeUseAffVUi: newtypeUseAffVUi
};
