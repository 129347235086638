// Generated by purs version 0.13.6
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Gen_Class = require("../Control.Monad.Gen.Class/index.js");
var Control_Monad_Rec_Class = require("../Control.Monad.Rec.Class/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid_Additive = require("../Data.Monoid.Additive/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semigroup_Foldable = require("../Data.Semigroup.Foldable/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Cons = (function () {
    function Cons(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Cons.create = function (value0) {
        return function (value1) {
            return new Cons(value0, value1);
        };
    };
    return Cons;
})();
var Nil = (function () {
    function Nil() {

    };
    Nil.value = new Nil();
    return Nil;
})();
var FreqSemigroup = function (x) {
    return x;
};
var AtIndex = function (x) {
    return x;
};
var unfoldable = function (dictMonadRec) {
    return function (dictMonadGen) {
        return function (dictUnfoldable) {
            return function (gen) {
                var unfold = function (v) {
                    if (v instanceof Nil) {
                        return Data_Maybe.Nothing.value;
                    };
                    if (v instanceof Cons) {
                        return new Data_Maybe.Just(new Data_Tuple.Tuple(v.value0, v.value1));
                    };
                    throw new Error("Failed pattern match at Control.Monad.Gen (line 101, column 12 - line 103, column 35): " + [ v.constructor.name ]);
                };
                var loopGen = function (v) {
                    if (v.value1 <= 0) {
                        return Control_Applicative.pure((dictMonadGen.Monad0()).Applicative0())(new Control_Monad_Rec_Class.Done(v.value0));
                    };
                    if (Data_Boolean.otherwise) {
                        return Control_Bind.bind((dictMonadGen.Monad0()).Bind1())(gen)(function (x) {
                            return Control_Applicative.pure((dictMonadGen.Monad0()).Applicative0())(new Control_Monad_Rec_Class.Loop(new Data_Tuple.Tuple(new Cons(x, v.value0), v.value1 - 1 | 0)));
                        });
                    };
                    throw new Error("Failed pattern match at Control.Monad.Gen (line 93, column 3 - line 93, column 68): " + [ v.constructor.name ]);
                };
                return Data_Functor.map((((dictMonadGen.Monad0()).Bind1()).Apply0()).Functor0())(Data_Unfoldable.unfoldr(dictUnfoldable)(unfold))(Control_Monad_Gen_Class.sized(dictMonadGen)((function () {
                    var $53 = Control_Monad_Rec_Class.tailRecM(dictMonadRec)(loopGen);
                    var $54 = Data_Tuple.Tuple.create(Nil.value);
                    return function ($55) {
                        return $53($54($55));
                    };
                })()));
            };
        };
    };
};
var semigroupFreqSemigroup = new Data_Semigroup.Semigroup(function (v) {
    return function (v1) {
        return function (pos) {
            var v2 = v(pos);
            if (v2.value0 instanceof Data_Maybe.Just) {
                return v1(v2.value0.value0);
            };
            return v2;
        };
    };
});
var semigroupAtIndex = new Data_Semigroup.Semigroup(function (v) {
    return function (v1) {
        return function (i) {
            var $42 = i <= 0;
            if ($42) {
                return v(i);
            };
            return v1(i - 1 | 0);
        };
    };
});
var getFreqVal = function (v) {
    return function ($56) {
        return Data_Tuple.snd(v($56));
    };
};
var getAtIndex = function (v) {
    return v;
};
var freqSemigroup = function (v) {
    return function (pos) {
        var $46 = pos >= v.value0;
        if ($46) {
            return new Data_Tuple.Tuple(new Data_Maybe.Just(pos - v.value0), v.value1);
        };
        return new Data_Tuple.Tuple(Data_Maybe.Nothing.value, v.value1);
    };
};
var frequency = function (dictMonadGen) {
    return function (dictFoldable1) {
        return function (xs) {
            var total = Data_Newtype.alaF(Data_Functor.functorFn)(Data_Functor.functorFn)(Data_Newtype.newtypeAdditive)(Data_Newtype.newtypeAdditive)(Data_Monoid_Additive.Additive)(Data_Foldable.foldMap(dictFoldable1.Foldable0())(Data_Monoid_Additive.monoidAdditive(Data_Semiring.semiringNumber)))(Data_Tuple.fst)(xs);
            return Control_Bind.bind((dictMonadGen.Monad0()).Bind1())(Control_Monad_Gen_Class.chooseFloat(dictMonadGen)(0.0)(total))(getFreqVal(Data_Semigroup_Foldable.foldMap1(dictFoldable1)(semigroupFreqSemigroup)(freqSemigroup)(xs)));
        };
    };
};
var filtered = function (dictMonadRec) {
    return function (dictMonadGen) {
        return function (gen) {
            var go = function (v) {
                return Data_Functor.mapFlipped((((dictMonadGen.Monad0()).Bind1()).Apply0()).Functor0())(gen)(function (a) {
                    if (a instanceof Data_Maybe.Nothing) {
                        return new Control_Monad_Rec_Class.Loop(Data_Unit.unit);
                    };
                    if (a instanceof Data_Maybe.Just) {
                        return new Control_Monad_Rec_Class.Done(a.value0);
                    };
                    throw new Error("Failed pattern match at Control.Monad.Gen (line 117, column 24 - line 119, column 23): " + [ a.constructor.name ]);
                });
            };
            return Control_Monad_Rec_Class.tailRecM(dictMonadRec)(go)(Data_Unit.unit);
        };
    };
};
var suchThat = function (dictMonadRec) {
    return function (dictMonadGen) {
        return function (gen) {
            return function (pred) {
                return filtered(dictMonadRec)(dictMonadGen)(Data_Functor.mapFlipped((((dictMonadGen.Monad0()).Bind1()).Apply0()).Functor0())(gen)(function (a) {
                    var $51 = pred(a);
                    if ($51) {
                        return new Data_Maybe.Just(a);
                    };
                    return Data_Maybe.Nothing.value;
                }));
            };
        };
    };
};
var choose = function (dictMonadGen) {
    return function (genA) {
        return function (genB) {
            return Control_Bind.bind((dictMonadGen.Monad0()).Bind1())(Control_Monad_Gen_Class.chooseBool(dictMonadGen))(function (v) {
                if (v) {
                    return genA;
                };
                return genB;
            });
        };
    };
};
var atIndex = function ($57) {
    return AtIndex(Data_Function["const"]($57));
};
var fromIndex = function (dictFoldable1) {
    return function (i) {
        return function (xs) {
            return getAtIndex(Data_Semigroup_Foldable.foldMap1(dictFoldable1)(semigroupAtIndex)(atIndex)(xs))(i);
        };
    };
};
var elements = function (dictMonadGen) {
    return function (dictFoldable1) {
        return function (xs) {
            return Control_Bind.bind((dictMonadGen.Monad0()).Bind1())(Control_Monad_Gen_Class.chooseInt(dictMonadGen)(0)(Data_Foldable.length(dictFoldable1.Foldable0())(Data_Semiring.semiringInt)(xs) - 1 | 0))(function (n) {
                return Control_Applicative.pure((dictMonadGen.Monad0()).Applicative0())(fromIndex(dictFoldable1)(n)(xs));
            });
        };
    };
};
var oneOf = function (dictMonadGen) {
    return function (dictFoldable1) {
        return function (xs) {
            return Control_Bind.bind((dictMonadGen.Monad0()).Bind1())(Control_Monad_Gen_Class.chooseInt(dictMonadGen)(0)(Data_Foldable.length(dictFoldable1.Foldable0())(Data_Semiring.semiringInt)(xs) - 1 | 0))(function (n) {
                return fromIndex(dictFoldable1)(n)(xs);
            });
        };
    };
};
module.exports = {
    choose: choose,
    oneOf: oneOf,
    frequency: frequency,
    elements: elements,
    unfoldable: unfoldable,
    suchThat: suchThat,
    filtered: filtered
};
