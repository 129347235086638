// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_AppAction = require("../AdminUi.AdminClient.Components.AppAction/index.js");
var AdminUi_AdminClient_Components_Content_ContentForm = require("../AdminUi.AdminClient.Components.Content.ContentForm/index.js");
var AdminUi_AdminClient_Components_Details = require("../AdminUi.AdminClient.Components.Details/index.js");
var AdminUi_AdminClient_Components_Page = require("../AdminUi.AdminClient.Components.Page/index.js");
var AdminUi_AdminClient_Components_Title = require("../AdminUi.AdminClient.Components.Title/index.js");
var AdminUi_AdminClient_Components_ZText = require("../AdminUi.AdminClient.Components.ZText/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Reader_Class = require("../Control.Monad.Reader.Class/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var brandChangeImage = function (brandName) {
    return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Data_Identity.bindIdentity))(Control_Monad_Reader_Class.ask(Control_Monad_Reader_Trans.monadAskReaderT(Data_Identity.monadIdentity)))(function (apiClient) {
        var title = AdminUi_AdminClient_Components_Title.title_("Change Image");
        var form = Data_Functor.mapFlipped(Effect_Aff.functorAff)(apiClient.getBrandDetails(brandName))(function (v) {
            return {
                details: new Data_Maybe.Just([ AdminUi_AdminClient_Components_Details.pair_("Current Image")(AdminUi_AdminClient_Components_Details.dvImg(v.details.image)) ]),
                fields: [ AdminUi_AdminClient_Components_Content_ContentForm.formField("New Image")(AdminUi_AdminClient_Components_Content_ContentForm.imageInput_) ],
                submit: {
                    label: "Save Changes",
                    action: AdminUi_AdminClient_Components_AppAction.showConfirmModal_("Confirm")(AdminUi_AdminClient_Components_ZText.text_("Change image?"))
                },
                cancel: AdminUi_AdminClient_Components_AppAction.goBack
            };
        });
        return Control_Applicative.pure(Control_Monad_Reader_Trans.applicativeReaderT(Data_Identity.applicativeIdentity))(AdminUi_AdminClient_Components_Page.pageForm(Data_Maybe.Nothing.value)(title)(Data_Maybe.Nothing.value)(form));
    });
};
module.exports = {
    brandChangeImage: brandChangeImage
};
