// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_AppAction = require("../AdminUi.AdminClient.Components.AppAction/index.js");
var AdminUi_AdminClient_Components_Table = require("../AdminUi.AdminClient.Components.Table/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var AdminUi_AdminClient_Components_ZText = require("../AdminUi.AdminClient.Components.ZText/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Cashier = function (x) {
    return x;
};
var cashierNewtype = new Data_Newtype.Newtype(function (n) {
    return n;
}, Cashier);
var tableSpecCashier = new AdminUi_AdminClient_Components_Table.TableSpec(function (v) {
    return {
        row: AdminUi_AdminClient_Components_Table.noRowClick,
        headers: [ AdminUi_AdminClient_Components_Table.sortableH(Data_Ord.ordString)(cashierNewtype)(AdminUi_AdminClient_Components_Table.toCellString)("Casher Code")(function (v1) {
            return v1.code;
        }), AdminUi_AdminClient_Components_Table.sortableH(Data_Ord.ordString)(cashierNewtype)(AdminUi_AdminClient_Components_Table.toCellString)("Cashier Name")(function (v1) {
            return v1.name;
        }), AdminUi_AdminClient_Components_Table.unsortableH(cashierNewtype)(AdminUi_AdminClient_Components_Table.toCellZText)("")(function (v1) {
            return Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.link_("Rename")(AdminUi_AdminClient_Components_AppAction.showConfirmModal_("Rename")(Data_Monoid.mempty(AdminUi_AdminClient_Components_Types.monoidZText))))(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.text_(" | "))(AdminUi_AdminClient_Components_ZText.link_("Delete")(AdminUi_AdminClient_Components_AppAction.showConfirmModal_("Delete")(Data_Monoid.mempty(AdminUi_AdminClient_Components_Types.monoidZText)))));
        }) ]
    };
});
var cashierTable = (function () {
    var cashier = function (name) {
        return function (code) {
            return {
                name: name,
                code: code
            };
        };
    };
    var cashierTable$prime = [ cashier("0001")("Maori"), cashier("0001")("Maori"), cashier("0001")("Maori"), cashier("0001")("Maori"), cashier("0001")("Maori"), cashier("0001")("Maori"), cashier("0001")("Maori"), cashier("0001")("Maori") ];
    return AdminUi_AdminClient_Components_Table.table(tableSpecCashier)(cashierTable$prime);
})();
module.exports = {
    cashierTable: cashierTable,
    Cashier: Cashier,
    cashierNewtype: cashierNewtype,
    tableSpecCashier: tableSpecCashier
};
