// Generated by purs version 0.13.6
"use strict";
var Data_Bounded = require("../Data.Bounded/index.js");
var Data_Date = require("../Data.Date/index.js");
var Data_Date_Component = require("../Data.Date.Component/index.js");
var Data_Enum = require("../Data.Enum/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var mkDate = function (y) {
    return function (mo) {
        return function (d) {
            var year = Data_Maybe.fromMaybe(2020)(Data_Enum.toEnum(Data_Date_Component.boundedEnumYear)(y));
            var month = Data_Maybe.fromMaybe(Data_Date_Component.January.value)(Data_Enum.toEnum(Data_Date_Component.boundedEnumMonth)(mo));
            var day = Data_Maybe.fromMaybe(Data_Bounded.bottom(Data_Date_Component.boundedDay))(Data_Enum.toEnum(Data_Date_Component.boundedEnumDay)(d));
            return Data_Date.canonicalDate(year)(month)(day);
        };
    };
};
module.exports = {
    mkDate: mkDate
};
