// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var AdminUi_AdminClient_Components_Utils = require("../AdminUi.AdminClient.Components.Utils/index.js");
var AdminUi_AdminClient_Components_ZText = require("../AdminUi.AdminClient.Components.ZText/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Exists = require("../Data.Exists/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var TableArgs = (function () {
    function TableArgs(value0) {
        this.value0 = value0;
    };
    TableArgs.create = function (value0) {
        return new TableArgs(value0);
    };
    return TableArgs;
})();
var TableAsyncArgs = (function () {
    function TableAsyncArgs(value0) {
        this.value0 = value0;
    };
    TableAsyncArgs.create = function (value0) {
        return new TableAsyncArgs(value0);
    };
    return TableAsyncArgs;
})();
var ToCell = function (toCell) {
    this.toCell = toCell;
};
var TableSpec = function (tableSpec) {
    this.tableSpec = tableSpec;
};
var toCell = function (dict) {
    return dict.toCell;
};
var tableSpec = function (dict) {
    return dict.tableSpec;
};
var sortTable$prime = function (dir) {
    return function (i) {
        return function (v) {
            var sortRows = function (f) {
                return Data_Array.sortBy(function (a) {
                    return function (b) {
                        return f(a.value)(b.value);
                    };
                })(v.value0.rawRows);
            };
            var sortedRows = function (f) {
                if (dir instanceof AdminUi_AdminClient_Components_Types.Ascending) {
                    return sortRows(f);
                };
                if (dir instanceof AdminUi_AdminClient_Components_Types.Descending) {
                    return sortRows(function (a) {
                        return function (b) {
                            return Data_Ordering.invert(f(a)(b));
                        };
                    });
                };
                throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Table (line 246, column 18 - line 248, column 52): " + [ dir.constructor.name ]);
            };
            var compare$prime = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v1) {
                return v1.compare;
            })(Data_Array.index(v.value0.rawHeaders)(i));
            return Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(compare$prime)(function (f) {
                return Data_Exists.mkExists(AdminUi_AdminClient_Components_Types["Table'"].create({
                    rawHeaders: v.value0.rawHeaders,
                    rawRows: sortedRows(f),
                    limit: v.value0.limit,
                    rawNext: v.value0.rawNext,
                    async: v.value0.async
                }));
            });
        };
    };
};
var sortTable = function (dir) {
    var $67 = sortTable$prime(dir);
    return function ($68) {
        return Data_Exists.runExists($67($68));
    };
};
var rowClick = function (dictNewtype) {
    return function (route) {
        return {
            route: (function () {
                var $69 = Data_Newtype.unwrap(dictNewtype);
                return function ($70) {
                    return Data_Maybe.Just.create(route($69($70)));
                };
            })()
        };
    };
};
var rawToRow = function (v) {
    return {
        route: v.route,
        cells: v.cells
    };
};
var noRowClick = {
    route: function (v) {
        return Data_Maybe.Nothing.value;
    }
};
var isAsync = (function () {
    var isAsync$prime = function (v) {
        return v.value0.async;
    };
    return Data_Exists.runExists(isAsync$prime);
})();
var header = function (dictNewtype) {
    return function (dictToCell) {
        return function (title) {
            return function (renderCell) {
                return function (compare) {
                    return {
                        title: title,
                        renderCell: (function () {
                            var $71 = toCell(dictToCell);
                            var $72 = Data_Newtype.unwrap(dictNewtype);
                            return function ($73) {
                                return $71(renderCell($72($73)));
                            };
                        })(),
                        compare: Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(compare)(function (f) {
                            return function (a) {
                                return function (b) {
                                    return f(Data_Newtype.unwrap(dictNewtype)(a))(Data_Newtype.unwrap(dictNewtype)(b));
                                };
                            };
                        })
                    };
                };
            };
        };
    };
};
var sortableH = function (dictOrd) {
    return function (dictNewtype) {
        return function (dictToCell) {
            return function (title) {
                return function (renderCell) {
                    var compare$prime = new Data_Maybe.Just(function (a) {
                        return function (b) {
                            return Data_Ord.compare(dictOrd)(renderCell(a))(renderCell(b));
                        };
                    });
                    return header(dictNewtype)(dictToCell)(title)(renderCell)(compare$prime);
                };
            };
        };
    };
};
var unsortableH = function (dictNewtype) {
    return function (dictToCell) {
        return function (title) {
            return function (renderCell) {
                return header(dictNewtype)(dictToCell)(title)(renderCell)(Data_Maybe.Nothing.value);
            };
        };
    };
};
var getRows = (function () {
    var getRows$prime = function (v) {
        return Data_Functor.mapFlipped(Data_Functor.functorArray)(v.value0.rawRows)(function (v1) {
            return {
                route: v1.route,
                cells: v1.cells
            };
        });
    };
    return Data_Exists.runExists(getRows$prime);
})();
var getNext = (function () {
    var getNext$prime = function (v) {
        return Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(v.value0.rawNext)(function (v1) {
            return {
                marker: v1.marker,
                fetch: function (m) {
                    return Data_Functor.mapFlipped(Effect_Aff.functorAff)(v1.fetch(m))(function (res) {
                        return {
                            marker: res.marker,
                            rows: Data_Functor.map(Data_Functor.functorArray)(rawToRow)(res.rawRows)
                        };
                    });
                }
            };
        });
    };
    return Data_Exists.runExists(getNext$prime);
})();
var getLimit = (function () {
    var getLimit$prime = function (v) {
        return v.value0.limit;
    };
    return Data_Exists.runExists(getLimit$prime);
})();
var getHeaders = (function () {
    var getHeaders$prime = function (v) {
        return Data_Functor.mapFlipped(Data_Functor.functorArray)(v.value0.rawHeaders)(function (h) {
            return {
                title: h.title,
                sortable: Data_Maybe.isJust(h.compare)
            };
        });
    };
    return Data_Exists.runExists(getHeaders$prime);
})();
var cell_ = function (text) {
    return {
        text: text,
        subText: Data_Maybe.Nothing.value
    };
};
var toCellString = new ToCell(function ($74) {
    return cell_(AdminUi_AdminClient_Components_ZText.text_($74));
});
var toCellDate = new ToCell((function () {
    var $75 = toCell(toCellString);
    return function ($76) {
        return $75(AdminUi_AdminClient_Components_Utils.formatDate($76));
    };
})());
var toCellInt = new ToCell((function () {
    var $77 = toCell(toCellString);
    var $78 = Data_Show.show(Data_Show.showInt);
    return function ($79) {
        return $77($78($79));
    };
})());
var toCellZText = new ToCell(cell_);
var cellText_ = function ($80) {
    return cell_(AdminUi_AdminClient_Components_ZText.text_($80));
};
var cell = function (text) {
    return function (subText) {
        return {
            text: text,
            subText: new Data_Maybe.Just(subText)
        };
    };
};
var _table = function (dictTableSpec) {
    var spec = tableSpec(dictTableSpec)(Type_Proxy["Proxy"].value);
    var toCells = function (a) {
        return Data_Functor.map(Data_Functor.functorArray)((function () {
            var $81 = Data_Function.applyFlipped(a);
            return function ($82) {
                return $81((function (v) {
                    return v.renderCell;
                })($82));
            };
        })())(spec.headers);
    };
    var rawHeaders = function (async) {
        return Data_Functor.mapFlipped(Data_Functor.functorArray)(spec.headers)(function (h) {
            return {
                title: h.title,
                compare: (function () {
                    if (async) {
                        return Data_Maybe.Nothing.value;
                    };
                    return h.compare;
                })()
            };
        });
    };
    var mkRawRow = function (a) {
        return {
            value: a,
            route: spec.row.route(a),
            cells: toCells(a)
        };
    };
    var mkTableProps = function (items) {
        return function (rawNext) {
            return function (async) {
                return {
                    rawHeaders: rawHeaders(async),
                    rawRows: Data_Functor.map(Data_Functor.functorArray)(mkRawRow)(items),
                    limit: 10,
                    rawNext: rawNext,
                    async: async
                };
            };
        };
    };
    var nextFetch = function (fetch) {
        return function (m) {
            return Data_Functor.mapFlipped(Effect_Aff.functorAff)(fetch(m))(function (res) {
                return {
                    marker: res.marker,
                    rawRows: Data_Functor.map(Data_Functor.functorArray)(mkRawRow)(res.items)
                };
            });
        };
    };
    var mkNext = Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
        return {
            fetch: nextFetch(v.fetch),
            marker: v.marker
        };
    });
    return function (v) {
        if (v instanceof TableArgs) {
            return Data_Exists.mkExists(AdminUi_AdminClient_Components_Types["Table'"].create(mkTableProps(v.value0.items)(Data_Maybe.Nothing.value)(false)));
        };
        if (v instanceof TableAsyncArgs) {
            return Data_Exists.mkExists(AdminUi_AdminClient_Components_Types["Table'"].create(mkTableProps(v.value0.items)(mkNext(v.value0.next))(true)));
        };
        throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Table (line 115, column 10 - line 121, column 46): " + [ v.constructor.name ]);
    };
};
var table = function (dictTableSpec) {
    return function (items) {
        return new AdminUi_AdminClient_Components_Types.CTable(_table(dictTableSpec)(new TableArgs({
            items: items
        })));
    };
};
var tableAsync = function (dictTableSpec) {
    var $83 = _table(dictTableSpec);
    return function ($84) {
        return AdminUi_AdminClient_Components_Types.CTable.create($83(TableAsyncArgs.create($84)));
    };
};
module.exports = {
    TableSpec: TableSpec,
    tableSpec: tableSpec,
    ToCell: ToCell,
    toCell: toCell,
    cell: cell,
    cell_: cell_,
    cellText_: cellText_,
    header: header,
    sortableH: sortableH,
    unsortableH: unsortableH,
    rowClick: rowClick,
    noRowClick: noRowClick,
    table: table,
    tableAsync: tableAsync,
    getLimit: getLimit,
    isAsync: isAsync,
    getRows: getRows,
    getHeaders: getHeaders,
    getNext: getNext,
    sortTable: sortTable,
    toCellString: toCellString,
    toCellInt: toCellInt,
    toCellDate: toCellDate,
    toCellZText: toCellZText
};
