const Dot = require('@zap/admin-ui-components/src/js/icons/Dot.jsx');

const Icons = require('@ant-design/icons');

const React = require('react');

exports.dot = coerceDict => props =>
  React.createElement(
    Icons.default,
    Object.assign(
      {
        component: Dot.default,
      },
      props
    )
  );
