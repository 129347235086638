// Generated by purs version 0.13.6
"use strict";
var xxl = 1600;
var xs = 480;
var xl = 1200;
var sm = 576;
var md = 768;
var lg = 992;
module.exports = {
    xs: xs,
    sm: sm,
    md: md,
    lg: lg,
    xl: xl,
    xxl: xxl
};
