// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Utils_Display = require("../AdminUi.AdminClient.Utils.Display/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Generic_Rep_Show = require("../Data.Generic.Rep.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var SiloDashboard_Common_Utils = require("../SiloDashboard.Common.Utils/index.js");
var DefaultSettings = (function () {
    function DefaultSettings() {

    };
    DefaultSettings.value = new DefaultSettings();
    return DefaultSettings;
})();
var AngusEmergency = (function () {
    function AngusEmergency() {

    };
    AngusEmergency.value = new AngusEmergency();
    return AngusEmergency;
})();
var AnotherSetting = (function () {
    function AnotherSetting() {

    };
    AnotherSetting.value = new AnotherSetting();
    return AnotherSetting;
})();
var AnotherCoolSetting = (function () {
    function AnotherCoolSetting() {

    };
    AnotherCoolSetting.value = new AnotherCoolSetting();
    return AnotherCoolSetting;
})();
var BrandX = (function () {
    function BrandX() {

    };
    BrandX.value = new BrandX();
    return BrandX;
})();
var BrandY = (function () {
    function BrandY() {

    };
    BrandY.value = new BrandY();
    return BrandY;
})();
var BrandZ = (function () {
    function BrandZ() {

    };
    BrandZ.value = new BrandZ();
    return BrandZ;
})();
var sampleDate = SiloDashboard_Common_Utils.mkDate(2019)(6)(22);
var genericDummySettingsProfile = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof DefaultSettings) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof AngusEmergency) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof AnotherSetting) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    };
    if (x instanceof AnotherCoolSetting) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)));
    };
    throw new Error("Failed pattern match at SiloDashboard.Common.Mock.MockData (line 19, column 1 - line 19, column 73): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return DefaultSettings.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return AngusEmergency.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return AnotherSetting.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
        return AnotherCoolSetting.value;
    };
    throw new Error("Failed pattern match at SiloDashboard.Common.Mock.MockData (line 19, column 1 - line 19, column 73): " + [ x.constructor.name ]);
});
var genericDummySenderId = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof BrandX) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof BrandY) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof BrandZ) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
    };
    throw new Error("Failed pattern match at SiloDashboard.Common.Mock.MockData (line 31, column 1 - line 31, column 64): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return BrandX.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return BrandY.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
        return BrandZ.value;
    };
    throw new Error("Failed pattern match at SiloDashboard.Common.Mock.MockData (line 31, column 1 - line 31, column 64): " + [ x.constructor.name ]);
});
var eqSettingsProfile = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof DefaultSettings && y instanceof DefaultSettings) {
            return true;
        };
        if (x instanceof AngusEmergency && y instanceof AngusEmergency) {
            return true;
        };
        if (x instanceof AnotherSetting && y instanceof AnotherSetting) {
            return true;
        };
        if (x instanceof AnotherCoolSetting && y instanceof AnotherCoolSetting) {
            return true;
        };
        return false;
    };
});
var eqDummySenderId = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof BrandX && y instanceof BrandX) {
            return true;
        };
        if (x instanceof BrandY && y instanceof BrandY) {
            return true;
        };
        if (x instanceof BrandZ && y instanceof BrandZ) {
            return true;
        };
        return false;
    };
});
var displaySettingsProfile = new AdminUi_AdminClient_Utils_Display.Display(AdminUi_AdminClient_Utils_Display.genericDisplay(genericDummySettingsProfile)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "DefaultSettings";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "AngusEmergency";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "AnotherSetting";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "AnotherCoolSetting";
})))))));
var displaySenderId = new AdminUi_AdminClient_Utils_Display.Display(AdminUi_AdminClient_Utils_Display.genericDisplay(genericDummySenderId)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "BrandX";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "BrandY";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "BrandZ";
}))))));
var brandDetails = {
    details: {
        senderId: "AngusStk",
        image: "https://www.w3schools.com/css/paris.jpg",
        settingsProfile: "Default Settings"
    },
    branchLimitUsage: {
        used: 20,
        limit: 20
    }
};
var branchDetails = {
    name: "Eastwood",
    settingsProfile: "Angus Emergency",
    pos: "No POS devices installed",
    dateCreated: sampleDate,
    dateUpdated: sampleDate,
    image: "https://hips.hearstapps.com/ghk.h-cdn.co/assets/17/30/2560x1280/landscape-1500925839-golden-retriever-puppy.jpg?resize=1200:*"
};
var branchDetailsList = [ branchDetails, {
    name: "Galleria",
    pos: "2 POS devices",
    dateCreated: SiloDashboard_Common_Utils.mkDate(2019)(6)(22),
    dateUpdated: sampleDate,
    image: "https://hips.hearstapps.com/ghk.h-cdn.co/assets/17/30/2560x1280/landscape-1500925839-golden-retriever-puppy.jpg?resize=1200:*",
    settingsProfile: "Angus Emergency"
}, {
    name: "Glorietta",
    pos: "2 POS devices",
    dateCreated: SiloDashboard_Common_Utils.mkDate(2019)(6)(23),
    dateUpdated: sampleDate,
    image: "https://hips.hearstapps.com/ghk.h-cdn.co/assets/17/30/2560x1280/landscape-1500925839-golden-retriever-puppy.jpg?resize=1200:*",
    settingsProfile: "Angus Emergency"
}, {
    name: "Makati",
    pos: "2 POS devices",
    dateCreated: SiloDashboard_Common_Utils.mkDate(2019)(4)(22),
    dateUpdated: sampleDate,
    image: "https://hips.hearstapps.com/ghk.h-cdn.co/assets/17/30/2560x1280/landscape-1500925839-golden-retriever-puppy.jpg?resize=1200:*",
    settingsProfile: "Angus Emergency"
}, {
    name: "SM Bicutan",
    pos: "2 POS devices",
    dateCreated: SiloDashboard_Common_Utils.mkDate(2020)(6)(22),
    dateUpdated: sampleDate,
    image: "https://hips.hearstapps.com/ghk.h-cdn.co/assets/17/30/2560x1280/landscape-1500925839-golden-retriever-puppy.jpg?resize=1200:*",
    settingsProfile: "Angus Emergency"
}, {
    name: "SM Cavite",
    pos: "2 POS devices",
    dateCreated: sampleDate,
    dateUpdated: sampleDate,
    image: "https://hips.hearstapps.com/ghk.h-cdn.co/assets/17/30/2560x1280/landscape-1500925839-golden-retriever-puppy.jpg?resize=1200:*",
    settingsProfile: "Angus Emergency"
}, {
    name: "SM Cubao",
    pos: "2 POS devices",
    dateCreated: sampleDate,
    dateUpdated: sampleDate,
    image: "https://hips.hearstapps.com/ghk.h-cdn.co/assets/17/30/2560x1280/landscape-1500925839-golden-retriever-puppy.jpg?resize=1200:*",
    settingsProfile: "Angus Emergency"
}, {
    name: "SM Megamall",
    pos: "2 POS devices",
    dateCreated: sampleDate,
    dateUpdated: sampleDate,
    image: "https://hips.hearstapps.com/ghk.h-cdn.co/assets/17/30/2560x1280/landscape-1500925839-golden-retriever-puppy.jpg?resize=1200:*",
    settingsProfile: "Angus Emergency"
}, {
    name: "SM North Edsa",
    pos: "2 POS devices",
    dateCreated: sampleDate,
    dateUpdated: sampleDate,
    image: "https://hips.hearstapps.com/ghk.h-cdn.co/assets/17/30/2560x1280/landscape-1500925839-golden-retriever-puppy.jpg?resize=1200:*",
    settingsProfile: "Angus Emergency"
}, {
    name: "UP Town",
    pos: "2 POS devices",
    dateCreated: sampleDate,
    dateUpdated: sampleDate,
    image: "https://hips.hearstapps.com/ghk.h-cdn.co/assets/17/30/2560x1280/landscape-1500925839-golden-retriever-puppy.jpg?resize=1200:*",
    settingsProfile: "Angus Emergency"
} ];
module.exports = {
    sampleDate: sampleDate,
    DefaultSettings: DefaultSettings,
    AngusEmergency: AngusEmergency,
    AnotherSetting: AnotherSetting,
    AnotherCoolSetting: AnotherCoolSetting,
    BrandX: BrandX,
    BrandY: BrandY,
    BrandZ: BrandZ,
    brandDetails: brandDetails,
    branchDetails: branchDetails,
    branchDetailsList: branchDetailsList,
    genericDummySettingsProfile: genericDummySettingsProfile,
    displaySettingsProfile: displaySettingsProfile,
    eqSettingsProfile: eqSettingsProfile,
    genericDummySenderId: genericDummySenderId,
    displaySenderId: displaySenderId,
    eqDummySenderId: eqDummySenderId
};
