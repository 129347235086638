// Generated by purs version 0.13.6
"use strict";
var Data_Bounded = require("../Data.Bounded/index.js");
var Data_DateTime = require("../Data.DateTime/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Formatter_DateTime = require("../Data.Formatter.DateTime/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Time = require("../Data.Time/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Literals_Undefined = require("../Literals.Undefined/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var Untagged_Coercible = require("../Untagged.Coercible/index.js");
var toUndefined = function (dictCoercible) {
    return function (v) {
        if (v instanceof Data_Maybe.Just) {
            return Untagged_Coercible.coerce()(v.value0);
        };
        if (v instanceof Data_Maybe.Nothing) {
            return Untagged_Coercible.coerce()(Literals_Undefined["undefined"]);
        };
        throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Utils (line 35, column 15 - line 37, column 34): " + [ v.constructor.name ]);
    };
};
var readableDateFormatter = Data_Either.fromRight()(Data_Formatter_DateTime.parseFormatString("MMM DD, YYYY"));
var formatDate = function (d) {
    return Data_Formatter_DateTime.format(readableDateFormatter)(new Data_DateTime.DateTime(d, Data_Bounded.bottom(Data_Time.boundedTime)));
};
var comp_ = function (dictMonadEffect) {
    return function (dictLacks) {
        return function (dictLacks1) {
            return function (dictLacks2) {
                return function (d) {
                    return function (c) {
                        return Effect_Class.liftEffect(dictMonadEffect)(React_Basic_Hooks.component()()()(d)(c));
                    };
                };
            };
        };
    };
};
module.exports = {
    comp_: comp_,
    formatDate: formatDate,
    readableDateFormatter: readableDateFormatter,
    toUndefined: toUndefined
};
