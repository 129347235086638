// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var React_Basic = require("../React.Basic/index.js");
var Untagged_Coercible = require("../Untagged.Coercible/index.js");
var steps = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_steps"])(Untagged_Coercible.coerce()(props));
    };
};
var step = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_step"])(Untagged_Coercible.coerce()(props));
    };
};
module.exports = {
    steps: steps,
    step: step,
    "_steps": $foreign["_steps"],
    "_step": $foreign["_step"]
};
