// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_Icons = require("../AdminUi.AdminClient.Components.Icons/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var AdminUi_Antd_Menu = require("../AdminUi.Antd.Menu/index.js");
var AdminUi_Antd_PropLits = require("../AdminUi.Antd.PropLits/index.js");
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_MonadZero = require("../Control.MonadZero/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_FoldableWithIndex = require("../Data.FoldableWithIndex/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Uncurried = require("../Effect.Uncurried/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Routing_Duplex = require("../Routing.Duplex/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var subMenu = AdminUi_AdminClient_Components_Types.SubMenu.create;
var mkSubMenuKey = function (v) {
    return Data_Show.show(Data_Show.showInt)(v.x);
};
var toSubMenuKeys = Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(Data_Monoid.monoidArray)(function (loc) {
    return [ mkSubMenuKey(loc) ];
});
var mkMenuItemKey = function (v) {
    return Data_Show.show(Data_Show.showInt)(v.x) + ("." + Data_Show.show(Data_Show.showInt)(v.y));
};
var toMenuItemKeys = Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(Data_Monoid.monoidArray)(function (loc) {
    return [ mkMenuItemKey(loc) ];
});
var menuItem = AdminUi_AdminClient_Components_Types.MenuItem.create;
var getParentRoute = function (routeD) {
    return function (route) {
        var go = function ($copy_segments) {
            var $tco_done = false;
            var $tco_result;
            function $tco_loop(segments) {
                var possibleParentArr = Data_Array.dropEnd(1)(segments);
                var v = Data_Either.hush(Routing_Duplex.parse(routeD)(Data_String_Common.joinWith("/")(possibleParentArr)));
                if (v instanceof Data_Maybe.Just) {
                    $tco_done = true;
                    return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(v.value0);
                };
                if (v instanceof Data_Maybe.Nothing) {
                    if (Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(possibleParentArr)([  ])) {
                        $tco_done = true;
                        return Data_Maybe.Nothing.value;
                    };
                    if (Data_Boolean.otherwise) {
                        $copy_segments = possibleParentArr;
                        return;
                    };
                };
                throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Menu (line 192, column 7 - line 196, column 46): " + [ v.constructor.name ]);
            };
            while (!$tco_done) {
                $tco_result = $tco_loop($copy_segments);
            };
            return $tco_result;
        };
        return go(Data_String_Common.split("/")(Routing_Duplex.print(routeD)(route)));
    };
};
var findMenuHighlight = function (dictEq) {
    return function (routeD) {
        return function (menu) {
            return function (routeToMatch) {
                var tryParent = Control_Bind.bind(Data_Maybe.bindMaybe)(getParentRoute(routeD)(routeToMatch))(function (parentRoute) {
                    return findMenuHighlight(dictEq)(routeD)(menu)(parentRoute);
                });
                var findLocation = Data_FoldableWithIndex.foldlWithIndex(Data_FoldableWithIndex.foldableWithIndexArray)(function (index) {
                    return function (acc) {
                        return function (menuI) {
                            return Control_Alt.alt(Data_Maybe.altMaybe)(acc)((function () {
                                if (menuI instanceof AdminUi_AdminClient_Components_Types.MenuItem) {
                                    return Data_Functor.voidLeft(Data_Maybe.functorMaybe)(Control_MonadZero.guard(Data_Maybe.monadZeroMaybe)(Data_Eq.eq(dictEq)(menuI.value0.route)(routeToMatch)))({
                                        x: index,
                                        y: 0
                                    });
                                };
                                if (menuI instanceof AdminUi_AdminClient_Components_Types.SubMenu) {
                                    var menuItemLoc = Data_FoldableWithIndex.foldlWithIndex(Data_FoldableWithIndex.foldableWithIndexArray)(function (i2) {
                                        return function (acc2) {
                                            return function (v) {
                                                return Control_Alt.alt(Data_Maybe.altMaybe)(acc2)(Data_Functor.voidLeft(Data_Maybe.functorMaybe)(Control_MonadZero.guard(Data_Maybe.monadZeroMaybe)(Data_Eq.eq(dictEq)(v.route)(routeToMatch)))(i2));
                                            };
                                        };
                                    })(Data_Maybe.Nothing.value)(menuI.value0.items);
                                    return Control_Alt.alt(Data_Maybe.altMaybe)(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(menuItemLoc)(function (y) {
                                        return {
                                            x: index,
                                            y: y
                                        };
                                    }))(acc);
                                };
                                throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Menu (line 168, column 17 - line 180, column 68): " + [ menuI.constructor.name ]);
                            })());
                        };
                    };
                })(Data_Maybe.Nothing.value)(menu);
                return Control_Alt.alt(Data_Maybe.altMaybe)(findLocation)(tryParent);
            };
        };
    };
};
var mkMenu = function (dictEq) {
    return function (routeD) {
        return React_Basic_Hooks.component()()()("Menu")(function (v) {
            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(Data_Maybe.Nothing.value))(function (v1) {
                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState([  ]))(function (v2) {
                    var onMenuItemClick = function (route) {
                        return Effect_Uncurried.mkEffectFn1(Data_Function["const"](v.router.push(route)));
                    };
                    return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useEffect(dictEq)(v.router.route)((function () {
                        var newLocation = findMenuHighlight(dictEq)(routeD)(v.menu)(v.router.route);
                        return function __do() {
                            v1.value1(Data_Function["const"](newLocation))();
                            (function () {
                                if (v1.value0 instanceof Data_Maybe.Just && (newLocation instanceof Data_Maybe.Just && Data_Eq.eq(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
                                    return "y";
                                }))(Data_Eq.eqInt))()(new Data_Symbol.IsSymbol(function () {
                                    return "x";
                                }))(Data_Eq.eqInt)))(v1.value0.value0)(newLocation.value0))) {
                                    return Data_Unit.unit;
                                };
                                return v2.value1(function (ks) {
                                    return Data_Array.nubEq(Data_Eq.eqString)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(ks)(toSubMenuKeys(newLocation)));
                                })();
                            })();
                            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                        };
                    })()))(function () {
                        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(AdminUi_Antd_Menu.menu()({
                            mode: AdminUi_Antd_PropLits.inline,
                            selectedKeys: toMenuItemKeys(v1.value0),
                            openKeys: v2.value0,
                            children: Data_Array.mapWithIndex(function (index) {
                                return function (item) {
                                    var loc = {
                                        x: index,
                                        y: 0
                                    };
                                    if (item instanceof AdminUi_AdminClient_Components_Types.MenuItem) {
                                        return AdminUi_Antd_Menu.menuItem()({
                                            key: mkMenuItemKey(loc),
                                            children: [ AdminUi_AdminClient_Components_Icons.mkIcon()(item.value0.icon)({}), React_Basic_DOM_Generated.span_([ React_Basic_DOM.text(item.value0.label) ]) ],
                                            onClick: onMenuItemClick(item.value0.route)
                                        });
                                    };
                                    if (item instanceof AdminUi_AdminClient_Components_Types.SubMenu) {
                                        return AdminUi_Antd_Menu.subMenu()({
                                            key: mkSubMenuKey(loc),
                                            title: React_Basic_DOM_Generated.span_([ AdminUi_AdminClient_Components_Icons.mkIcon()(item.value0.icon)({}), React_Basic_DOM_Generated.span_([ React_Basic_DOM.text(item.value0.label) ]) ]),
                                            onTitleClick: function (v3) {
                                                return v2.value1(function (keys) {
                                                    var v4 = Data_Array.elemIndex(Data_Eq.eqString)(v3.key)(keys);
                                                    if (v4 instanceof Data_Maybe.Just) {
                                                        return Data_Array["delete"](Data_Eq.eqString)(v3.key)(keys);
                                                    };
                                                    if (v4 instanceof Data_Maybe.Nothing) {
                                                        return Data_Array.cons(v3.key)(keys);
                                                    };
                                                    throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Menu (line 111, column 50 - line 113, column 74): " + [ v4.constructor.name ]);
                                                })();
                                            },
                                            children: Data_Array.mapWithIndex(function (i2) {
                                                return function (child) {
                                                    return AdminUi_Antd_Menu.menuItem()({
                                                        key: mkMenuItemKey({
                                                            x: index,
                                                            y: i2
                                                        }),
                                                        children: [ React_Basic_DOM.text(child.label) ],
                                                        onClick: onMenuItemClick(child.route)
                                                    });
                                                };
                                            })(item.value0.items)
                                        });
                                    };
                                    throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Menu (line 96, column 21 - line 128, column 28): " + [ item.constructor.name ]);
                                };
                            })(v.menu)
                        }));
                    });
                });
            });
        });
    };
};
module.exports = {
    mkMenu: mkMenu,
    findMenuHighlight: findMenuHighlight,
    subMenu: subMenu,
    menuItem: menuItem
};
