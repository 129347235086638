// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var Control_Promise = require("../Control.Promise/index.js");
var papaParse_ = function (file) {
    return Control_Promise.toAffE($foreign["_papaParse_"](file));
};
module.exports = {
    papaParse_: papaParse_,
    "_papaParse_": $foreign["_papaParse_"]
};
