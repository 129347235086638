// Generated by purs version 0.13.6
"use strict";
var Control_Category = require("../Control.Category/index.js");
var Data_Argonaut_Core = require("../Data.Argonaut.Core/index.js");
var Data_Date = require("../Data.Date/index.js");
var Data_Date_Component = require("../Data.Date.Component/index.js");
var Data_DateTime = require("../Data.DateTime/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Enum = require("../Data.Enum/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_HugeNum = require("../Data.HugeNum/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Long = require("../Data.Long/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Time = require("../Data.Time/index.js");
var Data_Time_Component = require("../Data.Time.Component/index.js");
var Foreign_Object = require("../Foreign.Object/index.js");
var Record = require("../Record/index.js");
var Type_Data_RowList = require("../Type.Data.RowList/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var ZRPC_ConsPrefix = require("../ZRPC.ConsPrefix/index.js");
var ZRPC_Types_LocalDateTime = require("../ZRPC.Types.LocalDateTime/index.js");
var ZRPC_Types_ZJson = require("../ZRPC.Types.ZJson/index.js");
var ZRPCWriteRep = function (zrpcWriteRep) {
    this.zrpcWriteRep = zrpcWriteRep;
};
var ZRPCWriteFields = function (zrpcWriteFields) {
    this.zrpcWriteFields = zrpcWriteFields;
};
var ZRPCWriteField = function (zrpcWriteField) {
    this.zrpcWriteField = zrpcWriteField;
};
var ZRPCWrite = function (zrpcWrite) {
    this.zrpcWrite = zrpcWrite;
};
var zrpcWriteZJson = new ZRPCWrite(Data_Newtype.unwrap(ZRPC_Types_ZJson.newtypeZJson));
var zrpcWriteUnit = new ZRPCWrite(function (v) {
    return Data_Argonaut_Core.fromObject(Foreign_Object.empty);
});
var zrpcWriteString = new ZRPCWrite(Data_Argonaut_Core.fromString);
var zrpcWriteRep = function (dict) {
    return dict.zrpcWriteRep;
};
var zrpcWriteNumber = new ZRPCWrite(Data_Argonaut_Core.fromNumber);
var zrpcWriteInt = new ZRPCWrite(function ($56) {
    return Data_Argonaut_Core.fromNumber(Data_Int.toNumber($56));
});
var zrpcWriteFieldsNil = new ZRPCWriteFields(function (v) {
    return function (v1) {
        return Control_Category.identity(Control_Category.categoryFn);
    };
});
var zrpcWriteFields = function (dict) {
    return dict.zrpcWriteFields;
};
var zrpcWriteRec = function (dictRowToList) {
    return function (dictZRPCWriteFields) {
        return new ZRPCWrite(function (r) {
            var o = zrpcWriteFields(dictZRPCWriteFields)(Type_Data_RowList.RLProxy.value)(r)(Foreign_Object.empty);
            return Data_Argonaut_Core.fromObject(o);
        });
    };
};
var zrpcWriteField = function (dict) {
    return dict.zrpcWriteField;
};
var zrpcWriteFieldsCons = function (dictIsSymbol) {
    return function (dictZRPCWriteField) {
        return function (dictZRPCWriteFields) {
            return function (dictCons) {
                return new ZRPCWriteFields(function (v) {
                    return function (r) {
                        return function (prev) {
                            var v1 = Record.get(dictIsSymbol)()(Data_Symbol.SProxy.value)(r);
                            var k = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
                            var next = (function () {
                                var v2 = zrpcWriteField(dictZRPCWriteField)(v1);
                                if (v2 instanceof Data_Maybe.Just) {
                                    return Foreign_Object.insert(k)(v2.value0)(prev);
                                };
                                if (v2 instanceof Data_Maybe.Nothing) {
                                    return prev;
                                };
                                throw new Error("Failed pattern match at ZRPC.ZRPCWrite (line 157, column 14 - line 159, column 24): " + [ v2.constructor.name ]);
                            })();
                            return zrpcWriteFields(dictZRPCWriteFields)(Type_Data_RowList.RLProxy.value)(r)(next);
                        };
                    };
                });
            };
        };
    };
};
var zrpcWriteBoolean = new ZRPCWrite(Data_Argonaut_Core.fromBoolean);
var zrpcWrite = function (dict) {
    return dict.zrpcWrite;
};
var zrpcWriteFieldId = function (dictZRPCWrite) {
    return new ZRPCWriteField((function () {
        var $57 = zrpcWrite(dictZRPCWrite);
        return function ($58) {
            return Data_Maybe.Just.create($57($58));
        };
    })());
};
var zrpcWriteFieldMaybe = function (dictZRPCWrite) {
    return new ZRPCWriteField(function (a) {
        return Data_Functor.map(Data_Maybe.functorMaybe)(zrpcWrite(dictZRPCWrite))(a);
    });
};
var zrpcWriteHugeNum = new ZRPCWrite((function () {
    var $59 = zrpcWrite(zrpcWriteString);
    return function ($60) {
        return $59(Data_HugeNum.toString($60));
    };
})());
var zrpcWriteLong = new ZRPCWrite(function (v) {
    var v1 = Data_Long.toInt(v);
    if (v1 instanceof Data_Maybe.Just) {
        return zrpcWrite(zrpcWriteInt)(v1.value0);
    };
    if (v1 instanceof Data_Maybe.Nothing) {
        return zrpcWrite(zrpcWriteString)(Data_Long.toString(v));
    };
    throw new Error("Failed pattern match at ZRPC.ZRPCWrite (line 64, column 17 - line 66, column 43): " + [ v1.constructor.name ]);
});
var zrpcWriteMillis = new ZRPCWrite(function (v) {
    return zrpcWrite(zrpcWriteLong)(v);
});
var zf = function (l) {
    return function (s) {
        var len = Data_String_CodePoints.length(s);
        var $43 = len >= l;
        if ($43) {
            return s;
        };
        return Data_Monoid.power(Data_Monoid.monoidString)("0")(l - len | 0) + s;
    };
};
var writeRepSum = function (dictZRPCWriteRep) {
    return function (dictZRPCWriteRep1) {
        return new ZRPCWriteRep(function (prefix) {
            return function (v) {
                if (v instanceof Data_Generic_Rep.Inl) {
                    return zrpcWriteRep(dictZRPCWriteRep)(prefix)(v.value0);
                };
                if (v instanceof Data_Generic_Rep.Inr) {
                    return zrpcWriteRep(dictZRPCWriteRep1)(prefix)(v.value0);
                };
                throw new Error("Failed pattern match at ZRPC.ZRPCWrite (line 184, column 1 - line 186, column 54): " + [ prefix.constructor.name, v.constructor.name ]);
            };
        });
    };
};
var unionTypeName = function (dictIsSymbol) {
    return function (prefix) {
        return function (sp) {
            var prefixLen = Data_String_CodePoints.length(prefix);
            var consName = Data_Symbol.reflectSymbol(dictIsSymbol)(sp);
            var p = Data_String_CodePoints.splitAt(prefixLen)(consName);
            var $48 = p.before === prefix;
            if ($48) {
                return p.after;
            };
            return consName;
        };
    };
};
var unionTypeObject = function (dictIsSymbol) {
    return function (prefix) {
        return function (v) {
            return Foreign_Object.singleton("type")(Data_Argonaut_Core.fromString(unionTypeName(dictIsSymbol)(prefix)(Data_Symbol.SProxy.value)));
        };
    };
};
var writeRepNoArgs = function (dictIsSymbol) {
    return new ZRPCWriteRep(function (prefix) {
        return function (v) {
            return unionTypeObject(dictIsSymbol)(prefix)(Data_Symbol.SProxy.value);
        };
    });
};
var writeRepRecArg = function (dictIsSymbol) {
    return function (dictRowToList) {
        return function (dictZRPCWriteFields) {
            return function (dictLacks) {
                return new ZRPCWriteRep(function (prefix) {
                    return function (v) {
                        var init = unionTypeObject(dictIsSymbol)(prefix)(Data_Symbol.SProxy.value);
                        return zrpcWriteFields(dictZRPCWriteFields)(Type_Data_RowList.RLProxy.value)(v)(init);
                    };
                });
            };
        };
    };
};
var typeSProxy = Data_Symbol.SProxy.value;
var maybeJsonToJson = function (v) {
    if (v instanceof Data_Maybe.Just) {
        return v.value0;
    };
    if (v instanceof Data_Maybe.Nothing) {
        return Data_Argonaut_Core.jsonNull;
    };
    throw new Error("Failed pattern match at ZRPC.ZRPCWrite (line 245, column 1 - line 245, column 38): " + [ v.constructor.name ]);
};
var zrpcWriteArray = function (dictZRPCWriteField) {
    return new ZRPCWrite((function () {
        var $61 = Data_Functor.map(Data_Functor.functorArray)((function () {
            var $63 = zrpcWriteField(dictZRPCWriteField);
            return function ($64) {
                return maybeJsonToJson($63($64));
            };
        })());
        return function ($62) {
            return Data_Argonaut_Core.fromArray($61($62));
        };
    })());
};
var zrpcWriteEither = function (dictZRPCWriteField) {
    return function (dictZRPCWriteField1) {
        return new ZRPCWrite(function (v) {
            if (v instanceof Data_Either.Left) {
                return Data_Argonaut_Core.fromObject(Foreign_Object.singleton("Left")(maybeJsonToJson(zrpcWriteField(dictZRPCWriteField)(v.value0))));
            };
            if (v instanceof Data_Either.Right) {
                return Data_Argonaut_Core.fromObject(Foreign_Object.singleton("Right")(maybeJsonToJson(zrpcWriteField(dictZRPCWriteField1)(v.value0))));
            };
            throw new Error("Failed pattern match at ZRPC.ZRPCWrite (line 92, column 1 - line 97, column 81): " + [ v.constructor.name ]);
        });
    };
};
var genericZRPCWrite = function (dictConsPrefix) {
    return function (dictGeneric) {
        return function (dictZRPCWriteRep) {
            var $65 = zrpcWriteRep(dictZRPCWriteRep)(ZRPC_ConsPrefix.consPrefix(dictConsPrefix)(Type_Proxy["Proxy"].value));
            var $66 = Data_Generic_Rep.from(dictGeneric);
            return function ($67) {
                return Data_Argonaut_Core.fromObject($65($66($67)));
            };
        };
    };
};
var formatTime = function (time) {
    var s = zf(2)(Data_Show.show(Data_Show.showInt)(Data_Enum.fromEnum(Data_Time_Component.boundedEnumSecond)(Data_Time.second(time))));
    var ms = zf(3)(Data_Show.show(Data_Show.showInt)(Data_Enum.fromEnum(Data_Time_Component.boundedEnumMillisecond)(Data_Time.millisecond(time))));
    var m = zf(2)(Data_Show.show(Data_Show.showInt)(Data_Enum.fromEnum(Data_Time_Component.boundedEnumMinute)(Data_Time.minute(time))));
    var h = Data_Show.show(Data_Show.showInt)(Data_Enum.fromEnum(Data_Time_Component.boundedEnumHour)(Data_Time.hour(time)));
    return h + (":" + (m + (":" + (s + ("." + ms)))));
};
var zrpcWriteTime = new ZRPCWrite((function () {
    var $68 = zrpcWrite(zrpcWriteString);
    return function ($69) {
        return $68(formatTime($69));
    };
})());
var formatDate = function (date) {
    var y = Data_Show.show(Data_Show.showInt)(Data_Enum.fromEnum(Data_Date_Component.boundedEnumYear)(Data_Date.year(date)));
    var m = zf(2)(Data_Show.show(Data_Show.showInt)(Data_Enum.fromEnum(Data_Date_Component.boundedEnumMonth)(Data_Date.month(date))));
    var d = zf(2)(Data_Show.show(Data_Show.showInt)(Data_Enum.fromEnum(Data_Date_Component.boundedEnumDay)(Data_Date.day(date))));
    return y + ("-" + (m + ("-" + d)));
};
var formatDateTime = function (ldt) {
    var tm = formatTime(Data_DateTime.time(ldt));
    var dt = formatDate(Data_DateTime.date(ldt));
    return dt + ("T" + (tm + "Z"));
};
var zrpcWriteDateTime = new ZRPCWrite((function () {
    var $70 = zrpcWrite(zrpcWriteString);
    return function ($71) {
        return $70(formatDateTime($71));
    };
})());
var formatLocalDateTime = function (ldt) {
    var tm = formatTime(ZRPC_Types_LocalDateTime.time(ldt));
    var dt = formatDate(ZRPC_Types_LocalDateTime.date(ldt));
    return dt + ("T" + tm);
};
var zrpcWriteLocalDateTime = new ZRPCWrite((function () {
    var $72 = zrpcWrite(zrpcWriteString);
    return function ($73) {
        return $72(formatLocalDateTime($73));
    };
})());
var zrpcWriteDate = new ZRPCWrite((function () {
    var $74 = zrpcWrite(zrpcWriteString);
    return function ($75) {
        return $74(formatDate($75));
    };
})());
module.exports = {
    ZRPCWrite: ZRPCWrite,
    zrpcWrite: zrpcWrite,
    genericZRPCWrite: genericZRPCWrite,
    ZRPCWriteField: ZRPCWriteField,
    zrpcWriteField: zrpcWriteField,
    ZRPCWriteFields: ZRPCWriteFields,
    zrpcWriteFields: zrpcWriteFields,
    ZRPCWriteRep: ZRPCWriteRep,
    zrpcWriteRep: zrpcWriteRep,
    zrpcWriteUnit: zrpcWriteUnit,
    zrpcWriteBoolean: zrpcWriteBoolean,
    zrpcWriteInt: zrpcWriteInt,
    zrpcWriteNumber: zrpcWriteNumber,
    zrpcWriteString: zrpcWriteString,
    zrpcWriteLong: zrpcWriteLong,
    zrpcWriteHugeNum: zrpcWriteHugeNum,
    zrpcWriteDate: zrpcWriteDate,
    zrpcWriteTime: zrpcWriteTime,
    zrpcWriteLocalDateTime: zrpcWriteLocalDateTime,
    zrpcWriteDateTime: zrpcWriteDateTime,
    zrpcWriteMillis: zrpcWriteMillis,
    zrpcWriteZJson: zrpcWriteZJson,
    zrpcWriteArray: zrpcWriteArray,
    zrpcWriteEither: zrpcWriteEither,
    zrpcWriteRec: zrpcWriteRec,
    zrpcWriteFieldMaybe: zrpcWriteFieldMaybe,
    zrpcWriteFieldId: zrpcWriteFieldId,
    zrpcWriteFieldsNil: zrpcWriteFieldsNil,
    zrpcWriteFieldsCons: zrpcWriteFieldsCons,
    writeRepNoArgs: writeRepNoArgs,
    writeRepRecArg: writeRepRecArg,
    writeRepSum: writeRepSum
};
