// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminCilent_Components_Sort = require("../AdminUi.AdminCilent.Components.Sort/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var AdminUi_AdminClient_Components_Utils = require("../AdminUi.AdminClient.Components.Utils/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Effect = require("../Effect/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var Jss_ReactJss = require("../Jss.ReactJss/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var Unsortable = (function () {
    function Unsortable() {

    };
    Unsortable.value = new Unsortable();
    return Unsortable;
})();
var Sortable = (function () {
    function Sortable(value0) {
        this.value0 = value0;
    };
    Sortable.create = function (value0) {
        return new Sortable(value0);
    };
    return Sortable;
})();
var useStyles = Jss_ReactJss.createUseStyles_()()({
    container: Jss_ReactJss.style({
        padding: "16px",
        display: "flex",
        alignItems: "center"
    })
});
var toggleSortDirection = function (v) {
    if (v instanceof AdminUi_AdminClient_Components_Types.Ascending) {
        return new Data_Maybe.Just(AdminUi_AdminClient_Components_Types.Descending.value);
    };
    if (v instanceof AdminUi_AdminClient_Components_Types.Descending) {
        return Data_Maybe.Nothing.value;
    };
    throw new Error("Failed pattern match at AdminUi.AdminClient.Component.Table.TableHeader (line 30, column 23 - line 32, column 24): " + [ v.constructor.name ]);
};
var toggleSort = function (i) {
    return function (v) {
        if (v instanceof Data_Maybe.Nothing) {
            return new Data_Maybe.Just(new Data_Tuple.Tuple(i, AdminUi_AdminClient_Components_Types.Ascending.value));
        };
        if (v instanceof Data_Maybe.Just) {
            if (i === v.value0.value0) {
                return Data_Functor.map(Data_Maybe.functorMaybe)(Data_Tuple.Tuple.create(v.value0.value0))(toggleSortDirection(v.value0.value1));
            };
            if (Data_Boolean.otherwise) {
                return new Data_Maybe.Just(new Data_Tuple.Tuple(i, AdminUi_AdminClient_Components_Types.Ascending.value));
            };
        };
        throw new Error("Failed pattern match at AdminUi.AdminClient.Component.Table.TableHeader (line 35, column 16 - line 39, column 44): " + [ v.constructor.name ]);
    };
};
var mkTableHeader = AdminUi_AdminClient_Components_Utils.comp_(Effect_Class.monadEffectEffect)()()()("TableHeader")(function (props) {
    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useStyles({}))(function (css) {
        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.div()({
            className: css.container,
            onClick: React_Basic_Events.handler_((function () {
                if (props.sorting instanceof Sortable) {
                    return props.onClick;
                };
                if (props.sorting instanceof Unsortable) {
                    return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                };
                throw new Error("Failed pattern match at AdminUi.AdminClient.Component.Table.TableHeader (line 61, column 22 - line 63, column 35): " + [ props.sorting.constructor.name ]);
            })()),
            children: [ React_Basic_DOM.text(props.title), (function () {
                if (props.sorting instanceof Sortable && (props.sorting.value0 instanceof Data_Maybe.Just && props.sorting.value0.value0 instanceof AdminUi_AdminClient_Components_Types.Ascending)) {
                    return AdminUi_AdminCilent_Components_Sort.sortAscending;
                };
                if (props.sorting instanceof Sortable && (props.sorting.value0 instanceof Data_Maybe.Just && props.sorting.value0.value0 instanceof AdminUi_AdminClient_Components_Types.Descending)) {
                    return AdminUi_AdminCilent_Components_Sort.sortDescending;
                };
                if (props.sorting instanceof Sortable && props.sorting.value0 instanceof Data_Maybe.Nothing) {
                    return AdminUi_AdminCilent_Components_Sort.sortDefault;
                };
                if (props.sorting instanceof Unsortable) {
                    return Data_Monoid.mempty(React_Basic.monoidJSX);
                };
                throw new Error("Failed pattern match at AdminUi.AdminClient.Component.Table.TableHeader (line 66, column 15 - line 70, column 37): " + [ props.sorting.constructor.name ]);
            })() ]
        }));
    });
});
var tableHeaderRC = Effect_Unsafe.unsafePerformEffect(mkTableHeader);
var tableHeader = React_Basic.element(tableHeaderRC);
module.exports = {
    Unsortable: Unsortable,
    Sortable: Sortable,
    tableHeader: tableHeader,
    toggleSort: toggleSort
};
