// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_Content_ContentForm = require("../AdminUi.AdminClient.Components.Content.ContentForm/index.js");
var AdminUi_AdminClient_Components_Content_ContentList = require("../AdminUi.AdminClient.Components.Content.ContentList/index.js");
var AdminUi_AdminClient_Components_Content_ContentTable = require("../AdminUi.AdminClient.Components.Content.ContentTable/index.js");
var AdminUi_AdminClient_Components_Details = require("../AdminUi.AdminClient.Components.Details/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var AdminUi_AdminClient_Components_Utils = require("../AdminUi.AdminClient.Components.Utils/index.js");
var AdminUi_AdminClient_Components_ZText = require("../AdminUi.AdminClient.Components.ZText/index.js");
var AdminUi_Spacer = require("../AdminUi.Spacer/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Reader_Class = require("../Control.Monad.Reader.Class/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Effect = require("../Effect/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var txt = AdminUi_AdminClient_Components_Types.CText.create;
var li_ = function (items) {
    return new AdminUi_AdminClient_Components_Types.CList({
        caption: "",
        items: items
    });
};
var li = function (caption) {
    return function (items) {
        return new AdminUi_AdminClient_Components_Types.CList({
            caption: caption,
            items: items
        });
    };
};
var frag = function (arr) {
    return new AdminUi_AdminClient_Components_Types.CFragment(arr);
};
var form = AdminUi_AdminClient_Components_Types.CForm.create;
var details = AdminUi_AdminClient_Components_Types.CDetails.create;
var mkContent = function (dictEq) {
    return function (dictMonadAsk) {
        return function (dictMonadEffect) {
            return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(Control_Monad_Reader_Class.ask(dictMonadAsk))(function (v) {
                return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Components_Details.mkDetails(dictMonadAsk)(dictMonadEffect))(function (detailsC) {
                    return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Components_Content_ContentForm.mkContentForm(dictMonadAsk)(dictMonadEffect))(function (contentForm) {
                        return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Components_Content_ContentTable.mkContentTable(dictEq)(dictMonadAsk)(dictMonadEffect))(function (contentTable) {
                            return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Components_Content_ContentList.mkContentList(dictMonadAsk)(dictMonadEffect))(function (contentList) {
                                return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Components_ZText.mkZText(dictMonadAsk)(dictMonadEffect))(function (zTextC) {
                                    return AdminUi_AdminClient_Components_Utils.comp_(dictMonadEffect)()()()("Content")(function (v1) {
                                        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))((function () {
                                            if (v1.content instanceof AdminUi_AdminClient_Components_Types.CCollection) {
                                                return React_Basic.element(contentTable)({
                                                    table: v1.content.value0.value0
                                                });
                                            };
                                            if (v1.content instanceof AdminUi_AdminClient_Components_Types.CDetails) {
                                                return React_Basic.element(detailsC)({
                                                    details: v1.content.value0,
                                                    columns: (function () {
                                                        if (v1.surface instanceof AdminUi_AdminClient_Components_Types.SModal) {
                                                            return 1;
                                                        };
                                                        if (v1.surface instanceof AdminUi_AdminClient_Components_Types.SPage) {
                                                            return 2;
                                                        };
                                                        throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Content (line 81, column 17 - line 83, column 29): " + [ v1.surface.constructor.name ]);
                                                    })()
                                                });
                                            };
                                            if (v1.content instanceof AdminUi_AdminClient_Components_Types.CForm) {
                                                return React_Basic.element(contentForm)({
                                                    scFormArg: v1.content.value0
                                                });
                                            };
                                            if (v1.content instanceof AdminUi_AdminClient_Components_Types.CText) {
                                                return React_Basic.element(zTextC)({
                                                    text: v1.content.value0
                                                });
                                            };
                                            if (v1.content instanceof AdminUi_AdminClient_Components_Types.CList) {
                                                return React_Basic.element(contentList)({
                                                    list: v1.content.value0
                                                });
                                            };
                                            if (v1.content instanceof AdminUi_AdminClient_Components_Types.CFragment) {
                                                return React_Basic.fragment(Data_Array.mapWithIndex(function (idx) {
                                                    return function (c$prime) {
                                                        return React_Basic.fragment([ React_Basic_DOM_Generated.div_([ React_Basic.element(contentRC(dictEq)({
                                                            router: v.router,
                                                            modal: v.modal
                                                        }))({
                                                            content: c$prime,
                                                            surface: v1.surface
                                                        }) ]), Data_Monoid.guard(React_Basic.monoidJSX)((idx + 1 | 0) !== Data_Array.length(v1.content.value0))(AdminUi_Spacer.vspacer(3)) ]);
                                                    };
                                                })(v1.content.value0));
                                            };
                                            throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Content (line 75, column 9 - line 96, column 18): " + [ v1.content.constructor.name ]);
                                        })());
                                    });
                                });
                            });
                        });
                    });
                });
            });
        };
    };
};
var contentRC = function (dictEq) {
    return function (contexts) {
        return Effect_Unsafe.unsafePerformEffect(Control_Monad_Reader_Trans.runReaderT(mkContent(dictEq)(Control_Monad_Reader_Trans.monadAskReaderT(Effect.monadEffect))(Control_Monad_Reader_Trans.monadEffectReader(Effect_Class.monadEffectEffect)))(contexts));
    };
};
var collection = AdminUi_AdminClient_Components_Types.CCollection.create;
module.exports = {
    mkContent: mkContent,
    details: details,
    form: form,
    collection: collection,
    txt: txt,
    li: li,
    li_: li_,
    frag: frag
};
