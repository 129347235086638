// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_Modal = require("../AdminUi.AdminClient.Components.Modal/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var showModal = AdminUi_AdminClient_Components_Types.ShowModal.create;
var showConfirmModal_ = function (title) {
    var $0 = AdminUi_AdminClient_Components_Modal.confirmModal_(title);
    return function ($1) {
        return showModal($0($1));
    };
};
var goTo = AdminUi_AdminClient_Components_Types.GoTo.create;
var goBack = AdminUi_AdminClient_Components_Types.GoBack.value;
var closeModal = new AdminUi_AdminClient_Components_Types.AAModalAction(AdminUi_AdminClient_Components_Types.CloseModal.value);
module.exports = {
    goTo: goTo,
    goBack: goBack,
    showModal: showModal,
    closeModal: closeModal,
    showConfirmModal_: showConfirmModal_
};
