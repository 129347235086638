// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var React_Basic = require("../React.Basic/index.js");
var Untagged_Coercible = require("../Untagged.Coercible/index.js");
var result = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_result"])(Untagged_Coercible.coerce()(props));
    };
};
module.exports = {
    result: result,
    "_result": $foreign["_result"]
};
