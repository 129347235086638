// Generated by purs version 0.13.6
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Routing_Duplex = require("../Routing.Duplex/index.js");
var parseZname = function (path) {
    return Data_String_Common.joinWith("_")(Data_Functor.mapFlipped(Data_Functor.functorArray)(Data_String_Common.split("__")(path))(Data_String_Common.replaceAll("_")(" ")));
};
var zname = (function () {
    var print = function (name) {
        var r__ = Data_String_Common.replaceAll("_")("__");
        var r_ = Data_String_Common.replaceAll(" ")("_");
        return r_(r__(name));
    };
    var parse = (function () {
        var $0 = Control_Applicative.pure(Data_Either.applicativeEither);
        return function ($1) {
            return $0(parseZname($1));
        };
    })();
    return Routing_Duplex.as(print)(parse)(Routing_Duplex.segment);
})();
module.exports = {
    zname: zname,
    parseZname: parseZname
};
