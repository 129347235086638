// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient = require("../AdminUi.AdminClient/index.js");
var AdminUi_AdminClient_Components_ActionGroup = require("../AdminUi.AdminClient.Components.ActionGroup/index.js");
var AdminUi_AdminClient_Components_AppAction = require("../AdminUi.AdminClient.Components.AppAction/index.js");
var AdminUi_AdminClient_Components_Icons_AntdIcons = require("../AdminUi.AdminClient.Components.Icons.AntdIcons/index.js");
var AdminUi_AdminClient_Components_Menu = require("../AdminUi.AdminClient.Components.Menu/index.js");
var AdminUi_AdminClient_Components_Page = require("../AdminUi.AdminClient.Components.Page/index.js");
var AdminUi_AdminClient_Components_Title = require("../AdminUi.AdminClient.Components.Title/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var AdminUi_AdminClient_Components_Utils = require("../AdminUi.AdminClient.Components.Utils/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect = require("../Effect/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var SiloDashboard_Client_ApiClient = require("../SiloDashboard.Client.ApiClient/index.js");
var SiloDashboard_Client_AuthRoutes_Brands_BranchChangeImage = require("../SiloDashboard.Client.AuthRoutes.Brands.BranchChangeImage/index.js");
var SiloDashboard_Client_AuthRoutes_Brands_BranchChangeSettings = require("../SiloDashboard.Client.AuthRoutes.Brands.BranchChangeSettings/index.js");
var SiloDashboard_Client_AuthRoutes_Brands_BranchEditInfo = require("../SiloDashboard.Client.AuthRoutes.Brands.BranchEditInfo/index.js");
var SiloDashboard_Client_AuthRoutes_Brands_BranchOverview = require("../SiloDashboard.Client.AuthRoutes.Brands.BranchOverview/index.js");
var SiloDashboard_Client_AuthRoutes_Brands_BranchRename = require("../SiloDashboard.Client.AuthRoutes.Brands.BranchRename/index.js");
var SiloDashboard_Client_AuthRoutes_Brands_BranchesAdd = require("../SiloDashboard.Client.AuthRoutes.Brands.BranchesAdd/index.js");
var SiloDashboard_Client_AuthRoutes_Brands_BranchesAddMulti = require("../SiloDashboard.Client.AuthRoutes.Brands.BranchesAddMulti/index.js");
var SiloDashboard_Client_AuthRoutes_Brands_BrandChangeImage = require("../SiloDashboard.Client.AuthRoutes.Brands.BrandChangeImage/index.js");
var SiloDashboard_Client_AuthRoutes_Brands_BrandChangeSenderId = require("../SiloDashboard.Client.AuthRoutes.Brands.BrandChangeSenderId/index.js");
var SiloDashboard_Client_AuthRoutes_Brands_BrandChangeSettings = require("../SiloDashboard.Client.AuthRoutes.Brands.BrandChangeSettings/index.js");
var SiloDashboard_Client_AuthRoutes_Brands_BrandOverview = require("../SiloDashboard.Client.AuthRoutes.Brands.BrandOverview/index.js");
var SiloDashboard_Client_AuthRoutes_Brands_BrandRename = require("../SiloDashboard.Client.AuthRoutes.Brands.BrandRename/index.js");
var SiloDashboard_Client_AuthRoutes_Brands_BrandsAdd = require("../SiloDashboard.Client.AuthRoutes.Brands.BrandsAdd/index.js");
var SiloDashboard_Client_AuthRoutes_Brands_POSAddMultipleCashierCodes = require("../SiloDashboard.Client.AuthRoutes.Brands.POSAddMultipleCashierCodes/index.js");
var SiloDashboard_Client_AuthRoutes_Brands_POSOverview = require("../SiloDashboard.Client.AuthRoutes.Brands.POSOverview/index.js");
var SiloDashboard_Client_AuthRoutes_Brands_POSRename = require("../SiloDashboard.Client.AuthRoutes.Brands.POSRename/index.js");
var SiloDashboard_Client_Routes = require("../SiloDashboard.Client.Routes/index.js");
var SiloDashboard_Client_Screens_Login = require("../SiloDashboard.Client.Screens.Login/index.js");
var SiloDashboard_Client_Screens_SiloInvitation = require("../SiloDashboard.Client.Screens.SiloInvitation/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var renderAuthRoute = function (v) {
    if (v instanceof SiloDashboard_Client_Routes.BrandsList) {
        return Control_Applicative.pure(Control_Monad_Reader_Trans.applicativeReaderT(Data_Identity.applicativeIdentity))(AdminUi_AdminClient_Components_Page.page(Data_Maybe.Nothing.value)(AdminUi_AdminClient_Components_Title.title_("Brands"))(Data_Maybe.Just.create(AdminUi_AdminClient_Components_ActionGroup.primaryActionGroup_(AdminUi_AdminClient_Components_ActionGroup.actionButton("Add Brand")(AdminUi_AdminClient_Components_AppAction.goTo(SiloDashboard_Client_Routes.BrandsAdd.value)))))([  ])(Data_Maybe.Nothing.value));
    };
    if (v instanceof SiloDashboard_Client_Routes.BrandsAdd) {
        return Control_Applicative.pure(Control_Monad_Reader_Trans.applicativeReaderT(Data_Identity.applicativeIdentity))(SiloDashboard_Client_AuthRoutes_Brands_BrandsAdd.brandsAdd);
    };
    if (v instanceof SiloDashboard_Client_Routes.BrandOverview) {
        return SiloDashboard_Client_AuthRoutes_Brands_BrandOverview.brandOverview(v.value0);
    };
    if (v instanceof SiloDashboard_Client_Routes.BrandRename) {
        return SiloDashboard_Client_AuthRoutes_Brands_BrandRename.brandRename(v.value0);
    };
    if (v instanceof SiloDashboard_Client_Routes.BrandChangeImage) {
        return SiloDashboard_Client_AuthRoutes_Brands_BrandChangeImage.brandChangeImage(v.value0);
    };
    if (v instanceof SiloDashboard_Client_Routes.BrandChangeSettings) {
        return SiloDashboard_Client_AuthRoutes_Brands_BrandChangeSettings.brandChangeSettings(v.value0);
    };
    if (v instanceof SiloDashboard_Client_Routes.BrandChangeSenderId) {
        return SiloDashboard_Client_AuthRoutes_Brands_BrandChangeSenderId.brandChangeSenderId(v.value0);
    };
    if (v instanceof SiloDashboard_Client_Routes.BranchesAdd) {
        return SiloDashboard_Client_AuthRoutes_Brands_BranchesAdd.branchesAdd(v.value0);
    };
    if (v instanceof SiloDashboard_Client_Routes.BranchesAddMulti) {
        return SiloDashboard_Client_AuthRoutes_Brands_BranchesAddMulti.branchesAddMulti(v.value0);
    };
    if (v instanceof SiloDashboard_Client_Routes.BranchOverview) {
        return SiloDashboard_Client_AuthRoutes_Brands_BranchOverview.branchOverview(v.value0)(v.value1);
    };
    if (v instanceof SiloDashboard_Client_Routes.BranchRename) {
        return SiloDashboard_Client_AuthRoutes_Brands_BranchRename.branchRename(v.value0)(v.value1);
    };
    if (v instanceof SiloDashboard_Client_Routes.BranchChangeImage) {
        return SiloDashboard_Client_AuthRoutes_Brands_BranchChangeImage.branchChangeImage(v.value0)(v.value1);
    };
    if (v instanceof SiloDashboard_Client_Routes.BranchEditInfo) {
        return SiloDashboard_Client_AuthRoutes_Brands_BranchEditInfo.branchEditInfo(v.value0)(v.value1);
    };
    if (v instanceof SiloDashboard_Client_Routes.BranchChangeSettings) {
        return SiloDashboard_Client_AuthRoutes_Brands_BranchChangeSettings.branchChangeSettings(v.value0)(v.value1);
    };
    if (v instanceof SiloDashboard_Client_Routes.POSOverview) {
        return SiloDashboard_Client_AuthRoutes_Brands_POSOverview.posOverview(v.value0)(v.value1)(v.value2);
    };
    if (v instanceof SiloDashboard_Client_Routes.POSRename) {
        return SiloDashboard_Client_AuthRoutes_Brands_POSRename.posRename(v.value0)(v.value1)(v.value2);
    };
    if (v instanceof SiloDashboard_Client_Routes.POSAddMultipleCashierCodes) {
        return SiloDashboard_Client_AuthRoutes_Brands_POSAddMultipleCashierCodes.posAddMultipleCashierCodes(v.value0)(v.value1)(v.value2);
    };
    if (v instanceof SiloDashboard_Client_Routes.EmployeesList) {
        return Control_Applicative.pure(Control_Monad_Reader_Trans.applicativeReaderT(Data_Identity.applicativeIdentity))(AdminUi_AdminClient_Components_Page.page(Data_Maybe.Nothing.value)(AdminUi_AdminClient_Components_Title.title_("Employees"))(Data_Maybe.Nothing.value)([  ])(Data_Maybe.Nothing.value));
    };
    if (v instanceof SiloDashboard_Client_Routes.EmployeesRoles) {
        return Control_Applicative.pure(Control_Monad_Reader_Trans.applicativeReaderT(Data_Identity.applicativeIdentity))(AdminUi_AdminClient_Components_Page.page(Data_Maybe.Nothing.value)(AdminUi_AdminClient_Components_Title.title_("Employees Roles"))(Data_Maybe.Nothing.value)([  ])(Data_Maybe.Nothing.value));
    };
    if (v instanceof SiloDashboard_Client_Routes.EmployeesAddRoles) {
        return Control_Applicative.pure(Control_Monad_Reader_Trans.applicativeReaderT(Data_Identity.applicativeIdentity))(AdminUi_AdminClient_Components_Page.page(Data_Maybe.Nothing.value)(AdminUi_AdminClient_Components_Title.title_("Add Roles"))(Data_Maybe.Nothing.value)([  ])(Data_Maybe.Nothing.value));
    };
    if (v instanceof SiloDashboard_Client_Routes.MembersList) {
        return Control_Applicative.pure(Control_Monad_Reader_Trans.applicativeReaderT(Data_Identity.applicativeIdentity))(AdminUi_AdminClient_Components_Page.page(Data_Maybe.Nothing.value)(AdminUi_AdminClient_Components_Title.title_("Members List"))(Data_Maybe.Nothing.value)([  ])(Data_Maybe.Nothing.value));
    };
    if (v instanceof SiloDashboard_Client_Routes.MembersInvite) {
        return Control_Applicative.pure(Control_Monad_Reader_Trans.applicativeReaderT(Data_Identity.applicativeIdentity))(AdminUi_AdminClient_Components_Page.page(Data_Maybe.Nothing.value)(AdminUi_AdminClient_Components_Title.title_("Members Invite"))(Data_Maybe.Nothing.value)([  ])(Data_Maybe.Nothing.value));
    };
    throw new Error("Failed pattern match at SiloDashboard.Client.App (line 100, column 1 - line 100, column 42): " + [ v.constructor.name ]);
};
var mkUnauthRC = Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(Effect_Class.liftEffect(Control_Monad_Reader_Trans.monadEffectReader(Effect_Class.monadEffectEffect))(React_Basic_Hooks.memo(SiloDashboard_Client_Screens_Login.mkLoginScreen)))(function (loginScreen) {
    return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(Effect_Class.liftEffect(Control_Monad_Reader_Trans.monadEffectReader(Effect_Class.monadEffectEffect))(React_Basic_Hooks.memo(SiloDashboard_Client_Screens_SiloInvitation.mkSiloInvitation)))(function (siloInvitation) {
        return AdminUi_AdminClient_Components_Utils.comp_(Control_Monad_Reader_Trans.monadEffectReader(Effect_Class.monadEffectEffect))()()()("SiloUnauthApp")(function (v) {
            return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))((function () {
                if (v.route instanceof SiloDashboard_Client_Routes.Login) {
                    return React_Basic.element(loginScreen)({});
                };
                if (v.route instanceof SiloDashboard_Client_Routes.SiloInvitation) {
                    return React_Basic.element(siloInvitation)({});
                };
                throw new Error("Failed pattern match at SiloDashboard.Client.App (line 63, column 9 - line 65, column 54): " + [ v.route.constructor.name ]);
            })());
        });
    });
});
var menu = [ AdminUi_AdminClient_Components_Menu.menuItem({
    label: "Brands",
    route: SiloDashboard_Client_Routes.BrandsList.value,
    icon: new AdminUi_AdminClient_Components_Types.AntdI(AdminUi_AdminClient_Components_Icons_AntdIcons.AppstoreOutlined.value)
}), AdminUi_AdminClient_Components_Menu.subMenu({
    label: "Employees",
    icon: new AdminUi_AdminClient_Components_Types.AntdI(AdminUi_AdminClient_Components_Icons_AntdIcons.SolutionOutlined.value),
    items: [ {
        label: "List",
        route: SiloDashboard_Client_Routes.EmployeesList.value
    }, {
        label: "Roles",
        route: SiloDashboard_Client_Routes.EmployeesRoles.value
    } ]
}), AdminUi_AdminClient_Components_Menu.subMenu({
    label: "Members",
    icon: new AdminUi_AdminClient_Components_Types.AntdI(AdminUi_AdminClient_Components_Icons_AntdIcons.TeamOutlined.value),
    items: [ {
        label: "Members List",
        route: SiloDashboard_Client_Routes.MembersList.value
    }, {
        label: "Invite Members",
        route: SiloDashboard_Client_Routes.MembersInvite.value
    } ]
}) ];
var mkAppComponent = AdminUi_AdminClient.mkAdminClient(SiloDashboard_Client_Routes.eqAuthRoutes)(SiloDashboard_Client_Routes.eqUnauthRoutes)({
    auth: {
        routeDuplex: SiloDashboard_Client_Routes.authRoutesD,
        renderRoute: renderAuthRoute,
        menu: menu
    },
    unauth: {
        routeDuplex: SiloDashboard_Client_Routes.unauthRoutesD,
        reactComponent: mkUnauthRC
    },
    apiClient: SiloDashboard_Client_ApiClient.apiClientMock
});
module.exports = {
    mkAppComponent: mkAppComponent,
    mkUnauthRC: mkUnauthRC,
    menu: menu,
    renderAuthRoute: renderAuthRoute
};
