// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Breakpoints = require("../AdminUi.AdminClient.Breakpoints/index.js");
var AdminUi_AdminClient_Colors = require("../AdminUi.AdminClient.Colors/index.js");
var AdminUi_AdminClient_Components_Menu = require("../AdminUi.AdminClient.Components.Menu/index.js");
var AdminUi_AdminClient_Components_Page_Ui = require("../AdminUi.AdminClient.Components.Page.Ui/index.js");
var AdminUi_AdminClient_Components_Utils = require("../AdminUi.AdminClient.Components.Utils/index.js");
var AdminUi_Antd_Drawer = require("../AdminUi.Antd.Drawer/index.js");
var AdminUi_Antd_Icons = require("../AdminUi.Antd.Icons/index.js");
var AdminUi_Antd_Layout = require("../AdminUi.Antd.Layout/index.js");
var AdminUi_Antd_PropLits = require("../AdminUi.Antd.PropLits/index.js");
var AdminUi_Logo = require("../AdminUi.Logo/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Reader = require("../Control.Monad.Reader/index.js");
var Control_Monad_Reader_Class = require("../Control.Monad.Reader.Class/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Show = require("../Data.Show/index.js");
var Effect = require("../Effect/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Jss_ReactJss = require("../Jss.ReactJss/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var ReactResponsive = require("../ReactResponsive/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var useStyles = Jss_ReactJss.createUseStyles_()()({
    container: Jss_ReactJss.style({
        minHeight: "100vh"
    }),
    siderIcon: Jss_ReactJss.style({
        color: AdminUi_AdminClient_Colors.getColor(AdminUi_AdminClient_Colors.White.value),
        fontSize: 18
    }),
    header: Jss_ReactJss.style({
        backgroundColor: AdminUi_AdminClient_Colors.getColor(AdminUi_AdminClient_Colors.Primary.value),
        display: "flex",
        alignItems: "center",
        zIndex: 1.0e7,
        padding: "0 20px",
        position: "sticky",
        top: 0
    }),
    iconContainer: Jss_ReactJss.style({
        flex: 1
    }),
    logo: Jss_ReactJss.style({
        flex: 3,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }),
    drawer: Jss_ReactJss.style({
        padding: "65px 0px 0px 0px",
        height: "100vh"
    }),
    sider: Jss_ReactJss.style({
        backgroundColor: AdminUi_AdminClient_Colors.getColor(AdminUi_AdminClient_Colors.White.value)
    }),
    menu: Jss_ReactJss.style({
        height: "100%",
        borderRight: 0
    })
});
var mkAuthApp = function (dictEq) {
    return function (apiClient) {
        return function (v) {
            return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(Control_Monad_Reader_Class.ask(Control_Monad_Reader_Trans.monadAskReaderT(Effect.monadEffect)))(function (v1) {
                return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(AdminUi_AdminClient_Components_Page_Ui.mkPage(dictEq)(Control_Monad_Reader_Trans.monadEffectReader(Effect_Class.monadEffectEffect))(Control_Monad_Reader_Trans.monadAskReaderT(Effect.monadEffect)))(function (page) {
                    return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Effect.bindEffect))(Effect_Class.liftEffect(Control_Monad_Reader_Trans.monadEffectReader(Effect_Class.monadEffectEffect))(AdminUi_AdminClient_Components_Menu.mkMenu(dictEq)(v.routeDuplex)))(function (menuC) {
                        return AdminUi_AdminClient_Components_Utils.comp_(Control_Monad_Reader_Trans.monadEffectReader(Effect_Class.monadEffectEffect))()()()("AuthApp")(function (v2) {
                            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useStyles({}))(function (css) {
                                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useContext(v1.router))(function (v3) {
                                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(ReactResponsive.useMediaQuery({
                                        query: "(max-width: " + (Data_Show.show(Data_Show.showInt)(AdminUi_AdminClient_Breakpoints.sm) + "px ) ")
                                    }))(function (isMobile) {
                                        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(isMobile))(function (v4) {
                                            var siderMenu = React_Basic.element(menuC)({
                                                menu: v.menu,
                                                router: {
                                                    route: v2.route,
                                                    push: v3.push
                                                }
                                            });
                                            return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(AdminUi_Antd_Layout.layout()({
                                                className: css.container,
                                                children: [ AdminUi_Antd_Layout.header()({
                                                    className: css.header,
                                                    children: [ React_Basic_DOM_Generated.div()({
                                                        className: css.iconContainer,
                                                        children: [ (function () {
                                                            if (v4.value0) {
                                                                return AdminUi_Antd_Icons.menuUnfoldOutlined()({
                                                                    className: css.siderIcon,
                                                                    onClick: v4.value1(Data_Function["const"](false))
                                                                });
                                                            };
                                                            if (!v4.value0) {
                                                                return AdminUi_Antd_Icons.menuFoldOutlined()({
                                                                    className: css.siderIcon,
                                                                    onClick: v4.value1(Data_Function["const"](true))
                                                                });
                                                            };
                                                            throw new Error("Failed pattern match at AdminUi.AdminClient.AuthApp (line 59, column 27 - line 61, column 119): " + [ v4.value0.constructor.name ]);
                                                        })() ]
                                                    }), React_Basic_DOM_Generated.div()({
                                                        className: css.logo,
                                                        children: [ AdminUi_Logo.logoWhite("") ]
                                                    }), React_Basic_DOM_Generated.div()({
                                                        className: css.iconContainer,
                                                        children: [  ]
                                                    }) ]
                                                }), AdminUi_Antd_Layout.layout()({
                                                    children: [ (function () {
                                                        if (isMobile) {
                                                            return AdminUi_Antd_Drawer.drawer()({
                                                                closable: false,
                                                                placement: AdminUi_Antd_PropLits.left,
                                                                visible: !v4.value0,
                                                                className: css.drawer,
                                                                bodyStyle: React_Basic_DOM_Internal.css({
                                                                    padding: 0
                                                                }),
                                                                onClose: v4.value1(Data_Function["const"](true)),
                                                                children: [ siderMenu ]
                                                            });
                                                        };
                                                        if (!isMobile) {
                                                            return AdminUi_Antd_Layout.sider()({
                                                                collapsible: true,
                                                                collapsed: v4.value0,
                                                                trigger: Data_Monoid.mempty(React_Basic.monoidJSX),
                                                                className: css.sider,
                                                                children: [ siderMenu ]
                                                            });
                                                        };
                                                        throw new Error("Failed pattern match at AdminUi.AdminClient.AuthApp (line 76, column 21 - line 98, column 28): " + [ isMobile.constructor.name ]);
                                                    })(), React_Basic.element(page)({
                                                        route: v2.route,
                                                        page: Control_Monad_Reader.runReader(v.renderRoute(v2.route))(apiClient),
                                                        routeDuplex: v.routeDuplex
                                                    }) ]
                                                }) ]
                                            }));
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        };
    };
};
module.exports = {
    mkAuthApp: mkAuthApp
};
