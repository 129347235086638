// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var logoWhite = function (className) {
    return React_Basic_DOM_Generated.img()({
        className: className,
        src: $foreign.zapLogoWhite
    });
};
var logo = function (className) {
    return React_Basic_DOM_Generated.img()({
        className: className,
        src: $foreign.zapLogo
    });
};
module.exports = {
    logo: logo,
    logoWhite: logoWhite,
    zapLogo: $foreign.zapLogo,
    zapLogoWhite: $foreign.zapLogoWhite
};
