// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var AdminUi_AdminClient_Components_BaseTable = require("../AdminUi.AdminClient.Components.BaseTable/index.js");
var AdminUi_AdminClient_Components_Icons = require("../AdminUi.AdminClient.Components.Icons/index.js");
var AdminUi_AdminClient_Components_Icons_AntdIcons = require("../AdminUi.AdminClient.Components.Icons.AntdIcons/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var AdminUi_AdminClient_Components_Utils = require("../AdminUi.AdminClient.Components.Utils/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Uncurried = require("../Effect.Uncurried/index.js");
var Jss_ReactJss = require("../Jss.ReactJss/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var useStyles = Jss_ReactJss.createUseStyles_()()({
    loaderContainer: Jss_ReactJss.style({
        textAlign: "center",
        padding: "16px"
    })
});
var infiniteScroll = React_Basic.element($foreign["_infiniteScroll"]);
var mkBaseInfiniteTable = AdminUi_AdminClient_Components_Utils.comp_(Effect_Class.monadEffectEffect)()()()("BaseInfiniteTable")(function (props) {
    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useStyles({}))(function (css) {
        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(infiniteScroll({
            loadMore: props.loadMore,
            hasMore: props.hasMore,
            loader: React_Basic_DOM_Generated.div()({
                key: "0",
                className: css.loaderContainer,
                children: [ AdminUi_AdminClient_Components_Icons.mkIcon()(new AdminUi_AdminClient_Components_Types.AntdI(AdminUi_AdminClient_Components_Icons_AntdIcons.LoadingOutlined.value))({}), React_Basic_DOM_Generated.br()({}), React_Basic_DOM.text("Loading More") ]
            }),
            children: [ AdminUi_AdminClient_Components_BaseTable.baseTable()({
                headers: props.headers,
                rows: props.rows,
                onRowClick: Effect_Uncurried.mkEffectFn1(props.onRowClick)
            }) ]
        }));
    });
});
module.exports = {
    mkBaseInfiniteTable: mkBaseInfiniteTable
};
