// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_Section_SectionTabs = require("../AdminUi.AdminClient.Components.Section.SectionTabs/index.js");
var AdminUi_AdminClient_Components_SectionWrapper = require("../AdminUi.AdminClient.Components.SectionWrapper/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var AdminUi_AdminClient_Components_Utils = require("../AdminUi.AdminClient.Components.Utils/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var section_ = function (title) {
    return function (actionGroup) {
        return function (content) {
            return {
                title: title,
                actionGroup: actionGroup,
                tabs: new AdminUi_AdminClient_Components_Types.STSingle(content)
            };
        };
    };
};
var section = function (title) {
    return function (actionGroup) {
        return function (tabs) {
            return {
                title: title,
                actionGroup: actionGroup,
                tabs: new AdminUi_AdminClient_Components_Types.STMultiple(tabs)
            };
        };
    };
};
var mkSection = function (dictEq) {
    return function (dictMonadAsk) {
        return function (dictMonadEffect) {
            return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Components_SectionWrapper.mkSectionWrapper(dictMonadAsk)(dictMonadEffect))(function (sectionWrapper) {
                return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Components_Section_SectionTabs.mkSectionTabs(dictEq)(dictMonadAsk)(dictMonadEffect))(function (sectionTabs) {
                    return AdminUi_AdminClient_Components_Utils.comp_(dictMonadEffect)()()()("Section")(function (v) {
                        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic.element(sectionWrapper)({
                            title: v.section.title,
                            actionGroup: v.section.actionGroup,
                            content: React_Basic.element(sectionTabs)({
                                tabs: v.section.tabs
                            })
                        }));
                    });
                });
            });
        };
    };
};
module.exports = {
    section: section,
    section_: section_,
    mkSection: mkSection
};
