// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_AppAction = require("../AdminUi.AdminClient.Components.AppAction/index.js");
var AdminUi_AdminClient_Components_Content = require("../AdminUi.AdminClient.Components.Content/index.js");
var AdminUi_AdminClient_Components_Content_ContentForm = require("../AdminUi.AdminClient.Components.Content.ContentForm/index.js");
var AdminUi_AdminClient_Components_Details = require("../AdminUi.AdminClient.Components.Details/index.js");
var AdminUi_AdminClient_Components_Modal = require("../AdminUi.AdminClient.Components.Modal/index.js");
var AdminUi_AdminClient_Components_Page = require("../AdminUi.AdminClient.Components.Page/index.js");
var AdminUi_AdminClient_Components_Title = require("../AdminUi.AdminClient.Components.Title/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var AdminUi_AdminClient_Components_ZText = require("../AdminUi.AdminClient.Components.ZText/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Generic_Rep_Bounded = require("../Data.Generic.Rep.Bounded/index.js");
var Data_Generic_Rep_Enum = require("../Data.Generic.Rep.Enum/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var SiloDashboard_Common_Mock_MockData = require("../SiloDashboard.Common.Mock.MockData/index.js");
var brandsAdd = (function () {
    var title = AdminUi_AdminClient_Components_Title.title_("Add Brand");
    var form = (function () {
        var confirm = AdminUi_AdminClient_Components_Modal.confirmModal("Confirm")(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.text_("Create "))(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.bold("Angus Steakhouse"))(AdminUi_AdminClient_Components_ZText.text_(" brand? You cannot undo this action."))))(AdminUi_AdminClient_Components_Content.details([ AdminUi_AdminClient_Components_Details.pair_("Brand Name")(AdminUi_AdminClient_Components_Details.text_("Angus Steakhouse")), AdminUi_AdminClient_Components_Details.pair_("Sender ID")(AdminUi_AdminClient_Components_Details.text_("ZAP Angus")), AdminUi_AdminClient_Components_Details.pair_("Settings Profile")(AdminUi_AdminClient_Components_Details.text_("Default Settings")), AdminUi_AdminClient_Components_Details.pair_("Image")(AdminUi_AdminClient_Components_Details.dvImg("https://www.w3schools.com/css/paris.jpg")) ]));
        return Control_Applicative.pure(Effect_Aff.applicativeAff)({
            fields: [ AdminUi_AdminClient_Components_Content_ContentForm.formField("Brand Name")(AdminUi_AdminClient_Components_Content_ContentForm.textInput({
                placeholder: "Enter brand name..."
            })), AdminUi_AdminClient_Components_Content_ContentForm.formField("Sender ID")(AdminUi_AdminClient_Components_Content_ContentForm.select(SiloDashboard_Common_Mock_MockData.eqDummySenderId)(SiloDashboard_Common_Mock_MockData.displaySenderId)({
                placeholder: "Select sender id...",
                choices: AdminUi_AdminClient_Components_Content_ContentForm.allChoices(SiloDashboard_Common_Mock_MockData.genericDummySenderId)(Data_Generic_Rep_Bounded.genericBottomSum(Data_Generic_Rep_Bounded.genericBottomConstructor(Data_Generic_Rep_Bounded.genericBottomNoArguments)))(Data_Generic_Rep_Bounded.genericTopSum(Data_Generic_Rep_Bounded.genericTopSum(Data_Generic_Rep_Bounded.genericTopConstructor(Data_Generic_Rep_Bounded.genericTopNoArguments))))(Data_Generic_Rep_Enum.genericEnumSum(Data_Generic_Rep_Enum.genericEnumConstructor(Data_Generic_Rep_Enum.genericEnumNoArguments))(Data_Generic_Rep_Bounded.genericTopConstructor(Data_Generic_Rep_Bounded.genericTopNoArguments))(Data_Generic_Rep_Enum.genericEnumSum(Data_Generic_Rep_Enum.genericEnumConstructor(Data_Generic_Rep_Enum.genericEnumNoArguments))(Data_Generic_Rep_Bounded.genericTopConstructor(Data_Generic_Rep_Bounded.genericTopNoArguments))(Data_Generic_Rep_Enum.genericEnumConstructor(Data_Generic_Rep_Enum.genericEnumNoArguments))(Data_Generic_Rep_Bounded.genericBottomConstructor(Data_Generic_Rep_Bounded.genericBottomNoArguments)))(Data_Generic_Rep_Bounded.genericBottomSum(Data_Generic_Rep_Bounded.genericBottomConstructor(Data_Generic_Rep_Bounded.genericBottomNoArguments)))),
                defaultChoice: Data_Maybe.Nothing.value
            })), AdminUi_AdminClient_Components_Content_ContentForm.formField("Settings Profile")(AdminUi_AdminClient_Components_Content_ContentForm.select(SiloDashboard_Common_Mock_MockData.eqSettingsProfile)(SiloDashboard_Common_Mock_MockData.displaySettingsProfile)({
                placeholder: "Select settings profile...",
                choices: AdminUi_AdminClient_Components_Content_ContentForm.allChoices(SiloDashboard_Common_Mock_MockData.genericDummySettingsProfile)(Data_Generic_Rep_Bounded.genericBottomSum(Data_Generic_Rep_Bounded.genericBottomConstructor(Data_Generic_Rep_Bounded.genericBottomNoArguments)))(Data_Generic_Rep_Bounded.genericTopSum(Data_Generic_Rep_Bounded.genericTopSum(Data_Generic_Rep_Bounded.genericTopSum(Data_Generic_Rep_Bounded.genericTopConstructor(Data_Generic_Rep_Bounded.genericTopNoArguments)))))(Data_Generic_Rep_Enum.genericEnumSum(Data_Generic_Rep_Enum.genericEnumConstructor(Data_Generic_Rep_Enum.genericEnumNoArguments))(Data_Generic_Rep_Bounded.genericTopConstructor(Data_Generic_Rep_Bounded.genericTopNoArguments))(Data_Generic_Rep_Enum.genericEnumSum(Data_Generic_Rep_Enum.genericEnumConstructor(Data_Generic_Rep_Enum.genericEnumNoArguments))(Data_Generic_Rep_Bounded.genericTopConstructor(Data_Generic_Rep_Bounded.genericTopNoArguments))(Data_Generic_Rep_Enum.genericEnumSum(Data_Generic_Rep_Enum.genericEnumConstructor(Data_Generic_Rep_Enum.genericEnumNoArguments))(Data_Generic_Rep_Bounded.genericTopConstructor(Data_Generic_Rep_Bounded.genericTopNoArguments))(Data_Generic_Rep_Enum.genericEnumConstructor(Data_Generic_Rep_Enum.genericEnumNoArguments))(Data_Generic_Rep_Bounded.genericBottomConstructor(Data_Generic_Rep_Bounded.genericBottomNoArguments)))(Data_Generic_Rep_Bounded.genericBottomSum(Data_Generic_Rep_Bounded.genericBottomConstructor(Data_Generic_Rep_Bounded.genericBottomNoArguments))))(Data_Generic_Rep_Bounded.genericBottomSum(Data_Generic_Rep_Bounded.genericBottomConstructor(Data_Generic_Rep_Bounded.genericBottomNoArguments)))),
                defaultChoice: Data_Maybe.Nothing.value
            })) ],
            submit: {
                label: "Add Brand",
                action: AdminUi_AdminClient_Components_AppAction.showModal(confirm)
            }
        });
    })();
    return AdminUi_AdminClient_Components_Page.pageForm_(title)(form);
})();
module.exports = {
    brandsAdd: brandsAdd
};
