// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_AppAction = require("../AdminUi.AdminClient.Components.AppAction/index.js");
var AdminUi_AdminClient_Components_Content = require("../AdminUi.AdminClient.Components.Content/index.js");
var AdminUi_AdminClient_Components_Content_ContentForm = require("../AdminUi.AdminClient.Components.Content.ContentForm/index.js");
var AdminUi_AdminClient_Components_Modal = require("../AdminUi.AdminClient.Components.Modal/index.js");
var AdminUi_AdminClient_Components_Page = require("../AdminUi.AdminClient.Components.Page/index.js");
var AdminUi_AdminClient_Components_Title = require("../AdminUi.AdminClient.Components.Title/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var AdminUi_AdminClient_Components_ZText = require("../AdminUi.AdminClient.Components.ZText/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep_Bounded = require("../Data.Generic.Rep.Bounded/index.js");
var Data_Generic_Rep_Enum = require("../Data.Generic.Rep.Enum/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var SiloDashboard_Common_Mock_MockData = require("../SiloDashboard.Common.Mock.MockData/index.js");
var branchesAddMulti = function (brandName) {
    var title = AdminUi_AdminClient_Components_Title.title_("Add Multiple Branches");
    var form = (function () {
        var confirm = AdminUi_AdminClient_Components_Modal.confirmModal("Confirm")(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.text_("Create " + (brandName + " branches?")))(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.text_("Your remaining branch limit from 19 branches will reduce to "))(AdminUi_AdminClient_Components_ZText.bold("15 branches left."))))(AdminUi_AdminClient_Components_Content.li("4 Branches to be uploaded:")(Data_Functor.mapFlipped(Data_Functor.functorArray)([ "Galleria", "Glorietta", "Makati", "UPTown" ])(AdminUi_AdminClient_Components_ZText.bold)));
        return Control_Applicative.pure(Effect_Aff.applicativeAff)({
            fields: [ AdminUi_AdminClient_Components_Content_ContentForm.formField("Upload CSV File containing Branch Details")(AdminUi_AdminClient_Components_Content_ContentForm.csvFileInput({
                templateUrl: new Data_Maybe.Just("https://s3-ap-southeast-1.amazonaws.com/merchant.assets.zap.com.ph/zap-sms-blast.xlsx"),
                entityName: "branches",
                maxEntries: new Data_Maybe.Just(10)
            })), AdminUi_AdminClient_Components_Content_ContentForm.formField("Settings Profile")(AdminUi_AdminClient_Components_Content_ContentForm.select(SiloDashboard_Common_Mock_MockData.eqSettingsProfile)(SiloDashboard_Common_Mock_MockData.displaySettingsProfile)({
                choices: AdminUi_AdminClient_Components_Content_ContentForm.allChoices(SiloDashboard_Common_Mock_MockData.genericDummySettingsProfile)(Data_Generic_Rep_Bounded.genericBottomSum(Data_Generic_Rep_Bounded.genericBottomConstructor(Data_Generic_Rep_Bounded.genericBottomNoArguments)))(Data_Generic_Rep_Bounded.genericTopSum(Data_Generic_Rep_Bounded.genericTopSum(Data_Generic_Rep_Bounded.genericTopSum(Data_Generic_Rep_Bounded.genericTopConstructor(Data_Generic_Rep_Bounded.genericTopNoArguments)))))(Data_Generic_Rep_Enum.genericEnumSum(Data_Generic_Rep_Enum.genericEnumConstructor(Data_Generic_Rep_Enum.genericEnumNoArguments))(Data_Generic_Rep_Bounded.genericTopConstructor(Data_Generic_Rep_Bounded.genericTopNoArguments))(Data_Generic_Rep_Enum.genericEnumSum(Data_Generic_Rep_Enum.genericEnumConstructor(Data_Generic_Rep_Enum.genericEnumNoArguments))(Data_Generic_Rep_Bounded.genericTopConstructor(Data_Generic_Rep_Bounded.genericTopNoArguments))(Data_Generic_Rep_Enum.genericEnumSum(Data_Generic_Rep_Enum.genericEnumConstructor(Data_Generic_Rep_Enum.genericEnumNoArguments))(Data_Generic_Rep_Bounded.genericTopConstructor(Data_Generic_Rep_Bounded.genericTopNoArguments))(Data_Generic_Rep_Enum.genericEnumConstructor(Data_Generic_Rep_Enum.genericEnumNoArguments))(Data_Generic_Rep_Bounded.genericBottomConstructor(Data_Generic_Rep_Bounded.genericBottomNoArguments)))(Data_Generic_Rep_Bounded.genericBottomSum(Data_Generic_Rep_Bounded.genericBottomConstructor(Data_Generic_Rep_Bounded.genericBottomNoArguments))))(Data_Generic_Rep_Bounded.genericBottomSum(Data_Generic_Rep_Bounded.genericBottomConstructor(Data_Generic_Rep_Bounded.genericBottomNoArguments)))),
                placeholder: "Select settings profile...",
                defaultChoice: Data_Maybe.Nothing.value
            })) ],
            submit: {
                label: "Add Branches",
                action: AdminUi_AdminClient_Components_AppAction.showModal(confirm)
            }
        });
    })();
    return Control_Applicative.pure(Control_Monad_Reader_Trans.applicativeReaderT(Data_Identity.applicativeIdentity))(AdminUi_AdminClient_Components_Page.pageForm_(title)(form));
};
module.exports = {
    branchesAddMulti: branchesAddMulti
};
