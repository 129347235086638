// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Component_Table_TableHeader = require("../AdminUi.AdminClient.Component.Table.TableHeader/index.js");
var AdminUi_AdminClient_Components_Table = require("../AdminUi.AdminClient.Components.Table/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var UseTable = function (x) {
    return x;
};
var newtypeUseTable = new Data_Newtype.Newtype(function (n) {
    return n;
}, UseTable);
var useTable = function (v) {
    return React_Basic_Hooks_Internal.coerceHook(newtypeUseTable)((function () {
        var initialRows = AdminUi_AdminClient_Components_Table.getRows(v.table);
        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(initialRows))(function (v1) {
            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(Data_Maybe.Nothing.value))(function (v2) {
                return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useEffect(Data_Maybe.eqMaybe(Data_Tuple.eqTuple(Data_Eq.eqInt)(AdminUi_AdminClient_Components_Types.eqSortDirection)))(v2.value0)(function __do() {
                    Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(Control_Alt.alt(Data_Maybe.altMaybe)(Control_Bind.bind(Data_Maybe.bindMaybe)(v2.value0)(function (v3) {
                        return Data_Functor.map(Data_Maybe.functorMaybe)(AdminUi_AdminClient_Components_Table.getRows)(AdminUi_AdminClient_Components_Table.sortTable(v3.value1)(v3.value0)(v.table));
                    }))(Control_Applicative.pure(Data_Maybe.applicativeMaybe)(initialRows)))(function ($23) {
                        return v1.value1(Data_Function["const"]($23));
                    })();
                    return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                }))(function () {
                    return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))({
                        headers: Data_Array.mapWithIndex(function (i) {
                            return function (h) {
                                return AdminUi_AdminClient_Component_Table_TableHeader.tableHeader({
                                    title: h.title,
                                    sorting: (function () {
                                        if (h.sortable) {
                                            return AdminUi_AdminClient_Component_Table_TableHeader.Sortable.create((function () {
                                                if (v2.value0 instanceof Data_Maybe.Just && i === v2.value0.value0.value0) {
                                                    return new Data_Maybe.Just(v2.value0.value0.value1);
                                                };
                                                return Data_Maybe.Nothing.value;
                                            })());
                                        };
                                        if (!h.sortable) {
                                            return AdminUi_AdminClient_Component_Table_TableHeader.Unsortable.value;
                                        };
                                        throw new Error("Failed pattern match at AdminUi.AdminClient.Hooks.UseTable (line 55, column 23 - line 62, column 44): " + [ h.sortable.constructor.name ]);
                                    })(),
                                    onClick: v2.value1(AdminUi_AdminClient_Component_Table_TableHeader.toggleSort(i))
                                });
                            };
                        })(AdminUi_AdminClient_Components_Table.getHeaders(v.table)),
                        rows: v1.value0
                    });
                });
            });
        });
    })());
};
module.exports = {
    useTable: useTable,
    newtypeUseTable: newtypeUseTable
};
