// Generated by purs version 0.13.6
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Char_Unicode = require("../Data.Char.Unicode/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_String_Regex = require("../Data.String.Regex/index.js");
var Data_String_Regex_Flags = require("../Data.String.Regex.Flags/index.js");
var toUnicodeUpper = (function () {
    var $10 = Data_Functor.map(Data_Functor.functorArray)(Data_Char_Unicode.toUpper);
    return function ($11) {
        return Data_String_CodeUnits.fromCharArray($10(Data_String_CodeUnits.toCharArray($11)));
    };
})();
var toUnicodeLower = (function () {
    var $12 = Data_Functor.map(Data_Functor.functorArray)(Data_Char_Unicode.toLower);
    return function ($13) {
        return Data_String_CodeUnits.fromCharArray($12(Data_String_CodeUnits.toCharArray($13)));
    };
})();
var upfirst = (function () {
    var $14 = Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(Data_Monoid.monoidString)(function (v) {
        return Data_String_CodeUnits.singleton(Data_Char_Unicode.toUpper(v.head)) + toUnicodeLower(v.tail);
    });
    return function ($15) {
        return $14(Data_String_CodeUnits.uncons($15));
    };
})();
var regexGlobal = function (regexStr) {
    return Data_Either.fromRight()(Data_String_Regex.regex(regexStr)(Data_String_Regex_Flags.global));
};
var unicodeWords = (function () {
    var rsUpper = "[" + ("A-Z\\xc0-\\xd6\\xd8-\\xde" + "]");
    var rsOptVar = "[" + ("\\ufe0e\\ufe0f" + "]?");
    var rsLower = "[" + ("a-z\\xdf-\\xf6\\xf8-\\xff" + "]");
    var rsDingbat = "[" + ("\\u2700-\\u27bf" + "]");
    var rsBreakRange = "\\xac\\xb1\\xd7\\xf7" + ("\\x00-\\x2f\\x3a-\\x40\\x5b-\\x60\\x7b-\\xbf" + ("\\u2000-\\u206f" + " \\t\\x0b\\f\\xa0\\ufeff\\n\\r\\u2028\\u2029\\u1680\\u180e\\u2000\\u2001\\u2002\\u2003\\u2004\\u2005\\u2006\\u2007\\u2008\\u2009\\u200a\\u202f\\u205f\\u3000"));
    var rsBreak = "[" + (rsBreakRange + "]");
    var rsMisc = "[^" + ("\\ud800-\\udfff" + (rsBreakRange + ("\\d+" + ("\\u2700-\\u27bf" + ("a-z\\xdf-\\xf6\\xf8-\\xff" + ("A-Z\\xc0-\\xd6\\xd8-\\xde" + "]"))))));
    var rsMiscLower = "(?:" + (rsLower + ("|" + (rsMisc + ")")));
    var rsMiscUpper = "(?:" + (rsUpper + ("|" + (rsMisc + ")")));
    var rsNonAstral = "[^" + ("\\ud800-\\udfff" + "]");
    var rsOptContrLower = "(?:" + ("['\\u2019]" + "(?:d|ll|m|re|s|t|ve))?");
    var rsOptContrUpper = "(?:" + ("['\\u2019]" + "(?:D|LL|M|RE|S|T|VE))?");
    var rsComboRange = "\\u0300-\\u036f" + ("\\ufe20-\\ufe2f" + "\\u20d0-\\u20ff");
    var rsCombo = "[" + (rsComboRange + "]");
    var rsModifier = "(?:" + (rsCombo + ("|" + "rsFitz)"));
    var reOptMod = rsModifier + "?";
    var rsOptJoin = "(?:" + ("\\u200d" + ("(?:" + (rsNonAstral + ("|" + ("(?:\\ud83c[\\udde6-\\uddff]){2}" + ("|" + ("[\\ud800-\\udbff][\\udc00-\\udfff]" + (")" + (rsOptVar + (reOptMod + ")*"))))))))));
    var rsSeq = rsOptVar + (reOptMod + rsOptJoin);
    var rsEmoji = "(?:" + (rsDingbat + ("|" + ("(?:\\ud83c[\\udde6-\\uddff]){2}" + ("|" + ("[\\ud800-\\udbff][\\udc00-\\udfff]" + (")" + rsSeq))))));
    var regexStr = Data_String_Common.joinWith("|")([ rsUpper + ("?" + (rsLower + ("+" + (rsOptContrLower + ("(?=" + (rsBreak + ("|" + (rsUpper + "|$)")))))))), rsMiscUpper + ("+" + (rsOptContrUpper + ("(?=" + (rsBreak + ("|" + (rsUpper + (rsMiscLower + "|$)"))))))), rsUpper + ("?" + (rsMiscLower + ("+" + rsOptContrLower))), rsUpper + ("+" + rsOptContrUpper), "\\d*(?:(?:1ST|2ND|3RD|(?![123])\\dTH)\\b)", "\\d*(?:(?:1st|2nd|3rd|(?![123])\\dth)\\b)", "\\d+", rsEmoji ]);
    var $16 = Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(Data_Monoid.monoidArray)(Data_Array_NonEmpty.catMaybes);
    var $17 = Data_String_Regex.match(regexGlobal(regexStr));
    return function ($18) {
        return $16($17($18));
    };
})();
var hasUnicodeWords = Data_String_Regex.test(regexGlobal("[a-z][A-Z]|[A-Z]{2,}[a-z]|[0-9][a-zA-Z]|[a-zA-Z][0-9]|[^a-zA-Z0-9]"));
var asciiWords = (function () {
    var $19 = Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(Data_Monoid.monoidArray)(Data_Array_NonEmpty.catMaybes);
    var $20 = Data_String_Regex.match(regexGlobal("[^\x00-/:-@[-`{-\x7f]+"));
    return function ($21) {
        return $19($20($21));
    };
})();
var words = function (string) {
    var $6 = hasUnicodeWords(string);
    if ($6) {
        return unicodeWords(string);
    };
    return asciiWords(string);
};
var kebabCase = (function () {
    var $22 = Data_String_Common.joinWith("-");
    var $23 = Data_Functor.map(Data_Functor.functorArray)(toUnicodeLower);
    return function ($24) {
        return $22($23(words($24)));
    };
})();
var pascalCase = (function () {
    var $25 = Data_Foldable.foldMap(Data_Foldable.foldableArray)(Data_Monoid.monoidString)(upfirst);
    return function ($26) {
        return $25(words($26));
    };
})();
var camelCase = (function () {
    var $27 = Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(Data_Monoid.monoidString)(function (v) {
        return toUnicodeLower(v.head) + Data_Foldable.foldMap(Data_Foldable.foldableArray)(Data_Monoid.monoidString)(pascalCase)(v.tail);
    });
    return function ($28) {
        return $27(Data_Array.uncons(words($28)));
    };
})();
var snakeCase = (function () {
    var $29 = Data_String_Common.joinWith("_");
    var $30 = Data_Functor.map(Data_Functor.functorArray)(toUnicodeLower);
    return function ($31) {
        return $29($30(words($31)));
    };
})();
module.exports = {
    camelCase: camelCase,
    kebabCase: kebabCase,
    pascalCase: pascalCase,
    snakeCase: snakeCase,
    words: words
};
