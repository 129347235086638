// Generated by purs version 0.13.6
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Class_Console = require("../Effect.Class.Console/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var UseModal = function (x) {
    return x;
};
var newtypeUseModal = new Data_Newtype.Newtype(function (n) {
    return n;
}, UseModal);
var useModal = React_Basic_Hooks_Internal.coerceHook(newtypeUseModal)((function () {
    var initState = {
        modal: Data_Maybe.Nothing.value,
        visible: false
    };
    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(initState))(function (v) {
        var setModal_ = function ($12) {
            return v.value1(Data_Function["const"]($12));
        };
        var showModal = function (m) {
            return Effect_Aff.launchAff_(Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Control_Applicative.when(Effect_Aff.applicativeAff)(v.value0.visible)(Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v.value1(function (s) {
                return {
                    modal: s.modal,
                    visible: false
                };
            })))(function () {
                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Aff.delay(1000.0))(function () {
                    return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v.value1(function (s) {
                        return {
                            modal: Data_Maybe.Nothing.value,
                            visible: s.visible
                        };
                    }));
                });
            })))(function () {
                return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(setModal_({
                    modal: new Data_Maybe.Just(m),
                    visible: true
                }));
            }));
        };
        var hideModal = v.value1(function (s) {
            return {
                modal: s.modal,
                visible: false
            };
        });
        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))({
            modal: v.value0.modal,
            visible: v.value0.visible,
            showModal: showModal,
            hideModal: hideModal
        });
    });
})());
var mkModalContext = React_Basic.createContext({
    showModal: Data_Function["const"](Effect_Class_Console.error(Effect_Class.monadEffectEffect)("Modal Context Error: You may have forgotten to set the proper values through React's Provider.")),
    hideModal: Effect_Class_Console.error(Effect_Class.monadEffectEffect)("Modal Context Error: You may have forgotten to set the proper values through React's Provider.")
});
module.exports = {
    mkModalContext: mkModalContext,
    UseModal: UseModal,
    useModal: useModal,
    newtypeUseModal: newtypeUseModal
};
