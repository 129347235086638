const papa = require('papaparse');

exports._papaParse_ = (file) => () =>
  new Promise((resolve, reject) => {
    papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: resolve,
      error: reject,
    });
  });
