// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_AppAction = require("../AdminUi.AdminClient.Components.AppAction/index.js");
var AdminUi_AdminClient_Components_Table = require("../AdminUi.AdminClient.Components.Table/index.js");
var AdminUi_AdminClient_Components_ZText = require("../AdminUi.AdminClient.Components.ZText/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var SiloDashboard_Client_Routes = require("../SiloDashboard.Client.Routes/index.js");
var POS = function (x) {
    return x;
};
var branchNewtype = new Data_Newtype.Newtype(function (n) {
    return n;
}, POS);
var tableSpecBranch = new AdminUi_AdminClient_Components_Table.TableSpec(function (v) {
    return {
        row: AdminUi_AdminClient_Components_Table.rowClick(branchNewtype)(function (v1) {
            return new SiloDashboard_Client_Routes.POSOverview(v1.brandName, v1.branchName, v1.name);
        }),
        headers: [ AdminUi_AdminClient_Components_Table.sortableH(Data_Ord.ordString)(branchNewtype)(AdminUi_AdminClient_Components_Table.toCellString)("Name")(function (v1) {
            return v1.name;
        }), AdminUi_AdminClient_Components_Table.sortableH(Data_Ord.ordString)(branchNewtype)(AdminUi_AdminClient_Components_Table.toCellString)("Implementation")(function (v1) {
            return v1.implementation;
        }), AdminUi_AdminClient_Components_Table.sortableH(Data_Ord.ordString)(branchNewtype)(AdminUi_AdminClient_Components_Table.toCellString)("Version")(function (v1) {
            return v1.version;
        }), AdminUi_AdminClient_Components_Table.sortableH(Data_Ord.ordString)(branchNewtype)(AdminUi_AdminClient_Components_Table.toCellString)("Status")(function (v1) {
            return v1.status;
        }), AdminUi_AdminClient_Components_Table.unsortableH(branchNewtype)(AdminUi_AdminClient_Components_Table.toCellZText)("")(function (v1) {
            return AdminUi_AdminClient_Components_ZText.link_("Rename")(AdminUi_AdminClient_Components_AppAction.goTo(new SiloDashboard_Client_Routes.POSRename(v1.brandName, v1.branchName, v1.name)));
        }) ]
    };
});
var posTable = function (brandName) {
    return function (branchName) {
        var pos = function (name) {
            return function (implementation) {
                return function (version) {
                    return function (status) {
                        return {
                            brandName: brandName,
                            branchName: branchName,
                            name: name,
                            implementation: implementation,
                            version: version,
                            status: status
                        };
                    };
                };
            };
        };
        var posTable$prime = [ pos("POS-1-primary")("-")("-")("Pending Activation"), pos("POS-2-fallback")("Bolt")("1.8.0")("Online for 4 hr 28 min"), pos("POS-3-test")("Standalone")("1.8.2")("Offline for 30 min"), pos("POS-4-test2")("-")("-")("Deactivated"), pos("POS-5-test3")("-")("-")("Deactivated") ];
        return AdminUi_AdminClient_Components_Table.table(tableSpecBranch)(posTable$prime);
    };
};
module.exports = {
    posTable: posTable,
    POS: POS,
    branchNewtype: branchNewtype,
    tableSpecBranch: tableSpecBranch
};
