import React from 'react';
import { hot } from 'react-hot-loader';
import { mkAppComponent } from '../../../output/SiloDashboard.Client.App';

const App = mkAppComponent();
function Root() {
  return <App />;
}

export default hot(module)(Root);
