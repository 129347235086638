// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var React_Basic = require("../React.Basic/index.js");
var Untagged_Coercible = require("../Untagged.Coercible/index.js");
var subMenu = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_subMenu"])(Untagged_Coercible.coerce()(props));
    };
};
var menuItemGroup = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_menuItemGroup"])(Untagged_Coercible.coerce()(props));
    };
};
var menuItem = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_menuItem"])(Untagged_Coercible.coerce()(props));
    };
};
var menuDivider = React_Basic.element($foreign["_menuDivider"])({});
var menu = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_menu"])(Untagged_Coercible.coerce()(props));
    };
};
module.exports = {
    menu: menu,
    menuItem: menuItem,
    subMenu: subMenu,
    menuItemGroup: menuItemGroup,
    menuDivider: menuDivider,
    "_menu": $foreign["_menu"],
    "_menuItem": $foreign["_menuItem"],
    "_subMenu": $foreign["_subMenu"],
    "_menuItemGroup": $foreign["_menuItemGroup"],
    "_menuDivider": $foreign["_menuDivider"]
};
