// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var React_Basic = require("../React.Basic/index.js");
var Untagged_Coercible = require("../Untagged.Coercible/index.js");
var skeletonInput = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_skeletonInput"])(Untagged_Coercible.coerce()(props));
    };
};
var skeletonButton = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_skeletonButton"])(Untagged_Coercible.coerce()(props));
    };
};
var skeletonAvatar = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_skeletonAvatar"])(Untagged_Coercible.coerce()(props));
    };
};
var skeleton = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_skeleton"])(Untagged_Coercible.coerce()(props));
    };
};
module.exports = {
    skeleton: skeleton,
    skeletonButton: skeletonButton,
    skeletonAvatar: skeletonAvatar,
    skeletonInput: skeletonInput,
    "_skeleton": $foreign["_skeleton"],
    "_skeletonButton": $foreign["_skeletonButton"],
    "_skeletonAvatar": $foreign["_skeletonAvatar"],
    "_skeletonInput": $foreign["_skeletonInput"]
};
