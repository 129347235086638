// Generated by purs version 0.13.6
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var ZString = (function () {
    function ZString(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ZString.create = function (value0) {
        return function (value1) {
            return new ZString(value0, value1);
        };
    };
    return ZString;
})();
var SModal = (function () {
    function SModal() {

    };
    SModal.value = new SModal();
    return SModal;
})();
var SPage = (function () {
    function SPage() {

    };
    SPage.value = new SPage();
    return SPage;
})();
var Ascending = (function () {
    function Ascending() {

    };
    Ascending.value = new Ascending();
    return Ascending;
})();
var Descending = (function () {
    function Descending() {

    };
    Descending.value = new Descending();
    return Descending;
})();
var CloseModal = (function () {
    function CloseModal() {

    };
    CloseModal.value = new CloseModal();
    return CloseModal;
})();
var AntdI = (function () {
    function AntdI(value0) {
        this.value0 = value0;
    };
    AntdI.create = function (value0) {
        return new AntdI(value0);
    };
    return AntdI;
})();
var ZapI = (function () {
    function ZapI(value0) {
        this.value0 = value0;
    };
    ZapI.create = function (value0) {
        return new ZapI(value0);
    };
    return ZapI;
})();
var MenuItem = (function () {
    function MenuItem(value0) {
        this.value0 = value0;
    };
    MenuItem.create = function (value0) {
        return new MenuItem(value0);
    };
    return MenuItem;
})();
var SubMenu = (function () {
    function SubMenu(value0) {
        this.value0 = value0;
    };
    SubMenu.create = function (value0) {
        return new SubMenu(value0);
    };
    return SubMenu;
})();
var AnyFileInput = (function () {
    function AnyFileInput() {

    };
    AnyFileInput.value = new AnyFileInput();
    return AnyFileInput;
})();
var CsvFileInput = (function () {
    function CsvFileInput(value0) {
        this.value0 = value0;
    };
    CsvFileInput.create = function (value0) {
        return new CsvFileInput(value0);
    };
    return CsvFileInput;
})();
var TextInput = (function () {
    function TextInput(value0) {
        this.value0 = value0;
    };
    TextInput.create = function (value0) {
        return new TextInput(value0);
    };
    return TextInput;
})();
var Select = (function () {
    function Select(value0) {
        this.value0 = value0;
    };
    Select.create = function (value0) {
        return new Select(value0);
    };
    return Select;
})();
var GooglePlacesInput = (function () {
    function GooglePlacesInput(value0) {
        this.value0 = value0;
    };
    GooglePlacesInput.create = function (value0) {
        return new GooglePlacesInput(value0);
    };
    return GooglePlacesInput;
})();
var ImageInput = (function () {
    function ImageInput(value0) {
        this.value0 = value0;
    };
    ImageInput.create = function (value0) {
        return new ImageInput(value0);
    };
    return ImageInput;
})();
var FileInput = (function () {
    function FileInput(value0) {
        this.value0 = value0;
    };
    FileInput.create = function (value0) {
        return new FileInput(value0);
    };
    return FileInput;
})();
var GoTo = (function () {
    function GoTo(value0) {
        this.value0 = value0;
    };
    GoTo.create = function (value0) {
        return new GoTo(value0);
    };
    return GoTo;
})();
var GoBack = (function () {
    function GoBack() {

    };
    GoBack.value = new GoBack();
    return GoBack;
})();
var ShowModal = (function () {
    function ShowModal(value0) {
        this.value0 = value0;
    };
    ShowModal.create = function (value0) {
        return new ShowModal(value0);
    };
    return ShowModal;
})();
var AAModalAction = (function () {
    function AAModalAction(value0) {
        this.value0 = value0;
    };
    AAModalAction.create = function (value0) {
        return new AAModalAction(value0);
    };
    return AAModalAction;
})();
var Modal = function (x) {
    return x;
};
var CDetails = (function () {
    function CDetails(value0) {
        this.value0 = value0;
    };
    CDetails.create = function (value0) {
        return new CDetails(value0);
    };
    return CDetails;
})();
var CForm = (function () {
    function CForm(value0) {
        this.value0 = value0;
    };
    CForm.create = function (value0) {
        return new CForm(value0);
    };
    return CForm;
})();
var CCollection = (function () {
    function CCollection(value0) {
        this.value0 = value0;
    };
    CCollection.create = function (value0) {
        return new CCollection(value0);
    };
    return CCollection;
})();
var CText = (function () {
    function CText(value0) {
        this.value0 = value0;
    };
    CText.create = function (value0) {
        return new CText(value0);
    };
    return CText;
})();
var CList = (function () {
    function CList(value0) {
        this.value0 = value0;
    };
    CList.create = function (value0) {
        return new CList(value0);
    };
    return CList;
})();
var CFragment = (function () {
    function CFragment(value0) {
        this.value0 = value0;
    };
    CFragment.create = function (value0) {
        return new CFragment(value0);
    };
    return CFragment;
})();
var DVText = (function () {
    function DVText(value0) {
        this.value0 = value0;
    };
    DVText.create = function (value0) {
        return new DVText(value0);
    };
    return DVText;
})();
var DVImg = (function () {
    function DVImg(value0) {
        this.value0 = value0;
    };
    DVImg.create = function (value0) {
        return new DVImg(value0);
    };
    return DVImg;
})();
var DVMap = (function () {
    function DVMap(value0) {
        this.value0 = value0;
    };
    DVMap.create = function (value0) {
        return new DVMap(value0);
    };
    return DVMap;
})();
var CTable = (function () {
    function CTable(value0) {
        this.value0 = value0;
    };
    CTable.create = function (value0) {
        return new CTable(value0);
    };
    return CTable;
})();
var Table$prime = (function () {
    function Table$prime(value0) {
        this.value0 = value0;
    };
    Table$prime.create = function (value0) {
        return new Table$prime(value0);
    };
    return Table$prime;
})();
var ZText = (function () {
    function ZText(value0) {
        this.value0 = value0;
    };
    ZText.create = function (value0) {
        return new ZText(value0);
    };
    return ZText;
})();
var ZTString = (function () {
    function ZTString(value0) {
        this.value0 = value0;
    };
    ZTString.create = function (value0) {
        return new ZTString(value0);
    };
    return ZTString;
})();
var ZTIcon = (function () {
    function ZTIcon(value0) {
        this.value0 = value0;
    };
    ZTIcon.create = function (value0) {
        return new ZTIcon(value0);
    };
    return ZTIcon;
})();
var ZTLink = (function () {
    function ZTLink(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ZTLink.create = function (value0) {
        return function (value1) {
            return new ZTLink(value0, value1);
        };
    };
    return ZTLink;
})();
var BCCollection = (function () {
    function BCCollection(value0) {
        this.value0 = value0;
    };
    BCCollection.create = function (value0) {
        return new BCCollection(value0);
    };
    return BCCollection;
})();
var STSingle = (function () {
    function STSingle(value0) {
        this.value0 = value0;
    };
    STSingle.create = function (value0) {
        return new STSingle(value0);
    };
    return STSingle;
})();
var STMultiple = (function () {
    function STMultiple(value0) {
        this.value0 = value0;
    };
    STMultiple.create = function (value0) {
        return new STMultiple(value0);
    };
    return STMultiple;
})();
var PAButton = (function () {
    function PAButton(value0) {
        this.value0 = value0;
    };
    PAButton.create = function (value0) {
        return new PAButton(value0);
    };
    return PAButton;
})();
var PADropdown = (function () {
    function PADropdown(value0) {
        this.value0 = value0;
    };
    PADropdown.create = function (value0) {
        return new PADropdown(value0);
    };
    return PADropdown;
})();
var semigroupZText = new Data_Semigroup.Semigroup(function (v) {
    return function (v1) {
        if (v instanceof ZText && v1 instanceof ZText) {
            return new ZText(Data_Semigroup.append(Data_Semigroup.semigroupArray)(v.value0)(v1.value0));
        };
        if (v instanceof ZText) {
            return new ZText(Data_Semigroup.append(Data_Semigroup.semigroupArray)(v.value0)([ v1 ]));
        };
        if (v1 instanceof ZText) {
            return new ZText(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ v ])(v1.value0));
        };
        return new ZText([ v, v1 ]);
    };
});
var semiGroupContent = new Data_Semigroup.Semigroup(function (v) {
    return function (v1) {
        if (v instanceof CFragment && v1 instanceof CFragment) {
            return new CFragment(Data_Semigroup.append(Data_Semigroup.semigroupArray)(v.value0)(v1.value0));
        };
        return new CFragment([ v, v1 ]);
    };
});
var monoidZText = new Data_Monoid.Monoid(function () {
    return semigroupZText;
}, new ZText([  ]));
var monoidContent = new Data_Monoid.Monoid(function () {
    return semiGroupContent;
}, new CFragment([  ]));
var eqZString = new Data_Eq.Eq(function (v) {
    return function (v1) {
        return Data_Eq.eq(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
            return "underline";
        }))(Data_Eq.eqBoolean))()(new Data_Symbol.IsSymbol(function () {
            return "italic";
        }))(Data_Eq.eqBoolean))()(new Data_Symbol.IsSymbol(function () {
            return "disabled";
        }))(Data_Eq.eqBoolean))()(new Data_Symbol.IsSymbol(function () {
            return "bold";
        }))(Data_Eq.eqBoolean)))(v.value0)(v1.value0) && v.value1 === v1.value1;
    };
});
var eqZText = new Data_Eq.Eq(function (v) {
    return function (v1) {
        if (v instanceof ZText && v1 instanceof ZText) {
            return Data_Eq.eq(Data_Eq.eqArray(eqZText))(v.value0)(v1.value0);
        };
        if (v instanceof ZTString && v1 instanceof ZTString) {
            return Data_Eq.eq(eqZString)(v.value0)(v1.value0);
        };
        if (v instanceof ZTLink && v1 instanceof ZTLink) {
            return Data_Eq.eq(eqZString)(v.value0)(v1.value0);
        };
        return false;
    };
});
var eqSortDirection = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Ascending && y instanceof Ascending) {
            return true;
        };
        if (x instanceof Descending && y instanceof Descending) {
            return true;
        };
        return false;
    };
});
module.exports = {
    MenuItem: MenuItem,
    SubMenu: SubMenu,
    PAButton: PAButton,
    PADropdown: PADropdown,
    Modal: Modal,
    CloseModal: CloseModal,
    ZText: ZText,
    ZTString: ZTString,
    ZTIcon: ZTIcon,
    ZTLink: ZTLink,
    ZString: ZString,
    GoTo: GoTo,
    GoBack: GoBack,
    ShowModal: ShowModal,
    AAModalAction: AAModalAction,
    AntdI: AntdI,
    ZapI: ZapI,
    STSingle: STSingle,
    STMultiple: STMultiple,
    CDetails: CDetails,
    CForm: CForm,
    CCollection: CCollection,
    CText: CText,
    CList: CList,
    CFragment: CFragment,
    TextInput: TextInput,
    Select: Select,
    GooglePlacesInput: GooglePlacesInput,
    ImageInput: ImageInput,
    FileInput: FileInput,
    AnyFileInput: AnyFileInput,
    CsvFileInput: CsvFileInput,
    DVText: DVText,
    DVImg: DVImg,
    DVMap: DVMap,
    CTable: CTable,
    BCCollection: BCCollection,
    "Table'": Table$prime,
    Ascending: Ascending,
    Descending: Descending,
    SModal: SModal,
    SPage: SPage,
    eqZString: eqZString,
    semigroupZText: semigroupZText,
    monoidZText: monoidZText,
    eqZText: eqZText,
    semiGroupContent: semiGroupContent,
    monoidContent: monoidContent,
    eqSortDirection: eqSortDirection
};
