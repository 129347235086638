// Generated by purs version 0.13.6
"use strict";
var Data_Generic_Rep_Show = require("../Data.Generic.Rep.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_String_Extra = require("../Data.String.Extra/index.js");
var Display = function (display) {
    this.display = display;
};
var genericDisplay = function (dictGeneric) {
    return function (dictGenericShow) {
        var $2 = Data_String_Common.joinWith(" ");
        var $3 = Data_Generic_Rep_Show.genericShow(dictGeneric)(dictGenericShow);
        return function ($4) {
            return $2(Data_String_Extra.words($3($4)));
        };
    };
};
var display = function (dict) {
    return dict.display;
};
module.exports = {
    display: display,
    Display: Display,
    genericDisplay: genericDisplay
};
