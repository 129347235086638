// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Colors = require("../AdminUi.AdminClient.Colors/index.js");
var AdminUi_AdminClient_Components_ActionGroup = require("../AdminUi.AdminClient.Components.ActionGroup/index.js");
var AdminUi_AdminClient_Components_AppAction = require("../AdminUi.AdminClient.Components.AppAction/index.js");
var AdminUi_AdminClient_Components_BottomContent = require("../AdminUi.AdminClient.Components.BottomContent/index.js");
var AdminUi_AdminClient_Components_BottomSection = require("../AdminUi.AdminClient.Components.BottomSection/index.js");
var AdminUi_AdminClient_Components_Content = require("../AdminUi.AdminClient.Components.Content/index.js");
var AdminUi_AdminClient_Components_Content_ContentForm = require("../AdminUi.AdminClient.Components.Content.ContentForm/index.js");
var AdminUi_AdminClient_Components_Details = require("../AdminUi.AdminClient.Components.Details/index.js");
var AdminUi_AdminClient_Components_Icons_AntdIcons = require("../AdminUi.AdminClient.Components.Icons.AntdIcons/index.js");
var AdminUi_AdminClient_Components_Modal = require("../AdminUi.AdminClient.Components.Modal/index.js");
var AdminUi_AdminClient_Components_Page = require("../AdminUi.AdminClient.Components.Page/index.js");
var AdminUi_AdminClient_Components_Section = require("../AdminUi.AdminClient.Components.Section/index.js");
var AdminUi_AdminClient_Components_Title = require("../AdminUi.AdminClient.Components.Title/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var AdminUi_AdminClient_Components_ZText = require("../AdminUi.AdminClient.Components.ZText/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var SiloDashboard_Client_Routes = require("../SiloDashboard.Client.Routes/index.js");
var SiloDashboard_Client_Tables_EntityLog = require("../SiloDashboard.Client.Tables.EntityLog/index.js");
var SiloDashboard_Client_Tables_POS = require("../SiloDashboard.Client.Tables.POS/index.js");
var branchOverview = function (brandName) {
    return function (branchName) {
        var title$prime = AdminUi_AdminClient_Components_Title.title()({
            overline: brandName,
            main: branchName,
            tag: {
                color: AdminUi_AdminClient_Colors.Warning.value,
                text: "Suspended"
            }
        });
        var posList = AdminUi_AdminClient_Components_Section.section_(new Data_Maybe.Just(AdminUi_AdminClient_Components_Title.title_("POS Devices")))(Data_Maybe.Just.create(AdminUi_AdminClient_Components_ActionGroup.primaryActionGroup_(AdminUi_AdminClient_Components_ActionGroup.actionButton("Add POS")(AdminUi_AdminClient_Components_AppAction.showModal(AdminUi_AdminClient_Components_Modal.formModal_("Add POS")([ AdminUi_AdminClient_Components_Content_ContentForm.formField("POS Name")(AdminUi_AdminClient_Components_Content_ContentForm.textInput({
            placeholder: "Enter POS name..."
        })) ])({
            label: "Add POS",
            action: AdminUi_AdminClient_Components_AppAction.closeModal
        }))))))(Control_Applicative.pure(Effect_Aff.applicativeAff)(AdminUi_AdminClient_Components_Content.collection(SiloDashboard_Client_Tables_POS.posTable(brandName)(branchName))));
        var branchLogs = new Data_Maybe.Just(AdminUi_AdminClient_Components_BottomSection.bSection(new Data_Maybe.Just(AdminUi_AdminClient_Components_Title.title_("Branch Logs")))(Data_Maybe.Just.create(AdminUi_AdminClient_Components_ActionGroup.primaryActionGroup_(AdminUi_AdminClient_Components_ActionGroup.actionButton("Exports Logs")(AdminUi_AdminClient_Components_AppAction.showConfirmModal_("Export Logs")(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.text_("An email containing the exported file will be sent to "))(AdminUi_AdminClient_Components_ZText.bold("dustin@zap.com.ph")))))))(Control_Applicative.pure(Effect_Aff.applicativeAff)(AdminUi_AdminClient_Components_BottomContent.bCollection(SiloDashboard_Client_Tables_EntityLog.branchLogTable))));
        var branchDetails = AdminUi_AdminClient_Components_Section.section_(Data_Maybe.Nothing.value)(Data_Maybe.Nothing.value)(Control_Applicative.pure(Effect_Aff.applicativeAff)(AdminUi_AdminClient_Components_Content.details([ AdminUi_AdminClient_Components_Details.pair_("Status")(AdminUi_AdminClient_Components_Details.text({
            color: AdminUi_AdminClient_Colors.Warning.value,
            icon: Data_Maybe.Just.create(new AdminUi_AdminClient_Components_Types.AntdI(AdminUi_AdminClient_Components_Icons_AntdIcons.SyncOutlined.value)),
            text: "Pending",
            rightAction: new Data_Maybe.Just({
                label: "Reactivate",
                action: AdminUi_AdminClient_Components_AppAction.showConfirmModal_("Confirm")(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.text_("Reactivate "))(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.bold(branchName))(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.text_(" branch? Your remaining branch limit of 1 branch will be reducted to "))(AdminUi_AdminClient_Components_ZText.bold("no branches left.")))))
            })
        })), AdminUi_AdminClient_Components_Details.pair_("Address")(AdminUi_AdminClient_Components_Details.text_("Second Floor, Eastwood Mall, Libis, Quezon City")), AdminUi_AdminClient_Components_Details.pair_("Landline No.")(AdminUi_AdminClient_Components_Details.text_("84506953")), AdminUi_AdminClient_Components_Details.pair_("Location")(AdminUi_AdminClient_Components_Details.dvMap({
            address: "Eastwood Mall, Libis, Quezon City",
            latLng: {
                lat: 14.585145,
                lng: 121.088407
            }
        })), AdminUi_AdminClient_Components_Details.pair({
            label: "Cellphone No.",
            value: Data_Maybe.Nothing.value,
            info: Data_Maybe.Nothing.value
        }), AdminUi_AdminClient_Components_Details.pair({
            label: "Settings Profile",
            value: Data_Maybe.Just.create(AdminUi_AdminClient_Components_Details.text_("Default Settings")),
            info: Data_Maybe.Just.create("Primary settings used for brands and branches.")
        }) ])));
        var sections = [ branchDetails, posList ];
        var actions = Data_Maybe.Just.create(AdminUi_AdminClient_Components_ActionGroup.actionGroup_(AdminUi_AdminClient_Components_ActionGroup.actionButton("Edit Branch Information")(AdminUi_AdminClient_Components_AppAction.goTo(new SiloDashboard_Client_Routes.BranchEditInfo(brandName, branchName))))([ AdminUi_AdminClient_Components_ActionGroup.action("Rename")(AdminUi_AdminClient_Components_AppAction.goTo(new SiloDashboard_Client_Routes.BranchRename(brandName, branchName))), AdminUi_AdminClient_Components_ActionGroup.action("Change Settings Profile")(AdminUi_AdminClient_Components_AppAction.goTo(new SiloDashboard_Client_Routes.BranchChangeSettings(brandName, branchName))), AdminUi_AdminClient_Components_ActionGroup.action("Deactivate")(AdminUi_AdminClient_Components_AppAction.showConfirmModal_("Confirm")(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.text_("Deactivate "))(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.bold(branchName))(AdminUi_AdminClient_Components_ZText.text_(" branch? You will no longer be able to transact using the POSs under this branch."))))) ]));
        return Control_Applicative.pure(Control_Monad_Reader_Trans.applicativeReaderT(Data_Identity.applicativeIdentity))(AdminUi_AdminClient_Components_Page.page(Data_Maybe.Nothing.value)(title$prime)(actions)(sections)(branchLogs));
    };
};
module.exports = {
    branchOverview: branchOverview
};
