// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_AppAction = require("../AdminUi.AdminClient.Components.AppAction/index.js");
var AdminUi_AdminClient_Components_Content_ContentForm = require("../AdminUi.AdminClient.Components.Content.ContentForm/index.js");
var AdminUi_AdminClient_Components_Details = require("../AdminUi.AdminClient.Components.Details/index.js");
var AdminUi_AdminClient_Components_Page = require("../AdminUi.AdminClient.Components.Page/index.js");
var AdminUi_AdminClient_Components_Title = require("../AdminUi.AdminClient.Components.Title/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var AdminUi_AdminClient_Components_ZText = require("../AdminUi.AdminClient.Components.ZText/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Reader_Class = require("../Control.Monad.Reader.Class/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var brandRename = function (brandName) {
    return Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Data_Identity.bindIdentity))(Control_Monad_Reader_Class.ask(Control_Monad_Reader_Trans.monadAskReaderT(Data_Identity.monadIdentity)))(function (apiClient) {
        var title = AdminUi_AdminClient_Components_Title.title_("Rename Brand");
        var form = Control_Applicative.pure(Effect_Aff.applicativeAff)({
            details: new Data_Maybe.Just([ AdminUi_AdminClient_Components_Details.pair_("Current Brand Name")(AdminUi_AdminClient_Components_Details.text_(brandName)) ]),
            fields: [ AdminUi_AdminClient_Components_Content_ContentForm.formField("New Brand Name")(AdminUi_AdminClient_Components_Content_ContentForm.textInput({
                placeholder: "Enter new brand name..."
            })) ],
            submit: {
                label: "Save Changes",
                action: AdminUi_AdminClient_Components_AppAction.showConfirmModal_("Confirm")(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.text_("Rename "))(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.bold(brandName))(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.text_(" to "))(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.bold("Angus Co." + "? "))(AdminUi_AdminClient_Components_ZText.text_("All previous transactions under " + (brandName + (" will be change to " + "Angus Co."))))))))
            },
            cancel: AdminUi_AdminClient_Components_AppAction.goBack
        });
        return Control_Applicative.pure(Control_Monad_Reader_Trans.applicativeReaderT(Data_Identity.applicativeIdentity))(AdminUi_AdminClient_Components_Page.pageForm(Data_Maybe.Nothing.value)(title)(Data_Maybe.Nothing.value)(form));
    });
};
module.exports = {
    brandRename: brandRename
};
