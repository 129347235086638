// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var React_Basic = require("../React.Basic/index.js");
var Untagged_Coercible = require("../Untagged.Coercible/index.js");
var zoomOutOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ZoomOutOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var zoomInOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ZoomInOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var zhihuSquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ZhihuSquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var zhihuOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ZhihuOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var zhihuCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ZhihuCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var yuqueOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("YuqueOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var yuqueFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("YuqueFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var youtubeOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("YoutubeOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var youtubeFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("YoutubeFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var yahooOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("YahooOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var yahooFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("YahooFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var womanOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("WomanOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var windowsOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("WindowsOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var windowsFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("WindowsFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var wifiOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("WifiOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var whatsAppOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("WhatsAppOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var weiboSquareOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("WeiboSquareOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var weiboSquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("WeiboSquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var weiboOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("WeiboOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var weiboCircleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("WeiboCircleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var weiboCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("WeiboCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var wechatOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("WechatOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var wechatFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("WechatFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var warningTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("WarningTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var warningOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("WarningOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var warningFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("WarningFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var walletTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("WalletTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var walletOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("WalletOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var walletFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("WalletFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var videoCameraTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("VideoCameraTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var videoCameraOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("VideoCameraOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var videoCameraFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("VideoCameraFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var videoCameraAddOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("VideoCameraAddOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var verticalRightOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("VerticalRightOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var verticalLeftOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("VerticalLeftOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var verticalAlignTopOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("VerticalAlignTopOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var verticalAlignMiddleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("VerticalAlignMiddleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var verticalAlignBottomOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("VerticalAlignBottomOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var verifiedOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("VerifiedOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var usergroupDeleteOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("UsergroupDeleteOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var usergroupAddOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("UsergroupAddOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var userSwitchOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("UserSwitchOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var userOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("UserOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var userDeleteOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("UserDeleteOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var userAddOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("UserAddOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var usbTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("UsbTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var usbOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("UsbOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var usbFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("UsbFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var uploadOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("UploadOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var upSquareTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("UpSquareTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var upSquareOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("UpSquareOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var upSquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("UpSquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var upOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("UpOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var upCircleTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("UpCircleTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var upCircleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("UpCircleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var upCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("UpCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var unorderedListOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("UnorderedListOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var unlockTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("UnlockTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var unlockOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("UnlockOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var unlockFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("UnlockFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var ungroupOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("UngroupOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var undoOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("UndoOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var underlineOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("UnderlineOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var twitterSquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("TwitterSquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var twitterOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("TwitterOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var twitterCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("TwitterCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var trophyTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("TrophyTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var trophyOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("TrophyOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var trophyFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("TrophyFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var translationOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("TranslationOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var transactionOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("TransactionOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var trademarkOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("TrademarkOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var trademarkCircleTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("TrademarkCircleTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var trademarkCircleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("TrademarkCircleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var trademarkCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("TrademarkCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var toolTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ToolTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var toolOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ToolOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var toolFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ToolFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var toTopOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ToTopOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var thunderboltTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ThunderboltTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var thunderboltOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ThunderboltOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var thunderboltFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ThunderboltFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var teamOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("TeamOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var taobaoSquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("TaobaoSquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var taobaoOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("TaobaoOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var taobaoCircleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("TaobaoCircleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var taobaoCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("TaobaoCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var tagsTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("TagsTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var tagsOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("TagsOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var tagsFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("TagsFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var tagTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("TagTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var tagOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("TagOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var tagFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("TagFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var tabletTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("TabletTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var tabletOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("TabletOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var tabletFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("TabletFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var tableOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("TableOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var syncOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SyncOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var switcherTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SwitcherTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var switcherOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SwitcherOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var switcherFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SwitcherFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var swapRightOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SwapRightOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var swapOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SwapOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var swapLeftOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SwapLeftOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var subnodeOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SubnodeOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var strikethroughOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("StrikethroughOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var stopTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("StopTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var stopOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("StopOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var stopFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("StopFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var stockOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("StockOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var stepForwardOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("StepForwardOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var stepForwardFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("StepForwardFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var stepBackwardOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("StepBackwardOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var stepBackwardFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("StepBackwardFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var starTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("StarTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var starOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("StarOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var starFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("StarFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var splitCellsOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SplitCellsOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var soundTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SoundTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var soundOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SoundOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var soundFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SoundFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var sortDescendingOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SortDescendingOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var sortAscendingOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SortAscendingOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var solutionOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SolutionOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var snippetsTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SnippetsTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var snippetsOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SnippetsOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var snippetsFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SnippetsFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var smileTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SmileTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var smileOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SmileOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var smileFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SmileFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var smallDashOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SmallDashOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var slidersTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SlidersTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var slidersOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SlidersOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var slidersFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SlidersFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var slackSquareOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SlackSquareOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var slackSquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SlackSquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var slackOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SlackOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var slackCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SlackCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var skypeOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SkypeOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var skypeFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SkypeFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var skinTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SkinTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var skinOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SkinOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var skinFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SkinFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var sketchSquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SketchSquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var sketchOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SketchOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var sketchCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SketchCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var sisternodeOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SisternodeOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var signalFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SignalFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var shrinkOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ShrinkOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var shoppingTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ShoppingTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var shoppingOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ShoppingOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var shoppingFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ShoppingFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var shoppingCartOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ShoppingCartOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var shopTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ShopTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var shopOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ShopOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var shopFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ShopFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var shareAltOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ShareAltOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var shakeOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ShakeOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var settingTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SettingTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var settingOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SettingOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var settingFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SettingFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var sendOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SendOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var selectOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SelectOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var securityScanTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SecurityScanTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var securityScanOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SecurityScanOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var securityScanFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SecurityScanFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var searchOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SearchOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var scissorOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ScissorOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var scheduleTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ScheduleTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var scheduleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ScheduleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var scheduleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ScheduleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var scanOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ScanOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var saveTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SaveTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var saveOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SaveOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var saveFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SaveFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var safetyOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SafetyOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var safetyCertificateTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SafetyCertificateTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var safetyCertificateOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SafetyCertificateOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var safetyCertificateFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("SafetyCertificateFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var rotateRightOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RotateRightOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var rotateLeftOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RotateLeftOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var rollbackOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RollbackOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var rocketTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RocketTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var rocketOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RocketOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var rocketFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RocketFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var robotOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RobotOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var robotFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RobotFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var riseOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RiseOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var rightSquareTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RightSquareTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var rightSquareOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RightSquareOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var rightSquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RightSquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var rightOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RightOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var rightCircleTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RightCircleTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var rightCircleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RightCircleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var rightCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RightCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var retweetOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RetweetOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var restTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RestTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var restOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RestOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var restFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RestFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var reloadOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ReloadOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var redoOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RedoOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var redditSquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RedditSquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var redditOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RedditOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var redditCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RedditCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var redEnvelopeTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RedEnvelopeTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var redEnvelopeOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RedEnvelopeOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var redEnvelopeFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RedEnvelopeFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var reconciliationTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ReconciliationTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var reconciliationOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ReconciliationOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var reconciliationFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ReconciliationFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var readOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ReadOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var readFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ReadFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var radiusUprightOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RadiusUprightOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var radiusUpleftOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RadiusUpleftOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var radiusSettingOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RadiusSettingOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var radiusBottomrightOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RadiusBottomrightOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var radiusBottomleftOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RadiusBottomleftOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var radarChartOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("RadarChartOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var questionOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("QuestionOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var questionCircleTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("QuestionCircleTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var questionCircleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("QuestionCircleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var questionCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("QuestionCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var qrcodeOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("QrcodeOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var qqSquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("QqSquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var qqOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("QqOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var qqCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("QqCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var pushpinTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PushpinTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var pushpinOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PushpinOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var pushpinFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PushpinFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var pullRequestOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PullRequestOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var propertySafetyTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PropertySafetyTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var propertySafetyOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PropertySafetyOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var propertySafetyFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PropertySafetyFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var projectTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ProjectTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var projectOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ProjectOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var projectFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ProjectFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var profileTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ProfileTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var profileOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ProfileOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var profileFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ProfileFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var printerTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PrinterTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var printerOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PrinterOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var printerFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PrinterFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var poweroffOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PoweroffOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var poundOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PoundOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var poundCircleTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PoundCircleTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var poundCircleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PoundCircleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var poundCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PoundCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var plusSquareTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PlusSquareTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var plusSquareOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PlusSquareOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var plusSquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PlusSquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var plusOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PlusOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var plusCircleTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PlusCircleTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var plusCircleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PlusCircleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var plusCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PlusCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var playSquareTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PlaySquareTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var playSquareOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PlaySquareOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var playSquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PlaySquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var playCircleTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PlayCircleTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var playCircleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PlayCircleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var playCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PlayCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var pieChartTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PieChartTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var pieChartOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PieChartOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var pieChartFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PieChartFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var pictureTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PictureTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var pictureOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PictureOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var pictureFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PictureFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var picRightOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PicRightOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var picLeftOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PicLeftOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var picCenterOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PicCenterOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var phoneTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PhoneTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var phoneOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PhoneOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var phoneFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PhoneFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var percentageOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PercentageOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var payCircleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PayCircleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var payCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PayCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var pauseOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PauseOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var pauseCircleTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PauseCircleTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var pauseCircleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PauseCircleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var pauseCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PauseCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var partitionOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PartitionOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var paperClipOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("PaperClipOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var orderedListOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("OrderedListOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var oneToOneOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("OneToOneOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var numberOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("NumberOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var notificationTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("NotificationTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var notificationOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("NotificationOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var notificationFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("NotificationFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var nodeIndexOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("NodeIndexOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var nodeExpandOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("NodeExpandOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var nodeCollapseOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("NodeCollapseOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var moreOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MoreOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var monitorOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MonitorOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var moneyCollectTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MoneyCollectTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var moneyCollectOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MoneyCollectOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var moneyCollectFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MoneyCollectFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var mobileTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MobileTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var mobileOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MobileOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var mobileFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MobileFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var minusSquareTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MinusSquareTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var minusSquareOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MinusSquareOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var minusSquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MinusSquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var minusOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MinusOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var minusCircleTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MinusCircleTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var minusCircleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MinusCircleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var minusCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MinusCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var messageTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MessageTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var messageOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MessageOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var messageFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MessageFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var mergeCellsOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MergeCellsOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var menuUnfoldOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MenuUnfoldOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var menuOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MenuOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var menuFoldOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MenuFoldOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var mehTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MehTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var mehOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MehOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var mehFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MehFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var mediumWorkmarkOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MediumWorkmarkOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var mediumSquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MediumSquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var mediumOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MediumOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var mediumCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MediumCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var medicineBoxTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MedicineBoxTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var medicineBoxOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MedicineBoxOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var medicineBoxFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MedicineBoxFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var manOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ManOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var mailTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MailTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var mailOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MailOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var mailFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MailFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var macCommandOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MacCommandOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var macCommandFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("MacCommandFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var logoutOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("LogoutOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var loginOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("LoginOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var lockTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("LockTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var lockOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("LockOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var lockFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("LockFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var loadingOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("LoadingOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var loading3QuartersOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("Loading3QuartersOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var linkedinOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("LinkedinOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var linkedinFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("LinkedinFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var linkOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("LinkOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var lineOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("LineOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var lineHeightOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("LineHeightOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var lineChartOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("LineChartOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var likeTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("LikeTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var likeOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("LikeOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var likeFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("LikeFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var leftSquareTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("LeftSquareTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var leftSquareOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("LeftSquareOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var leftSquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("LeftSquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var leftOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("LeftOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var leftCircleTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("LeftCircleTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var leftCircleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("LeftCircleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var leftCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("LeftCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var layoutTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("LayoutTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var layoutOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("LayoutOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var layoutFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("LayoutFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var laptopOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("LaptopOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var keyOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("KeyOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var italicOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ItalicOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var issuesCloseOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("IssuesCloseOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var interactionTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("InteractionTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var interactionOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("InteractionOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var interactionFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("InteractionFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var insuranceTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("InsuranceTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var insuranceOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("InsuranceOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var insuranceFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("InsuranceFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var instagramOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("InstagramOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var instagramFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("InstagramFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var insertRowRightOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("InsertRowRightOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var insertRowLeftOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("InsertRowLeftOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var insertRowBelowOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("InsertRowBelowOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var insertRowAboveOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("InsertRowAboveOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var infoOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("InfoOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var infoCircleTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("InfoCircleTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var infoCircleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("InfoCircleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var infoCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("InfoCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var inboxOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("InboxOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var importOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ImportOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var ieSquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("IeSquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var ieOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("IeOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var ieCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("IeCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var idcardTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("IdcardTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var idcardOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("IdcardOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var idcardFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("IdcardFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var html5TwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("Html5TwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var html5Outlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("Html5Outlined"))(Untagged_Coercible.coerce()(props));
    };
};
var html5Filled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("Html5Filled"))(Untagged_Coercible.coerce()(props));
    };
};
var hourglassTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("HourglassTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var hourglassOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("HourglassOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var hourglassFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("HourglassFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var homeTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("HomeTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var homeOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("HomeOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var homeFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("HomeFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var historyOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("HistoryOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var highlightTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("HighlightTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var highlightOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("HighlightOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var highlightFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("HighlightFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var heatMapOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("HeatMapOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var heartTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("HeartTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var heartOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("HeartOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var heartFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("HeartFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var hddTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("HddTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var hddOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("HddOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var hddFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("HddFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var groupOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("GroupOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var googleSquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("GoogleSquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var googlePlusSquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("GooglePlusSquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var googlePlusOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("GooglePlusOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var googlePlusCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("GooglePlusCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var googleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("GoogleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var googleCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("GoogleCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var goldenFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("GoldenFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var goldTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("GoldTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var goldOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("GoldOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var goldFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("GoldFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var globalOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("GlobalOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var gitlabOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("GitlabOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var gitlabFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("GitlabFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var githubOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("GithubOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var githubFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("GithubFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var giftTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("GiftTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var giftOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("GiftOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var giftFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("GiftFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var gifOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("GifOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var gatewayOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("GatewayOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var funnelPlotTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FunnelPlotTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var funnelPlotOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FunnelPlotOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var funnelPlotFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FunnelPlotFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var fundViewOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FundViewOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fundTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FundTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var fundProjectionScreenOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FundProjectionScreenOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fundOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FundOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fundFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FundFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var functionOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FunctionOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fullscreenOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FullscreenOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fullscreenExitOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FullscreenExitOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var frownTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FrownTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var frownOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FrownOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var frownFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FrownFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var forwardOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ForwardOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var forwardFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ForwardFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var formatPainterOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FormatPainterOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var formatPainterFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FormatPainterFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var formOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FormOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var forkOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ForkOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fontSizeOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FontSizeOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fontColorsOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FontColorsOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var folderViewOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FolderViewOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var folderTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FolderTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var folderOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FolderOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var folderOpenTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FolderOpenTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var folderOpenOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FolderOpenOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var folderOpenFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FolderOpenFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var folderFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FolderFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var folderAddTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FolderAddTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var folderAddOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FolderAddOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var folderAddFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FolderAddFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var flagTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FlagTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var flagOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FlagOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var flagFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FlagFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var fireTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FireTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var fireOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FireOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fireFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FireFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var filterTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FilterTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var filterOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FilterOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var filterFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FilterFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var fileZipTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileZipTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var fileZipOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileZipOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fileZipFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileZipFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var fileWordTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileWordTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var fileWordOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileWordOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fileWordFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileWordFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var fileUnknownTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileUnknownTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var fileUnknownOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileUnknownOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fileUnknownFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileUnknownFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var fileTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var fileTextTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileTextTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var fileTextOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileTextOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fileTextFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileTextFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var fileSyncOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileSyncOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fileSearchOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileSearchOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fileProtectOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileProtectOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var filePptTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FilePptTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var filePptOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FilePptOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var filePptFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FilePptFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var filePdfTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FilePdfTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var filePdfOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FilePdfOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var filePdfFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FilePdfFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var fileOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fileMarkdownTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileMarkdownTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var fileMarkdownOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileMarkdownOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fileMarkdownFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileMarkdownFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var fileJpgOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileJpgOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fileImageTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileImageTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var fileImageOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileImageOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fileImageFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileImageFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var fileGifOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileGifOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fileFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var fileExclamationTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileExclamationTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var fileExclamationOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileExclamationOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fileExclamationFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileExclamationFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var fileExcelTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileExcelTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var fileExcelOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileExcelOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fileExcelFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileExcelFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var fileDoneOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileDoneOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fileAddTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileAddTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var fileAddOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileAddOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fileAddFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FileAddFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var fieldTimeOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FieldTimeOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fieldStringOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FieldStringOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fieldNumberOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FieldNumberOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fieldBinaryOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FieldBinaryOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fastForwardOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FastForwardOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fastForwardFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FastForwardFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var fastBackwardOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FastBackwardOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var fastBackwardFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FastBackwardFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var fallOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FallOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var facebookOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FacebookOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var facebookFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("FacebookFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var eyeTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("EyeTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var eyeOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("EyeOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var eyeInvisibleTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("EyeInvisibleTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var eyeInvisibleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("EyeInvisibleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var eyeInvisibleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("EyeInvisibleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var eyeFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("EyeFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var exportOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ExportOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var experimentTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ExperimentTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var experimentOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ExperimentOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var experimentFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ExperimentFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var expandOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ExpandOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var expandAltOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ExpandAltOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var exclamationOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ExclamationOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var exclamationCircleTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ExclamationCircleTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var exclamationCircleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ExclamationCircleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var exclamationCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ExclamationCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var exceptionOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ExceptionOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var euroTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("EuroTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var euroOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("EuroOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var euroCircleTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("EuroCircleTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var euroCircleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("EuroCircleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var euroCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("EuroCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var environmentTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("EnvironmentTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var environmentOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("EnvironmentOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var environmentFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("EnvironmentFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var enterOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("EnterOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var ellipsisOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("EllipsisOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var editTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("EditTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var editOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("EditOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var editFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("EditFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var dropboxSquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DropboxSquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var dropboxOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DropboxOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var dropboxCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DropboxCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var dribbbleSquareOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DribbbleSquareOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var dribbbleSquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DribbbleSquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var dribbbleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DribbbleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var dribbbleCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DribbbleCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var dragOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DragOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var downloadOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DownloadOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var downSquareTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DownSquareTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var downSquareOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DownSquareOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var downSquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DownSquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var downOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DownOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var downCircleTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DownCircleTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var downCircleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DownCircleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var downCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DownCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var doubleRightOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DoubleRightOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var doubleLeftOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DoubleLeftOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var dotChartOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DotChartOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var dollarTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DollarTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var dollarOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DollarOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var dollarCircleTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DollarCircleTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var dollarCircleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DollarCircleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var dollarCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DollarCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var dislikeTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DislikeTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var dislikeOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DislikeOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var dislikeFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DislikeFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var disconnectOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DisconnectOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var dingtalkSquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DingtalkSquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var dingtalkOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DingtalkOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var dingtalkCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DingtalkCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var dingdingOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DingdingOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var diffTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DiffTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var diffOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DiffOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var diffFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DiffFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var desktopOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DesktopOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var deploymentUnitOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DeploymentUnitOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var deliveredProcedureOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DeliveredProcedureOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var deleteTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DeleteTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var deleteRowOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DeleteRowOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var deleteOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DeleteOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var deleteFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DeleteFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var deleteColumnOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DeleteColumnOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var databaseTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DatabaseTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var databaseOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DatabaseOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var databaseFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DatabaseFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var dashboardTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DashboardTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var dashboardOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DashboardOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var dashboardFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DashboardFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var dashOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("DashOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var customerServiceTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CustomerServiceTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var customerServiceOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CustomerServiceOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var customerServiceFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CustomerServiceFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var crownTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CrownTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var crownOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CrownOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var crownFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CrownFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var creditCardTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CreditCardTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var creditCardOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CreditCardOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var creditCardFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CreditCardFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var copyrightTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CopyrightTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var copyrightOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CopyrightOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var copyrightCircleTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CopyrightCircleTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var copyrightCircleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CopyrightCircleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var copyrightCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CopyrightCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var copyTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CopyTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var copyOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CopyOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var copyFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CopyFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var controlTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ControlTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var controlOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ControlOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var controlFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ControlFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var containerTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ContainerTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var containerOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ContainerOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var containerFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ContainerFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var contactsTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ContactsTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var contactsOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ContactsOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var contactsFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ContactsFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var consoleSqlOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ConsoleSqlOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var compressOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CompressOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var compassTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CompassTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var compassOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CompassOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var compassFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CompassFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var commentOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CommentOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var columnWidthOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ColumnWidthOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var columnHeightOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ColumnHeightOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var coffeeOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CoffeeOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var codepenSquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CodepenSquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var codepenOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CodepenOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var codepenCircleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CodepenCircleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var codepenCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CodepenCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var codeTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CodeTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var codeSandboxSquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CodeSandboxSquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var codeSandboxOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CodeSandboxOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var codeSandboxCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CodeSandboxCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var codeOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CodeOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var codeFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CodeFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var clusterOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ClusterOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var cloudUploadOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CloudUploadOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var cloudTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CloudTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var cloudSyncOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CloudSyncOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var cloudServerOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CloudServerOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var cloudOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CloudOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var cloudFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CloudFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var cloudDownloadOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CloudDownloadOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var closeSquareTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CloseSquareTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var closeSquareOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CloseSquareOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var closeSquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CloseSquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var closeOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CloseOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var closeCircleTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CloseCircleTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var closeCircleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CloseCircleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var closeCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CloseCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var clockCircleTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ClockCircleTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var clockCircleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ClockCircleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var clockCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ClockCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var clearOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ClearOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var ciTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CiTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var ciOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CiOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var ciCircleTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CiCircleTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var ciCircleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CiCircleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var ciCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CiCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var chromeOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ChromeOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var chromeFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ChromeFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var checkSquareTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CheckSquareTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var checkSquareOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CheckSquareOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var checkSquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CheckSquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var checkOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CheckOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var checkCircleTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CheckCircleTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var checkCircleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CheckCircleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var checkCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CheckCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var carryOutTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CarryOutTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var carryOutOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CarryOutOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var carryOutFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CarryOutFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var caretUpOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CaretUpOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var caretUpFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CaretUpFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var caretRightOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CaretRightOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var caretRightFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CaretRightFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var caretLeftOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CaretLeftOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var caretLeftFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CaretLeftFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var caretDownOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CaretDownOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var caretDownFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CaretDownFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var carTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CarTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var carOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CarOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var carFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CarFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var cameraTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CameraTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var cameraOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CameraOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var cameraFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CameraFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var calendarTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CalendarTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var calendarOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CalendarOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var calendarFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CalendarFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var calculatorTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CalculatorTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var calculatorOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CalculatorOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var calculatorFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("CalculatorFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var bulbTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BulbTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var bulbOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BulbOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var bulbFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BulbFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var buildTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BuildTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var buildOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BuildOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var buildFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BuildFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var bugTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BugTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var bugOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BugOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var bugFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BugFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var branchesOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BranchesOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var boxPlotTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BoxPlotTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var boxPlotOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BoxPlotOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var boxPlotFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BoxPlotFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var borderlessTableOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BorderlessTableOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var borderVerticleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BorderVerticleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var borderTopOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BorderTopOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var borderRightOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BorderRightOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var borderOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BorderOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var borderOuterOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BorderOuterOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var borderLeftOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BorderLeftOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var borderInnerOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BorderInnerOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var borderHorizontalOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BorderHorizontalOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var borderBottomOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BorderBottomOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var bookTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BookTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var bookOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BookOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var bookFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BookFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var boldOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BoldOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var blockOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BlockOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var bgColorsOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BgColorsOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var bellTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BellTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var bellOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BellOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var bellFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BellFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var behanceSquareOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BehanceSquareOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var behanceSquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BehanceSquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var behanceOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BehanceOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var behanceCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BehanceCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var barsOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BarsOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var barcodeOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BarcodeOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var barChartOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BarChartOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var bankTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BankTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var bankOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BankOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var bankFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BankFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var backwardOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BackwardOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var backwardFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("BackwardFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var auditOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AuditOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var audioTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AudioTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var audioOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AudioOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var audioMutedOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AudioMutedOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var audioFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AudioFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var arrowsAltOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ArrowsAltOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var arrowUpOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ArrowUpOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var arrowRightOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ArrowRightOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var arrowLeftOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ArrowLeftOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var arrowDownOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ArrowDownOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var areaChartOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AreaChartOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var appstoreTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AppstoreTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var appstoreOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AppstoreOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var appstoreFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AppstoreFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var appstoreAddOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AppstoreAddOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var appleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AppleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var appleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AppleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var apiTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ApiTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var apiOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ApiOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var apiFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ApiFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var apartmentOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("ApartmentOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var antDesignOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AntDesignOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var antCloudOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AntCloudOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var androidOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AndroidOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var androidFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AndroidFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var amazonSquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AmazonSquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var amazonOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AmazonOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var amazonCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AmazonCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var aliyunOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AliyunOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var aliwangwangOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AliwangwangOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var aliwangwangFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AliwangwangFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var alipaySquareFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AlipaySquareFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var alipayOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AlipayOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var alipayCircleOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AlipayCircleOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var alipayCircleFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AlipayCircleFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var alignRightOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AlignRightOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var alignLeftOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AlignLeftOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var alignCenterOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AlignCenterOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var alibabaOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AlibabaOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var alertTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AlertTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var alertOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AlertOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var alertFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AlertFilled"))(Untagged_Coercible.coerce()(props));
    };
};
var aimOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AimOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var accountBookTwoTone = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AccountBookTwoTone"))(Untagged_Coercible.coerce()(props));
    };
};
var accountBookOutlined = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AccountBookOutlined"))(Untagged_Coercible.coerce()(props));
    };
};
var accountBookFilled = function (dictCoercible) {
    return function (props) {
        return React_Basic.element($foreign["_icon"]("AccountBookFilled"))(Untagged_Coercible.coerce()(props));
    };
};
module.exports = {
    accountBookFilled: accountBookFilled,
    accountBookOutlined: accountBookOutlined,
    accountBookTwoTone: accountBookTwoTone,
    aimOutlined: aimOutlined,
    alertFilled: alertFilled,
    alertOutlined: alertOutlined,
    alertTwoTone: alertTwoTone,
    alibabaOutlined: alibabaOutlined,
    alignCenterOutlined: alignCenterOutlined,
    alignLeftOutlined: alignLeftOutlined,
    alignRightOutlined: alignRightOutlined,
    alipayCircleFilled: alipayCircleFilled,
    alipayCircleOutlined: alipayCircleOutlined,
    alipayOutlined: alipayOutlined,
    alipaySquareFilled: alipaySquareFilled,
    aliwangwangFilled: aliwangwangFilled,
    aliwangwangOutlined: aliwangwangOutlined,
    aliyunOutlined: aliyunOutlined,
    amazonCircleFilled: amazonCircleFilled,
    amazonOutlined: amazonOutlined,
    amazonSquareFilled: amazonSquareFilled,
    androidFilled: androidFilled,
    androidOutlined: androidOutlined,
    antCloudOutlined: antCloudOutlined,
    antDesignOutlined: antDesignOutlined,
    apartmentOutlined: apartmentOutlined,
    apiFilled: apiFilled,
    apiOutlined: apiOutlined,
    apiTwoTone: apiTwoTone,
    appleFilled: appleFilled,
    appleOutlined: appleOutlined,
    appstoreAddOutlined: appstoreAddOutlined,
    appstoreFilled: appstoreFilled,
    appstoreOutlined: appstoreOutlined,
    appstoreTwoTone: appstoreTwoTone,
    areaChartOutlined: areaChartOutlined,
    arrowDownOutlined: arrowDownOutlined,
    arrowLeftOutlined: arrowLeftOutlined,
    arrowRightOutlined: arrowRightOutlined,
    arrowUpOutlined: arrowUpOutlined,
    arrowsAltOutlined: arrowsAltOutlined,
    audioFilled: audioFilled,
    audioMutedOutlined: audioMutedOutlined,
    audioOutlined: audioOutlined,
    audioTwoTone: audioTwoTone,
    auditOutlined: auditOutlined,
    backwardFilled: backwardFilled,
    backwardOutlined: backwardOutlined,
    bankFilled: bankFilled,
    bankOutlined: bankOutlined,
    bankTwoTone: bankTwoTone,
    barChartOutlined: barChartOutlined,
    barcodeOutlined: barcodeOutlined,
    barsOutlined: barsOutlined,
    behanceCircleFilled: behanceCircleFilled,
    behanceOutlined: behanceOutlined,
    behanceSquareFilled: behanceSquareFilled,
    behanceSquareOutlined: behanceSquareOutlined,
    bellFilled: bellFilled,
    bellOutlined: bellOutlined,
    bellTwoTone: bellTwoTone,
    bgColorsOutlined: bgColorsOutlined,
    blockOutlined: blockOutlined,
    boldOutlined: boldOutlined,
    bookFilled: bookFilled,
    bookOutlined: bookOutlined,
    bookTwoTone: bookTwoTone,
    borderBottomOutlined: borderBottomOutlined,
    borderHorizontalOutlined: borderHorizontalOutlined,
    borderInnerOutlined: borderInnerOutlined,
    borderLeftOutlined: borderLeftOutlined,
    borderOuterOutlined: borderOuterOutlined,
    borderOutlined: borderOutlined,
    borderRightOutlined: borderRightOutlined,
    borderTopOutlined: borderTopOutlined,
    borderVerticleOutlined: borderVerticleOutlined,
    borderlessTableOutlined: borderlessTableOutlined,
    boxPlotFilled: boxPlotFilled,
    boxPlotOutlined: boxPlotOutlined,
    boxPlotTwoTone: boxPlotTwoTone,
    branchesOutlined: branchesOutlined,
    bugFilled: bugFilled,
    bugOutlined: bugOutlined,
    bugTwoTone: bugTwoTone,
    buildFilled: buildFilled,
    buildOutlined: buildOutlined,
    buildTwoTone: buildTwoTone,
    bulbFilled: bulbFilled,
    bulbOutlined: bulbOutlined,
    bulbTwoTone: bulbTwoTone,
    calculatorFilled: calculatorFilled,
    calculatorOutlined: calculatorOutlined,
    calculatorTwoTone: calculatorTwoTone,
    calendarFilled: calendarFilled,
    calendarOutlined: calendarOutlined,
    calendarTwoTone: calendarTwoTone,
    cameraFilled: cameraFilled,
    cameraOutlined: cameraOutlined,
    cameraTwoTone: cameraTwoTone,
    carFilled: carFilled,
    carOutlined: carOutlined,
    carTwoTone: carTwoTone,
    caretDownFilled: caretDownFilled,
    caretDownOutlined: caretDownOutlined,
    caretLeftFilled: caretLeftFilled,
    caretLeftOutlined: caretLeftOutlined,
    caretRightFilled: caretRightFilled,
    caretRightOutlined: caretRightOutlined,
    caretUpFilled: caretUpFilled,
    caretUpOutlined: caretUpOutlined,
    carryOutFilled: carryOutFilled,
    carryOutOutlined: carryOutOutlined,
    carryOutTwoTone: carryOutTwoTone,
    checkCircleFilled: checkCircleFilled,
    checkCircleOutlined: checkCircleOutlined,
    checkCircleTwoTone: checkCircleTwoTone,
    checkOutlined: checkOutlined,
    checkSquareFilled: checkSquareFilled,
    checkSquareOutlined: checkSquareOutlined,
    checkSquareTwoTone: checkSquareTwoTone,
    chromeFilled: chromeFilled,
    chromeOutlined: chromeOutlined,
    ciCircleFilled: ciCircleFilled,
    ciCircleOutlined: ciCircleOutlined,
    ciCircleTwoTone: ciCircleTwoTone,
    ciOutlined: ciOutlined,
    ciTwoTone: ciTwoTone,
    clearOutlined: clearOutlined,
    clockCircleFilled: clockCircleFilled,
    clockCircleOutlined: clockCircleOutlined,
    clockCircleTwoTone: clockCircleTwoTone,
    closeCircleFilled: closeCircleFilled,
    closeCircleOutlined: closeCircleOutlined,
    closeCircleTwoTone: closeCircleTwoTone,
    closeOutlined: closeOutlined,
    closeSquareFilled: closeSquareFilled,
    closeSquareOutlined: closeSquareOutlined,
    closeSquareTwoTone: closeSquareTwoTone,
    cloudDownloadOutlined: cloudDownloadOutlined,
    cloudFilled: cloudFilled,
    cloudOutlined: cloudOutlined,
    cloudServerOutlined: cloudServerOutlined,
    cloudSyncOutlined: cloudSyncOutlined,
    cloudTwoTone: cloudTwoTone,
    cloudUploadOutlined: cloudUploadOutlined,
    clusterOutlined: clusterOutlined,
    codeFilled: codeFilled,
    codeOutlined: codeOutlined,
    codeSandboxCircleFilled: codeSandboxCircleFilled,
    codeSandboxOutlined: codeSandboxOutlined,
    codeSandboxSquareFilled: codeSandboxSquareFilled,
    codeTwoTone: codeTwoTone,
    codepenCircleFilled: codepenCircleFilled,
    codepenCircleOutlined: codepenCircleOutlined,
    codepenOutlined: codepenOutlined,
    codepenSquareFilled: codepenSquareFilled,
    coffeeOutlined: coffeeOutlined,
    columnHeightOutlined: columnHeightOutlined,
    columnWidthOutlined: columnWidthOutlined,
    commentOutlined: commentOutlined,
    compassFilled: compassFilled,
    compassOutlined: compassOutlined,
    compassTwoTone: compassTwoTone,
    compressOutlined: compressOutlined,
    consoleSqlOutlined: consoleSqlOutlined,
    contactsFilled: contactsFilled,
    contactsOutlined: contactsOutlined,
    contactsTwoTone: contactsTwoTone,
    containerFilled: containerFilled,
    containerOutlined: containerOutlined,
    containerTwoTone: containerTwoTone,
    controlFilled: controlFilled,
    controlOutlined: controlOutlined,
    controlTwoTone: controlTwoTone,
    copyFilled: copyFilled,
    copyOutlined: copyOutlined,
    copyTwoTone: copyTwoTone,
    copyrightCircleFilled: copyrightCircleFilled,
    copyrightCircleOutlined: copyrightCircleOutlined,
    copyrightCircleTwoTone: copyrightCircleTwoTone,
    copyrightOutlined: copyrightOutlined,
    copyrightTwoTone: copyrightTwoTone,
    creditCardFilled: creditCardFilled,
    creditCardOutlined: creditCardOutlined,
    creditCardTwoTone: creditCardTwoTone,
    crownFilled: crownFilled,
    crownOutlined: crownOutlined,
    crownTwoTone: crownTwoTone,
    customerServiceFilled: customerServiceFilled,
    customerServiceOutlined: customerServiceOutlined,
    customerServiceTwoTone: customerServiceTwoTone,
    dashOutlined: dashOutlined,
    dashboardFilled: dashboardFilled,
    dashboardOutlined: dashboardOutlined,
    dashboardTwoTone: dashboardTwoTone,
    databaseFilled: databaseFilled,
    databaseOutlined: databaseOutlined,
    databaseTwoTone: databaseTwoTone,
    deleteColumnOutlined: deleteColumnOutlined,
    deleteFilled: deleteFilled,
    deleteOutlined: deleteOutlined,
    deleteRowOutlined: deleteRowOutlined,
    deleteTwoTone: deleteTwoTone,
    deliveredProcedureOutlined: deliveredProcedureOutlined,
    deploymentUnitOutlined: deploymentUnitOutlined,
    desktopOutlined: desktopOutlined,
    diffFilled: diffFilled,
    diffOutlined: diffOutlined,
    diffTwoTone: diffTwoTone,
    dingdingOutlined: dingdingOutlined,
    dingtalkCircleFilled: dingtalkCircleFilled,
    dingtalkOutlined: dingtalkOutlined,
    dingtalkSquareFilled: dingtalkSquareFilled,
    disconnectOutlined: disconnectOutlined,
    dislikeFilled: dislikeFilled,
    dislikeOutlined: dislikeOutlined,
    dislikeTwoTone: dislikeTwoTone,
    dollarCircleFilled: dollarCircleFilled,
    dollarCircleOutlined: dollarCircleOutlined,
    dollarCircleTwoTone: dollarCircleTwoTone,
    dollarOutlined: dollarOutlined,
    dollarTwoTone: dollarTwoTone,
    dotChartOutlined: dotChartOutlined,
    doubleLeftOutlined: doubleLeftOutlined,
    doubleRightOutlined: doubleRightOutlined,
    downCircleFilled: downCircleFilled,
    downCircleOutlined: downCircleOutlined,
    downCircleTwoTone: downCircleTwoTone,
    downOutlined: downOutlined,
    downSquareFilled: downSquareFilled,
    downSquareOutlined: downSquareOutlined,
    downSquareTwoTone: downSquareTwoTone,
    downloadOutlined: downloadOutlined,
    dragOutlined: dragOutlined,
    dribbbleCircleFilled: dribbbleCircleFilled,
    dribbbleOutlined: dribbbleOutlined,
    dribbbleSquareFilled: dribbbleSquareFilled,
    dribbbleSquareOutlined: dribbbleSquareOutlined,
    dropboxCircleFilled: dropboxCircleFilled,
    dropboxOutlined: dropboxOutlined,
    dropboxSquareFilled: dropboxSquareFilled,
    editFilled: editFilled,
    editOutlined: editOutlined,
    editTwoTone: editTwoTone,
    ellipsisOutlined: ellipsisOutlined,
    enterOutlined: enterOutlined,
    environmentFilled: environmentFilled,
    environmentOutlined: environmentOutlined,
    environmentTwoTone: environmentTwoTone,
    euroCircleFilled: euroCircleFilled,
    euroCircleOutlined: euroCircleOutlined,
    euroCircleTwoTone: euroCircleTwoTone,
    euroOutlined: euroOutlined,
    euroTwoTone: euroTwoTone,
    exceptionOutlined: exceptionOutlined,
    exclamationCircleFilled: exclamationCircleFilled,
    exclamationCircleOutlined: exclamationCircleOutlined,
    exclamationCircleTwoTone: exclamationCircleTwoTone,
    exclamationOutlined: exclamationOutlined,
    expandAltOutlined: expandAltOutlined,
    expandOutlined: expandOutlined,
    experimentFilled: experimentFilled,
    experimentOutlined: experimentOutlined,
    experimentTwoTone: experimentTwoTone,
    exportOutlined: exportOutlined,
    eyeFilled: eyeFilled,
    eyeInvisibleFilled: eyeInvisibleFilled,
    eyeInvisibleOutlined: eyeInvisibleOutlined,
    eyeInvisibleTwoTone: eyeInvisibleTwoTone,
    eyeOutlined: eyeOutlined,
    eyeTwoTone: eyeTwoTone,
    facebookFilled: facebookFilled,
    facebookOutlined: facebookOutlined,
    fallOutlined: fallOutlined,
    fastBackwardFilled: fastBackwardFilled,
    fastBackwardOutlined: fastBackwardOutlined,
    fastForwardFilled: fastForwardFilled,
    fastForwardOutlined: fastForwardOutlined,
    fieldBinaryOutlined: fieldBinaryOutlined,
    fieldNumberOutlined: fieldNumberOutlined,
    fieldStringOutlined: fieldStringOutlined,
    fieldTimeOutlined: fieldTimeOutlined,
    fileAddFilled: fileAddFilled,
    fileAddOutlined: fileAddOutlined,
    fileAddTwoTone: fileAddTwoTone,
    fileDoneOutlined: fileDoneOutlined,
    fileExcelFilled: fileExcelFilled,
    fileExcelOutlined: fileExcelOutlined,
    fileExcelTwoTone: fileExcelTwoTone,
    fileExclamationFilled: fileExclamationFilled,
    fileExclamationOutlined: fileExclamationOutlined,
    fileExclamationTwoTone: fileExclamationTwoTone,
    fileFilled: fileFilled,
    fileGifOutlined: fileGifOutlined,
    fileImageFilled: fileImageFilled,
    fileImageOutlined: fileImageOutlined,
    fileImageTwoTone: fileImageTwoTone,
    fileJpgOutlined: fileJpgOutlined,
    fileMarkdownFilled: fileMarkdownFilled,
    fileMarkdownOutlined: fileMarkdownOutlined,
    fileMarkdownTwoTone: fileMarkdownTwoTone,
    fileOutlined: fileOutlined,
    filePdfFilled: filePdfFilled,
    filePdfOutlined: filePdfOutlined,
    filePdfTwoTone: filePdfTwoTone,
    filePptFilled: filePptFilled,
    filePptOutlined: filePptOutlined,
    filePptTwoTone: filePptTwoTone,
    fileProtectOutlined: fileProtectOutlined,
    fileSearchOutlined: fileSearchOutlined,
    fileSyncOutlined: fileSyncOutlined,
    fileTextFilled: fileTextFilled,
    fileTextOutlined: fileTextOutlined,
    fileTextTwoTone: fileTextTwoTone,
    fileTwoTone: fileTwoTone,
    fileUnknownFilled: fileUnknownFilled,
    fileUnknownOutlined: fileUnknownOutlined,
    fileUnknownTwoTone: fileUnknownTwoTone,
    fileWordFilled: fileWordFilled,
    fileWordOutlined: fileWordOutlined,
    fileWordTwoTone: fileWordTwoTone,
    fileZipFilled: fileZipFilled,
    fileZipOutlined: fileZipOutlined,
    fileZipTwoTone: fileZipTwoTone,
    filterFilled: filterFilled,
    filterOutlined: filterOutlined,
    filterTwoTone: filterTwoTone,
    fireFilled: fireFilled,
    fireOutlined: fireOutlined,
    fireTwoTone: fireTwoTone,
    flagFilled: flagFilled,
    flagOutlined: flagOutlined,
    flagTwoTone: flagTwoTone,
    folderAddFilled: folderAddFilled,
    folderAddOutlined: folderAddOutlined,
    folderAddTwoTone: folderAddTwoTone,
    folderFilled: folderFilled,
    folderOpenFilled: folderOpenFilled,
    folderOpenOutlined: folderOpenOutlined,
    folderOpenTwoTone: folderOpenTwoTone,
    folderOutlined: folderOutlined,
    folderTwoTone: folderTwoTone,
    folderViewOutlined: folderViewOutlined,
    fontColorsOutlined: fontColorsOutlined,
    fontSizeOutlined: fontSizeOutlined,
    forkOutlined: forkOutlined,
    formOutlined: formOutlined,
    formatPainterFilled: formatPainterFilled,
    formatPainterOutlined: formatPainterOutlined,
    forwardFilled: forwardFilled,
    forwardOutlined: forwardOutlined,
    frownFilled: frownFilled,
    frownOutlined: frownOutlined,
    frownTwoTone: frownTwoTone,
    fullscreenExitOutlined: fullscreenExitOutlined,
    fullscreenOutlined: fullscreenOutlined,
    functionOutlined: functionOutlined,
    fundFilled: fundFilled,
    fundOutlined: fundOutlined,
    fundProjectionScreenOutlined: fundProjectionScreenOutlined,
    fundTwoTone: fundTwoTone,
    fundViewOutlined: fundViewOutlined,
    funnelPlotFilled: funnelPlotFilled,
    funnelPlotOutlined: funnelPlotOutlined,
    funnelPlotTwoTone: funnelPlotTwoTone,
    gatewayOutlined: gatewayOutlined,
    gifOutlined: gifOutlined,
    giftFilled: giftFilled,
    giftOutlined: giftOutlined,
    giftTwoTone: giftTwoTone,
    githubFilled: githubFilled,
    githubOutlined: githubOutlined,
    gitlabFilled: gitlabFilled,
    gitlabOutlined: gitlabOutlined,
    globalOutlined: globalOutlined,
    goldFilled: goldFilled,
    goldOutlined: goldOutlined,
    goldTwoTone: goldTwoTone,
    goldenFilled: goldenFilled,
    googleCircleFilled: googleCircleFilled,
    googleOutlined: googleOutlined,
    googlePlusCircleFilled: googlePlusCircleFilled,
    googlePlusOutlined: googlePlusOutlined,
    googlePlusSquareFilled: googlePlusSquareFilled,
    googleSquareFilled: googleSquareFilled,
    groupOutlined: groupOutlined,
    hddFilled: hddFilled,
    hddOutlined: hddOutlined,
    hddTwoTone: hddTwoTone,
    heartFilled: heartFilled,
    heartOutlined: heartOutlined,
    heartTwoTone: heartTwoTone,
    heatMapOutlined: heatMapOutlined,
    highlightFilled: highlightFilled,
    highlightOutlined: highlightOutlined,
    highlightTwoTone: highlightTwoTone,
    historyOutlined: historyOutlined,
    homeFilled: homeFilled,
    homeOutlined: homeOutlined,
    homeTwoTone: homeTwoTone,
    hourglassFilled: hourglassFilled,
    hourglassOutlined: hourglassOutlined,
    hourglassTwoTone: hourglassTwoTone,
    html5Filled: html5Filled,
    html5Outlined: html5Outlined,
    html5TwoTone: html5TwoTone,
    idcardFilled: idcardFilled,
    idcardOutlined: idcardOutlined,
    idcardTwoTone: idcardTwoTone,
    ieCircleFilled: ieCircleFilled,
    ieOutlined: ieOutlined,
    ieSquareFilled: ieSquareFilled,
    importOutlined: importOutlined,
    inboxOutlined: inboxOutlined,
    infoCircleFilled: infoCircleFilled,
    infoCircleOutlined: infoCircleOutlined,
    infoCircleTwoTone: infoCircleTwoTone,
    infoOutlined: infoOutlined,
    insertRowAboveOutlined: insertRowAboveOutlined,
    insertRowBelowOutlined: insertRowBelowOutlined,
    insertRowLeftOutlined: insertRowLeftOutlined,
    insertRowRightOutlined: insertRowRightOutlined,
    instagramFilled: instagramFilled,
    instagramOutlined: instagramOutlined,
    insuranceFilled: insuranceFilled,
    insuranceOutlined: insuranceOutlined,
    insuranceTwoTone: insuranceTwoTone,
    interactionFilled: interactionFilled,
    interactionOutlined: interactionOutlined,
    interactionTwoTone: interactionTwoTone,
    issuesCloseOutlined: issuesCloseOutlined,
    italicOutlined: italicOutlined,
    keyOutlined: keyOutlined,
    laptopOutlined: laptopOutlined,
    layoutFilled: layoutFilled,
    layoutOutlined: layoutOutlined,
    layoutTwoTone: layoutTwoTone,
    leftCircleFilled: leftCircleFilled,
    leftCircleOutlined: leftCircleOutlined,
    leftCircleTwoTone: leftCircleTwoTone,
    leftOutlined: leftOutlined,
    leftSquareFilled: leftSquareFilled,
    leftSquareOutlined: leftSquareOutlined,
    leftSquareTwoTone: leftSquareTwoTone,
    likeFilled: likeFilled,
    likeOutlined: likeOutlined,
    likeTwoTone: likeTwoTone,
    lineChartOutlined: lineChartOutlined,
    lineHeightOutlined: lineHeightOutlined,
    lineOutlined: lineOutlined,
    linkOutlined: linkOutlined,
    linkedinFilled: linkedinFilled,
    linkedinOutlined: linkedinOutlined,
    loading3QuartersOutlined: loading3QuartersOutlined,
    loadingOutlined: loadingOutlined,
    lockFilled: lockFilled,
    lockOutlined: lockOutlined,
    lockTwoTone: lockTwoTone,
    loginOutlined: loginOutlined,
    logoutOutlined: logoutOutlined,
    macCommandFilled: macCommandFilled,
    macCommandOutlined: macCommandOutlined,
    mailFilled: mailFilled,
    mailOutlined: mailOutlined,
    mailTwoTone: mailTwoTone,
    manOutlined: manOutlined,
    medicineBoxFilled: medicineBoxFilled,
    medicineBoxOutlined: medicineBoxOutlined,
    medicineBoxTwoTone: medicineBoxTwoTone,
    mediumCircleFilled: mediumCircleFilled,
    mediumOutlined: mediumOutlined,
    mediumSquareFilled: mediumSquareFilled,
    mediumWorkmarkOutlined: mediumWorkmarkOutlined,
    mehFilled: mehFilled,
    mehOutlined: mehOutlined,
    mehTwoTone: mehTwoTone,
    menuFoldOutlined: menuFoldOutlined,
    menuOutlined: menuOutlined,
    menuUnfoldOutlined: menuUnfoldOutlined,
    mergeCellsOutlined: mergeCellsOutlined,
    messageFilled: messageFilled,
    messageOutlined: messageOutlined,
    messageTwoTone: messageTwoTone,
    minusCircleFilled: minusCircleFilled,
    minusCircleOutlined: minusCircleOutlined,
    minusCircleTwoTone: minusCircleTwoTone,
    minusOutlined: minusOutlined,
    minusSquareFilled: minusSquareFilled,
    minusSquareOutlined: minusSquareOutlined,
    minusSquareTwoTone: minusSquareTwoTone,
    mobileFilled: mobileFilled,
    mobileOutlined: mobileOutlined,
    mobileTwoTone: mobileTwoTone,
    moneyCollectFilled: moneyCollectFilled,
    moneyCollectOutlined: moneyCollectOutlined,
    moneyCollectTwoTone: moneyCollectTwoTone,
    monitorOutlined: monitorOutlined,
    moreOutlined: moreOutlined,
    nodeCollapseOutlined: nodeCollapseOutlined,
    nodeExpandOutlined: nodeExpandOutlined,
    nodeIndexOutlined: nodeIndexOutlined,
    notificationFilled: notificationFilled,
    notificationOutlined: notificationOutlined,
    notificationTwoTone: notificationTwoTone,
    numberOutlined: numberOutlined,
    oneToOneOutlined: oneToOneOutlined,
    orderedListOutlined: orderedListOutlined,
    paperClipOutlined: paperClipOutlined,
    partitionOutlined: partitionOutlined,
    pauseCircleFilled: pauseCircleFilled,
    pauseCircleOutlined: pauseCircleOutlined,
    pauseCircleTwoTone: pauseCircleTwoTone,
    pauseOutlined: pauseOutlined,
    payCircleFilled: payCircleFilled,
    payCircleOutlined: payCircleOutlined,
    percentageOutlined: percentageOutlined,
    phoneFilled: phoneFilled,
    phoneOutlined: phoneOutlined,
    phoneTwoTone: phoneTwoTone,
    picCenterOutlined: picCenterOutlined,
    picLeftOutlined: picLeftOutlined,
    picRightOutlined: picRightOutlined,
    pictureFilled: pictureFilled,
    pictureOutlined: pictureOutlined,
    pictureTwoTone: pictureTwoTone,
    pieChartFilled: pieChartFilled,
    pieChartOutlined: pieChartOutlined,
    pieChartTwoTone: pieChartTwoTone,
    playCircleFilled: playCircleFilled,
    playCircleOutlined: playCircleOutlined,
    playCircleTwoTone: playCircleTwoTone,
    playSquareFilled: playSquareFilled,
    playSquareOutlined: playSquareOutlined,
    playSquareTwoTone: playSquareTwoTone,
    plusCircleFilled: plusCircleFilled,
    plusCircleOutlined: plusCircleOutlined,
    plusCircleTwoTone: plusCircleTwoTone,
    plusOutlined: plusOutlined,
    plusSquareFilled: plusSquareFilled,
    plusSquareOutlined: plusSquareOutlined,
    plusSquareTwoTone: plusSquareTwoTone,
    poundCircleFilled: poundCircleFilled,
    poundCircleOutlined: poundCircleOutlined,
    poundCircleTwoTone: poundCircleTwoTone,
    poundOutlined: poundOutlined,
    poweroffOutlined: poweroffOutlined,
    printerFilled: printerFilled,
    printerOutlined: printerOutlined,
    printerTwoTone: printerTwoTone,
    profileFilled: profileFilled,
    profileOutlined: profileOutlined,
    profileTwoTone: profileTwoTone,
    projectFilled: projectFilled,
    projectOutlined: projectOutlined,
    projectTwoTone: projectTwoTone,
    propertySafetyFilled: propertySafetyFilled,
    propertySafetyOutlined: propertySafetyOutlined,
    propertySafetyTwoTone: propertySafetyTwoTone,
    pullRequestOutlined: pullRequestOutlined,
    pushpinFilled: pushpinFilled,
    pushpinOutlined: pushpinOutlined,
    pushpinTwoTone: pushpinTwoTone,
    qqCircleFilled: qqCircleFilled,
    qqOutlined: qqOutlined,
    qqSquareFilled: qqSquareFilled,
    qrcodeOutlined: qrcodeOutlined,
    questionCircleFilled: questionCircleFilled,
    questionCircleOutlined: questionCircleOutlined,
    questionCircleTwoTone: questionCircleTwoTone,
    questionOutlined: questionOutlined,
    radarChartOutlined: radarChartOutlined,
    radiusBottomleftOutlined: radiusBottomleftOutlined,
    radiusBottomrightOutlined: radiusBottomrightOutlined,
    radiusSettingOutlined: radiusSettingOutlined,
    radiusUpleftOutlined: radiusUpleftOutlined,
    radiusUprightOutlined: radiusUprightOutlined,
    readFilled: readFilled,
    readOutlined: readOutlined,
    reconciliationFilled: reconciliationFilled,
    reconciliationOutlined: reconciliationOutlined,
    reconciliationTwoTone: reconciliationTwoTone,
    redEnvelopeFilled: redEnvelopeFilled,
    redEnvelopeOutlined: redEnvelopeOutlined,
    redEnvelopeTwoTone: redEnvelopeTwoTone,
    redditCircleFilled: redditCircleFilled,
    redditOutlined: redditOutlined,
    redditSquareFilled: redditSquareFilled,
    redoOutlined: redoOutlined,
    reloadOutlined: reloadOutlined,
    restFilled: restFilled,
    restOutlined: restOutlined,
    restTwoTone: restTwoTone,
    retweetOutlined: retweetOutlined,
    rightCircleFilled: rightCircleFilled,
    rightCircleOutlined: rightCircleOutlined,
    rightCircleTwoTone: rightCircleTwoTone,
    rightOutlined: rightOutlined,
    rightSquareFilled: rightSquareFilled,
    rightSquareOutlined: rightSquareOutlined,
    rightSquareTwoTone: rightSquareTwoTone,
    riseOutlined: riseOutlined,
    robotFilled: robotFilled,
    robotOutlined: robotOutlined,
    rocketFilled: rocketFilled,
    rocketOutlined: rocketOutlined,
    rocketTwoTone: rocketTwoTone,
    rollbackOutlined: rollbackOutlined,
    rotateLeftOutlined: rotateLeftOutlined,
    rotateRightOutlined: rotateRightOutlined,
    safetyCertificateFilled: safetyCertificateFilled,
    safetyCertificateOutlined: safetyCertificateOutlined,
    safetyCertificateTwoTone: safetyCertificateTwoTone,
    safetyOutlined: safetyOutlined,
    saveFilled: saveFilled,
    saveOutlined: saveOutlined,
    saveTwoTone: saveTwoTone,
    scanOutlined: scanOutlined,
    scheduleFilled: scheduleFilled,
    scheduleOutlined: scheduleOutlined,
    scheduleTwoTone: scheduleTwoTone,
    scissorOutlined: scissorOutlined,
    searchOutlined: searchOutlined,
    securityScanFilled: securityScanFilled,
    securityScanOutlined: securityScanOutlined,
    securityScanTwoTone: securityScanTwoTone,
    selectOutlined: selectOutlined,
    sendOutlined: sendOutlined,
    settingFilled: settingFilled,
    settingOutlined: settingOutlined,
    settingTwoTone: settingTwoTone,
    shakeOutlined: shakeOutlined,
    shareAltOutlined: shareAltOutlined,
    shopFilled: shopFilled,
    shopOutlined: shopOutlined,
    shopTwoTone: shopTwoTone,
    shoppingCartOutlined: shoppingCartOutlined,
    shoppingFilled: shoppingFilled,
    shoppingOutlined: shoppingOutlined,
    shoppingTwoTone: shoppingTwoTone,
    shrinkOutlined: shrinkOutlined,
    signalFilled: signalFilled,
    sisternodeOutlined: sisternodeOutlined,
    sketchCircleFilled: sketchCircleFilled,
    sketchOutlined: sketchOutlined,
    sketchSquareFilled: sketchSquareFilled,
    skinFilled: skinFilled,
    skinOutlined: skinOutlined,
    skinTwoTone: skinTwoTone,
    skypeFilled: skypeFilled,
    skypeOutlined: skypeOutlined,
    slackCircleFilled: slackCircleFilled,
    slackOutlined: slackOutlined,
    slackSquareFilled: slackSquareFilled,
    slackSquareOutlined: slackSquareOutlined,
    slidersFilled: slidersFilled,
    slidersOutlined: slidersOutlined,
    slidersTwoTone: slidersTwoTone,
    smallDashOutlined: smallDashOutlined,
    smileFilled: smileFilled,
    smileOutlined: smileOutlined,
    smileTwoTone: smileTwoTone,
    snippetsFilled: snippetsFilled,
    snippetsOutlined: snippetsOutlined,
    snippetsTwoTone: snippetsTwoTone,
    solutionOutlined: solutionOutlined,
    sortAscendingOutlined: sortAscendingOutlined,
    sortDescendingOutlined: sortDescendingOutlined,
    soundFilled: soundFilled,
    soundOutlined: soundOutlined,
    soundTwoTone: soundTwoTone,
    splitCellsOutlined: splitCellsOutlined,
    starFilled: starFilled,
    starOutlined: starOutlined,
    starTwoTone: starTwoTone,
    stepBackwardFilled: stepBackwardFilled,
    stepBackwardOutlined: stepBackwardOutlined,
    stepForwardFilled: stepForwardFilled,
    stepForwardOutlined: stepForwardOutlined,
    stockOutlined: stockOutlined,
    stopFilled: stopFilled,
    stopOutlined: stopOutlined,
    stopTwoTone: stopTwoTone,
    strikethroughOutlined: strikethroughOutlined,
    subnodeOutlined: subnodeOutlined,
    swapLeftOutlined: swapLeftOutlined,
    swapOutlined: swapOutlined,
    swapRightOutlined: swapRightOutlined,
    switcherFilled: switcherFilled,
    switcherOutlined: switcherOutlined,
    switcherTwoTone: switcherTwoTone,
    syncOutlined: syncOutlined,
    tableOutlined: tableOutlined,
    tabletFilled: tabletFilled,
    tabletOutlined: tabletOutlined,
    tabletTwoTone: tabletTwoTone,
    tagFilled: tagFilled,
    tagOutlined: tagOutlined,
    tagTwoTone: tagTwoTone,
    tagsFilled: tagsFilled,
    tagsOutlined: tagsOutlined,
    tagsTwoTone: tagsTwoTone,
    taobaoCircleFilled: taobaoCircleFilled,
    taobaoCircleOutlined: taobaoCircleOutlined,
    taobaoOutlined: taobaoOutlined,
    taobaoSquareFilled: taobaoSquareFilled,
    teamOutlined: teamOutlined,
    thunderboltFilled: thunderboltFilled,
    thunderboltOutlined: thunderboltOutlined,
    thunderboltTwoTone: thunderboltTwoTone,
    toTopOutlined: toTopOutlined,
    toolFilled: toolFilled,
    toolOutlined: toolOutlined,
    toolTwoTone: toolTwoTone,
    trademarkCircleFilled: trademarkCircleFilled,
    trademarkCircleOutlined: trademarkCircleOutlined,
    trademarkCircleTwoTone: trademarkCircleTwoTone,
    trademarkOutlined: trademarkOutlined,
    transactionOutlined: transactionOutlined,
    translationOutlined: translationOutlined,
    trophyFilled: trophyFilled,
    trophyOutlined: trophyOutlined,
    trophyTwoTone: trophyTwoTone,
    twitterCircleFilled: twitterCircleFilled,
    twitterOutlined: twitterOutlined,
    twitterSquareFilled: twitterSquareFilled,
    underlineOutlined: underlineOutlined,
    undoOutlined: undoOutlined,
    ungroupOutlined: ungroupOutlined,
    unlockFilled: unlockFilled,
    unlockOutlined: unlockOutlined,
    unlockTwoTone: unlockTwoTone,
    unorderedListOutlined: unorderedListOutlined,
    upCircleFilled: upCircleFilled,
    upCircleOutlined: upCircleOutlined,
    upCircleTwoTone: upCircleTwoTone,
    upOutlined: upOutlined,
    upSquareFilled: upSquareFilled,
    upSquareOutlined: upSquareOutlined,
    upSquareTwoTone: upSquareTwoTone,
    uploadOutlined: uploadOutlined,
    usbFilled: usbFilled,
    usbOutlined: usbOutlined,
    usbTwoTone: usbTwoTone,
    userAddOutlined: userAddOutlined,
    userDeleteOutlined: userDeleteOutlined,
    userOutlined: userOutlined,
    userSwitchOutlined: userSwitchOutlined,
    usergroupAddOutlined: usergroupAddOutlined,
    usergroupDeleteOutlined: usergroupDeleteOutlined,
    verifiedOutlined: verifiedOutlined,
    verticalAlignBottomOutlined: verticalAlignBottomOutlined,
    verticalAlignMiddleOutlined: verticalAlignMiddleOutlined,
    verticalAlignTopOutlined: verticalAlignTopOutlined,
    verticalLeftOutlined: verticalLeftOutlined,
    verticalRightOutlined: verticalRightOutlined,
    videoCameraAddOutlined: videoCameraAddOutlined,
    videoCameraFilled: videoCameraFilled,
    videoCameraOutlined: videoCameraOutlined,
    videoCameraTwoTone: videoCameraTwoTone,
    walletFilled: walletFilled,
    walletOutlined: walletOutlined,
    walletTwoTone: walletTwoTone,
    warningFilled: warningFilled,
    warningOutlined: warningOutlined,
    warningTwoTone: warningTwoTone,
    wechatFilled: wechatFilled,
    wechatOutlined: wechatOutlined,
    weiboCircleFilled: weiboCircleFilled,
    weiboCircleOutlined: weiboCircleOutlined,
    weiboOutlined: weiboOutlined,
    weiboSquareFilled: weiboSquareFilled,
    weiboSquareOutlined: weiboSquareOutlined,
    whatsAppOutlined: whatsAppOutlined,
    wifiOutlined: wifiOutlined,
    windowsFilled: windowsFilled,
    windowsOutlined: windowsOutlined,
    womanOutlined: womanOutlined,
    yahooFilled: yahooFilled,
    yahooOutlined: yahooOutlined,
    youtubeFilled: youtubeFilled,
    youtubeOutlined: youtubeOutlined,
    yuqueFilled: yuqueFilled,
    yuqueOutlined: yuqueOutlined,
    zhihuCircleFilled: zhihuCircleFilled,
    zhihuOutlined: zhihuOutlined,
    zhihuSquareFilled: zhihuSquareFilled,
    zoomInOutlined: zoomInOutlined,
    zoomOutOutlined: zoomOutOutlined,
    "_icon": $foreign["_icon"]
};
