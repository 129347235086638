// Generated by purs version 0.13.6
"use strict";
var Control_Category = require("../Control.Category/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Global_Unsafe = require("../Global.Unsafe/index.js");
var Routing_Duplex_Types = require("../Routing.Duplex.Types/index.js");
var RoutePrinter = function (x) {
    return x;
};
var semigroupRoutePrinter = new Data_Semigroup.Semigroup(function (v) {
    return function (v1) {
        return function ($27) {
            return v1(v($27));
        };
    };
});
var put = function (str) {
    return function (state) {
        return {
            segments: Data_Array.snoc(state.segments)(str),
            params: state.params,
            hash: state.hash
        };
    };
};
var printPath = function (v) {
    var printSegments = function (v1) {
        if (v1.length === 1 && v1[0] === "") {
            return "/";
        };
        return Data_String_Common.joinWith("/")(Data_Functor.map(Data_Functor.functorArray)(Global_Unsafe.unsafeEncodeURIComponent)(v1));
    };
    var printParam = function (key) {
        return function (v1) {
            if (v1 === "") {
                return Global_Unsafe.unsafeEncodeURIComponent(key);
            };
            return Global_Unsafe.unsafeEncodeURIComponent(key) + ("=" + Global_Unsafe.unsafeEncodeURIComponent(v1));
        };
    };
    var printParams = function (v1) {
        if (v1.length === 0) {
            return "";
        };
        return "?" + Data_String_Common.joinWith("&")(Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.uncurry(printParam))(v1));
    };
    var printHash = function (v1) {
        if (v1 === "") {
            return "";
        };
        return "#" + v1;
    };
    return printSegments(v.segments) + (printParams(v.params) + printHash(v.hash));
};
var param = function (key) {
    return function (val) {
        return function (state) {
            return {
                segments: state.segments,
                params: Data_Array.cons(new Data_Tuple.Tuple(key, val))(state.params),
                hash: state.hash
            };
        };
    };
};
var newtypeRoutePrinter = new Data_Newtype.Newtype(function (n) {
    return n;
}, RoutePrinter);
var run = (function () {
    var $28 = Data_Function.applyFlipped(Routing_Duplex_Types.emptyRouteState);
    var $29 = Data_Newtype.unwrap(newtypeRoutePrinter);
    return function ($30) {
        return printPath($28($29($30)));
    };
})();
var monoidRoutePRinter = new Data_Monoid.Monoid(function () {
    return semigroupRoutePrinter;
}, Control_Category.identity(Control_Category.categoryFn));
var hash = function (h) {
    return function (v) {
        return {
            segments: v.segments,
            params: v.params,
            hash: h
        };
    };
};
var flag = function (key) {
    return function (val) {
        if (val) {
            return param(key)("");
        };
        if (Data_Boolean.otherwise) {
            return Data_Monoid.mempty(monoidRoutePRinter);
        };
        throw new Error("Failed pattern match at Routing.Duplex.Printer (line 37, column 1 - line 37, column 42): " + [ key.constructor.name, val.constructor.name ]);
    };
};
module.exports = {
    RoutePrinter: RoutePrinter,
    put: put,
    param: param,
    flag: flag,
    hash: hash,
    run: run,
    printPath: printPath,
    newtypeRoutePrinter: newtypeRoutePrinter,
    semigroupRoutePrinter: semigroupRoutePrinter,
    monoidRoutePRinter: monoidRoutePRinter
};
