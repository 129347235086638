// Generated by purs version 0.13.6
"use strict";
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Generic_Rep_Show = require("../Data.Generic.Rep.Show/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var ZRPC_ConsPrefix = require("../ZRPC.ConsPrefix/index.js");
var ZRPC_ZRPCRead = require("../ZRPC.ZRPCRead/index.js");
var ZRPC_ZRPCWrite = require("../ZRPC.ZRPCWrite/index.js");
var User = function (x) {
    return x;
};
var InternalErr = (function () {
    function InternalErr(value0) {
        this.value0 = value0;
    };
    InternalErr.create = function (value0) {
        return new InternalErr(value0);
    };
    return InternalErr;
})();
var Unauthorized = (function () {
    function Unauthorized(value0) {
        this.value0 = value0;
    };
    Unauthorized.create = function (value0) {
        return new Unauthorized(value0);
    };
    return Unauthorized;
})();
var IncompatibleVersion = (function () {
    function IncompatibleVersion() {

    };
    IncompatibleVersion.value = new IncompatibleVersion();
    return IncompatibleVersion;
})();
var BadRequest = (function () {
    function BadRequest(value0) {
        this.value0 = value0;
    };
    BadRequest.create = function (value0) {
        return new BadRequest(value0);
    };
    return BadRequest;
})();
var NotFound = (function () {
    function NotFound(value0) {
        this.value0 = value0;
    };
    NotFound.create = function (value0) {
        return new NotFound(value0);
    };
    return NotFound;
})();
var ReqLogData = function (x) {
    return x;
};
var Masked = (function () {
    function Masked(value0) {
        this.value0 = value0;
    };
    Masked.create = function (value0) {
        return new Masked(value0);
    };
    return Masked;
})();
var LRSuccess = (function () {
    function LRSuccess() {

    };
    LRSuccess.value = new LRSuccess();
    return LRSuccess;
})();
var LRError = (function () {
    function LRError(value0) {
        this.value0 = value0;
    };
    LRError.create = function (value0) {
        return new LRError(value0);
    };
    return LRError;
})();
var RpcLog = (function () {
    function RpcLog(value0) {
        this.value0 = value0;
    };
    RpcLog.create = function (value0) {
        return new RpcLog(value0);
    };
    return RpcLog;
})();
var UncaughtErrorLog = (function () {
    function UncaughtErrorLog(value0) {
        this.value0 = value0;
    };
    UncaughtErrorLog.create = function (value0) {
        return new UncaughtErrorLog(value0);
    };
    return UncaughtErrorLog;
})();
var Context = function (x) {
    return x;
};
var AuthToken = function (x) {
    return x;
};
var MalformedRes = (function () {
    function MalformedRes() {

    };
    MalformedRes.value = new MalformedRes();
    return MalformedRes;
})();
var FromServer = (function () {
    function FromServer(value0) {
        this.value0 = value0;
    };
    FromServer.create = function (value0) {
        return new FromServer(value0);
    };
    return FromServer;
})();
var zrpcWriteMasked = new ZRPC_ZRPCWrite.ZRPCWrite(function (v) {
    return "******";
});
var genericServerErr = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof InternalErr) {
        return new Data_Generic_Rep.Inl(x.value0);
    };
    if (x instanceof Unauthorized) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
    };
    if (x instanceof IncompatibleVersion) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    };
    if (x instanceof BadRequest) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
    };
    if (x instanceof NotFound) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0))));
    };
    throw new Error("Failed pattern match at ExpressRpc.Types (line 60, column 1 - line 60, column 58): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return new InternalErr(x.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return new Unauthorized(x.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return IncompatibleVersion.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
        return new BadRequest(x.value0.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr))) {
        return new NotFound(x.value0.value0.value0.value0);
    };
    throw new Error("Failed pattern match at ExpressRpc.Types (line 60, column 1 - line 60, column 58): " + [ x.constructor.name ]);
});
var showServerErr = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericServerErr)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "message";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
    return "InternalErr";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "message";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
    return "Unauthorized";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "IncompatibleVersion";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "message";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
    return "BadRequest";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "message";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
    return "NotFound";
}))))))));
var genericMasked = new Data_Generic_Rep.Generic(function (x) {
    return x.value0;
}, function (x) {
    return new Masked(x);
});
var genericLogs = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof RpcLog) {
        return new Data_Generic_Rep.Inl(x.value0);
    };
    if (x instanceof UncaughtErrorLog) {
        return new Data_Generic_Rep.Inr(x.value0);
    };
    throw new Error("Failed pattern match at ExpressRpc.Types (line 128, column 1 - line 128, column 48): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return new RpcLog(x.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr) {
        return new UncaughtErrorLog(x.value0);
    };
    throw new Error("Failed pattern match at ExpressRpc.Types (line 128, column 1 - line 128, column 48): " + [ x.constructor.name ]);
});
var genericLR = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof LRSuccess) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof LRError) {
        return new Data_Generic_Rep.Inr(x.value0);
    };
    throw new Error("Failed pattern match at ExpressRpc.Types (line 92, column 1 - line 92, column 51): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return LRSuccess.value;
    };
    if (x instanceof Data_Generic_Rep.Inr) {
        return new LRError(x.value0);
    };
    throw new Error("Failed pattern match at ExpressRpc.Types (line 92, column 1 - line 92, column 51): " + [ x.constructor.name ]);
});
var consPrefixServerErr = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "";
});
var zrpcReadServerErr = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixServerErr)(genericServerErr)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "InternalErr";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "message";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "Unauthorized";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "message";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "IncompatibleVersion";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "BadRequest";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "message";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "NotFound";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "message";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))))))));
var zrpcWriteServerErr = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixServerErr)(genericServerErr)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "InternalErr";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "message";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "Unauthorized";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "message";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "IncompatibleVersion";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "BadRequest";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "message";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "NotFound";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "message";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())()))))));
var consPrefixMasked = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "";
});
var zrpcReadMasked = function (dictZRPCRead) {
    return new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixMasked)(genericMasked)(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "Masked";
    }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "val";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(dictZRPCRead))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))));
};
var consPrefixLogs = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "";
});
var consPrefixLR = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "LR";
});
var zrpcWriteLR = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixLR)(genericLR)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "LRSuccess";
})))(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "LRError";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "error";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(zrpcWriteServerErr))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())));
var zrpcWriteLogs = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixLogs)(genericLogs)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "RpcLog";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "request";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "payload";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "service";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "requestId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "requestTime";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "response";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(zrpcWriteLR))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "sourceIp";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "url";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())())())())())(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "UncaughtErrorLog";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "error";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())));
module.exports = {
    AuthToken: AuthToken,
    User: User,
    Context: Context,
    ReqLogData: ReqLogData,
    MalformedRes: MalformedRes,
    FromServer: FromServer,
    InternalErr: InternalErr,
    Unauthorized: Unauthorized,
    IncompatibleVersion: IncompatibleVersion,
    BadRequest: BadRequest,
    NotFound: NotFound,
    Masked: Masked,
    LRSuccess: LRSuccess,
    LRError: LRError,
    RpcLog: RpcLog,
    UncaughtErrorLog: UncaughtErrorLog,
    genericServerErr: genericServerErr,
    consPrefixServerErr: consPrefixServerErr,
    zrpcWriteServerErr: zrpcWriteServerErr,
    zrpcReadServerErr: zrpcReadServerErr,
    showServerErr: showServerErr,
    genericMasked: genericMasked,
    consPrefixMasked: consPrefixMasked,
    zrpcReadMasked: zrpcReadMasked,
    zrpcWriteMasked: zrpcWriteMasked,
    genericLR: genericLR,
    consPrefixLR: consPrefixLR,
    zrpcWriteLR: zrpcWriteLR,
    genericLogs: genericLogs,
    consPrefixLogs: consPrefixLogs,
    zrpcWriteLogs: zrpcWriteLogs
};
