// Generated by purs version 0.13.6
"use strict";
var AdminUi_Antd_Button = require("../AdminUi.Antd.Button/index.js");
var AdminUi_Antd_Divider = require("../AdminUi.Antd.Divider/index.js");
var AdminUi_Antd_Form = require("../AdminUi.Antd.Form/index.js");
var AdminUi_Antd_Input = require("../AdminUi.Antd.Input/index.js");
var AdminUi_Antd_PropLits = require("../AdminUi.Antd.PropLits/index.js");
var AdminUi_Antd_Steps = require("../AdminUi.Antd.Steps/index.js");
var AdminUi_Antd_Typography = require("../AdminUi.Antd.Typography/index.js");
var AdminUi_BasicLayout = require("../AdminUi.BasicLayout/index.js");
var AdminUi_Colors = require("../AdminUi.Colors/index.js");
var AdminUi_OtpInput = require("../AdminUi.OtpInput/index.js");
var AdminUi_Spacer = require("../AdminUi.Spacer/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Effect = require("../Effect/index.js");
var Jss_ReactJss = require("../Jss.ReactJss/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Record = require("../Record/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var mkSiloInvitation = (function () {
    var useStyles = Jss_ReactJss.createUseStyles()()()({
        contentWrapper: Jss_ReactJss.style({
            maxWidth: "850px",
            margin: "auto"
        }),
        stepContentContainer: Jss_ReactJss.style({
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            maxWidth: "420px",
            margin: "auto"
        }),
        title: Jss_ReactJss.style({
            color: AdminUi_Colors.primary + " !important",
            textAlign: "center"
        }),
        submitButton: Jss_ReactJss.style({
            width: "100%"
        }),
        steps: Jss_ReactJss.style({
            width: 230,
            margin: "0 auto"
        }),
        centerText: Jss_ReactJss.style({
            textAlign: "center"
        }),
        summaryContainer: Jss_ReactJss.style({
            width: 280,
            alignSelf: "center"
        }),
        summaryVal: Jss_ReactJss.style({
            fontSize: 20
        }),
        summaryKey: Jss_ReactJss.style({
            marginBottom: "5px !important"
        })
    })([ {
        minWidth: 480,
        classes: {
            steps: Jss_ReactJss.style({
                width: "initial"
            })
        }
    } ]);
    var mkStepContent = React_Basic_Hooks.component()()()("StepContent")(function (v) {
        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.div()({
            className: v.css.stepContentContainer,
            children: [ AdminUi_Antd_Typography.title()({
                level: AdminUi_Antd_PropLits.four,
                className: v.css.title,
                children: v.title
            }), AdminUi_Antd_Typography.p()({
                className: v.css.centerText,
                children: v.subtitle
            }), AdminUi_Spacer.vspacer(4), React_Basic.fragment(v.content), (function () {
                var $9 = v.step < 3;
                if ($9) {
                    return AdminUi_Antd_Button.button()({
                        type: AdminUi_Antd_PropLits.primary,
                        className: v.css.submitButton,
                        loading: false,
                        children: React_Basic_DOM.text("Next"),
                        onClick: React_Basic_Events.handler_(v.setStep(v.step + 1 | 0))
                    });
                };
                return AdminUi_Antd_Button.button()({
                    type: AdminUi_Antd_PropLits.primary,
                    className: v.css.submitButton,
                    loading: false,
                    children: React_Basic_DOM.text("Proceed to Login")
                });
            })(), AdminUi_Spacer.vspacer(2), (function () {
                var $10 = v.step > 0 && v.step < 3;
                if ($10) {
                    return AdminUi_Antd_Button.button()({
                        type: AdminUi_Antd_PropLits.link,
                        className: v.css.submitButton,
                        loading: false,
                        children: React_Basic_DOM.text("Back"),
                        onClick: React_Basic_Events.handler_(v.setStep(v.step - 1 | 0))
                    });
                };
                return Data_Monoid.mempty(React_Basic.monoidJSX);
            })() ]
        }));
    });
    return function __do() {
        var blayout = AdminUi_BasicLayout.mkBasicLayout();
        var stepContent = mkStepContent();
        return React_Basic_Hooks.component()()()("SiloInvitation")(function (v) {
            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(0))(function (v1) {
                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState({
                    password: "",
                    confirmPassword: "",
                    mobileNumber: "",
                    otp: ""
                }))(function (v2) {
                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useStyles({}))(function (v3) {
                        var onChange = function (dictCons) {
                            return function (dictIsSymbol) {
                                return function (sp) {
                                    return React_Basic_DOM_Events.capture(React_Basic_DOM_Events.targetValue)(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(function (v4) {
                                        return v2.value1(function (s) {
                                            return Record.set(dictIsSymbol)()()(sp)(v4)(s);
                                        });
                                    }));
                                };
                            };
                        };
                        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic.element(blayout)({
                            content: [ React_Basic_DOM_Generated.div()({
                                className: v3.contentWrapper,
                                children: [ AdminUi_Antd_Steps.steps()({
                                    current: v1.value0,
                                    className: v3.steps,
                                    children: [ AdminUi_Antd_Steps.step()({
                                        title: React_Basic_DOM.text("Create your password")
                                    }), AdminUi_Antd_Steps.step()({
                                        title: React_Basic_DOM.text("Enter Mobile Number")
                                    }), AdminUi_Antd_Steps.step()({
                                        title: React_Basic_DOM.text("Verify Mobile Number")
                                    }) ]
                                }), AdminUi_Antd_Divider.divider()({}), (function () {
                                    if (v1.value0 === 0) {
                                        return React_Basic.element(stepContent)({
                                            step: v1.value0,
                                            setStep: function ($25) {
                                                return v1.value1(Data_Function["const"]($25));
                                            },
                                            css: v3,
                                            title: "Create your password",
                                            subtitle: "Hello Dustin! To get your ZAPGroup silo up and running, please create your password",
                                            content: [ AdminUi_Antd_Form.formItem()({
                                                label: "Create password",
                                                colon: false,
                                                children: AdminUi_Antd_Input.input()({
                                                    onChange: onChange()(new Data_Symbol.IsSymbol(function () {
                                                        return "password";
                                                    }))(Data_Symbol.SProxy.value),
                                                    value: v2.value0.password
                                                })
                                            }), AdminUi_Antd_Form.formItem()({
                                                label: "Confirm password",
                                                colon: false,
                                                children: AdminUi_Antd_Input.input()({
                                                    onChange: onChange()(new Data_Symbol.IsSymbol(function () {
                                                        return "confirmPassword";
                                                    }))(Data_Symbol.SProxy.value),
                                                    value: v2.value0.confirmPassword
                                                })
                                            }) ]
                                        });
                                    };
                                    if (v1.value0 === 1) {
                                        return React_Basic.element(stepContent)({
                                            step: v1.value0,
                                            setStep: function ($26) {
                                                return v1.value1(Data_Function["const"]($26));
                                            },
                                            css: v3,
                                            title: "Mobile Number",
                                            subtitle: "This will be used in case you need to recover your password and your account",
                                            content: [ AdminUi_Antd_Form.formItem()({
                                                label: "Enter your mobile number",
                                                colon: false,
                                                children: AdminUi_Antd_Input.input()({
                                                    onChange: onChange()(new Data_Symbol.IsSymbol(function () {
                                                        return "mobileNumber";
                                                    }))(Data_Symbol.SProxy.value),
                                                    value: v2.value0.mobileNumber
                                                })
                                            }) ]
                                        });
                                    };
                                    if (v1.value0 === 2) {
                                        return React_Basic.element(stepContent)({
                                            step: v1.value0,
                                            setStep: function ($27) {
                                                return v1.value1(Data_Function["const"]($27));
                                            },
                                            css: v3,
                                            title: "Verify your mobile number",
                                            subtitle: "Please enter the 4-digit OTP sent to 09170000000",
                                            content: [ AdminUi_Antd_Typography.p()({
                                                className: v3.centerText,
                                                children: "Didn't receive a text?"
                                            }), AdminUi_Antd_Button.button()({
                                                type: AdminUi_Antd_PropLits.link,
                                                size: AdminUi_Antd_PropLits.small,
                                                children: React_Basic_DOM.text("Resend OTP")
                                            }), AdminUi_Spacer.vspacer(8), AdminUi_OtpInput.otpInput({
                                                numInputs: 4,
                                                onChange: function (v4) {
                                                    return v2.value1(function (s) {
                                                        return {
                                                            password: s.password,
                                                            confirmPassword: s.confirmPassword,
                                                            mobileNumber: s.mobileNumber,
                                                            otp: v4
                                                        };
                                                    });
                                                },
                                                value: v2.value0.otp,
                                                hasErrored: true
                                            }), AdminUi_Spacer.vspacer(2), AdminUi_Antd_Typography.p()({
                                                type: AdminUi_Antd_PropLits.danger,
                                                className: v3.centerText,
                                                children: "Invalid OTP"
                                            }), AdminUi_Spacer.vspacer(6) ]
                                        });
                                    };
                                    return React_Basic.element(stepContent)({
                                        step: v1.value0,
                                        setStep: function ($28) {
                                            return v1.value1(Data_Function["const"]($28));
                                        },
                                        css: v3,
                                        title: "Congratulations",
                                        subtitle: "ZAP Group is now an official ZAP Silo. You are now ready to begin your loyalty program. Your account credentials are provided below.",
                                        content: [ React_Basic_DOM_Generated.div()({
                                            className: v3.summaryContainer,
                                            children: [ AdminUi_Antd_Typography.p()({
                                                className: v3.summaryKey,
                                                children: "Silo ID"
                                            }), AdminUi_Antd_Typography.p()({
                                                className: v3.summaryVal,
                                                strong: true,
                                                children: "58"
                                            }), AdminUi_Spacer.vspacer(2), AdminUi_Antd_Typography.p()({
                                                className: v3.summaryKey,
                                                children: "Silo Code"
                                            }), AdminUi_Antd_Typography.p()({
                                                className: v3.summaryVal,
                                                strong: true,
                                                children: "ZAPGroup"
                                            }), AdminUi_Spacer.vspacer(2), AdminUi_Antd_Typography.p()({
                                                className: v3.summaryKey,
                                                children: "Email"
                                            }), AdminUi_Antd_Typography.p()({
                                                className: v3.summaryVal,
                                                strong: true,
                                                children: "dustin@zap.com.ph"
                                            }), AdminUi_Spacer.vspacer(6) ]
                                        }) ]
                                    });
                                })() ]
                            }) ]
                        }));
                    });
                });
            });
        })();
    };
})();
module.exports = {
    mkSiloInvitation: mkSiloInvitation
};
