// Generated by purs version 0.13.6
"use strict";
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var AffV = function (x) {
    return x;
};
var semigroupAffV = function (dictSemigroup) {
    return Effect_Aff.semigroupAff(dictSemigroup);
};
var ntAffV = new Data_Newtype.Newtype(function (n) {
    return n;
}, AffV);
var runAffV = Data_Newtype.un(ntAffV)(AffV);
var monoidAffV = function (dictMonoid) {
    return Effect_Aff.monoidAff(dictMonoid);
};
var monadAffV = Effect_Aff.monadAff;
var mkAffV = function (aff) {
    return function (handler) {
        return Data_Newtype.wrap(ntAffV)(Data_Functor.mapFlipped(Effect_Aff.functorAff)(Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff)(aff))(function ($5) {
            return handler(Data_Either.hush($5));
        }));
    };
};
var functorAffV = Effect_Aff.functorAff;
var bindAffV = Effect_Aff.bindAff;
var applyAffV = Effect_Aff.applyAff;
var applicativeAffV = Effect_Aff.applicativeAff;
module.exports = {
    mkAffV: mkAffV,
    runAffV: runAffV,
    ntAffV: ntAffV,
    functorAffV: functorAffV,
    applicativeAffV: applicativeAffV,
    applyAffV: applyAffV,
    bindAffV: bindAffV,
    monadAffV: monadAffV,
    semigroupAffV: semigroupAffV,
    monoidAffV: monoidAffV
};
