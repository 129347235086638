// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var Effect_Uncurried = require("../Effect.Uncurried/index.js");
var React_Basic = require("../React.Basic/index.js");
var Untagged_Coercible = require("../Untagged.Coercible/index.js");
var modalWarning = function (dictCoercible) {
    var $6 = Effect_Uncurried.runEffectFn1($foreign["_modalWarning"]);
    var $7 = Untagged_Coercible.coerce();
    return function ($8) {
        return $6($7($8));
    };
};
var modalSuccess = function (dictCoercible) {
    var $9 = Effect_Uncurried.runEffectFn1($foreign["_modalSuccess"]);
    var $10 = Untagged_Coercible.coerce();
    return function ($11) {
        return $9($10($11));
    };
};
var modalInfo = function (dictCoercible) {
    var $12 = Effect_Uncurried.runEffectFn1($foreign["_modalInfo"]);
    var $13 = Untagged_Coercible.coerce();
    return function ($14) {
        return $12($13($14));
    };
};
var modalError = function (dictCoercible) {
    var $15 = Effect_Uncurried.runEffectFn1($foreign["_modalError"]);
    var $16 = Untagged_Coercible.coerce();
    return function ($17) {
        return $15($16($17));
    };
};
var modalConfirm = function (dictCoercible) {
    var $18 = Effect_Uncurried.runEffectFn1($foreign["_modalConfirm"]);
    var $19 = Untagged_Coercible.coerce();
    return function ($20) {
        return $18($19($20));
    };
};
var modal = function (dictCoercible) {
    var $21 = React_Basic.element($foreign["_modal"]);
    var $22 = Untagged_Coercible.coerce();
    return function ($23) {
        return $21($22($23));
    };
};
module.exports = {
    modal: modal,
    modalInfo: modalInfo,
    modalSuccess: modalSuccess,
    modalError: modalError,
    modalWarning: modalWarning,
    modalConfirm: modalConfirm,
    "_modal": $foreign["_modal"],
    "_modalInfo": $foreign["_modalInfo"],
    "_modalSuccess": $foreign["_modalSuccess"],
    "_modalError": $foreign["_modalError"],
    "_modalWarning": $foreign["_modalWarning"],
    "_modalConfirm": $foreign["_modalConfirm"]
};
