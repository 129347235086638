// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Reader_Class = require("../Control.Monad.Reader.Class/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var UseActionHandler = function (x) {
    return x;
};
var newtypeUseActionHandler = new Data_Newtype.Newtype(function (n) {
    return n;
}, UseActionHandler);
var mkUseActionHandler = function (dictMonadEffect) {
    return function (dictMonadAsk) {
        return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(Control_Monad_Reader_Class.ask(dictMonadAsk))(function (v) {
            return Control_Applicative.pure((dictMonadAsk.Monad0()).Applicative0())(React_Basic_Hooks_Internal.coerceHook(newtypeUseActionHandler)(React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useContext(v.router))(function (v1) {
                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useContext(v.modal))(function (v2) {
                    return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(function (v3) {
                        if (v3 instanceof AdminUi_AdminClient_Components_Types.GoTo) {
                            return v1.push(v3.value0);
                        };
                        if (v3 instanceof AdminUi_AdminClient_Components_Types.ShowModal) {
                            return v2.showModal(v3.value0);
                        };
                        if (v3 instanceof AdminUi_AdminClient_Components_Types.GoBack) {
                            return v1.goBack;
                        };
                        if (v3 instanceof AdminUi_AdminClient_Components_Types.AAModalAction) {
                            return v2.hideModal;
                        };
                        throw new Error("Failed pattern match at AdminUi.AdminClient.Hooks.UseActionHandler (line 34, column 13 - line 39, column 40): " + [ v3.constructor.name ]);
                    });
                });
            })));
        });
    };
};
var actionHandler = function (v) {
    return function (v1) {
        if (v1 instanceof AdminUi_AdminClient_Components_Types.GoTo) {
            return v.push(v1.value0);
        };
        if (v1 instanceof AdminUi_AdminClient_Components_Types.ShowModal) {
            return v.showModal(v1.value0);
        };
        if (v1 instanceof AdminUi_AdminClient_Components_Types.GoBack) {
            return v.goBack;
        };
        if (v1 instanceof AdminUi_AdminClient_Components_Types.AAModalAction) {
            return v.hideModal;
        };
        throw new Error("Failed pattern match at AdminUi.AdminClient.Hooks.UseActionHandler (line 49, column 56 - line 54, column 28): " + [ v1.constructor.name ]);
    };
};
module.exports = {
    UseActionHandler: UseActionHandler,
    mkUseActionHandler: mkUseActionHandler,
    actionHandler: actionHandler,
    newtypeUseActionHandler: newtypeUseActionHandler
};
