// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Colors = require("../AdminUi.AdminClient.Colors/index.js");
var AdminUi_AdminClient_Components_Utils = require("../AdminUi.AdminClient.Components.Utils/index.js");
var AdminUi_AdminClient_Hooks_UseAffUi = require("../AdminUi.AdminClient.Hooks.UseAffUi/index.js");
var AdminUi_Antd_PropLits = require("../AdminUi.Antd.PropLits/index.js");
var AdminUi_Antd_Skeleton = require("../AdminUi.Antd.Skeleton/index.js");
var AdminUi_Antd_Tag = require("../AdminUi.Antd.Tag/index.js");
var AdminUi_Antd_Typography = require("../AdminUi.Antd.Typography/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Jss_ReactJss = require("../Jss.ReactJss/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var Untagged_Coercible = require("../Untagged.Coercible/index.js");
var useTitleStyles = Jss_ReactJss.createUseStyles_()()({
    container: Jss_ReactJss.style({
        paddingBottom: "5px"
    }),
    title: Jss_ReactJss.style({
        margin: "0 !important"
    }),
    titleContent: Jss_ReactJss.style({
        display: "flex",
        alignItems: "center"
    }),
    tag: Jss_ReactJss.style({
        marginLeft: "10px"
    })
});
var useCaptionStyles = Jss_ReactJss.createUseStyles_()()({
    skeleton: Jss_ReactJss.style({
        width: "150px",
        "& .ant-skeleton-content .ant-skeleton-title": {
            margin: "5px 0px 10px"
        }
    })
});
var mkCaption = function (dictMonadEffect) {
    return AdminUi_AdminClient_Components_Utils.comp_(dictMonadEffect)()()()("Caption")(function (v) {
        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useCaptionStyles({}))(function (css) {
            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(AdminUi_AdminClient_Hooks_UseAffUi.useAffVUi({
                affV: v.caption,
                success: React_Basic_DOM.text,
                skeleton: AdminUi_Antd_Skeleton.skeleton()({
                    loading: true,
                    active: true,
                    avatar: false,
                    paragraph: false,
                    title: true,
                    className: css.skeleton
                })
            }))(function (jsx) {
                return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(jsx);
            });
        });
    });
};
var mkTitle = function (dictMonadEffect) {
    return Control_Bind.bind((dictMonadEffect.Monad0()).Bind1())(mkCaption(dictMonadEffect))(function (captionC) {
        return AdminUi_AdminClient_Components_Utils.comp_(dictMonadEffect)()()()("Title")(function (v) {
            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useTitleStyles({}))(function (css) {
                return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.div()({
                    className: css.container,
                    children: [ Data_Foldable.fold(Data_Foldable.foldableMaybe)(React_Basic.monoidJSX)(Data_Functor.map(Data_Maybe.functorMaybe)(React_Basic_DOM.text)(v.title.overline)), AdminUi_Antd_Typography.title()({
                        style: React_Basic_DOM_Internal.css({
                            margin: "0"
                        }),
                        className: css.title,
                        children: React_Basic_DOM_Generated.div()({
                            className: css.titleContent,
                            children: [ React_Basic_DOM.text(v.title.main), (function () {
                                if (v.title.tag instanceof Data_Maybe.Just) {
                                    return AdminUi_Antd_Tag.tag()({
                                        className: css.tag,
                                        color: AdminUi_AdminClient_Colors.getColor(v.title.tag.value0.color),
                                        children: v.title.tag.value0.text
                                    });
                                };
                                if (v.title.tag instanceof Data_Maybe.Nothing) {
                                    return Data_Monoid.mempty(React_Basic.monoidJSX);
                                };
                                throw new Error("Failed pattern match at AdminUi.AdminClient.Components.Title.Ui (line 74, column 25 - line 81, column 44): " + [ v.title.tag.constructor.name ]);
                            })() ]
                        }),
                        level: v.level
                    }), Data_Foldable.fold(Data_Foldable.foldableMaybe)(React_Basic.monoidJSX)(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(v.title.caption)(function (caption) {
                        return React_Basic.element(captionC)({
                            caption: caption
                        });
                    })) ]
                }));
            });
        });
    });
};
var mkPageTitle = function (dictMonadEffect) {
    return Control_Bind.bind((dictMonadEffect.Monad0()).Bind1())(mkTitle(dictMonadEffect))(function (titleComp) {
        return AdminUi_AdminClient_Components_Utils.comp_(dictMonadEffect)()()()("PageTitle")(function (v) {
            return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic.element(titleComp)({
                title: v.title,
                level: Untagged_Coercible.coerce()(AdminUi_Antd_PropLits.three)
            }));
        });
    });
};
var mkSectionTitle = function (dictMonadEffect) {
    return Control_Bind.bind((dictMonadEffect.Monad0()).Bind1())(mkTitle(dictMonadEffect))(function (titleComp) {
        return AdminUi_AdminClient_Components_Utils.comp_(dictMonadEffect)()()()("SectionTitle")(function (v) {
            return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic.element(titleComp)({
                title: v.title,
                level: Untagged_Coercible.coerce()(AdminUi_Antd_PropLits.four)
            }));
        });
    });
};
module.exports = {
    mkPageTitle: mkPageTitle,
    mkSectionTitle: mkSectionTitle
};
