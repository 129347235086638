// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_AppAction = require("../AdminUi.AdminClient.Components.AppAction/index.js");
var AdminUi_AdminClient_Components_Content = require("../AdminUi.AdminClient.Components.Content/index.js");
var AdminUi_AdminClient_Components_Content_ContentForm = require("../AdminUi.AdminClient.Components.Content.ContentForm/index.js");
var AdminUi_AdminClient_Components_Details = require("../AdminUi.AdminClient.Components.Details/index.js");
var AdminUi_AdminClient_Components_Modal = require("../AdminUi.AdminClient.Components.Modal/index.js");
var AdminUi_AdminClient_Components_Page = require("../AdminUi.AdminClient.Components.Page/index.js");
var AdminUi_AdminClient_Components_Title = require("../AdminUi.AdminClient.Components.Title/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var AdminUi_AdminClient_Components_ZText = require("../AdminUi.AdminClient.Components.ZText/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var branchEditInfo = function (brandName) {
    return function (branchName) {
        var title = AdminUi_AdminClient_Components_Title.title_("Edit Branch Information");
        var form = (function () {
            var confirm = AdminUi_AdminClient_Components_Modal.confirmModal("Confirm")(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.text_("Make the following changes to "))(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.bold(branchName))(AdminUi_AdminClient_Components_ZText.text_(" branch?"))))(AdminUi_AdminClient_Components_Content.details([ AdminUi_AdminClient_Components_Details.pair_("Branch Name")(AdminUi_AdminClient_Components_Details.text_("Makati")), AdminUi_AdminClient_Components_Details.pair_("Address")(AdminUi_AdminClient_Components_Details.text_("Second Floor, Eastwood Mall, Libis, Quezon City")), AdminUi_AdminClient_Components_Details.pair_("Landline No.")(AdminUi_AdminClient_Components_Details.text_("84506953")), AdminUi_AdminClient_Components_Details.pair_("Location")(AdminUi_AdminClient_Components_Details.dvMap({
                address: "Eastwood Mall, Libis, Quezon City",
                latLng: {
                    lat: 14.585145,
                    lng: 121.088407
                }
            })) ]));
            return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                details: new Data_Maybe.Just([ AdminUi_AdminClient_Components_Details.pair_("Current Branch Name")(AdminUi_AdminClient_Components_Details.text_(branchName)) ]),
                fields: [ AdminUi_AdminClient_Components_Content_ContentForm.formField("Landline No.")(AdminUi_AdminClient_Components_Content_ContentForm.textInput({
                    placeholder: "Enter Landline No."
                })), AdminUi_AdminClient_Components_Content_ContentForm.formField("Cellphone No.")(AdminUi_AdminClient_Components_Content_ContentForm.textInput({
                    placeholder: "Enter Cellphone No."
                })), AdminUi_AdminClient_Components_Content_ContentForm.formField("Address")(AdminUi_AdminClient_Components_Content_ContentForm.textInput({
                    placeholder: "Enter Address"
                })), AdminUi_AdminClient_Components_Content_ContentForm.formField("Location")(AdminUi_AdminClient_Components_Content_ContentForm.googlePlacesInput({
                    placeholder: "Search Location...",
                    defaultValue: new Data_Maybe.Just("Unit 407 JG Building, C. Raymundo Avenue, Rosario, Pasig, 1909 Metro Manila")
                })) ],
                submit: {
                    label: "Save Changes",
                    action: AdminUi_AdminClient_Components_AppAction.showModal(confirm)
                },
                cancel: AdminUi_AdminClient_Components_AppAction.goBack
            });
        })();
        return Control_Applicative.pure(Control_Monad_Reader_Trans.applicativeReaderT(Data_Identity.applicativeIdentity))(AdminUi_AdminClient_Components_Page.pageForm(Data_Maybe.Nothing.value)(title)(Data_Maybe.Nothing.value)(form));
    };
};
module.exports = {
    branchEditInfo: branchEditInfo
};
