// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Breakpoints = require("../AdminUi.AdminClient.Breakpoints/index.js");
var AdminUi_AdminClient_Colors = require("../AdminUi.AdminClient.Colors/index.js");
var AdminUi_AdminClient_Components_ActionGroup = require("../AdminUi.AdminClient.Components.ActionGroup/index.js");
var AdminUi_AdminClient_Components_Title_Ui = require("../AdminUi.AdminClient.Components.Title.Ui/index.js");
var AdminUi_AdminClient_Components_Utils = require("../AdminUi.AdminClient.Components.Utils/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Jss_ReactJss = require("../Jss.ReactJss/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var useStyles = Jss_ReactJss.createUseStyles()()()({
    header: Jss_ReactJss.style({
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column"
    }),
    wrapper: Jss_ReactJss.style({
        marginBottom: "25px",
        padding: "24px",
        background: AdminUi_AdminClient_Colors.getColor(AdminUi_AdminClient_Colors.White.value),
        borderRadius: "2px"
    })
})([ {
    minWidth: AdminUi_AdminClient_Breakpoints.sm,
    classes: {
        header: Jss_ReactJss.style({
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
        })
    }
} ]);
var mkSectionWrapper = function (dictMonadAsk) {
    return function (dictMonadEffect) {
        return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Components_Title_Ui.mkSectionTitle(dictMonadEffect))(function (sectionTitle) {
            return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Components_ActionGroup.mkActionGroup(dictMonadEffect)(dictMonadAsk))(function (actionGroup) {
                return AdminUi_AdminClient_Components_Utils.comp_(dictMonadEffect)()()()("SectionWrapper")(function (props) {
                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useStyles({}))(function (css) {
                        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.div()({
                            className: css.wrapper,
                            children: [ (function () {
                                if (props.title instanceof Data_Maybe.Nothing) {
                                    return Data_Monoid.mempty(React_Basic.monoidJSX);
                                };
                                if (props.title instanceof Data_Maybe.Just) {
                                    return React_Basic_DOM_Generated.div()({
                                        className: css.header,
                                        children: [ React_Basic.element(sectionTitle)({
                                            title: props.title.value0
                                        }), React_Basic.element(actionGroup)({
                                            actionGroup: props.actionGroup
                                        }) ]
                                    });
                                };
                                throw new Error("Failed pattern match at AdminUi.AdminClient.Components.SectionWrapper (line 42, column 15 - line 51, column 22): " + [ props.title.constructor.name ]);
                            })(), React_Basic_DOM_Generated.div()({
                                children: [ props.content ]
                            }) ]
                        }));
                    });
                });
            });
        });
    };
};
module.exports = {
    mkSectionWrapper: mkSectionWrapper
};
