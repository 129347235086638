// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Breakpoints = require("../AdminUi.AdminClient.Breakpoints/index.js");
var AdminUi_AdminClient_Colors = require("../AdminUi.AdminClient.Colors/index.js");
var AdminUi_AdminClient_Components_ActionGroup = require("../AdminUi.AdminClient.Components.ActionGroup/index.js");
var AdminUi_AdminClient_Components_BottomSection = require("../AdminUi.AdminClient.Components.BottomSection/index.js");
var AdminUi_AdminClient_Components_Breadcrumb = require("../AdminUi.AdminClient.Components.Breadcrumb/index.js");
var AdminUi_AdminClient_Components_Section = require("../AdminUi.AdminClient.Components.Section/index.js");
var AdminUi_AdminClient_Components_Title_Ui = require("../AdminUi.AdminClient.Components.Title.Ui/index.js");
var AdminUi_AdminClient_Components_Utils = require("../AdminUi.AdminClient.Components.Utils/index.js");
var AdminUi_Antd_Layout = require("../AdminUi.Antd.Layout/index.js");
var AdminUi_Spacer = require("../AdminUi.Spacer/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Reader_Class = require("../Control.Monad.Reader.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Show = require("../Data.Show/index.js");
var Jss_ReactJss = require("../Jss.ReactJss/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Routing_Duplex = require("../Routing.Duplex/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var useStyles = Jss_ReactJss.createUseStyles()()()({
    antdLayout: Jss_ReactJss.style({}),
    wrapper: Jss_ReactJss.style({}),
    headerGroup: Jss_ReactJss.style({
        display: "flex",
        flexDirection: "column"
    }),
    headerGroupTop: Jss_ReactJss.style({
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
    }),
    pageTitle: Jss_ReactJss.style({}),
    pageActionGroup: Jss_ReactJss.style({
        display: "flex",
        justifyContent: "flex-start"
    }),
    top: Jss_ReactJss.style({
        background: AdminUi_AdminClient_Colors.getColor(AdminUi_AdminClient_Colors.White.value),
        padding: "15px 25px 10px",
        display: "flex",
        flexDirection: "column"
    }),
    pageBody: Jss_ReactJss.style({
        padding: "25px 25px 0"
    }),
    pageHeader: Jss_ReactJss.style({})
})([ {
    minWidth: AdminUi_AdminClient_Breakpoints.sm,
    classes: {
        headerGroupTop: Jss_ReactJss.style({
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
        }),
        pageActionGroup: Jss_ReactJss.style({
            justifyContent: "flex-end"
        })
    }
} ]);
var mkPage = function (dictEq) {
    return function (dictMonadEffect) {
        return function (dictMonadAsk) {
            return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(Control_Monad_Reader_Class.ask(dictMonadAsk))(function (v) {
                return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Components_Title_Ui.mkPageTitle(dictMonadEffect))(function (pageTitle) {
                    return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Components_ActionGroup.mkActionGroup(dictMonadEffect)(dictMonadAsk))(function (actionGroup) {
                        return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Components_Section.mkSection(dictEq)(dictMonadAsk)(dictMonadEffect))(function (section) {
                            return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Components_BottomSection.mkBottomSection(dictEq)(dictMonadAsk)(dictMonadEffect))(function (bottomSection) {
                                return Control_Bind.bind((dictMonadAsk.Monad0()).Bind1())(AdminUi_AdminClient_Components_Breadcrumb.mkBreadcrumb(dictEq)(dictMonadEffect))(function (breadcrumb) {
                                    return AdminUi_AdminClient_Components_Utils.comp_(dictMonadEffect)()()()("Page")(function (v1) {
                                        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useStyles({}))(function (css) {
                                            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useContext(v.router))(function (v2) {
                                                var routeStr = Routing_Duplex.print(v1.routeDuplex)(v1.route);
                                                return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(AdminUi_Antd_Layout.layout()({
                                                    className: css.antdLayout,
                                                    children: [ AdminUi_Antd_Layout.content()({
                                                        className: css.wrapper,
                                                        children: [ React_Basic_DOM_Generated.div()({
                                                            className: css.top,
                                                            children: [ React_Basic.element(breadcrumb)({
                                                                route: v1.route,
                                                                push: v2.push,
                                                                routeDuplex: v1.routeDuplex,
                                                                overwrite: v1.page.breadcrumbOverwrite
                                                            }), AdminUi_Spacer.vspacer(3), React_Basic_DOM_Generated.div()({
                                                                className: css.headerGroup,
                                                                key: routeStr,
                                                                children: [ React_Basic_DOM_Generated.div()({
                                                                    className: css.headerGroupTop,
                                                                    children: [ React_Basic.element(pageTitle)({
                                                                        title: v1.page.title
                                                                    }), React_Basic_DOM_Generated.div()({
                                                                        className: css.pageActionGroup,
                                                                        children: [ React_Basic.element(actionGroup)({
                                                                            actionGroup: v1.page.actionGroup
                                                                        }) ]
                                                                    }) ]
                                                                }), React_Basic_DOM_Generated.div()({
                                                                    className: css.pageHeader,
                                                                    children: [  ]
                                                                }) ]
                                                            }) ]
                                                        }), React_Basic_DOM_Generated.div()({
                                                            className: css.pageBody,
                                                            key: routeStr,
                                                            children: Data_Array.snoc(Data_Array.mapWithIndex(function (idx) {
                                                                return function (s) {
                                                                    return React_Basic.keyed("section-" + Data_Show.show(Data_Show.showInt)(idx))(React_Basic.element(section)({
                                                                        section: s
                                                                    }));
                                                                };
                                                            })(v1.page.sections))(React_Basic.keyed("bottomsection-0")(React_Basic.element(bottomSection)({
                                                                section: v1.page.bottomSection
                                                            })))
                                                        }) ]
                                                    }) ]
                                                }));
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        };
    };
};
module.exports = {
    mkPage: mkPage,
    useStyles: useStyles
};
