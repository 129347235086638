// Generated by purs version 0.13.6
"use strict";
var Primary = (function () {
    function Primary() {

    };
    Primary.value = new Primary();
    return Primary;
})();
var Success = (function () {
    function Success() {

    };
    Success.value = new Success();
    return Success;
})();
var Warning = (function () {
    function Warning() {

    };
    Warning.value = new Warning();
    return Warning;
})();
var $$Error = (function () {
    function $$Error() {

    };
    $$Error.value = new $$Error();
    return $$Error;
})();
var White = (function () {
    function White() {

    };
    White.value = new White();
    return White;
})();
var Black = (function () {
    function Black() {

    };
    Black.value = new Black();
    return Black;
})();
var getColor = function (v) {
    if (v instanceof Primary) {
        return "#9a3a3a";
    };
    if (v instanceof Success) {
        return "#52c41a";
    };
    if (v instanceof Warning) {
        return "#faad14";
    };
    if (v instanceof $$Error) {
        return "#ff4d4f";
    };
    if (v instanceof White) {
        return "#fff";
    };
    if (v instanceof Black) {
        return "#212121";
    };
    throw new Error("Failed pattern match at AdminUi.AdminClient.Colors (line 12, column 12 - line 18, column 21): " + [ v.constructor.name ]);
};
module.exports = {
    Primary: Primary,
    Success: Success,
    Warning: Warning,
    "Error": $$Error,
    White: White,
    Black: Black,
    getColor: getColor
};
