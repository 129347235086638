// Generated by purs version 0.13.6
"use strict";
var AdminUi_AdminClient_Components_AppAction = require("../AdminUi.AdminClient.Components.AppAction/index.js");
var AdminUi_AdminClient_Components_Content_ContentForm = require("../AdminUi.AdminClient.Components.Content.ContentForm/index.js");
var AdminUi_AdminClient_Components_Details = require("../AdminUi.AdminClient.Components.Details/index.js");
var AdminUi_AdminClient_Components_Page = require("../AdminUi.AdminClient.Components.Page/index.js");
var AdminUi_AdminClient_Components_Title = require("../AdminUi.AdminClient.Components.Title/index.js");
var AdminUi_AdminClient_Components_Types = require("../AdminUi.AdminClient.Components.Types/index.js");
var AdminUi_AdminClient_Components_ZText = require("../AdminUi.AdminClient.Components.ZText/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Monad_Reader_Trans = require("../Control.Monad.Reader.Trans/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var branchRename = function (brandName) {
    return function (branchName) {
        var title = AdminUi_AdminClient_Components_Title.title_("Rename Brand");
        var form = Control_Applicative.pure(Effect_Aff.applicativeAff)({
            details: new Data_Maybe.Just([ AdminUi_AdminClient_Components_Details.pair_("Current Branch Name")(AdminUi_AdminClient_Components_Details.text_(branchName)) ]),
            fields: [ AdminUi_AdminClient_Components_Content_ContentForm.formField("New Branch Name")(AdminUi_AdminClient_Components_Content_ContentForm.textInput({
                placeholder: "Enter new branch name..."
            })) ],
            submit: {
                label: "Save Changes",
                action: AdminUi_AdminClient_Components_AppAction.showConfirmModal_("Confirm")(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.text_("Rename "))(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.bold(branchName))(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.text_(" branch to "))(Data_Semigroup.append(AdminUi_AdminClient_Components_Types.semigroupZText)(AdminUi_AdminClient_Components_ZText.bold("Libis" + "branch? "))(AdminUi_AdminClient_Components_ZText.text_("All previous transactions under " + (branchName + (" will be change to " + "Libis"))))))))
            },
            cancel: AdminUi_AdminClient_Components_AppAction.goBack
        });
        return Control_Applicative.pure(Control_Monad_Reader_Trans.applicativeReaderT(Data_Identity.applicativeIdentity))(AdminUi_AdminClient_Components_Page.pageForm(Data_Maybe.Nothing.value)(title)(Data_Maybe.Nothing.value)(form));
    };
};
module.exports = {
    branchRename: branchRename
};
